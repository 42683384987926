import React, { Component } from "react";
import "./App.css";
import Login from "./login";
import Dashboard from "./dashboard";
import Search from "./search";
import Profile from "./profile";
import PurchaseDetail from "./PurchaseDetail";
// accessed from request monitoring page, intended for fulfilling requests
import AllTranslations from "./allTranslations";
// accessed from accounts and user management, intended for searching all
// translations ever requested/completed on the site
import ViewAllTranslations from "./admin_pages/view_all_translations";
import EditPurchase from "./editPurchase";
import UserDetail from "./user_detail";
import AuctionCar from "./AuctionCar";
import m3Costs from "./m3Costs";
import NotifyParty from "./notifyParty";
import searchHistory from "./searchHistory";
import AuctionCarHistory from "./AuctionCarHistory";
import default_line_items from "./default_line_items";
import config_items from "./config_items";
import AllQuestions from "./AllQuestions";
// accessed from request monitoring page, intended for fulfilling requests
import AllBids from "./AllBids";
// accessed from accounts and user management, intended for searching all
// bids ever made on the site
import ViewAllBids from "./admin_pages/view_all_bids";
import NewsEdit from "./admin_views/newsEdit";
import Enroll from "./enroll";
import Balances from "./Balances";
import AllUsers from "./AllUsers";
import TranslationCounts from "./reports/translation_counts";
import ImageRequests from "./AllImageRequests";
import Requests from "./admin_pages/queues";
import Accounts from "./admin_pages/accounts";
import Manage from "./admin_pages/site_management";
import ShippingFees from "./shipping_fees";
import BidManager from "./BidManager";
import DepositBalance from "./DepositBalance";
import AccountManager from "./AccountManager";
import MyCars from "./MyCars";
import AllAlerts from "./AllAlerts";
import Copyright from "./Copyright";
import PurchaseQuiz from "./PurchaseQuiz";
import Footer from "./footer/Footer";
import MyStats from "./MyStats";
import Reports from "./admin_pages/reports";
import AuctionCarPrintable from "./auction_car_views/AuctionCarPrintable";

import { set_store } from "./settings";

import {
    HashRouter,
    Route
} from "react-router-dom";

// redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/rootReducer";

// CSS for react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import TaskManager from "./TaskManager";

const store = createStore(rootReducer);
// save the settings
set_store(store);

class App extends Component {
    render () {
        return (
            <Provider store={store}>
                <HashRouter>
                    <div>
                        <Route exact path="/" component={Login} />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/search" component={Search} />
                        <Route path="/history" component={searchHistory} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/requests" component={Requests} />
                        <Route path="/accounts" component={Accounts} />
                        <Route path="/manage" component={Manage} />
                        <Route path="/reports" component={Reports} />

                        <Route path="/purchase_detail/:purchase_id" component={PurchaseDetail} />
                        <Route path="/all_translations" component={AllTranslations} />
                        <Route path="/view_translations" component={ViewAllTranslations} />
                        <Route path="/editPurchase/:purchase_id" component={EditPurchase} />
                        <Route path="/user_detail/:user_id" component={UserDetail} />
                        <Route path="/auction_car/:auction_id" component={AuctionCar} />
                        <Route path="/print_auction_car/:auction_id" component={AuctionCarPrintable} />
                        <Route path="/auction_car_history/:auction_id" component={AuctionCarHistory} />
                        <Route path="/m3" component={m3Costs} />
                        <Route path="/shipping_fees" component={ShippingFees} />
                        <Route path="/notify" component={NotifyParty} />
                        <Route path="/default_line_items" component={default_line_items} />
                        <Route path="/config_items" component={config_items} />
                        <Route path="/question_list" component={AllQuestions} />
                        <Route path="/bid_list" component={AllBids} />
                        <Route path="/view_bids" component={ViewAllBids} />
                        <Route path="/editNews" component={NewsEdit} />
                        <Route path="/enroll" component={Enroll} />
                        <Route path="/balances" component={Balances} />
                        <Route path="/AllUsers" component={AllUsers} />
                        <Route path="/reports/translations" component={TranslationCounts} />
                        <Route path="/ImageRequests" component={ImageRequests} />
                        <Route path="/BidManager" component={BidManager} />
                        <Route path="/DepositBalance" component={DepositBalance} />
                        <Route path="/AccountManager" component={AccountManager} />
                        <Route path="/MyCars" component={MyCars} />
                        <Route path="/AllAlerts" component={AllAlerts} />
                        <Route path="/taskManager" component={TaskManager} />
                        <Route path="/copyright" component={Copyright} />
                        <Route path="/purchaseQuiz/:purchase_id" component={PurchaseQuiz} />
                        <Route path="/MyStats" component={MyStats} />
                        <Footer/>
                    </div>
                </HashRouter>
            </Provider>
        );
    }
}

export default App;
