
import { settings } from "../settings";

const alert = (state = settings.alert_defaultState, action) => {
    switch (action.type) {
    case "SET_MESSAGE":
        return {
            ...state,
            message: action.message
        };
    case "SET_MAINTENANCE":
        return {
            ...state,
            maintenance: action.message,
            maintenanceLoaded: true
        };
    case "CLEAR_MESSAGE":
        return {
            ...state,
            message: null
        };
    case "SET_POPUP": // note: there can only be one modal per screen. Do not use in a modal
        if (state.popup !== null)
        // add to the message
        {
            return {
                ...state,
                popup: state.popup + "<br />" + action.message
            };
        }
        return {
            ...state,
            popup: action.message
        };
    case "CLEAR_POPUP":
        return {
            ...state,
            popup: null
        };
    default:
        return state;
    }
};

export default alert;
