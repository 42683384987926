import { settings } from "../settings";

// actions for user that we are currently viewing (not current user - that is profile)

const fetch_fx = function fetch_line_items () {
    const url = settings.api_server + "/fx/";

    const result = fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                // throw new Error("Bad response from server");
                console.log("failure fetching fx");
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            // do the dispatch
            settings.store.dispatch(setFX(data));
        });
    return result;
};

const setFX = function (data) {
    return {
        type: "SET_FX",
        fx_rates: data
    };
};

export { fetch_fx };
