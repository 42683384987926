import React, { Component } from "react";

import { settings } from "../settings";
import { Button, ListGroup } from "react-bootstrap";
import Octicon, {Pin, Comment} from "@githubprimer/octicons-react";

import "./AlertsDisplay.css";

class AlertsDisplay extends Component {
    constructor() {
        super();
        this.clear = this.clear.bind(this);
        this.get = this.get.bind(this);
        this.state = {
            "alerts": []
        };
    }

    componentDidMount() {
        this.get();
    }

    clear() {
        const url = settings.api_server + "/alerts/clear";
        fetch(url, {
            credentials: "include",
            method: "GET"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    // it sends back the alerts
                    this.setState({
                        ...this.state,
                        alerts: data,
                        errorMessage: data.message,
                    });
                }.bind(this)
            );
    }

    get() {

        // update the search parameters
        const url = settings.api_server + "/alerts/";

        fetch(url, {
            credentials: "include",
            method: "GET",
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    // data is [{"message": "tests", "sticky": true}, ...]
                    this.setState({
                        ...this.state,
                        alerts: data,
                        errorMessage: data.message,
                    });
                }.bind(this)
            );
    }

    render() {
        function extractDate(datetime) {
            return datetime.slice(0, datetime.indexOf("T"));
        }

        // Move sticky alerts to the top by filtering the alerts into a sticky
        // and non-sticky array (preserves order within the resulting arrays)
        const stickies = this.state.alerts.filter(r => r.sticky)
            .map(r => <ListGroup.Item key={r.created_at} className="alertListItem">
                <Octicon icon={Pin}/> {extractDate(r.created_at)} &nbsp; {r.message}
            </ListGroup.Item>);
        const messages = this.state.alerts.filter(r => !r.sticky)
            .map(r => <ListGroup.Item key={r.created_at} className="alertListItem">
                <Octicon icon={Comment}/> {extractDate(r.created_at)} &nbsp; {r.message}
            </ListGroup.Item>);

        return (
            <div>
                <ListGroup variant="flush" className="alertList">
                    {stickies}
                    {messages}
                </ListGroup>
                <div style={{ textAlign: "right" }}>
                    <Button onClick={this.clear}>
                        Clear
                    </Button>
                </div>
            </div>
        );
    }
}

export default AlertsDisplay;
