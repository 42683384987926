/**
 * This component is for admins to view the quizzes completed for a given
 * purchase, and review the customer's answers.
 */

import {settings} from "../settings";
import React from "react";
import {Button, Modal, ListGroup} from "react-bootstrap";

import "../App.css";

export default function CarCompletedQuizzes({purchase_id}) {
    const [submissions, setSubmissions] = React.useState(null);
    const [fetchError, setFetchError] = React.useState(null);

    const [showModal, setShowModal] = React.useState(false);
    const [modalQuiz, setModalQuiz] = React.useState(null);

    // fetch quizzes
    React.useEffect(() => {
        const url = `${settings.api_server}/quiz/admin/${purchase_id}/completed`;
        // if component is unmounted or purchase changes before response
        // arrives, cancel setting the quiz/error.
        let cancelled = false;
        fetch (url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(`${resp.status} ${resp.statusText}`))
            .then(
                body => {
                    if (!cancelled) {
                        setSubmissions(body);
                        setFetchError(null);
                    }
                },
                reason => {
                    if (!cancelled) {
                        setSubmissions(null);
                        setFetchError("Error: " + reason);
                    }
                });
        return () => cancelled = true;
    }, [purchase_id]);

    let quizList = "None";
    if (fetchError != null) {
        quizList = fetchError;
    }
    else if (submissions != null && submissions.length > 0) {
        // show submission IDs if there are duplicate quiz names
        const quizNames = submissions.map(quiz => quiz.quiz_name).sort();
        let showIDs = false;
        for (let i = 1; i < quizNames.length; i++) {
            showIDs = showIDs || quizNames[i] == quizNames[i - 1];
        }
        // show quiz name (and possibly ID), and button to view answers
        quizList = <ul>
            {submissions.map(quiz => <li key={quiz.submission_id}>
                {quiz.quiz_name}
                {showIDs && " (submission ID: " + quiz.submission_id + ")"}
                &nbsp;
                <Button size="sm" variant="outline-success"
                    onClick={() => {setShowModal(true); setModalQuiz(quiz);}}
                >
                    View
                </Button>
            </li>)}
        </ul>;
    }
    return <>
        {quizList}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                    Completed quiz
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CompletedQuizView quiz={modalQuiz}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                    onClick={() => setShowModal(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}

function CompletedQuizView({quiz}) {
    if (quiz == null)
        return "No quiz selected";

    return <div>
        <p>
            <b>Quiz name: </b>{quiz.quiz_name}
            <br/>
            {/* Decided to not add vehicle/customer info because it's on the
                same page that this modal is activated from */}
            <b>Submission ID: </b>{quiz.submission_id}
        </p>
        <ListGroup variant="flush">
            {quiz.answers.map(q => {
                let chosenAnswer;
                let textAnswer = null;
                if (q.used_text_input) {
                    chosenAnswer = "(manual input)";
                    textAnswer = <ul>
                        {q.text_input_label && <li><b>Prompt: </b>{q.text_input_label}</li>}
                        <li><b>Customer's Response: </b>{q.answer_text}</li>
                    </ul>;
                }
                else {
                    chosenAnswer = q.answer_text;
                }
                let lineItem = null;
                if (q.adds_line_item) {
                    lineItem = <>
                        <b>Associated line item: </b>
                        {q.line_item_type} (¥{q.line_item_amount})
                    </>;
                }
                return <ListGroup.Item key={q.sub_ans_id}>
                    <b>Question: </b>{q.question_text}
                    <br/>
                    <b>Chosen answer: </b>{chosenAnswer}
                    {q.used_text_input && <><br/>{textAnswer}</>}
                    {q.adds_line_item && <><br/>{lineItem}</>}
                </ListGroup.Item>;
            })}
        </ListGroup>
    </div>;
}
