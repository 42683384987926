import moment from "moment-timezone";

import React, { Component } from "react";

import { connect } from "react-redux";

import { settings } from "./settings";

export const isToday = function (date) {
    // date is a AVTO date 2020-11-14 15:00:00
    const parsed = new Date(date);
    return moment(parsed).isSame(new Date(), "day");
};

export const isTomorrow = function (date) {
    const parsed = new Date(date);
    const tomorrow = moment(new Date());
    tomorrow.add(1, "days");
    return tomorrow.isSame(parsed, "day");
};

export const isBeforeToday = function (date) {
    const parsed = new Date(date);
    return moment(parsed).isBefore(new Date(), "day");
};

export const isTodayTZ = function (date) {
    const auctionTimeLocal = moment.tz(date, "Asia/Tokyo").tz(moment.tz.guess());
    return auctionTimeLocal.isSame(new Date(), "day");
};

export const isTomorrowTZ = function(date) {
    const auctionTimeLocal = moment.tz(date, "Asia/Tokyo").tz(moment.tz.guess());
    const tomorrow = moment(new Date());
    tomorrow.add(1, "days");
    return auctionTimeLocal.isSame(tomorrow, "day");
};

export const LastSeen = function (date) {
    const parsed = new Date(date);
    return moment(parsed).fromNow();
};

export const prettyDate = function (date) {
    const parsed = new Date(date);
    return moment(parsed).format("MMMM Do YYYY");
};

/**
 * Convert a date and time to a human-readable string.
 * @param date any value which can be parsed by moment.js
 * @param {boolean} compact if true, the month will be shortened to 3 letters
 * @param {boolean} seconds if true, seconds will be included in the time
 * @returns {string | undefined} the formatted date, or `undefined` if the date
 * could not be parsed.
 */
export const prettyDateTime = function (date, compact = false, seconds = true) {
    const parsed = moment(date);
    if (!parsed.isValid())
        return undefined;
    const monthFormat = compact ? "MMM" : "MMMM";
    const secondsFormat = seconds ? ":ss" : "";
    return parsed.format(`${monthFormat} Do YYYY HH:mm${secondsFormat}`);
};

export const LastSeenUTC = function (date) {
    return moment.tz(date, "UTC").fromNow();
};

export const displayString = function (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

/*
Info string is a key-value pair string.
Return an object with the contents.
"INFO":"Inspection: Aug. 2019, Rate ext: -, Rate int: A"
Expecting the value part of this column
*/
export const parseAVTOInfoString = function (str) {
    const parts = str.split(",");
    const obj = {};
    // add Rate ext stuff, so we can reliably call them.
    obj["Rate ext"] = null;
    obj["Rate int"] = null;
    for (let i = 0; i < parts.length; i++) {
        const kv = parts[i].split(":");
        if (kv.length === 2) { obj[kv[0].trim()] = kv[1].trim(); }
    }
    return obj;
};

export const makeVehicleGrade = function (gradeNumber, infoString) {
    const info = parseAVTOInfoString(infoString);
    let grade = "";
    if (!gradeNumber.isNaN()) {
        grade = grade + gradeNumber;

        // add the exterior grade
        grade = grade + info["Rate ext"] + info["Rate int"];
    }
    return grade;
};

export const allowEntities = (val) => {
    return <div dangerouslySetInnerHTML={{ __html: val }} />;
};
export const upDownArrow = () => {
    return allowEntities("&#8597;");
};

export const getRandomInt = (min, max, fromRandom = Math.random()) => {
    return Math.floor(fromRandom * (max - min + 1)) + min;
};

// translate a thing

class TranslateMakeComponent extends Component {
    render () {
    // lookup the word
        const clean = this.props.trans.filter(t => t.avto_name === this.props.make);

        if (clean.length > 0) { return (<span>{clean[0].clean_name}</span>); }
        return (<span>{this.props.make}</span>);
    }
}
/* TranslateMakeComponent.propTypes = {
  make: PropTypes.string.isRequired
} */
export const makeMapStateToProps = state => {
    return {
        trans: state.make_model_translation.make_translations
    };
};

export const TranslateMake = connect(makeMapStateToProps)(TranslateMakeComponent);

class TranslateModelComponent extends Component {
    render () {
    // lookup the word
        const clean = this.props.trans.filter(t => t.avto_name === this.props.model);

        if (clean.length > 0) { return (<span>{clean[0].clean_name}</span>); }
        return (<span>{this.props.model}</span>);
    }
}
/* TranslateModelComponent.propTypes = {
  model: PropTypes.string.isRequired
} */
export const modelMapStateToProps = state => {
    return {
        trans: state.make_model_translation.model_translations
    };
};
export const TranslateModel = connect(modelMapStateToProps)(TranslateModelComponent);

// from https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
function toTitleCase (str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
// for places where we can't use the component - see: option dropdowns
export const translateMakeF = function (word) {
    // get the whole state,
    const data = settings.store.getState();
    const makes = data.make_model_translation.make_translations;
    const clean = makes.filter(t => t.avto_name === word);
    if (clean.length > 0) { return clean[0].clean_name; }
    return toTitleCase(word);
};

export const translateModelF = function (word) {
    // get the whole state,
    const data = settings.store.getState();
    const models = data.make_model_translation.model_translations;
    const clean = models.filter(t => t.avto_name === word);
    if (clean.length > 0) { return clean[0].clean_name; }
    return toTitleCase(word);
};

export const encodeQueryString = function (params) {
    const keys = Object.keys(params);
    return keys.length
        ? "?" + keys
            .map(key => encodeURIComponent(key) +
                "=" + encodeURIComponent(params[key]))
            .join("&")
        : "";
};

export const days = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };
export const dayToNum = function (day) {
    if (day in days) { return days[day]; }
    return 0;
};

// https://stackoverflow.com/a/29829361/1457012
// for sorting arrays of dicts
export const alphabetically = function (ascending, key) {
    return function (left, right) {
        const a = left[key];
        const b = right[key];
        // equal items sort equally
        if (a === b) {
            return 0;
        }
        // nulls sort after anything else
        else if (a === null) {
            return 1;
        } else if (b === null) {
            return -1;
        }
        // otherwise, if we're ascending, lowest sorts first
        else if (ascending) {
            return a < b ? -1 : 1;
        }
        // if descending, highest sorts first
        else {
            return a < b ? 1 : -1;
        }
    };
};
