import React, { Component } from "react";

import Octicon, { Unverified } from "@githubprimer/octicons-react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

const odo_help = (
    <Tooltip id="tooltip">Odometer readings can be in miles, or kilometres, depending on make.</Tooltip>
);

export class OdoHelp extends Component {
    render () {
        return <OverlayTrigger placement="right-start" overlay={odo_help}><span><sup>[?]</sup></span></OverlayTrigger>;
    }
}

const title_help = (
    <Tooltip id="tooltip">If the titles match (case-sensitive), the line items will be grouped together.</Tooltip>
);

export class TitleHelp extends Component {
    render () {
        return <OverlayTrigger placement="right-start" overlay={title_help}><span><Octicon icon={Unverified} /></span></OverlayTrigger>;
    }
}
