
const car_detail_defaultState = {
    loading: true,
    purchase_modal_is_open: false,
    image_links: [],
    num_images: 0,
    last_car_update: 0,
    showAddNoteModal: false,
    addNoteText: "",
    car_info: null
};

const car_detail = (state = car_detail_defaultState, action) => {
    switch (action.type) {
    case "CLEAR_CAR_DETAIL":
        return {
            ...state,
            loading: true,
            car_info: null
        };
    case "SET_CAR_DETAIL":
        return {
            ...state,
            loading: false,
            car_info: { ...action.car_detail },
            last_car_update: (new Date()).getTime() // to trigger an update
        };
    case "OPEN_EDIT_PURCHASE_MODAL":
        return {
            ...state,
            purchase_modal_is_open: true
        };
    case "CLOSE_EDIT_PURCHASE_MODAL":
        return {
            ...state,
            purchase_modal_is_open: false
        };
    case "SET_CAR_IMAGE_LINKS": {
        // remove duplicates
        const unique = [...new Set(action.links)];
        return {
            ...state,
            image_links: unique,
            num_images: unique.length
        };
    }
    case "ADD_CAR_IMAGE_LINK":
        if (!(action.link in state.image_links)) {
            state.image_links.push(action.link);
        }
        return {
            ...state,
            num_images: state.image_links.length // for refreshing the data - changes to the list are not detected
        };
    case "TOGGLE_INCLUDE_CAR":
        /* type: 'TOGGLE_INCLUDE_CAR',
                        purchase_id: purchase_id,
                        invoice_id: invoice_id,
                        newSetting: newSetting */
        var invoice_data = state.car_info.invoices;
        for (let i = 0; i < invoice_data.length; i++) {
            if (invoice_data[i].invoice_id === action.invoice_id && invoice_data[i].purchase_id === action.purchase_id)
            // flip the boolean
            { invoice_data[i].include_car_details = action.newSetting; }
        }
        state.car_info.invoices = invoice_data;
        return {
            ...state,
            car_info: state.car_info,
            last_car_update: (new Date()).getTime() // to trigger an update
        };
    case "CAR_DETAIL_SINGLE_FIELD":
        var newState = { ...state };
        newState.car_info[action.field] = action.value;
        newState.last_car_update = (new Date()).getTime(); // to trigger an update
        return newState;
    case "CAR_NOTIFY_PARTY_UPDATE":{
        const newNotify = { ...state };

        newNotify.car_info.ship_notify = action.party.email;
        newNotify.car_info.notify_name = action.party.name;
        newNotify.car_info.notify_address = action.party.address;
        newNotify.car_info.notify_phone = action.party.phone;
        newNotify.car_info.notify_note = action.party.note;
        newNotify.last_car_update = (new Date()).getTime(); // to trigger an update
        return newNotify;
    }
    case "SHOW_ADD_NOTE_MODAL":
        return {
            ...state,
            showAddNoteModal: true
        };
    case "TOGGLE_ADD_NOTE_MODAL":
        return {
            ...state,
            showAddNoteModal: !state.showAddNoteModal
        };
    case "SET_ADD_NOTE_MODAL_TEXT":
        return {
            ...state,
            addNoteText: action.text
        };
    case "CLEAR_ADD_NOTE":
        return {
            ...state,
            showAddNoteModal: false,
            addNoteText: ""
        };
    case "COPY_PURCHASER_TO_LOGISTICS":{
        const copyPtoL = { ...state };

        copyPtoL.car_info.ship_notify = state.car_info.owner.username;
        copyPtoL.car_info.notify_name = state.car_info.owner.firstname + " " + state.car_info.owner.lastname;
        copyPtoL.car_info.notify_address = state.car_info.address + "\n" + state.car_info.city + "\n" + state.car_info.country;
        copyPtoL.car_info.notify_phone = state.car_info.phone_number;
        copyPtoL.last_car_update = (new Date()).getTime(); // to trigger an update
        return copyPtoL;
    }
    case "COPY_PURCHASER_TO_CONSIGNEE":
    {
        const copyCtoL = { ...state };

        copyCtoL.car_info.consignee_email = state.car_info.owner.username;
        copyCtoL.car_info.consignee_name = state.car_info.owner.firstname + " " + state.car_info.owner.lastname;
        copyCtoL.car_info.consignee_address = state.car_info.address + "\n" + state.car_info.city + "\n" + state.car_info.country;
        copyCtoL.car_info.consignee_phone = state.car_info.phone_number;
        copyCtoL.last_car_update = (new Date()).getTime(); // to trigger an update
        return copyCtoL;
    }
    case "COPY_PURCHASER_TO_EXPORTER":
    {
        const compiled_purchaser_data = [];

        // load this with any consignee data
        if (state.car_info.consignee_name !== "") { compiled_purchaser_data.push(state.car_info.owner.firstname + " " + state.car_info.owner.lastname); }
        if (state.car_info.consignee_address !== "") { compiled_purchaser_data.push(state.car_info.address); }
        if (state.car_info.city !== "") { compiled_purchaser_data.push(state.car_info.city); }
        if (state.car_info.country !== "") { compiled_purchaser_data.push(state.car_info.country); }
        if (state.car_info.consignee_email !== "") { compiled_purchaser_data.push(state.car_info.owner.username); }
        if (state.car_info.consignee_phone !== "") { compiled_purchaser_data.push(state.car_info.phone_number); }

        const all_data = { ...state };

        all_data.car_info.exporter_use = compiled_purchaser_data.join("\n");
        all_data.last_car_update = (new Date()).getTime(); // to trigger an update
        return {
            ...all_data
        };
    }
    case "COPY_CONSIGNEE_TO_NOTES":{
        const compiled_data = [];

        // load this with any consignee data
        if (state.car_info.consignee_name) { compiled_data.push(state.car_info.consignee_name); }
        if (state.car_info.consignee_address) { compiled_data.push(state.car_info.consignee_address); }
        if (state.car_info.consignee_email !== "") { compiled_data.push(state.car_info.consignee_email); }
        if (state.car_info.consignee_phone !== "") { compiled_data.push(state.car_info.consignee_phone); }

        return {
            ...state,
            exporter_use: compiled_data.join("\n"),
            last_car_update: (new Date()).getTime() // to trigger an update
        };
    }
    default:
        return state;
    }
};

export default car_detail;
