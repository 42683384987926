import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";

import FormControl from "react-bootstrap/Form";


// import { settings } from "../../settings";

import CountryChooser from "./CountryChooser";
import UserChooser from "./UserChooser";
import RoleChooser from "./RoleChooser";
import IsDealerChooser from "./IsDealerChooser";
import PortOfExport from "./PortOfExport";

var nextKey = 1;

class AlertFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { "key": nextKey, "deleted": false, "which": "Country" };
        this.options = {
            "Country": <CountryChooser which={this.props.which} changeFilter={this.props.changeFilter} />,
            "User": <UserChooser which={this.props.which} changeFilter={this.props.changeFilter} />,
            "Role": <RoleChooser which={this.props.which} changeFilter={this.props.changeFilter} />,
            "Is Dealer": <IsDealerChooser which={this.props.which} changeFilter={this.props.changeFilter} />,
            "Port of Export": <PortOfExport which={this.props.which} changeFilter={this.props.changeFilter} />
        };
        nextKey++;

        this.sendUpdate = this.sendUpdate.bind(this);
    }

    componentDidMount() {
        this.sendUpdate();
    }

    sendUpdate() {
        // this is a non-filter, really.
        // send nothing, and it will get filtered out.
        this.props.changeFilter(this.props.which, {});
    }

    render() {
        if (this.state.deleted)
            return null;

        let dropOptions = [];
        for (const [key,] of Object.entries(this.options)) {
            dropOptions.push(
                <option value={key}>{key}</option>
            );
        }

        return <Row key={this.state.key}>
            <Col md={2}>
                <FormControl
                    as="select"
                    placeholder="select"
                    onChange={(e) => this.setState({ ...this.state, "which": e.target.value })}
                    value={this.state.which}
                >
                    {dropOptions}
                </FormControl>
            </Col>
            <Col md={8}>
                {this.state.which === null ? <span>None chosen</span> : this.options[this.state.which]}
            </Col>
            <Col md={2}>
                <Button onClick={() => this.setState({ ...this.state, "deleted": true })}>delete</Button>
            </Col>
        </Row>;
    }
}

export default AlertFilter;