import { settings } from "../settings";
import { setMessage } from "./alert_actions";

import { fetch_invoice } from "./invoice_actions";
const fetchCarData = function (purchase_id) {
    const url = settings.api_server + "/purchaseDetail/" + purchase_id;

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            // data should be a list of bids (even if it's empty)

            settings.store.dispatch(setCarDetail(data));

            // load up the invoice data
            if (data.success) {
                data.invoices.map(inv => fetch_invoice(purchase_id, inv.invoice_id));
            }
        });
};

const sendCarDetails = function (purchase_id, invoice_id, newSetting) {
    // don't really need to url encode.
    const url = settings.api_server + "/invoice/admin/purchase/" + purchase_id + "/" + invoice_id + "/includeCar?include=" + newSetting;
    fetch(url, {
        credentials: "include",
        method: "POST",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            // data should be a list of line items (even if it's empty)
            if (!data.success) {
                settings.store.dispatch(setMessage("Error setting Form.Check"));
            } else {
                // flip the Form.Check
                const checkbox = document.getElementById("include_car_details_" + invoice_id);
                if (checkbox) {
                    checkbox.checked = newSetting;
                }
                else {
                    console.log("src/actions/car_detail_actions::sendCarDetails(): couldn't find checkbox to update");
                }
            }
        });
};

const setCarDetail = function (car_detail) {
    return {
        type: "SET_CAR_DETAIL",
        car_detail
    };
};

const clearCarDetail = function () {
    return {
        type: "CLEAR_CAR_DETAIL"
    };
};
const setCarImageLinks = function (list_of_links) {
    return {
        type: "SET_CAR_IMAGE_LINKS",
        links: list_of_links
    };
};

const addCarImageLink = function (newLink) {
    return {
        type: "ADD_CAR_IMAGE_LINK",
        link: newLink
    };
};
const openEditPurchaseModal = function () {
    return {
        type: "OPEN_EDIT_PURCHASE_MODAL"
    };
};
const closeEditPurchaseModal = function () {
    return {
        type: "CLOSE_EDIT_PURCHASE_MODAL"
    };
};

const setCarInclude = function (purchase_id, invoice_id, newSetting) {
    // send the update, manually update the invoice without reloading
    sendCarDetails(purchase_id, invoice_id, newSetting);
    return {
        type: "TOGGLE_INCLUDE_CAR",
        purchase_id,
        invoice_id,
        newSetting
    };
};

// Edit single field
const setCarYear = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "year",
        value: e.target.value
    };
};

const setCarExchange = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "exchange_rate",
        value: e.target.value
    };
};

const setCarMake = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "make",
        value: e.target.value
    };
};

const setCarModel = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "model",
        value: e.target.value
    };
};

const setCarChassisNum = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "chassis_num",
        value: e.target.value
    };
};

const setCarAmount = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "amount",
        value: e.target.value
    };
};

const setCarAddress = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "address",
        value: e.target.value
    };
};
const setCarAddressCity = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "city",
        value: e.target.value
    };
};
const setCarAddressCountry = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "country",
        value: e.target.value
    };
};

const setCarPhoneNumber = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "phone_number",
        value: e.target.value
    };
};

const setCarShipToCountry = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_to_country",
        value: e.target.value
    };
};

const setCarHeight = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "height",
        value: e.target.value
    };
};

const setCarWidth = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "width",
        value: e.target.value
    };
};

const setCarLength = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "length",
        value: e.target.value
    };
};

const setCarPort = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_port",
        value: e.target.value
    };
};

// setting the port with text, for chooing from the list
const setCarPortText = function (text) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_port",
        value: text
    };
};

const setCarShipConsigneeName = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "consignee_name",
        value: e.target.value
    };
};
const setCarShipConsigneeAddress = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "consignee_address",
        value: e.target.value
    };
};
const setCarShipConsigneePhone = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "consignee_phone",
        value: e.target.value
    };
};
const setCarShipConsigneeEmail = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "consignee_email",
        value: e.target.value
    };
};

const setCarShipMethod = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_method",
        value: e.target.value
    };
};

/**
 * Sets the account manager ID for the purchase. Does not update the name.
 * @param {number | null} id
 */
const setAcctManagerID = function (id) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "acct_manager",
        value: id
    };
};

/**
 * Sets the account manager name for the purchse. The account manager name is
 * for display only, and ignored by the backend.
 * @param {string} name
 */
const setAcctManagerName = function (name) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "acct_manager_name",
        value: name
    };
};

const setShipM3 = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_m3_cost",
        value: e.target.value
    };
};
const setShipM3Number = function (n) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_m3_cost",
        value: n
    };
};

const setBolsterFee = function (n) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "bolster_fee",
        value: n.target.value
    };
};

const setOceanFreight = function (n) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_m3_cost",
        value: n
    };
};

const setCarNotifyParty = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_notify",
        value: e.target.value
    };
};

const setCarNotifyPhone = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "notify_phone",
        value: e.target.value
    };
};

const setCarNotifyAddress = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "notify_address",
        value: e.target.value
    };
};

const setCarNotifyName = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "notify_name",
        value: e.target.value
    };
};
const setCarNotifyNote = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "notify_note",
        value: e.target.value
    };
};

const setCarPurchaseNote = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "purchase_note",
        value: e.target.value

    };
};

const setCarPaymentMethod = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "payment_method",
        value: e.target.value

    };
};

const setCarExportDate = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "export_date",
        value: e // uses a datepicker don't need to call target

    };
};

const setTotalCost = function (v) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "ship_m3_total_cost",
        value: v
    };
};

const setCarUserNote = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "user_note",
        value: e.target.value

    };
};

const setExporterUse = function (e) {
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: "exporter_use",
        value: e.target.value
    };
};

const setSingleField = function (key, value){
    return {
        type: "CAR_DETAIL_SINGLE_FIELD",
        field: key,
        value: value
    };
};

const setCarNotifyPartySelection = function (party) {
    return {
        type: "CAR_NOTIFY_PARTY_UPDATE",
        party

    };
};

const showAddNoteModal = function () {
    return {
        type: "SHOW_ADD_NOTE_MODAL"
    };
};
const toggleAddNoteModal = function () {
    return {
        type: "TOGGLE_ADD_NOTE_MODAL"
    };
};
const setAddNoteText = function (text) {
    return {
        type: "SET_ADD_NOTE_MODAL_TEXT",
        text
    };
};

const clearAddNote = function () {
    return {
        type: "CLEAR_ADD_NOTE"
    };
};

const copyPurchaserToLogistics = function () {
    return {
        type: "COPY_PURCHASER_TO_LOGISTICS"
    };
};

const copyPurchaserToConsignee = function () {
    return {
        type: "COPY_PURCHASER_TO_CONSIGNEE"
    };
};

const copyConsigneeToNotes = function () {
    return {
        type: "COPY_CONSIGNEE_TO_NOTES"
    };
};

const copyPurchaserToExporterUse = function () {
    return {
        type: "COPY_PURCHASER_TO_EXPORTER"
    };
};
export {
    setCarDetail,
    setCarImageLinks,
    addCarImageLink,
    openEditPurchaseModal,
    closeEditPurchaseModal,
    clearCarDetail,
    fetchCarData,
    setCarInclude,
    setCarYear,
    setCarExchange,
    setCarMake,
    setCarModel,
    setCarChassisNum,
    setCarAmount,
    setCarAddress,
    setCarAddressCity,
    setCarAddressCountry,
    setCarPhoneNumber,
    setCarShipToCountry,
    setCarHeight,
    setCarWidth,
    setCarLength,
    setCarShipConsigneeName,
    setCarShipConsigneeAddress,
    setCarShipConsigneePhone,
    setCarShipConsigneeEmail,
    setCarShipMethod,
    setAcctManagerID,
    setAcctManagerName,
    setShipM3,
    setCarNotifyParty,
    setCarNotifyPhone,
    setCarNotifyAddress,
    setCarNotifyName,
    setCarNotifyNote,
    setCarNotifyPartySelection,
    setCarPort,
    setCarPortText,
    setShipM3Number,
    setBolsterFee,
    setOceanFreight,
    setCarPurchaseNote,
    setCarUserNote,
    setCarExportDate,
    setCarPaymentMethod,
    showAddNoteModal,
    toggleAddNoteModal,
    setAddNoteText,
    clearAddNote,
    setTotalCost,
    setExporterUse,
    copyPurchaserToLogistics,
    copyPurchaserToConsignee,
    copyConsigneeToNotes,
    copyPurchaserToExporterUse,
    setSingleField
};
