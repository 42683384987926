import React, { Component } from "react";
import "./App.css";

import { connect } from "react-redux";

import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";

import BidList from "./dashboard-components/CurrentBids";
import WatchedCars from "./dashboard-components/watchedCars";
import CurrentPurchases from "./dashboard-components/LatestPurchases";
import AvailableTranslations from "./dashboard-components/AvailableTranslations";
import TranslationUserModal from "./user_views/translation_modal_user";
import GeneralLedgerList from "./ledger_views/generalLedger";

import ChangePassword from "./profile_page_widgets/change_passwd";
import Subscribed from "./profile_page_widgets/subscribe";
import UpdateAddress from "./profile_page_widgets/updateAddress";
import InvoiceLedger from "./ledger_views/invoiceLedger";

import {
    showPasswordChange,
    toggleAddressChange
} from "./actions/profile_actions";

import {
    Container,
    Card,
    ListGroup,
    Button,
    Accordion
} from "react-bootstrap";
import RecentPurchases from "./profile_page_widgets/RecentPurchases";
import CustomAccordionToggle from "./admin_views/user_admin_views/CustomAccordionToggle";

class Profile extends Component {
    constructor(props) {
        super(props);
    }

    // List of user information displayed on the page
    getUserInfo() {
        return (
            <Card.Body style={{padding: 0}}><ListGroup variant="flush">
                <ListGroup.Item>
                    <b>First name:</b> {this.props.firstname}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Last name:</b> {this.props.lastname}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Joined:</b> {this.props.profile.joined_on}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Purchases:</b> <RecentPurchases who={-1}/> 
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Balance:</b> {this.props.balance}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Translation requests:</b> {this.props.profile.translation_requests_available < 0 ? "Unlimited" : this.props.profile.translation_requests_available}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Bids available:</b> {this.props.profile.bids_available < 0 ? "Unlimited" : this.props.profile.bids_available}
                </ListGroup.Item>
                <ListGroup.Item className="whitespace">
                    <b>Address:</b>&nbsp;<Button size="sm" onClick={this.props.toggleAddressChange}>Update Address</Button><br />{this.props.profile.address} 
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Country:</b> {this.props.profile.ship_to_country}
                </ListGroup.Item>
                <ListGroup.Item>
                    <b>Phone Number:</b> {this.props.profile.phone_number}
                </ListGroup.Item>
                <Subscribed />
            </ListGroup></Card.Body>
        );
    }

    // Wrap any element in an accordion
    wrapInAccordion(title, element) {
        return (
            <Accordion defaultActiveKey={title}>
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey={title}>
                            {title}
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={title}>
                        {element}
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        );
    }

    render () {
        // give canned response if this is a guest
        if (this.props.profile.guest) {
            return (
                <Container className="wideContainer">
                    <PCANav currentPage="#/dashboard" />
                    <DashNav which="#/profile" />
                    <Container>
                        <div className="whiteTitle">
                            Profile
                        </div>
                        <Container className="whiteBackground dropShadow"
                            style={{"padding": "10px"}}>
                            Create an account to create a profile, add a deposit, and bid on cars!
                        </Container>
                    </Container>
                </Container>
            );
        }
        return (
            <Container className="wideContainer">
                <PCANav currentPage="#/dashboard"  />
                <DashNav which="#/profile" />
                <Container>
                    <div className="whiteTitle">
                        Profile
                    </div>
                    <Container className="whiteBackground dropShadow wideContainer"
                        style={{"paddingTop": "10px", "paddingBottom": "10px"}}>
                        
                        {this.wrapInAccordion("About Me", this.getUserInfo())}

                        {this.wrapInAccordion("Deposit Account Ledger", <GeneralLedgerList/>)}
                        
                        {this.wrapInAccordion("Invoice Account Ledger", <InvoiceLedger who={-1}/>)}
                        
                        {this.wrapInAccordion("Latest Bids", <>
                            <ListGroup.Item action href="#/BidManager">
                                List latest bids (open or closed)
                            </ListGroup.Item>
                            <BidList who={-1}/>
                        </>)}

                        {this.wrapInAccordion("Watched cars", <WatchedCars who={-1}/>)}

                        {this.wrapInAccordion("Latest Purchases", <CurrentPurchases who={-1}/>)}

                        {this.wrapInAccordion("Latest Translation Requests", <AvailableTranslations who={-1} useLink/>)}

                        {this.wrapInAccordion("Most Visited Cars", <p>List of most visited cars</p>)}
                    </Container>
                </Container>
                <TranslationUserModal />
                <ChangePassword />
                <UpdateAddress />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        firstname: state.profile.firstname,
        lastname: state.profile.lastname,
        isAdmin: state.profile.is_admin,
        balance: state.profile.balance,
        profile: state.profile
    };
};

const mapDispatchToProps = dispatch => ({
    showPasswordChange: () => dispatch(showPasswordChange()),
    toggleAddressChange: () => dispatch(toggleAddressChange())
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
