
import { settings } from "../settings";

const config_items = (state = settings.config_items_defaultState, action) => {
    switch (action.type) {
    case "SET_CONFIG_ITEMS":
        return {
            ...state,
            loaded: true,
            config_items: action.items
        };
    default:
        return state;
    }
};

export default config_items;
