import React, { Component } from "react";

import { Link } from "react-router-dom";

import moment from "moment-timezone";

import {
    Image
} from "react-bootstrap";

import {
    allowEntities,
    isToday
} from "../../functions";

import spaceNumbers from "../../widgets/spaceNumbers";

export default class ResultRow extends Component {
    constructor () {
        super();
        this.getImages = this.getImages.bind(this);
    }

    getImages (imageList) {
    // given a list of images. Ensure it is long enough
        const images = [];

        const style = { paddingRight: "5px" }; // space it out

        // remove duplicates
        const imageSplit = [...new Set(imageList)];
        if (imageSplit.length > 0) { images.push(<Image fluid style={style} key={imageSplit[0]} src={imageSplit[0]} />); }
        // moved to 1 image, too weak to pull this out.
        // if (imageSplit.length > 1)
        // bootstrap voodoo https://getbootstrap.com/docs/4.0/utilities/display/
        // hide second image on small displays
        //    images.push(<Image fluid className="d-none d-sm-none d-md-inline" style={style} key={imageSplit[1]} src={imageSplit[1]} />);
        return images;
    }

    render () {
        const result = this.props.car;
        const time_to_auction = moment.tz(result.auction_time, "Asia/Tokyo");
        let colour = "auctionOK"; // CSS class to use
        let dateDisplay = <span>{time_to_auction.fromNow()} <br /> {result.auction_time}  JST</span>;

        // always truncate time if it's 00:00:00
        if (time_to_auction.hour() === 0) {
            // we want it to day the range in days.
            // inpired from https://stackoverflow.com/questions/26201414/how-to-get-days-with-moment-js-fromnow-method
            const now = moment.tz("Asia/Tokyo");
            now.set({ h: 0, m: 0 });
            dateDisplay = <span>{time_to_auction.from(now)} <br /> {result.auction_time.substring(0, 10)}</span>;
        }
        if (time_to_auction.isAfter(moment().add(7, "days"))) {
            // is this date not really set?
            // Some auctions are marking it months out.
            // don't show the fake date, just show that it's far
            dateDisplay = <span>Date not set</span>;
        }

        if (time_to_auction.hour() === 0 && isToday(time_to_auction)) {
            /*
            if this is in the future, fine. But
            if it's today and the time is not set... mark it!
            */
            colour = "auctionTimeNotSet";
            // the 'time to' is inaccurate at this resolution. Lose it.
            dateDisplay = <span>{result.auction_time.substring(0, 10)}</span>;
        }
        // set the colour
        else if (time_to_auction.isBefore()) { // has this action passed?
            colour = "auctionPassed";
        } else if (time_to_auction.isBefore(moment().add(1, "hour"))) {
            colour = "auctionNear";
        }

        // if we have something more to show, show it.
        let more = null;
        if (typeof this.props.children !== "undefined") {
            more = <div>{this.props.children}</div>;
        }

        return <tr id={result.vehicle_id} key={result.vehicle_id} style={{cursor: "pointer"}}>
            {/* Auction Info */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.auction_house}<br/>{result.lot}
                </Link>
            </td>
            {/* Image */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {this.getImages(result.car_image_list)}
                </Link>
            </td>
            {/* Year */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.year}
                </Link>
            </td>
            {/* Chassis */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {allowEntities(result.chassis_code)}
                </Link>
            </td>
            {/* Make */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.make}
                </Link>
            </td>
            {/* Model */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.model}
                </Link>
            </td>
            {/* Submodel */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.submodel}
                </Link>
            </td>
            {/* Transmission */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.transmission}
                </Link>
            </td>
            {/* Engine */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.displacement}
                </Link>
            </td>
            {/* Grade */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {result.grade}
                </Link>
            </td>
            {/* Odometer */}
            <td>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {spaceNumbers(result.mileage * 1000) + " km"}
                </Link>
            </td>
            {/* Auction Time */}
            <td className={colour}>
                <Link target="_blank" to={"/auction_car/" + result.vehicle_id}
                    className="nostyle" rel="noreferrer">
                    {dateDisplay}
                </Link>
                {more}
            </td>
        </tr>;
    }
}
