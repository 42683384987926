import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    Modal
} from "react-bootstrap";

import { unshowTranslation } from "../actions/translation_actions";

class TranslationUserModal extends Component {
    constructor () {
        super();
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose () {
        this.props.unshowTranslation();
    }

    render () {
        let body = <p>Loading</p>;

        if (this.props.show_translation_id !== -1) {
            if (this.props.show_translation_data !== null) {
                body = <div>Translation:
                    {this.props.show_translation_data.main !== null && this.props.show_translation_data.main.trim() !== "" &&
                        <div>
                            <div className="translationheading">Main</div>
                            <div className="whitespace">{this.props.show_translation_data.main}</div>
                        </div>}
                    {this.props.show_translation_data.notes !== null && this.props.show_translation_data.notes.trim() !== "" &&
                        <div>
                            <div className="translationheading">Notes</div>
                            <div className="whitespace">{this.props.show_translation_data.notes}</div>
                        </div>}
                    {this.props.show_translation_data.sales_points !== null && this.props.show_translation_data.sales_points.trim() !== "" &&
                        <div>
                            <div className="translationheading">Sales points</div>
                            <div className="whitespace">{this.props.show_translation_data.sales_points}</div>
                        </div>}
                    {this.props.show_translation_data.report !== null && this.props.show_translation_data.report.trim() !== "" &&
                        <div>
                            <div className="translationheading">Report</div>
                            <div className="whitespace">{this.props.show_translation_data.report}</div>
                        </div>}
                    {this.props.show_translation_data.diagram_notes !== null && this.props.show_translation_data.diagram_notes.trim() !== "" &&
                        <div>
                            <div className="translationheading">Diagram notes</div>
                            <div className="whitespace">{this.props.show_translation_data.diagram_notes}</div>
                        </div>}
                    <Link to={"/auction_car/" + this.props.vehicle_id}>Go to the auction listing</Link>
                </div>;
            } else {
                body = <p>Translation text pending review</p>;
            }
        }

        return (
            <Modal show={this.props.show_translation} onHide={this.handleClose} onEntering={this.loadData}>
                <Modal.Header closeButton>
                    <Modal.Title>View Translation: Translation id {this.props.show_translation_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {body}
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        show_translation: state.translations.show_translation,
        show_translation_id: state.translations.show_translation_id,
        show_translation_data: state.translations.show_translation_data,
        vehicle_id: state.translations.show_translation_vehicle_id
    };
};

const mapDispatchToProps = dispatch => ({
    unshowTranslation: () => dispatch(unshowTranslation())
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationUserModal);
