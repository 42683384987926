import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col, Row,
    Alert,
    Button,
    Modal,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";

import { updateAmount, updateComment, clearBidData, showCreateConfirm } from "../../actions/user_bid_modal_actions";

import FxView from "../../widgets/FxView";
import InlandTransportInfo from "../../user_views/InlandTransportInfo";
import BidLimits from "../../dashboard-components/BidLimits";

class CreateBidModal extends Component {
    render () {
        return (
            <Modal size='lg' show={this.props.show} onHide={this.props.clearBidData}>
                <Modal.Header>Bid on this car</Modal.Header>
                <Modal.Body>
                    <BidLimits />
                    {this.props.message !== null && <Alert variant="info">{this.props.message}</Alert>}
            How much would you like to bid?

                    <FormGroup className="alignCentre" as={Row} controlId="amount" >
                        <FormLabel column={true} sm={2} md={2}>Amount JPY</FormLabel>
                        <Col sm={2} md={2}>
                            <FormControl className="rightAlignTextbox" placeholder="Amount" value={this.props.amount} onChange={this.props.updateAmount} />
                        </Col>
                        <Col sm={1} md={1}> x 1000JPY
                        </Col>
                        <Col sm={7} md={7}>
                            {/* Don't display when null or an empty string */}
                            {this.props.amount && <span>Approximately <FxView amount={this.props.amount} thousands={true} /></span>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="alignCentre" as={Row} controlId="comment">
                        <FormLabel column={true} sm={2} md={2} >Comment</FormLabel>
                        <Col sm={6} md={6}>
                            <FormControl placeholder="Optional" value={this.props.comment} onChange={this.props.updateComment} />
                        </Col>
                    </FormGroup>

            Reminder! Your bid amount is only PART of your invoice.  <a href={"https://www.pacificcoastjdm.com/price"}>See our fee structure.</a>
                    <InlandTransportInfo />
                </Modal.Body>
                <Modal.Footer>
                    {/* Don't allow proceeding further if amount is empty */}
                    <Button
                        disabled={!this.props.amount || this.props.fetching}
                        onClick={this.props.showCreateConfirm}
                    >
                        Place bid
                    </Button>
                    <Button
                        disabled={this.props.fetching}
                        onClick={this.props.clearBidData}
                    >
                        Close without bidding
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amount: state.user_bid_modal.amount,
        comment: state.user_bid_modal.comment,
        fetching: state.user_bid_modal.fetching,
        message: state.user_bid_modal.message,
        show: state.user_bid_modal.showCreate,

        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const mapDispatchToProps = dispatch => ({
    updateAmount: (e) => dispatch(updateAmount(e)),
    updateComment: (e) => dispatch(updateComment(e)),
    clearBidData: () => dispatch(clearBidData()), // for closing the modal
    showCreateConfirm: () => dispatch(showCreateConfirm())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBidModal);
