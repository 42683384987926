import React, {Component} from "react";
import {Container, Row, Col, Button, Card, Accordion} from "react-bootstrap";
import CustomAccordionToggle from "./CustomAccordionToggle";
import AddPurchase from "../AddPurchase";
import {connect} from "react-redux";
import { openDepositModal, openDepositWithdrawModal, openTransRequestModal, openAddBidAvailableModal } from "../../actions/edit_user_actions";
import EditUser from "../edit_user";
import EditDepositModal from "../EditDepositModal";
import AddTranslationRequests from "../AddTranslationRequests";
import RemoveDepositModal from "../RemoveDepositModal";
import AddBidsAvailable from "../AddBidsAvailable";
import { settings } from "../../settings";
import { update_view_profile } from "../../actions/user_actions";

/*
Required props:
user_id
topUp
*/
class ManageAccount extends Component {
    constructor(props) {
        super(props);

        this.toggleTranslationExempt = this.toggleTranslationExempt.bind(this);
        this.makeTranslationExempt = this.makeTranslationExempt.bind(this);
        this.makeNotTranslationExempt = this.makeNotTranslationExempt.bind(this);

        this.toggleBidExempt = this.toggleBidExempt.bind(this);
        this.makeBidExempt = this.makeBidExempt.bind(this);
        this.makeNotBidExempt = this.makeNotBidExempt.bind(this);
    }

    fetch_profile () {
        update_view_profile(this.props.user_id);
    }
    
    // bid exemption
    toggleBidExempt (method) {
        const url = settings.api_server + "/user/bid_exempt/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                // update to keep back and front end in sync
                this.fetch_profile();
            }.bind(this));
    }

    makeBidExempt () {
        this.toggleBidExempt("PUT");
    }

    makeNotBidExempt () {
        this.toggleBidExempt("DELETE");
    }

    // translation count exemption
    toggleTranslationExempt (method) {
        const url = settings.api_server + "/user/translation_exempt/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                // update to keep back and front end in sync
                this.fetch_profile();
            }.bind(this));
    }

    makeTranslationExempt () {
        this.toggleTranslationExempt("PUT");
    }

    makeNotTranslationExempt () {
        this.toggleTranslationExempt("DELETE");
    }

    getTranslationRequests() {
        const profile = this.props.user.profile;
        if (profile.translation_requests_available < 0) {
            return <span>
                <b>Number of Translation Requests:</b> unlimited
                <br/>
                <Button size="sm" onClick={this.makeNotTranslationExempt}>
                    Remove translation request exemption
                </Button>
            </span>;
        }
        else {
            return <span>
                <b>Number of Translation Requests:</b> {profile.translation_requests_available}
                <br/>
                <Button size="sm" onClick={this.props.openTransRequestModal}>
                    ± Add/remove translation request
                </Button>
                &nbsp;
                <Button size="sm" onClick={this.makeTranslationExempt}>
                    Make exempt to translation count
                </Button>
            </span>;
        }
    }

    getBidRequests() {
        const profile = this.props.user.profile;

        if (profile.bids_available < 0) {
            return <span>
                <b>Number of Bid Requests:</b> unlimited
                <br/>
                <Button size="sm" onClick={this.makeNotBidExempt}>
                    Remove bid exemption
                </Button>
            </span>;
        }
        else {
            return <span>
                <b>Number of Bid Requests:</b> {profile.bids_available}
                <br/>
                <Button size="sm" onClick={this.props.openAddBidAvailableModal}>
                    ± Add/remove Bids
                </Button>
                &nbsp;
                <Button size="sm" onClick={this.makeBidExempt}>
                    Make exempt to bid count
                </Button>
            </span>;
        }   
    }

    render() {
        if (!this.props.profile_loaded)
        {
            return <Accordion defaultActiveKey="manageAccount">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="manageAccount">
                            Loading...
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="manageAccount">
                        <Card.Body>
                            Loading...
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>;
        }

        return (
            <Accordion defaultActiveKey="manageAccount">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="manageAccount">
                            Manage
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="manageAccount">
                        <Container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                            <Row style={{marginBottom: "5px"}}>
                                <Col>
                                    <Button size="small" onClick={this.props.topUp}>
                                        Top up requests
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                {this.getTranslationRequests()}
                            </Row>
                            <Row>
                                {this.getBidRequests()}
                            </Row>
                            <Row><br/></Row>
                            <Row style={{marginBottom: "5px"}}>
                                <Col>
                                    <Button size="small" onClick={this.props.openDepositModal}>Add deposit</Button>
                                    &nbsp;
                                    <Button size="small" onClick={this.props.openDepositWithdrawModal}>Remove/withdraw deposit</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AddPurchase who={this.props.user_id} />
                                </Col>
                            </Row>
                        </Container>
                    </Accordion.Collapse>
                </Container>
                <EditUser/>
                <EditDepositModal/>
                <AddTranslationRequests/>
                <AddBidsAvailable/>
                <RemoveDepositModal/>
            </Accordion>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_founder_admin: state.profile.is_founder_admin,
        user_id: state.user.user_id,
        profile_loaded: state.user.profile_loaded,
        user: state.user,
        lastUpdate: state.user.lastUpdate // to detect changes
    };
};

const mapDispatchToProps = dispatch => ({
    openDepositModal: () => dispatch(openDepositModal()),
    openDepositWithdrawModal: () => dispatch(openDepositWithdrawModal()),
    openTransRequestModal: () => dispatch(openTransRequestModal()),
    openAddBidAvailableModal: () => dispatch(openAddBidAvailableModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
