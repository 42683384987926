import React, { Component } from "react";
import "../App.css";
import "../user_views/ProfileTable.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Table,
    Button
} from "react-bootstrap";

import { settings } from "../settings";
import { setBidList } from "../actions/profile_actions";
import { openBidModal } from "../actions/bid_actions";
import BidCloseModal from "../admin_views/BidCloseModal";

class CurrentBids extends Component {
    constructor (props) {
        super(props);
        this.state = { loaded: false, bids: [], loadAll: false };
        this.showBidModal = this.showBidModal.bind(this);
        this.updateData = this.updateData.bind(this);
        this.doLoadAll = this.doLoadAll.bind(this);
        this.filterBid = this.filterBid.bind(this);
    }

    componentDidUpdate (oldProps) {
        if (this.props.who !== oldProps.who) { this.updateData(); }
        // edge-case that this componentloaded before the admin status was set
        if (oldProps.is_admin !== this.props.is_admin) { this.updateData(); }
    }

    componentDidMount () {
        this.updateData();
    }

    showBidModal (e, bid_id) {
        e.stopPropagation();
        this.props.openBidModal(bid_id);
    }

    doLoadAll () {
        this.setState({ ...this.state, loaded: false, loadAll: true }, this.updateData);
    }

    updateData () {
    /*
        -1 gets current user.
        number gets specific user (admin-only)
        */
        let url = settings.api_server + "/bids/current";
        if (this.state.loadAll) {
            url = settings.api_server + "/bids/all";
        }

        // this is protected on the back-end too.
        if (this.props.isAdmin && this.props.who >= 0) {
            url = url + "/" + this.props.who;
        }

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setBidList(data);
            }.bind(this));
    }

    filterBid (bid) {
        // convert to lowercase if it exists, otherwise use empty string
        const filter = this.props.filter?.toLowerCase() ?? "";
        const desc = bid.description?.toLowerCase() ?? "";
        const auction = bid.auction_name?.toLowerCase() ?? "";
        return !filter
            || (desc && desc.indexOf(filter) >= 0)
            || (auction && auction.indexOf(filter) >= 0);
    }

    render () {
        // render differently if we're an admin
        // admins can click, see modal
        function makeRow (x) {
            let won = null;
            if (x.won !== null) {
                if (x.won) {
                    won = "Win";
                } else {
                    won = "Lost";
                }
            }
            return <tr
                key={x.bid_id}
                onClick={() => window.location.href = "#/auction_car/" + x.atvo_id}
                className="theHand"
            >
                <td>{x.bid_datetime}</td>
                <td>{x.auction_name}</td>
                <td>{x.auction_number}</td>
                <td>{x.description}</td>

                <td>{x.amount} JPY</td>
                <td>{x.open ? "Open" : "Completed"}</td>
                <td>{x.sold_for !== null ? x.sold_for : null}</td>
                <td>
                    {won}
                    {this.props.isAdmin && won !== null && <br />} {/* put this on a newline if there is already something here */}
                    {this.props.isAdmin && <Button onClick={(e) => this.showBidModal(e, x.bid_id)}>View bid</Button>}
                </td>
            </tr>;
        }
        const localMakeRow = makeRow.bind(this); // clears linter warning.
        
        let bids;

        if (!this.props.bidsLoaded) {
            bids = [<tr key={0}><td colSpan={5}>Loading....</td></tr>];
        }
        else if (this.props.bidList.length === 0) {
            bids = [<tr key={0}><td colSpan={5}>No bids</td></tr>];
        }
        else {
            bids = this.props.bidList
                .filter(this.filterBid)
                .map(x => localMakeRow(x, this.props.isAdmin));
        }

        return (
            <Table responsive size="sm" className="profileTable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Auction name</th>
                        <th>Auction number</th>
                        <th>Car name</th>
                        <th>Bid amount</th>
                        <th>Status</th>
                        <th>Sold for</th>
                        <th>Win/Lose</th>
                    </tr>
                </thead>
                <tbody>
                    {bids}
                </tbody>
                {
                    /* load all  button, only show if we haven't loaded all of them, and there is 10 (edge case is complex without back-end update) */
                    this.props.bidsLoaded && !this.state.loadAll && this.props.bidList.length === 10 && <Button onClick={this.doLoadAll}>Load all bids</Button>
                }
                <BidCloseModal updateCB={this.updateData} />
            </Table>
        );
    }
}

CurrentBids.propTypes = {
    who: PropTypes.number,
    filter: PropTypes.string
};

CurrentBids.defaultProps = {
    who: -1, // means current user. Pass user id for that user (admin-only)
    filter: ""
};

const mapStateToProps = state => {
    return {
        bidList: state.profile.bidList,
        bidsLoaded: state.profile.bidsLoaded,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list)),
    openBidModal: (bid_id) => dispatch(openBidModal(bid_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentBids);
