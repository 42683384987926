import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Alert,
    Form,
    Modal,
    Button
} from "react-bootstrap";

import UserDataForm from "./UserDataForm";

import { settings } from "../settings";
import { closeEditUser } from "../actions/edit_user_actions";
import { setViewProfile } from "../actions/user_actions";
import AdminOnly from "../dashboard-components/AdminOnly";

class EditUser extends Component {
    constructor () {
        super();
        this.pushData = this.pushData.bind(this);
        this.state = { message: null };
    }

    pushData () {
        const url = settings.api_server + "/admin/user";
        let newState = this.state;

        const postData = {
            user_id: this.props.user.user_id,
            username: this.props.user.profile.username,
            firstname: this.props.user.profile.firstname,
            lastname: this.props.user.profile.lastname,
            isvip: this.props.user.profile.isvip,
            address: this.props.user.profile.address,
            city: this.props.user.profile.city,
            country: this.props.user.profile.country,
            phone_number: this.props.user.profile.phone_number,
            ship_port: this.props.user.profile.ship_port,
            ship_method: this.props.user.profile.ship_method,
            ship_to_country: this.props.user.profile.ship_to_country,
            ship_notify: this.props.dropdownSelection
        };

        fetch(url, {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    // update underlying data
                    this.props.setViewProfile(data.profile);
                    // close the modal
                    this.props.closeEditUser();

                    // clear state for next use
                    newState = this.state;
                    newState.message = null;
                    this.setState(newState);
                } else {
                    newState = this.state;
                    newState.message = data.message;
                    this.setState(newState);
                }
            }.bind(this));
    }

    render () {
        return (
            <Modal size="lg" show={this.props.isOpen} onHide={this.props.closeEditUser} onEnter={this.set_port}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit this user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    {this.state.message != null && <Alert variant='warning'>{this.state.message}</Alert>}
                    <Form>
                        <UserDataForm />
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.pushData}>Save</Button>
                    <Button onClick={this.props.closeEditUser}>Close (discard changes)</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.edit_user.open,
        user_id: state.user.user_id,
        isAdmin: state.profile.is_admin,
        user: state.user,
        m3Data: state.m3Data.m3Data,
        m3Loaded: state.m3Data.loaded,
        update: state.user.lastUpdate,

        dropdownSelection: state.notify.dropdownSelection // for which notify party
    };
};

const mapDispatchToProps = dispatch => ({
    closeEditUser: () => dispatch(closeEditUser()),
    setViewProfile: (data) => dispatch(setViewProfile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
