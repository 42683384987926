/**
 * This component is a modal for admins to search through all notes associated
 * with a purchase (and the bid for that purchase). It returns a Button which
 * opens the modal.
 */

import {settings} from "../../settings";
import React from "react";

import "../../App.css";
import {Button, Container, Row, Col, Modal, ListGroup} from "react-bootstrap";

/**
 * @typedef {Object} BidNote a row from the bid_notes table
 * @prop {number} note_id
 * @prop {number} bid_id
 * @prop {string} text
 * @prop {number} author_id
 */

/**
 * @typedef {Object} PurchaseNote a row from the purchase_notes table
 * @prop {number} note_id
 * @prop {string} purchase_id
 * @prop {string} text
 * @prop {number} author_id
 * @prop {string} note_date
 */

/**
 * @typedef {Object} Notes
 * @prop {string} bid_customer_note bids.customer_note
 * @prop {string} bid_private_admin_note bids.private_admin_note
 * @prop {BidNote[]} bid_notes rows from bid_notes table
 * @prop {string} purchase_notify_note car_purchase.notify_note
 * @prop {string} purchase_user_note car_purchase.user_note
 * @prop {string} purchase_purchase_note car_purchase.purchase_note
 * @prop {PurchaseNote[]} purchase_notes rows from purchase_notes table
 */

/**
 * Returns a button, and the modal which it opens. The modal shows all notes
 * associated with a purchase, see the Notes type documented above for what gets
 * displayed. The modal contains a PurchaseNotesView, see the function below for
 * details on its features.
 * @param {Object} props
 * @param {string} props.purchase_id
 */
export default function PurchaseNotesButton({purchase_id}) {
    const [showModal, setShowModal] = React.useState(false);
    /** @type {[Notes | null, (notes: Notes) => void]} */
    const [notes, setNotes] = React.useState(null);
    const [error, setError] = React.useState(null);

    // fetch notes
    React.useEffect(() => {
        // if component is unmounted or purchase changes before response
        // arrives, cancel setting the notes/error.
        let cancelled = false;
        const url = `${settings.api_server}/purchaseDetail/${purchase_id}/all_notes`;
        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(body => body.success ?
                Promise.resolve(body.notes)
                : Promise.reject(body.message)
            )
            .then(
                notes => {
                    if (!cancelled) {
                        setNotes(notes);
                        setError(null);
                    }
                },
                reason => {
                    if (!cancelled) {
                        setNotes(null);
                        setError(reason);
                    }
                }
            );
        // cleanup function: cancel the fetch
        return () => cancelled = true;
    }, [purchase_id]);

    // Return the button and the modal it opens
    return <>
        <Button size="sm" onClick={() => setShowModal(true)}>
            Search Notes
        </Button>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                    Notes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PurchaseNotesView notes={notes} error={error}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                    onClick={() => setShowModal(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}

/**
 * The display of all notes associated with the purchase. When text is entered
 * in this component's search bar, it highlights any matching text in the notes
 * and doesn't display items with no matching text.
 * @param {Object} props
 * @param {Notes | null} props.notes
 * @param {string | null} props.error
 */
function PurchaseNotesView({notes, error}) {
    // For the lists of items from the purchase_notes and bid_notes tables,
    // limit their height so they display up to NOTE_LIST_ITEMS. This way,
    // instead of having to scroll the whole modal to look through a long list,
    // users can just scroll the lists.
    // NOTE_ITEM_HEIGHT was derived from the ListGroup.Item bootstrap styling
    // (text is 24px tall, top and bottom paddings are 12px each).
    const NOTE_LIST_ITEMS = 6, NOTE_ITEM_HEIGHT = "48px";

    const [search, setSearch] = React.useState("");

    if (notes === null) {
        return <Container>
            <Row>
                <Col>
                    {error === null ? "Loading..." : ("Error: " + error)}
                </Col>
            </Row>
        </Container>;
    }
    else {
        const purchaseFields = [
            ["Shipping notify note", notes.purchase_notify_note],
            ["Purchaser note", notes.purchase_purchase_note],
            ["Private notes", notes.purchase_user_note]
        ];
        const bidFields = [
            ["Customer note", notes.bid_customer_note],
            ["Admin note", notes.bid_private_admin_note]
        ];

        return <Container>
            {/* Search bar */}
            <Row>
                <Col style={{textAlign: "right"}}>
                    <label htmlFor="purchase_note_search">Search:</label>&nbsp;
                    <input type="text" id="purchase_note_search" onChange={
                        (e) => setSearch(e.target.value)
                    }/>
                </Col>
            </Row>
            {/* Headers for purchase and bid fields */}
            <Row>
                <Col><h3>Purchase</h3></Col>
                <Col><h3>Bid</h3></Col>
            </Row>
            {/* Column for purchase fields and one for bid fields */}
            <Row>
                <Col>
                    {purchaseFields.map(([labelText, noteText]) =>
                        filteredField(noteText, search, labelText)
                    )}
                </Col>
                <Col>
                    {bidFields.map(([labelText, noteText]) =>
                        filteredField(noteText, search, labelText)
                    )}
                </Col>
            </Row>
            {/* Rows from the purchase_notes and bid_notes tables */}
            <Row>
                <Col>
                    <h5>Other notes (purchase)</h5>
                    <ListGroup style={{
                        maxHeight: `calc(${NOTE_ITEM_HEIGHT} * ${NOTE_LIST_ITEMS})`,
                        overflow: "auto"
                    }}>
                        {notes.purchase_notes.map((note, index) =>
                            filteredListItem(note.text, search, index)
                        )}
                    </ListGroup>
                </Col>
                <Col>
                    <h5>Other notes (bid)</h5>
                    <ListGroup style={{
                        maxHeight: `calc(${NOTE_ITEM_HEIGHT} * ${NOTE_LIST_ITEMS})`,
                        overflow: "auto"
                    }}>
                        {notes.bid_notes.map((note, index) =>
                            filteredListItem(note.text, search, index)
                        )}
                    </ListGroup>
                </Col>
            </Row>
        </Container>;
    }
}

/**
 * If there's no search text, or the search is a match, return the label and
 * (possibly highlighted) note text. If there's search text and it doesn't
 * match, return null (thereby entirely excluding the field).
 * @param {string | null} noteText
 * @param {string} searchText
 * @param {string} labelText
 * @returns {React.JSX.Element | null}
 */
function filteredField(noteText, searchText, labelText) {
    const [match, text] = highlightedNote(noteText, searchText);
    if (match || searchText.length <= 0) {
        return <div key={labelText}>
            <b>{labelText}</b>
            <br/>
            {text}
        </div>;
    }
    else {
        return null;
    }
}

/**
 * Like filteredField() except instead of a label and the text, it'll return
 * a ListGroup.Item with the text (or null if the search isn't matched).
 * @param {string | null} noteText
 * @param {string} searchText
 * @param {number} index To allow each item to have a unique key
 */
function filteredListItem(noteText, searchText, index) {
    const [match, text] = highlightedNote(noteText, searchText);
    if (match || searchText.length <= 0) {
        return <ListGroup.Item key={index}>{text}</ListGroup.Item>;
    }
    else {
        return null;
    }
}

/**
 * Given a note and some text to search for within the note, return a boolean to
 * indicate whether a match was found, and a <span> where any matching text is
 * highlighted.
 * @param {string | null} noteText
 * @param {string} searchText
 * @returns {[boolean, React.JSX.Element]}
 */
function highlightedNote(noteText, searchText) {
    if (noteText === null || searchText.length <= 0) {
        // eslint complains if there's no key for the span element -_-
        return [false, <span key='0'>{noteText}</span>];
    }
    noteText = noteText.toLowerCase();
    searchText = searchText.toLowerCase();

    const start = noteText.indexOf(searchText);
    if (start < 0) {
        return [false, <span key='0'>{noteText}</span>];
    }

    const end = start + searchText.length;
    return [true,
        <span key='0'>
            {noteText.substring(0, start)}
            <span style={{backgroundColor: "yellow"}}>
                {noteText.substring(start, end)}
            </span>
            {noteText.substring(end)}
        </span>
    ];
}
