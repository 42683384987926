import React, { Component } from "react";
import "../App.css";

import { connect } from "react-redux";

import { setMessage } from "../actions/alert_actions";

import {

    fetch_profile
} from "../actions/profile_actions";

import { settings } from "../settings";

import {
    ListGroup,
    Button
} from "react-bootstrap";

class SubscribedComponent extends Component {
    constructor () {
        super();
        this.state = { updating: false };
        this.subscribeClick = this.subscribeClick.bind(this);
        this.unsubscribeClick = this.unsubscribeClick.bind(this);
    }

    subscribeClick () {
        this.subscribe(true);
    }

    unsubscribeClick () {
        this.subscribe(false);
    }

    subscribe (doSubscribe) {
        const url = settings.api_server + "/user/subscribe";

        this.setState({ ...this.state, updating: true });
        let method = "PUT"; // for subscribing
        if (!doSubscribe) { method = "DELETE"; }

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    // TODO... something
                } else { return response.json(); }
            })
            .then(function () {
                this.setState({ ...this.state, updating: false });
                // update the profile, should update the buttons.
                fetch_profile();
            }.bind(this));
    }

    render () {
        return <ListGroup.Item><b>Subscribed to emails:&nbsp;&nbsp;</b>
            {this.props.subscribed ? "Yes" : "No"}&nbsp;
            {this.props.subscribed
                ? <Button size="sm" disabled={this.state.updating} onClick={this.unsubscribeClick}>Unsubscribe</Button>
                : <Button size="sm" disabled={this.state.updating} onClick={this.subscribeClick}>Subscribe</Button>}
        </ListGroup.Item>;
    }
}
const subscribedMapStateToProps = state => {
    return {
        subscribed: state.profile.subscribed
    };
};
const subscribedMapDispatchToProps = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});
export default connect(subscribedMapStateToProps, subscribedMapDispatchToProps)(SubscribedComponent);
