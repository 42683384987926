import React, { Component } from "react";
import { settings } from "../../settings";
import { Row, Col, Form } from "react-bootstrap";


class RoleChooser extends Component {
    constructor() {
        super();
        this.state = { "roles": ["Loading..."], chosenRole: "Loading...", fetched:false };
        this.sendUpdate = this.sendUpdate.bind(this);
        this.get = this.get.bind(this);
        this.changed = this.changed.bind(this);
    }

    get() {
        // Fetch all countries
        const url = settings.api_server + "/user/role/all";

        fetch(url, {
            credentials: "include",
            method: "GET",
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {

                    // data is ["FOUNDER", "QUEUES", "ACCOUNTS", "BIDS", "USER", "VIP"]
                    // Chooses the first one
                    this.setState({
                        ...this.state,
                        chosenRole: data[0][0],
                        roles: data,
                        fetched: true
                    });
                    this.sendUpdate(data[0]);
                }.bind(this)
            );
    }

    componentDidMount() {
        this.get();
        this.sendUpdate("");
    }

    sendUpdate(newRole) {
        // this is a non-filter, really.
        // send nothing, and it will get filtered out.
        this.props.changeFilter(this.props.which, {"type": "role", "which": newRole});
    }

    changed(e) {
        this.setState({ ...this.state, chosenCountry: e.target.value });
        this.sendUpdate(e.target.value);
    }

    render() {
        // build the dropdown
        // tuple is (role_name, role_desc)
        let options = this.state.roles.map(role =>
            <option key={role} value={role[0]}>{role[0]} - {role[1]}</option>
        );
        return <Form>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Role</Form.Label>
                <Col sm={10}>
                    <Form.Control disabled={!this.state.fetched} as="select" placeholder="select" onChange={this.changed} value={this.props.chosenRole}>
                        {options}
                    </Form.Control>
                </Col>
            </Form.Group>
        </Form>;
    }
}

export default RoleChooser;