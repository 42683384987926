import React, { Component } from "react";

import "./App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import AdminOnly from "./dashboard-components/AdminOnly";

import PCANav from "./dashboard-components/navbar";

import { fetchM3 } from "./actions/m3_actions";

import {
    Alert,
    Button,
    ListGroup
} from "react-bootstrap";
import { settings } from "./settings";

import M3Row from "./m3views/m3Row";
import AddPort from "./m3views/AddPort";

class AdminM3 extends Component {
    constructor () {
        super();
        this.toggleShowPort = this.toggleShowPort.bind(this);

        this.state = { showPortModal: false, showAlert: null, redirect: false };
        this.save = this.save.bind(this);
    }

    toggleShowPort () {
        this.setState({ ...this.state, showPortModal: !this.state.showPortModal });
    }

    componentDidMount () {
    // update the data, regardless of if there is something there
        fetchM3();
    }

    save () {
        this.setState({ ...this.state, updating: true, showAlert: null });

        const url = settings.api_server + "/m3/ports";

        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(this.props.m3Data),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ ...this.state, redirect:true });
                } else {
                    this.setState({ ...this.state, updating: false, showAlert: data.message });
                }
            }.bind(this));
    }

    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <div><PCANav /> <p>Loading...</p> </div>; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        if (this.state.redirect) { return <Redirect to={"/manage"}></Redirect>; }
        

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <div className="container">
                    <h2>m<sup>3</sup> Costs  <Button size="sm" variant="outline-primary" onClick={this.toggleShowPort}>Add a port</Button>
                        <div className="float-right"><Button variant={"outline-primary"} onClick={this.save}>Save</Button></div>
                    </h2>
                    <AddPort show={this.state.showPortModal} toggle={this.toggleShowPort} portList={this.props.m3Data} />
                    {this.state.showAlert !== null && <Alert variant="warning">{this.state.showAlert}</Alert>}

                    <ListGroup>
                        {this.props.m3Data.map(d => <M3Row key={d.port} rowdata={d} />)}
                    </ListGroup>
                    <div className="float-right"><Button variant={"outline-primary"} onClick={this.save}>Save</Button></div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        m3Loaded: state.m3Data.loaded,
        m3Data: state.m3Data.m3Data,
        loggedIn: state.profile.profileLoaded,
        isAdmin: state.profile.is_admin
    };
};



export default connect(mapStateToProps)(AdminM3);
