import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";

import {
    Container, Row, Col
} from "react-bootstrap";

import { settings } from "../settings";

class ImageRow extends Component {
    render () {
        const style = {
            maxWidth: "100%",
            maxHeight: "100%"
        };

        if (this.props.images === []) { return null; }

        // show 2 cars on a row
        const out = [];
        // show images 1 and 2, if 2 exist, by default
        for (let i = this.props.min; i < this.props.images.length && i < this.props.max; i++) { out.push(<Col key={i} sm={6} md={6} lg={6} ><img style={style} alt="Auction car" src={this.props.images[i]} /></Col>); }

        // Due to new 'no translation sheet' from USS auctions. If there's any images.. just show them.
        if (out.length === 0 && this.props.images.length === 1) {
            out.push(<Col key={0} sm={6} md={6} lg={6} ><img style={style} alt="Auction car" src={this.props.images[0]} /></Col>);
        }
        return (
            <Container fluid>
                <Row>
                    {out}
                </Row>
            </Container>
        );
    }
}
ImageRow.propTypes = {
    images: PropTypes.array.isRequired
};
ImageRow.defaultProps = {
    min: 1,
    max: 3
};

class Images extends Component {
    constructor () {
        super();
        this.state = { loaded: false, images: [] };
    }

    componentDidMount () {
    // fetch the data
        const url = settings.api_server + "/search/auction_car_images/" + this.props.vehicle_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status === 429) {
                    // silently fail
                    return { images: [] };
                }
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure
                this.setState({ ...this.state, loaded: true, images: data.images });
            }.bind(this));
    }

    render () {
        return <ImageRow images={this.state.images} min={this.props.min} max={this.props.max} />;
    }
}
Images.propTypes = {
    vehicle_id: PropTypes.string.isRequired
};
Images.defaultProps = {
    min: 1,
    max: 3
};

export default Images;
export { ImageRow };
