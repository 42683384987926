import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col, Row,
    Alert,
    Button,
    Modal,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";

import { setUserModalBidData, updateAmount, updateComment, clearBidData, showUpdateConfirm, showConfirmCancel } from "../../actions/user_bid_modal_actions";

import FxView from "../../widgets/FxView";

class UpdateBidModal extends Component {
    constructor () {
        super();
        this.loadOrig = this.loadOrig.bind(this);
    }

    loadOrig () {
        this.props.setUserModalBidData(this.props.origamount, this.props.origcomment);
    }

    render () {
        return (
            <Modal size='lg' show={this.props.show} onEntering={this.loadOrig} onHide={this.props.clearBidData}>
                <Modal.Header>Update your bid</Modal.Header>
                <Modal.Body>
                    {this.props.message !== null && <Alert>{this.props.message}</Alert>}
                    What would you like to change your bid to?

                    <Row><Col md={2}></Col><Col md={10}> Bid in increments of 1000JPY</Col></Row>
                    <FormGroup as={Row} controlId="amount">
                        <Col sm={2} md={2}>
                            <FormLabel>Amount JPY</FormLabel>
                        </Col>
                        <Col sm={5} md={5}>
                            <FormControl placeholder="Amount" value={this.props.amount} onChange={this.props.updateAmount} />
                        </Col>

                        <Col sm={5} md={5}>
                            {/* Don't display when null or an empty string */}
                            {this.props.amount && <span>Approximately <FxView amount={this.props.amount} thousands={false} /></span>}
                        </Col>

                    </FormGroup>

                    <FormGroup as={Row} controlId="comment">
                        <Col sm={2} md={2}>
                            <FormLabel>Comment</FormLabel>
                        </Col>
                        <Col sm={5} md={5}>
                            <FormControl placeholder="Optional" value={this.props.comment} onChange={this.props.updateComment} />
                        </Col>
                    </FormGroup>

                    Reminder! Your bid amount is only PART of your invoice.  <a target="_blank" href={"https://www.pacificcoastjdm.com/price"} rel="noreferrer">See our fee structure.</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.props.fetching}
                        onClick={this.props.showConfirmCancel}
                    >
                        Cancel bid
                    </Button>
                    {/* Don't allow proceeding further if amount is empty */}
                    <Button
                        disabled={!this.props.amount || this.props.fetching}
                        onClick={this.props.showUpdateConfirm}
                    >
                        Update bid
                    </Button>
                    <Button
                        disabled={this.props.fetching}
                        onClick={this.props.clearBidData}
                    >
                        Close without changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amount: state.user_bid_modal.amount,
        comment: state.user_bid_modal.comment,
        fetching: state.user_bid_modal.fetching,
        message: state.user_bid_modal.message,
        show: state.user_bid_modal.showUpdate,

        origamount: state.auction_car.bid_amount,
        origcomment: state.auction_car.comment,

        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const mapDispatchToProps = dispatch => ({
    updateAmount: (e) => dispatch(updateAmount(e)),
    updateComment: (e) => dispatch(updateComment(e)),
    clearBidData: () => dispatch(clearBidData()), // for closing the modal
    showUpdateConfirm: () => dispatch(showUpdateConfirm()),
    showConfirmCancel: () => dispatch(showConfirmCancel()),
    setUserModalBidData: (amount, comment) => dispatch(setUserModalBidData(amount, comment))

});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBidModal);
