/**
 * This component shows a user which quiz needs to be completed for a given
 * purchase, if any, and has a button to access the quiz.
 */

import { settings } from "../settings";
import React from "react";
import { Button } from "react-bootstrap";
import "./QuizStyles.css";

export default function CarPendingQuizUser({purchase_id}) {
    const [quiz, setQuiz] = React.useState(null);
    const [error, setError] = React.useState(null);

    // fetch the quiz
    React.useEffect(() => {
        const url = `${settings.api_server}/quiz/${purchase_id}`;
        // if component is unmounted (or a different purchase is opened) before
        // the quiz is fetched, cancel setting the quiz
        let cancelled = false;
        fetch (url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(`${resp.status} ${resp.statusText}`))
            .then(
                (body) => {
                    if (!cancelled) {
                        if (body.success) {
                            setQuiz(body.template);
                            setError(null);
                        }
                        else {
                            setQuiz(null);
                            setError("Error: " + body.message);
                        }
                    }
                },
                (reason) => {
                    if (!cancelled) {
                        setQuiz(null);
                        setError("Error: " + reason);
                    }
                }
            );
        return () => cancelled = true;
    }, [purchase_id]);

    if (error != null) {
        return <div>{error}</div>;
    }
    else if (quiz == null) {
        return <div style={{color: "#080"}}>All quizzes complete</div>;
    }
    else {
        return <div>
            <b>Quiz required: </b>{quiz.quiz_name} &nbsp;
            <Button size="sm" variant="outline-primary" action
                href={"#/purchaseQuiz/" + purchase_id}
            >
                View
            </Button>
        </div>;
    }
}
