import React, { Component } from "react";
import "../App.css";

import { connect } from "react-redux";

import { setMessage } from "../actions/alert_actions";

import {
    hidePasswordChange
} from "../actions/profile_actions";

import { settings } from "../settings";

import {
    Modal,
    Button,
    FormControl,
    Alert
} from "react-bootstrap";

class ChangePasswordModal extends Component {
    constructor () {
        super();
        this.newPass = "";
        this.setPassword = this.setPassword.bind(this);

        this.state = { setting: false, message: null };
    }

    setPassword () {
        const url = settings.api_server + "/user/changePasswd";
        // in post, so proxy servers can't see the data
        const postdata = { newPass: this.newPass.value };

        const newState = this.state;
        newState.setting = true;
        this.setState(newState);

        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    const newState = this.state;
                    newState.setting = false;
                    newState.message = "Password set failed";
                    this.setState(newState);
                } else { return response.json(); }
            })
            .then(function (data) {
                const newState = this.state;
                if (data.success) {
                    this.props.setMessage(<p>Password changed</p>);
                    newState.setting = false;
                    this.setState(newState);
                    this.props.hidePasswordChange();
                } else {
                    newState.setting = false;
                    newState.message = data.message;
                    this.setState(newState);
                }
            }.bind(this));
    }

    render () {
        return <Modal show={this.props.showPasswordChange} onHide={this.props.hidePasswordChange}>
            <Modal.Header>
                <Modal.Title>Update password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                <FormControl ref={ref => { this.newPass = ref; }} />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.setting} onClick={this.setPassword}>{this.state.setting ? "Setting password" : "Set password"}</Button> <Button onClick={this.props.hidePasswordChange}>Close without setting</Button>
            </Modal.Footer>
        </Modal>;
    }
}

const passwdMapStateToProps = state => {
    return {
        showPasswordChange: state.profile.showPasswordChange
    };
};

const passwdMapDispatchToProps = dispatch => ({
    hidePasswordChange: () => dispatch(hidePasswordChange()),
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(passwdMapStateToProps, passwdMapDispatchToProps)(ChangePasswordModal);
