import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import moment from "moment-timezone";

import { Link } from "react-router-dom";

import {
    Table
} from "react-bootstrap";

import {
    allowEntities,
    TranslateMake,
    TranslateModel
} from "../functions";

import { OdoHelp } from "../widgets/mouseovers";
import spaceNumbers from "../widgets/spaceNumbers";

class AuctionCarDetails extends Component {
    render () {
        if (this.props.loading) { return <p>Loading...</p>; }

        if (this.props.failure) { return <p>Could not load car details. Car could have been removed from auction. <Link to={"/auction_car_history/" + this.props.id}>Click here to check for history page.</Link></p>; }

        const time_to_auction = moment.tz(this.props.data.auction_time, "Asia/Tokyo");
        /* AVTO-specific parsing
        var info = parseAVTOInfoString(this.props.data.info);
        var grade = null;
        if (info["Rate int"] !== null && info["Rate ext"] !== null) {
            grade = "Interior: " + info["Rate int"] + " Exterior: " + info["Rate ext"];
            // delete the rate stuff from the info object for display
            delete info["Rate int"];
            delete info["Rate ext"];
        }
        // make a display string for Information
        var infoArray = [];
        for (var key in info) {
            if (key !== "Rate int" && key !== "Rate ext")
                infoArray.push(key + ": " + info[key]);
        } */

        let when = <span>{this.props.data.auction_time} JST, {time_to_auction.fromNow()}</span>;
        if (time_to_auction.isAfter(moment().add(7, "days"))) {
            when = "Date not set";
        }

        return (
            <Table striped bordered size="sm" hover>
                <tbody>
                    <tr><td><b>Grade</b></td><td>{this.props.data.grade}</td></tr>
                    <tr><td><b>Year</b></td><td>{this.props.data.year}</td></tr>
                    <tr><td><b>Make</b></td><td><TranslateMake make={this.props.data.make} /></td></tr>
                    <tr><td><b>Model</b></td><td><TranslateModel model={this.props.data.model} /></td></tr>
                    <tr><td><b>Chassis code:</b></td><td>{this.props.data.chassis_code}</td></tr>
                    <tr><td><b>Fuel:</b></td><td>{this.props.data.info[" fuel "]}</td></tr>
                    <tr><td><b>Odometer reading</b></td><td>{spaceNumbers(this.props.data.mileage * 1000)} <OdoHelp /></td></tr>
                    <tr><td><b>Trim</b></td><td>{allowEntities(this.props.data.trim)}</td></tr>
                    <tr><td><b>Transmission</b></td><td>{this.props.data.transmission}</td></tr>
                    <tr><td><b>Chassis Number</b></td><td>{this.props.data.full_chassis_number}</td></tr>
                    <tr><td><b>Displacement</b></td><td>{this.props.data.displacement}</td></tr>
                    <tr><td><b>Auctioned at</b></td><td>{this.props.data.auction_house}</td></tr>
                    <tr><td><b>Auction time</b></td><td>{when}</td></tr>
                    <tr><td><b>Auction lot</b></td><td>{this.props.data.lot}</td></tr>
                    <tr><td><b>Colour</b></td><td>{this.props.data.colour}</td></tr>
                    <tr><td><b>Features</b></td><td>{this.props.data.info[" equipment "]}</td></tr>
                    <tr><td><b>Starting bid</b></td><td>{spaceNumbers(this.props.data.start_price)}</td></tr>
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure
    };
};


export default connect(mapStateToProps)(AuctionCarDetails);
