import React, { Component } from "react";
import "../App.css";

import LineItems from "../ledger_views/gl_data";

class GeneralLedgerList extends Component {
    /*
    Yeah, so this is basically just a wrapper. But it's nice.
    */
    render () {
        return <LineItems who={-1} />;
    }
}

export default GeneralLedgerList;
