import React from "react";
import { Form } from "react-bootstrap";
import "./QuizStyles.css";

/**
 * @typedef {import('./QuizQuestion.js').Question} Question
 */

/**
 * Depending on whether the question has additional preset answers, this will
 * return a text field with a radio button to select it, or one big text field
 * @param {Object} props
 * @param {Question} props.question
 * @param {Object | null} props.autofill user's previous answer to this question
 */
export default function QuizTextInput({question, autofill}) {
    if (question.answers.length > 0) {
        return TextInputOption({question, autofill});
    }
    else {
        return TextInputOnly({question, autofill});
    }
}

/**
 * Component with a text field and a radio button to select it, when the text
 * input is an alternative to other options
 * @param {Object} props
 * @param {Question} props.question
 * @param {Object | null} props.autofill user's previous answer to this question
 */
function TextInputOption({question, autofill}) {
    const radioRef = React.useRef(null);
    const textRef = React.useRef(null);

    // see if user previously selected this answer
    let checked = false;
    let defaultText = "";
    if (typeof(autofill) != "undefined" && autofill != null) {
        checked = autofill.used_text_input === true;
        // only set default text if user used text input previously, otherwise
        // it'll show the text of the other answer they selected
        if (checked) {
            defaultText = autofill.answer_text;
        }
    }

    // use refs and onClicks so selecting the radio selects the text input
    // and vice-versa
    return <Form.Check className="quizRadio"
        id={"text_radio_" + question.question_id}
        name={"q_" + question.question_id}
        value={"a_text"}
        type="radio"
        required
        ref={radioRef}
        onClick={() => {if (textRef.current) textRef.current.focus();}}
        defaultChecked={checked}
        label={
            <div>
                {question.text_input_label}
                <Form.Control className="quizTextInput"
                    name={"input_" + question.question_id}
                    ref={textRef}
                    onClick={() => {if (radioRef.current) radioRef.current.click();}}
                    defaultValue={defaultText}
                />
            </div>
        }
    />;
}

/**
 * Component with just a text input, the question does not have other options
 * @param {Object} props
 * @param {Question} props.question
 * @param {Object | null} props.autofill user's previous answer to this question
 */
function TextInputOnly({question, autofill}) {
    let defaultText = "";
    if (typeof(autofill) != "undefined" && autofill != null && autofill.used_text_input) {
        defaultText = autofill.answer_text;
    }

    return <div>
        {question.text_input_label &&
            <Form.Label htmlFor={"text_input_" + question.question_id}>
                {question.text_input_label}
            </Form.Label>
        }
        <Form.Control className="quizTextInput"
            id={"text_input_" + question.question_id}
            name={"input_" + question.question_id}
            defaultValue={defaultText}
        />
    </div>;
}
