const setSavedList = function (listOfSaved) {
    return {
        type: "SET_SAVED_LIST",
        savedList: listOfSaved
    };
};
const toggleModal = function () {
    return {
        type: "TOGGLE_SAVED_MODAL"
    };
};

const toggleSubscribe = function () {
    return {
        type: "TOGGLE_SUBSCRIBE"
    };
};

const changeQueryName = function (e) {
    return {
        type: "SAVED_QUERY_NAME",
        modalQueryName: e.target.value
    };
};
const clearSavedQuery = function () {
    return {
        type: "CLEAR_SAVED_QUERY"
    };
};
export { setSavedList, toggleModal, changeQueryName, clearSavedQuery, toggleSubscribe };
