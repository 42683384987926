/**
 * Footer.js
 * 
 * This footer is used across the site and contains PCA's copyright info and a
 * link to the copyright page
 */

import * as React from "react";
import "./Footer.css";

export default function Footer() {
    const year = new Date().getFullYear();
    return <div className="pcaFooter">
        <a href="#/copyright">
            Copyright © 2023-{year} Pacific Coast Auto Imports<br/>All rights reserved
        </a>
    </div>;
}
