import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import QuizAnswer from "./QuizAnswer";
import QuizTextInput from "./QuizTextInput";
import "./QuizStyles.css";

/**
 * @typedef {Object} Question
 * @property {number} question_id
 * @property {string | null} question_text
 * @property {boolean} has_text_input
 * @property {string | null} text_input_label
 * @property {string | null} help_text
 * @property {Answer[]} answers
 */

/**
 * @param {Object} props
 * @param {Question} props.question
 * @param {Object | null} props.autofill user's previous answer to this question
 */
export default function QuizQuestion({question, autofill}) {
    const formGroup = <Form.Group>
        {question.answers.map(a => (
            <QuizAnswer answer={a} key={a.answer_id} autofill={autofill}/>
        ))}
        {question.has_text_input &&
            <QuizTextInput question={question} autofill={autofill}/>
        }
    </Form.Group>;

    const rowSpan = 12;
    let formSpan;
    // no help text? form group takes up whole width
    if (question.help_text == null) {
        formSpan = 12;
    }
    // text input? make it take up most of the width
    else if (question.has_text_input) {
        formSpan = 9;
    }
    // no text input? split width equally with help text
    else {
        formSpan = 6;
    }

    return <Container style={{padding: "0px"}}>
        <Row>
            <Col xs={rowSpan}>
                <h5>{question.question_text}</h5>
            </Col>
        </Row>
        <Row>
            <Col xs={formSpan}>
                {formGroup}
            </Col>
            {question.help_text != null &&
                <Col>
                    <HelpText text={question.help_text}/>
                </Col>
            }
        </Row>
    </Container>;
}

/**
 * @param {Object} props
 * @param {String} props.text
 */
function HelpText({text}) {
    const lines = text.split(/\r?\n/);
    if (lines.length == 0) {
        console.log("Warning: help text was empty after splitting into lines.");
        return null;
    }
    // insert line breaks between each line of text
    const parsed = <>
        {lines[0]}
        {lines.slice(1).map(line => <>
            <br/>
            {line}
        </>)}
    </>;
    return <div className="helpContainer">
        <div className="helpBox">
            {parsed}
        </div>
        <div className="helpLabel">
            HELP
        </div>
    </div>;
}
