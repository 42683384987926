import React from "react";
import {
    Accordion,
    Button,
    Card,
    Container, Row, Col,
    Form,
    ListGroup
} from "react-bootstrap";
import {Link} from "react-router-dom";

import {settings} from "../settings";

/**
 * @typedef {Object} Note
 * @prop {number} note_id
 * @prop {string} text
 * @prop {number} author_id
 * @prop {string} vehicle_id
 * @prop {string} author_name
 * @prop {string} author_email
 * @prop {string} vehicle_desc
 * @prop {string} [purchase_id] if the note is for a purchase
 * @prop {number} [bid_id] if the note is for a bid
 */

/**
 * This component allows admins to search through all notes made on all bids
 * and purchases (the bid_notes and purchase_notes tables).
 */
export default function PurchaseNoteLookup() {
    const [results, setResults] = React.useState(null);
    const [error, setError] = React.useState(null);

    function doSearch() {
        const searchText = document.querySelector("#purchaseLookupText").value;
        const encoded = window.btoa(searchText);
        const url = settings.api_server +
            "/purchaseDetail/search_all_notes?query=" + encoded;
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve(data) : Promise.reject(data.message)
            )
            .then(
                data => {
                    setResults(data);
                    setError(null);
                },
                reason => {
                    setResults(null);
                    setError(reason);
                }
            );
    }

    return <Card>
        <Card.Header>
            <Card.Title>Bid/purchase notes lookup</Card.Title>
        </Card.Header>
        <Card.Body>
            <Accordion>
                <Form.Control
                    id="purchaseLookupText"
                    type="text"
                    aria-describedby="purchaseLookupHelp"
                />
                {/* Search and Help buttons */}
                <Container style={{marginTop: "5px"}}>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Button
                                size="sm"
                                variant="outline-success"
                                onClick={doSearch}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col md="auto">
                            <Accordion.Toggle as={Button}
                                eventKey="0"
                                size="sm"
                                variant="outline-info"
                            >
                                Toggle help
                            </Accordion.Toggle>
                        </Col>
                    </Row>
                </Container>
                {/* Collapsable help */}
                <Accordion.Collapse eventKey="0">
                    <Form.Text className="text-muted" id="purchaseLookupHelp">
                        Enter some text to search for it in the notes, or
                        enter query arguments to search for the notes by a
                        specific author, or attached to a specific car.
                        <br/>
                        Example arguments:
                        <ul>
                            <li><code>
                                user="firstname lastname"
                            </code></li>
                            <li><code>
                                email=author@email.com
                            </code></li>
                            <li><code>
                                make='Toyota'
                            </code></li>
                            <li><code>
                                model="Camry"
                            </code></li>
                            <li><code>
                                year=2005
                            </code></li>
                        </ul>
                        Note: quotes are only necessary if the argument contains
                        spaces.
                        <br/>
                        Example search: looking up a note on a Honda Civic about
                        its odometer <code>
                            odometer&nbsp;make=Honda&nbsp;model=Civic
                        </code>
                    </Form.Text>
                </Accordion.Collapse>
                <BothLookupResults results={results} error={error}/>
            </Accordion>
        </Card.Body>
    </Card>;
}

function BothLookupResults({results, error}) {
    if (results === null) {
        // note: if error is null too (no search has been performed yet), we
        // won't display anything, which is intended
        return error;
    }
    else {
        return <Container style={{marginTop: "5px"}}>
            <Row>
                <Col>
                    <LookupResults
                        title="Purchase notes"
                        results={results.purchase_notes}
                    />
                    <LookupResults
                        title="Bid notes"
                        results={results.bid_notes}
                    />
                </Col>
            </Row>
        </Container>;
    }
}

/**
 * @param {Object} props
 * @param {Note[]} props.results
 */
function LookupResults({results, title}) {
    let listContent = <ListGroup.Item>No results</ListGroup.Item>;
    if (results.length > 0) {
        listContent = results.map(note => <NoteItem
            key={note.note_id}
            note={note}
        />);
    }
    return <Card>
        <Card.Header>
            {title}
        </Card.Header>
        <Card.Body style={{padding: "0px"}}>
            {/* Limit the height of the results list, make it scrollable */}
            <ListGroup variant="flush" style={{maxHeight: "500px", overflow: "auto"}}>
                {listContent}
            </ListGroup>
        </Card.Body>
    </Card>;
}

/**
 * @param {Object} props
 * @param {Note} props.note
 */
function NoteItem({note}) {
    // link to purchase if this is a purchase note, otherwise link to the car
    const page = note.purchase_id ?
        `/editPurchase/${note.purchase_id}`
        : `/auction_car/${note.vehicle_id}`;
    return <Link target="_blank" rel="noreferrer" to={page} className="nostyle">
        <ListGroup.Item>
            <i>"{note.text}"</i>
            <br/>
            Author: {note.author_name}
            <br/>
            Vehicle: {note.vehicle_desc}
        </ListGroup.Item>
    </Link>;
}
