import React, { Component } from "react";
import { Link } from "react-router-dom";
import { settings } from "../settings";
import { Image, Spinner } from "react-bootstrap";
import CarStatusDisplay from "./status_display";
import "./MyCars.css";

/**
 * @typedef {Object} InvoiceObj Info about an invoice. Not all fields are
 * documented here, only ones which are relavent to this file.
 * @property {string | null} due_on_date
 * @property {number} amount unpaid amount
 * @property {string} invoice_id
 * @property {boolean} purchase_complete
 * @property {boolean} released_to_client
 */

/**
 * @typedef {Object} PurchaseObj Info about a car_purchase and its associated
 * invoices. Not all fields are documented here, only ones which are relavent to
 * this file.
 * @property {string} purchase_id
 * @property {string} vehicle_id
 * @property {InvoiceObj[]} invoices
 * @property {string | null} arrival_estimate
 * @property {string | null} date_of_purchase
 * @property {string | null} documents_sent
 * @property {string | null} export_date
 * @property {string | null} export_estimate
 * @property {string} make
 * @property {string} model
 * @property {string | null} ship_port
 * @property {number | null} quiz_required
 * @property {string | null} vessel_name
 * @property {number} year
 */

/**
 * @typedef {Object} CarObj Info about a car. Not all fields are documented
 * here, only ones which are relavent to this file.
 * @property {string} vehicle_id
 * @property {string} chassis_code
 * @property {string} images '#'-separated URLs of images
 * @property {string} make
 * @property {string} model
 * @property {string} year
 */

/**
 * @typedef {Object} MyCarRowProps
 * @property {PurchaseObj} purchase purchase object
 * @property {CarObj | null | undefined} car car object if cached, null if car
 * fetch failed, undefined if not cached
 * @property {(carID: string, car: CarObj) => void} storeCar function to cache
 * a car
 * @property {string} search string in search bar, to allow highlighting
 * matched data
 */

/**
 * @typedef {Object} MyCarState
 * @property {boolean} loading
 * @property {boolean} carFail
 * @property {CarObj | null} car
 */

class MyCarRow extends Component {

    /**
     * @param {MyCarRowProps} props
     */
    constructor(props) {
        super(props);

        /**
         * @type {MyCarState}
         */
        this.state = {
            loading: true,
            carFail: false,
            car: null
        };
    }

    componentDidMount() {
        // if given a car, set state and don't proceed with fetch
        if (this.props.car !== undefined) {
            this.setState({
                ...this.state,
                loading: false,
                carFail: this.props.car == null,
                car: this.props.car
            });
            return;
        }

        // fetch car if it wasn't cached
        const url = settings.api_server + "/search/auction_car/" +
            this.props.purchase["vehicle_id"];
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.length > 0) {
                    this.setState({ ...this.state, loading: false, carFail: false, car: data[0] });
                    this.props.storeCar(this.props.purchase["vehicle_id"], data[0]);
                }
                else {
                    this.setState({ ...this.state, loading: false, carFail: true, car: null });
                    this.props.storeCar(this.props.purchase["vehicle_id"], null);
                }
            }.bind(this));
    }

    carLink(element) {
        return <Link target="_blank" className="nostyle" rel="noreferrer"
            to={"/purchase_detail/" + this.props.purchase["purchase_id"]}>{element}
        </Link>;
    }

    /** Given a string, highlight any text matching the current search
     * @param {string} str
     */
    searchHighlight(str) {
        if (str === null || this.props.search.length <= 0) {
            return str;
        }

        const start = str.toLowerCase().indexOf(this.props.search);
        if (start < 0) {
            return str;
        }

        const end = start + this.props.search.length;
        return <>
            {str.substring(0, start)}
            <span className="highlight">{str.substring(start, end)}</span>
            {str.substring(end)}
        </>;
    }

    getInvoice() {
        if (this.props.purchase["invoices"].length <= 0) {
            return <td>{this.carLink("-")}</td>;
        }

        return <td>{this.carLink(this.searchHighlight(
            this.props.purchase["invoices"][0]["invoice_id"]))}
        </td>;
    }

    // Return due date of first invoice
    // Highlighted red if an incomplete invoice is overdue, green if complete
    getInvoiceDueDate() {
        /** @type {PurchaseObj} */
        const data = this.props.purchase;
        if (data.invoices.length <= 0 || !data.invoices[0]["released_to_client"]) {
            return <td>-</td>;
        }

        const iv = data.invoices[0];

        let color = "";
        if (iv["purchase_complete"] === true) {
            color = "auctionOK";
        }
        else if (iv["due_on_date"] != null
            && new Date(iv["due_on_date"]).valueOf() < Date.now()) {
            color = "auctionPassed";
        }
        return <td className={color}>{this.carLink(iv["due_on_date"])}</td>;
    }

    getCarName() {
        return <td>{
            this.carLink(this.searchHighlight(this.state.carFail ?
                this.props.purchase["make"] + " " + this.props.purchase["model"]
                : this.state.car["make"] + " " + this.state.car["model"]))
        }</td>;
    }

    getChassisCode() {
        return <td>{
            this.carLink(this.searchHighlight(this.state.carFail ?
                this.props.purchase["chassis_num"]
                : this.state.car["chassis_code"]))
        }</td>;
    }

    getImage() {
        if (this.state.carFail) {
            return <td></td>;
        }

        const images = this.state.car["images"].split("#");

        if (images.length <= 0) {
            return <td></td>;
        }
        else {
            // return the second image -- first is typically the auction sheet
            // if only one image, then use that
            const index = Math.min(1, images.length - 1);
            return <td>{this.carLink(
                <Image style={{ height: "80px" }}
                    key={images[index]} src={images[index]} />
            )}</td>;
        }
    }

    getExportEstimate() {
        return <td>{this.carLink(this.props.purchase["export_estimate"])}</td>;
    }

    // When an export date is selected with the Edit Purchase modal, it results
    // in the date returned being a full UTC date & time. If the export_date
    // field contains an actual date, reduce the precision to yyyy-mm so it
    // matches the precision available in the Edit Purchase modal.
    getExportDate() {
        const exportDate = this.props.purchase["export_date"];
        const asTimestamp = Date.parse(exportDate);
        // If the date was parsed successfully, extract the year and month.
        // Otherwise, use the actual value of the field.
        let result = exportDate;
        if (!isNaN(asTimestamp)) {
            const date = new Date(asTimestamp);
            // Use leading zeroes for consistency with other dates. Also add 1
            // because getUTCMonth() returns a 0-indexed month.
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
            result = date.getUTCFullYear() + "-" + month;
        }
        return <td>{this.carLink(result)}</td>;
    }

    getArrivalEstimate() {
        return <td>{this.carLink(this.props.purchase["arrival_estimate"])}</td>;
    }

    getDocumentsSent() {
        return <td>{this.carLink(this.props.purchase["documents_sent"])}</td>;
    }

    getVesselName() {
        return <td>{this.carLink(
            this.searchHighlight(this.props.purchase["vessel_name"]))}</td>;
    }

    render() {
        /** @type {PurchaseObj} */
        const data = this.props.purchase;

        if (this.state.loading) {
            return <tr id={data.purchase_id} key={data.purchase_id}>
                <td colSpan="13">Loading... <Spinner variant="primary" role="status" animation="border"/></td>
            </tr>;
        }

        {/* date formats are MM/DD/YYYY unless otherwise specified */ }
        return (
            <tr id={data.purchase_id} key={data.purchase_id} style={{ cursor: "pointer" }}>

                {/* invoice number */}
                {this.getInvoice()}

                {/* bought date */}
                <td>{this.carLink(data["date_of_purchase"])}</td>

                {/* invoice due date */}
                {this.getInvoiceDueDate()}

                {/* car make and model */}
                {this.getCarName()}

                {/* chassis number */}
                {this.getChassisCode()}

                {/* image */}
                {this.getImage()}

                {/* auction house */}
                <td>{this.carLink(this.searchHighlight(
                    this.state.carFail ? "" : this.state.car["auction_house"]))}
                </td>

                {/* port */}
                <td>{this.carLink(this.searchHighlight(data["ship_port"]))}</td>

                {/* export date estimate (ex: June end) */}
                {this.getExportEstimate()}

                {/* export date */}
                {this.getExportDate()}

                {/* arrival date estimate */}
                {this.getArrivalEstimate()}

                {/* documents sent date */}
                {this.getDocumentsSent()}

                {/* vessel name */}
                {this.getVesselName()}

                {/*
                <td></td> // Option column omitted for now
                */}

                {/* status */}
                <td><CarStatusDisplay purchase={this.props.purchase}/></td>
            </tr>
        );
    }
}

export default MyCarRow;
