import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
    ListGroup,
    Button,
    Row, Col
} from "react-bootstrap";
import { connect } from "react-redux";
import { setMessage } from "../actions/alert_actions";
import { settings } from "../settings";

class PaymentItemComponent extends Component {
    constructor () {
        super();
        this.delete = this.delete.bind(this);
    }

    delete () {
    /*
        Delete this item, refresh data
        */
        const url = settings.api_server + "/invoice/admin/payment/" + this.props.data.payment_id;
        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh, or show a message
                if (data.success) {
                    this.props.refresh();
                } else {
                    // show the message in the banner
                    this.props.setMessage(data.message);
                }
            }.bind(this));
    }

    render () {
        return <ListGroup.Item>
            <Row>
                <Col md={4}>
                    <Link to={"/user_detail/" + this.props.data.user_id}>{this.props.data.user}</Link>&nbsp;
                    paid ¥{this.props.data.amount_paid}
                </Col>
                <Col md={4}>
                    Applied to invoices
                    {this.props.data.invoices.map(i => <li key={i.invoice_id}><Link to={"/purchase_detail/" + i.purchase_id} >{i.invoice_id}</Link> ¥{Math.abs(i.amount)}</li>)}
                </Col>
                <Col md={2}>Deposit
                    {this.props.data.deposits.map(i => <li key={i.entry_id}>¥{Math.abs(i.amount)}</li>)}
                </Col>
                <Col md={2}>
                    <Button variant='danger' size='sm' onClick={this.delete}>Delete</Button>
                </Col>
            </Row>
        </ListGroup.Item>;
    }
}
const invoiceEditMapStateToProps = state => {
    state;
    return {
    };
};

const invoiceEditMapDispatchToProps = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});

const PaymentItem = connect(invoiceEditMapStateToProps, invoiceEditMapDispatchToProps)(PaymentItemComponent);

class RecentPayments extends Component {
    constructor () {
        super();
        this.state = {
            loaded: false,
            payments: []
        };
        this.getData = this.getData.bind(this);
    }

    getData () {
        const url = settings.api_server + "/invoice/admin/payment";
        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({
                    ...this.state,
                    loaded: true,
                    payments: data // returns an array of payments
                });
            }.bind(this));
    }

    componentDidMount () {
        this.getData();
    }

    render () {
        return <ListGroup variant="flush">
            <ListGroup.Item><Button onClick={this.getData}>Refresh</Button></ListGroup.Item>
            {this.state.payments.map(x => <PaymentItem key={x.payment_id} data={x} refresh={this.getData} />)}
        </ListGroup>;
    }
}

export default RecentPayments;
