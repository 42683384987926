import React, { Component } from "react";

import { settings } from "./settings";

import PCANav from "./dashboard-components/navbar";
import { connect } from "react-redux";

// import {settings} from './settings';

import { Accordion, Button, Form, Modal, Container, Collapse } from "react-bootstrap";
import AvtoResults from "./avto-components/avto_results";
import NormalSearch from "./avto-components/NormalSearch";
import {
    clearSelection,
    doSearch,
    getCount,
    clear_results,
    makeSearchObject,
    baseInfoRefresher,
    toggleViewSearch,
    show_results_section,
} from "./actions/search_actions";
import {
    toggleModal,
    changeQueryName,
    clearSavedQuery,
    setSavedList,
    toggleSubscribe,
} from "./actions/saved_query_actions";

class SaveSearchModalComponent extends Component {
    constructor() {
        super();

        this.doSave = this.doSave.bind(this);
        this.state = { fetching: false, message: null };
    }

    doSave() {
        this.setState({ ...this.state, fetching: true });

        // update the search parameters
        const url = settings.api_server + "/saved/";
        const postData = {};
        postData.query = makeSearchObject(this.props.data);
        postData.name = this.props.queryName;
        postData.subscribe = this.props.subscribe;

        fetch(url, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json",
            },
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    if (data.success) {
                        // clear the form, and close
                        this.setState({
                            ...this.state,
                            fetching: false,
                            message: null,
                        });
                        this.props.clearSavedQuery();
                        this.props.toggleModal();
                        this.props.setSavedList(data.searches);
                    } else {
                        // show a message
                        this.setState({
                            ...this.state,
                            fetching: false,
                            message: data.message,
                        });
                    }
                }.bind(this)
            );
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Query</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.message !== null && <p>{this.state.message}</p>}
                    <p>Give your query a name:</p>
                    <Form.Control
                        type="text"
                        placeholder="Query name"
                        value={this.props.queryName}
                        onChange={this.props.changeQueryName}
                    />
                    <Form.Check
                        type="checkbox"
                        id="doSubscribe"
                        label="Subscribe to email notifications for this query"
                        checked={this.props.subscribe}
                        onChange={this.props.toggleSubscribe}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={this.doSave}
                        disabled={this.props.fetching}
                    >
                        Save Changes
                    </Button>
                    <Button onClick={this.props.toggleModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const saveStateToProps = (state) => {
    return {
        show: state.saved_queries.showSaveModal,
        data: state.search,
        queryName: state.saved_queries.modalQueryName,
        subscribe: state.saved_queries.subscribe,
    };
};

const saveDispatchToProps = (dispatch) => ({
    toggleModal: () => dispatch(toggleModal()),
    changeQueryName: (e) => dispatch(changeQueryName(e)),
    clearSavedQuery: () => dispatch(clearSavedQuery()),
    setSavedList: (saved_list) => dispatch(setSavedList(saved_list)),
    toggleSubscribe: () => dispatch(toggleSubscribe()),
});

const SaveSearchModal = connect(
    saveStateToProps,
    saveDispatchToProps
)(SaveSearchModalComponent);

class Search extends Component {
    constructor() {
        super();
        this.doBasicSearch = this.doBasicSearch.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            key: 3,
        };
    }

    componentDidMount() {
        // make sure we have make/model data
        baseInfoRefresher();
    }

    handleSelect(key) {
        this.setState({ key });
    }

    doBasicSearch() {
        this.props.clear_results();
        // have to send makes to filter to 'other'
        doSearch(this.props.data);
        // get the count to populate the paginator
        getCount(this.props.data);
        this.props.show_results_section();
    }

    kludge() {
        // do nothing. Gets rid of warning on Card
    }

    render() {
        return (
            <div>
                <PCANav currentPage="#/search" />
                <Collapse in={this.props.showSearch}>
                    <div>
                        <Container className="wideContainer">
                            <span className="whiteTitle">Auction Search</span>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Accordion.Toggle
                                    eventKey="1"
                                    className="border-0 p-0 bg-transparent"
                                >
                                    <Button onClick={this.props.clearSelection}>
                                        New Search
                                    </Button>
                                </Accordion.Toggle>
                            </div>
                        </Container>
                        <NormalSearch searchCallback={this.doBasicSearch} />
                    </div>
                </Collapse>
                <AvtoResults />
                <SaveSearchModal />


            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        results: state.search.results,
        fetching: state.search.fetching,
        showSearch: state.search.showSearch,
        data: state.search,
        guest: state.profile.guest,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearSelection: () => dispatch(clearSelection()),
    clear_results: () => dispatch(clear_results()),
    toggleViewSearch: () => dispatch(toggleViewSearch()),
    toggleModal: () => dispatch(toggleModal()),
    show_results_section: () => dispatch(show_results_section()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
