import { settings } from "../settings";

// fetch the tips. Try local first (default behaviour)
const fetchItems = function () {
    // if the lo
    const url = settings.api_server + "/default_line_items/";

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                // throw new Error("Bad response from server");
                console.log("failure fetching default line items");
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            // do the dispatch
            settings.store.dispatch(setItems(data));
        });
};

const setItems = function (item_list) {
    return {
        type: "SET_DEFAULT_LINE_ITEMS",
        items: item_list
    };
};

export { fetchItems, setItems };
