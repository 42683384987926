import React, { Component } from "react";
import { settings } from "../../settings";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";



class UserChooser extends Component {
    constructor() {
        super();
        this.state = { "users": [], chosenUser: null, query:"" };
        this.sendUpdate = this.sendUpdate.bind(this);
        this.search = this.search.bind(this);
        this.changed = this.changed.bind(this);
        this.queryChanged = this.queryChanged.bind(this);
    }

    search(query) {
        const url = settings.api_server + "/user/getUsers/" + query;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // data is.
                // [{"user_id": 1, "firstname": "Testname", "lastname": "McGee", "username": "test@mailinator.com", "active": true}, ....
                this.setState({ ...this.state, users: data });
            }.bind(this))
            .catch(function (){
                // happens when "" is sent
                // any way, clear the table
                this.setState({ ...this.state, users: [] });
            }.bind(this));
    }

    componentDidMount() {
        this.sendUpdate(null);
    }

    sendUpdate(newUser) {
        // this is a non-filter, really.
        // send nothing, and it will get filtered out.
        this.props.changeFilter(this.props.which, { "type": "user", "which": newUser });
    }

    queryChanged(e){
        this.setState({...this.state, query:e.target.value});
        this.search(e.target.value);
    }
    changed(e) {
        // e.target.value is the value set for the list group item (the user id)
        // Find that user, pass the object around
        let user = null;
        let id = parseInt(e.target.value, 10);
        for (var i = 0; i<this.state.users.length; i++){
            if(this.state.users[i].user_id === id){
                user = this.state.users[i];
            }
        }

        this.setState({ ...this.state, chosenUser: user });
        this.sendUpdate(id);
    }

    render() {

        // if there is someone chosen, just show that name
        // they can delete it if they mis-clicked!
        if(this.state.chosenUser !== null){
            return <span>{this.state.chosenUser.username}</span>;
        }
        // build the dropdown
        let options = this.state.users.map(user =>
            <ListGroup.Item action onClick={this.changed} key={user.user_id} value={user.user_id}>{user.username}: {user.firstname} {user.lastname}</ListGroup.Item>
        );
        
        return <ListGroup>
            <ListGroup.Item>Filter: <Form.Control onChange={this.queryChanged} value={this.state.query} /></ListGroup.Item>
            {options}
        </ListGroup>;
    }
}

export default UserChooser;