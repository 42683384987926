const viewEditUser = function () {
    return {
        type: "OPEN_EDIT_USER"
    };
};

const closeEditUser = function () {
    return {
        type: "CLOSE_EDIT_USER"
    };
};

const openDepositModal = function () {
    return {
        type: "SHOW_DEPOSIT_MODAL"
    };
};
const closeDepositModal = function () {
    return {
        type: "CLOSE_DEPOSIT_MODAL"
    };
};

const openTransRequestModal = function () {
    return {
        type: "OPEN_TRANS_REQUEST_MODAL"
    };
};

const closeTransRequestModal = function () {
    return {
        type: "CLOSE_TRANS_REQUEST_MODAL"
    };
};

const openAddBidAvailableModal = function () {
    return {
        type: "OPEN_BIDS_AVAILABLE_MODAL"
    };
};

const closeAddBidAvailableModal = function () {
    return {
        type: "CLOSE_BIDS_AVAILABLE_MODAL"
    };
};

const openDepositWithdrawModal = function () {
    return {
        type: "SHOW_DEPOSIT_WITHDRAW_MODAL"
    };
};
const closeDepositWithdrawModal = function () {
    return {
        type: "HIDE_DEPOSIT_WITHDRAW_MODAL"
    };
};

export {
    viewEditUser,
    closeEditUser,
    openDepositModal,
    closeDepositModal,
    openTransRequestModal,
    closeTransRequestModal,
    openAddBidAvailableModal,
    closeAddBidAvailableModal,
    openDepositWithdrawModal,
    closeDepositWithdrawModal
};
