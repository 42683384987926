import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Alert,
    Modal,
    Button,
    Form, FormGroup, FormLabel,
    FormControl,
    Col, Row
} from "react-bootstrap";

import { settings } from "../settings";
import { setMessage } from "../actions/alert_actions";
import { closeAddBidAvailableModal } from "../actions/edit_user_actions";
import { update_view_profile } from "../actions/user_actions";
import AdminOnly from "../dashboard-components/AdminOnly";

class AddBidsAvailable extends Component {
    constructor () {
        super();
        this.saveClick = this.saveClick.bind(this);
        this.state = { message: null, working: false };

        this.amount = null;
    }

    saveClick () {
    // click was saved
        let newState = this.state;
        newState.working = true;
        this.setState(newState);

        // basic validation of the number
        if (isNaN(this.amount.value)) {
            newState = this.state;
            newState.working = false;
            newState.message = "Enter a number for the amount";
            this.setState(newState);
        } else {
            const url = settings.api_server + "/user/addBids/" + this.props.user_id;

            const postData = { amount: parseInt(this.amount.value, 10) };

            fetch(url, {
                credentials: "include",
                method: "PUT",
                body: JSON.stringify(postData),
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    if (data.success) {
                        // reset modal
                        newState = this.state;
                        newState.working = false;
                        this.setState(newState);
                        // update the underlying profile
                        update_view_profile(this.props.user_id);

                        this.props.setMessage(<div>{this.amount.value} bid requests added to {this.props.firstname} {this.props.lastname}</div>);

                        // close the modal
                        this.props.close();
                    } else {
                        newState = this.state;
                        newState.message = "Could not add bids";
                        newState.working = false;
                        this.setState(newState);
                    }
                }.bind(this));
        }
    }

    render () {
        return (
            <Modal show={this.props.isOpen} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add or remove bids</Modal.Title></Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    <p>How many bids to add or remove? Current allowed is {this.props.balance}.</p>
                    {this.state.message !== null && <Alert variant='warning'>{this.state.message}</Alert>}
                    <p>Negative numbers for removing bids</p>
                    <Form>
                        <FormGroup as={Row} controlId="amount">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Bids to add:</b>
                            </Col>
                            <Col sm={8} md={8}>
                                <FormControl placeholder="Amount" ref={ref => { this.amount = ref; }} />
                            </Col>
                        </FormGroup>
                    </Form>

                </Modal.Body>
                <Modal.Footer><Button onClick={this.saveClick}>Save</Button> <Button onClick={this.props.close}>Close</Button></Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.edit_user.add_bids_available_open,
        user_id: state.user.user_id,
        balance: state.user.profile.bids_available,
        firstname: state.user.profile.firstname,
        lastname: state.user.profile.lastname
    };
};

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(closeAddBidAvailableModal()),
    setMessage: (message) => dispatch(setMessage(message))

});

export default connect(mapStateToProps, mapDispatchToProps)(AddBidsAvailable);
