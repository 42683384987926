import React, { Component } from "react";
import "./App.css";
import { Redirect } from "react-router";

import { connect } from "react-redux";

import PCANav from "./dashboard-components/navbar";

import { setUser, fetch_set_line_items, setLineItems, clearViewUser, update_view_profile } from "./actions/user_actions";
import { viewEditUser, openDepositModal, openDepositWithdrawModal, closeDepositModal, openTransRequestModal, openAddBidAvailableModal } from "./actions/edit_user_actions";
import CurrentPurchases from "./dashboard-components/LatestPurchases";
import RecentPurchases from "./profile_page_widgets/RecentPurchases";
import BidList from "./dashboard-components/CurrentBids";
import LineItems from "./ledger_views/gl_data";
import UserOverview from "./admin_views/user_overview";
import ManageAccount from "./admin_views/user_admin_views/ManageAccount";
import UserBidLimits from "./admin_views/BidLimits/UserBidLevels";

import PerMonthUser from "./reports/single_translations_per_month";
import InvoiceLedger from "./ledger_views/invoiceLedger";
import PasswordReset from "./admin_views/user_admin_views/PasswordReset";
import { settings } from "./settings";

import {
    Col, Row, Container, Card, Accordion, ListGroup
} from "react-bootstrap";

import CustomAccordionToggle from "./admin_views/user_admin_views/CustomAccordionToggle";

class UserDetail extends Component {
    constructor () {
        super();
        this.updateData = this.updateData.bind(this);
        this.topUp = this.topUp.bind(this);
    }

    componentDidUpdate (oldProps) {
        if (this.props.user_id !== oldProps.user_id) { this.updateData(); }
    }

    componentDidMount () {
        this.updateData();
    }

    componentWillUnmount () {
    // clear the state
        this.props.clearViewUser();
    }

    updateData () {
    // push the user to redux
        let num = this.props.match.params.user_id;
        if (!isNaN(num)) { num = parseInt(num, 10); }

        this.props.setUser(num);
        fetch_set_line_items(num, this.props.setLineItems);
    }

    topUp () {
        const url = settings.api_server + "/user/top_up/" + this.props.match.params.user_id;

        fetch(url, {
            credentials: "include",
            method: "PUT"
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    update_view_profile(this.props.match.params.user_id);
                }
            }.bind(this));
    }

    // Wrap RecentPurchases and CurrentPurchases in an accordion
    getPurchases() {
        return (
            <Accordion defaultActiveKey="purchases">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="purchases">
                            Purchases
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="purchases">
                        <Card.Body style={{padding: 0}}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <RecentPurchases who={this.props.user_id}/>
                                </ListGroup.Item>
                            </ListGroup>
                            <CurrentPurchases who={this.props.user_id}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        );
    }

    // Wrap any element in an accordion
    wrapInAccordion(title, element) {
        return (
            <Accordion defaultActiveKey={title}>
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey={title}>
                            {title}
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={title}>
                        {element}
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        );
    }

    render () {
        // Don't show anything while logged out
        if (!this.props.loggedIn) { return <PCANav />; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // if we're loading... show loading
        if (isNaN(this.props.user_id) || parseInt(this.props.user_id, 10) < 0) {
            return (
                <div>
                    <PCANav />
                    <Container>
                        <Row>
                            <Col md={12}>Loading...</Col>
                        </Row>
                    </Container>
                </div>);
        }

        return <div>
            <PCANav/>
            <Container>
                <div className="whiteTitle">
                    User Details
                </div>
                <Container className="whiteBackground dropShadow wideContainer"
                    style={{"paddingTop": "10px", "paddingBottom": "10px"}}>
                    <ManageAccount
                        user_id={this.props.user_id}
                        topUp={this.topUp}/>
                    <PasswordReset user_id={this.props.user_id}/>
                    <UserOverview who={this.props.user_id}/>
                    {this.getPurchases()}
                    <PerMonthUser user_id={this.props.user_id}/>
                    {this.wrapInAccordion("Deposits Account",
                        <LineItems who={this.props.user_id}/>)}
                    {this.wrapInAccordion("Invoice Account",
                        <InvoiceLedger who={this.props.user_id}/>)}
                    {this.wrapInAccordion("Latest Bids",
                        <BidList who={this.props.user_id}/>)}
                    <UserBidLimits user_id={this.props.user_id}/>
                </Container>
            </Container>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.user.user_id,
        isAdmin: state.profile.is_admin,
        user: state.user,
        loggedIn: state.profile.profileLoaded

    };
};

const mapDispatchToProps = dispatch => ({
    setUser: (id) => dispatch(setUser(id)),
    setLineItems: (line_items) => dispatch(setLineItems(line_items)),
    viewEditUser: () => dispatch(viewEditUser()),
    openDepositModal: () => dispatch(openDepositModal()),
    openDepositWithdrawModal: () => dispatch(openDepositWithdrawModal()),
    closeDepositModal: () => dispatch(closeDepositModal()),
    clearViewUser: () => dispatch(clearViewUser()),
    openTransRequestModal: () => dispatch(openTransRequestModal()),
    openAddBidAvailableModal: () => dispatch(openAddBidAvailableModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
