import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import WatchCar from "./sidebar_views/WatchedCars";
import Translation from "./sidebar_views/Translation";
import Bid from "./sidebar_views/Bid";
import Question from "./sidebar_views/Question";
import RequestImages from "./sidebar_views/RequestImages";
//import RequestQuickImages from "./sidebar_views/RequestQuickImages";

class AuctionCarUserStatus extends Component {
    render () {
        if (this.props.loading) {
            return null;
        }
        if (this.props.failure) { return null; }
        if (this.props.guest) {
            let moreText = null;
            if (this.props.data.auction_house.startsWith("USS")) {
                moreText = ", including request higher resolution images from USS auctions.";
            }
            return (
                <div className='container around_container'>
                    <p>Create a user account for more features{moreText}</p>
                </div>
            );
        }
        return (
            <div className='container around_container'>
                <WatchCar id={this.props.id} />
                <Translation id={this.props.id} />
                <Bid id={this.props.id} />
                <Question />
                {/* Temporarily removed, broken by Aleado <RequestQuickImages
                    lot={this.props.data.lot}
                    auction_time={this.props.data.auction_time}
                    auction_house={this.props.data.auction_house}
                />*/}
                <RequestImages id={this.props.id} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        guest: state.profile.guest,
        failure: state.auction_car.failure
    };
};


export default connect(mapStateToProps)(AuctionCarUserStatus);
