import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import { settings } from "../settings";
import { Redirect } from "react-router";

import { setSearchFromDict, force_show } from "../actions/search_actions";

import {
    ListGroup,
    Image,
    Container,
    ListGroupItem
} from "react-bootstrap";

import { setPreviousAuctionList, setPreviousAuctionMessage, clearPreviousAuction } from "../actions/previous_auction_actions";

class PreviousCarData extends Component {
    constructor () {
        super();
        this.getFirstImage = this.getFirstImage.bind(this);
    }

    getFirstImage (imageStr) {
    /*
        * get the first image from the list of images.
        * returns an image component, or null, if no images
        *
        */
        if (imageStr === null) { return null; }
        const imageList = imageStr.split("#");
        if (imageList.length > 0) { return <Image style={{ paddingRight: "5px" }} className="float-left" src={imageList[0]} thumbnnail />; }
        return null;
    }

    render () {
    // from when we had relative time

        return <ListGroup.Item action href={"#/auction_car_history/" + this.props.data.vehicle_id}>
            {this.getFirstImage(this.props.data.images)}
            TODO sold or not on {this.props.data.auction_time.split(" ")[0]} at {this.props.data.auction_house} with {this.props.data.mileage}km
        </ListGroup.Item>;
    }
}

class PreviousAuctions extends Component {
    constructor () {
        super();
        this.fetchData = this.fetchData.bind(this);
        this.clickSeeAll = this.clickSeeAll.bind(this);
        this.state = { redirect: false };
    }

    fetchData () {
    // can we do this?
        if (!this.props.auction_car_loading && this.props.auction_car_data !== null) {
            // let's do it
            const url = settings.api_server + "/history/similar";
            const getQ = "?" +
                "make=" + this.props.auction_car_data.make +
                "&model=" + this.props.auction_car_data.model +
                "&colour=" + this.props.auction_car_data.colour +
                "&odo=" + this.props.auction_car_data.mileage +
                "&year=" + this.props.auction_car_data.year +
                "&transmission=" + this.props.auction_car_data.transmission +
                "&chassis_code=" + this.props.auction_car_data.chassis_code;

            fetch(url + getQ, {
                credentials: "include",
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    if (data.success) {
                        this.props.setPreviousAuctionList(data.matches);
                    } else {
                        this.props.setPreviousAuctionMessage(data.message);
                    }
                }.bind(this));
        }
    }

    componentDidUpdate (prevProps) {
        if (this.props.auction_car_loading !== prevProps.auction_car_loading &&
            !this.props.auction_car_loading && this.props.auction_car_data !== null) {
            // load this car
            this.fetchData(this.props.userID);
        }
    }

    componentWillUnmount () {
        this.props.clearPreviousAuction();
    }

    clickSeeAll () {
    // load the serach criteria
        const query = {
            make_selected: this.props.auction_car_data.make,
            models_selected: [this.props.auction_car_data.model],
            odo_min: this.props.auction_car_data.mileage - 2000,
            odo_max: this.props.auction_car_data.mileage + 5000,
            start_year: this.props.auction_car_data.year,
            end_year: this.props.auction_car_data.year
        };
        this.props.setSearchFromDict(query);
        // update the display
        this.props.force_show();
        // move to the search screen
        this.setState({ ...this.state, redirect: true });
    }

    render () {
        let noneText = null;
        if (this.state.redirect) { return <Redirect push to={"/history"} />; } else if (!this.props.loaded) { noneText = <Container>Loading</Container>; } else if (this.props.auctions.length === 0) { noneText = <Container>Can not find previous sales of this vehicle</Container>; } else {
            var data = this.props.auctions.map((d) => <PreviousCarData key={d.vehicle_id} data={d} />);
            data.push(<ListGroupItem action onClick={this.clickSeeAll} >See all similar</ListGroupItem>);
            // add in the "all" link at the bottom
        }

        return <ListGroup variant="flush" className="flush_card">
            {this.props.message !== null && this.props.message}
            {noneText}
            {data}
        </ListGroup>;
    }
}

const mapStateToProps = state => {
    return {
    // our data
        loaded: state.previous_auctions.loaded,
        auctions: state.previous_auctions.auctions,
        message: state.previous_auctions.message,

        // the car to load
        auction_car_loading: state.auction_car.loading,
        auction_car_data: state.auction_car.data,

        // the search stuff, for querying for more cars
        data: state.search
    };
};

const mapDispatchToProps = dispatch => ({
    setPreviousAuctionList: (list) => dispatch(setPreviousAuctionList(list)),
    setPreviousAuctionMessage: (m) => dispatch(setPreviousAuctionMessage(m)),
    clearPreviousAuction: () => dispatch(clearPreviousAuction()),
    setSearchFromDict: (theDict) => dispatch(setSearchFromDict(theDict)),
    force_show: () => dispatch(force_show())
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousAuctions);
