import React, { Component } from "react";
import { connect } from "react-redux";

import "../App.css";

import {
    Alert,
    Button,
    Modal,
    Col, Row,
    FormControl
} from "react-bootstrap";

import Octicon, { Plus } from "@githubprimer/octicons-react";

import { setMessage } from "../actions/alert_actions";
import { settings } from "../settings";
import { Link } from "react-router-dom";

class AddPurchase extends Component {
    constructor () {
        super();

        this.make = "";
        this.model = "";
        this.year = "";

        this.state = { show: false, setting: false, message: null };
        this.save = this.save.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal () {
        const newState = this.state;
        newState.show = !newState.show;
        this.setState(newState);
    }

    save () {
        const url = settings.api_server + "/invoice/admin/";
        // in post, so proxy servers can't see the data
        const postdata = {
            user_id: this.props.who,
            make: this.make.value,
            model: this.model.value,
            year: this.year.value
        };

        const newState = this.state;
        newState.setting = true;
        this.setState(newState);

        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    const newState = this.state;
                    newState.setting = false;
                    newState.message = "New purchase failed - unknown error";
                    this.setState(newState);
                } else { return response.json(); }
            })
            .then(function (data) {
                const newState = this.state;
                if (data.success) {
                    // close it, set banner
                    newState.setting = false;
                    newState.message = null;
                    newState.show = false;

                    this.props.setMessage(<div> <Link to={"/purchase_detail/" + data.purchase_id}>Purchase id {data.purchase_id} </Link> has been created</div>);
                    this.setState(newState);
                } else {
                    newState.setting = false;
                    newState.message = data.message;
                    this.setState(newState);
                }
            }.bind(this));
    }

    render () {
        return <span>
            <Button onClick={this.toggleModal} size="small" ><Octicon icon={Plus} /> Add non-auction purchase</Button>
            <Modal show={this.state.show} onHide={this.toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Add new purchase</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.message !== null ? <Alert variant="warning">{this.state.message}</Alert> : null}
                    <p>Non-auction purchase</p>

                    <p>All fields are optional. If a parts purchase, put the model year for the car purchased.</p>
                    <Row>
                        <Col md={4}>Model year:</Col>
                        <Col md={8}>
                            <FormControl onChange={this.handleChange} ref={ref => { this.year = ref; }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Make:</Col>
                        <Col md={8}>
                            <FormControl onChange={this.handleChange} ref={ref => { this.make = ref; }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>Model:</Col>
                        <Col md={8}>
                            <FormControl onChange={this.handleChange} ref={ref => { this.model = ref; }} />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.toggleModal}>Close</Button>
                    <Button disabled={this.state.setting} variant="primary" onClick={this.save}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </span>;
    }
}

const stateToProps = state => {
    state;
    return {
    };
};

const dispatchToProps = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(stateToProps, dispatchToProps)(AddPurchase);
