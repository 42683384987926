/**
 * This component shows an admin which quiz is required to be completed for a
 * given purchase, if any. It also has buttons which allow admins to bypass the
 * quiz, or remind the customer of it.
 */

import { settings } from "../settings";
import React from "react";
import { Accordion, Button, Card, Modal } from "react-bootstrap";
import CustomAccordionToggle from "../admin_views/user_admin_views/CustomAccordionToggle";

export default function CarPendingQuizAdmin({purchase_id}) {
    const [quiz, setQuiz] = React.useState(null);
    const [error, setError] = React.useState(null);
    // we can set this to trigger re-fetching the quiz after bypassing it
    const [update, setUpdate] = React.useState(Date.now());
    // set to true if admin clicks "Remind" button, which disables the button to
    // prevent accidental repeated reminders
    const [reminded, setReminded] = React.useState(false);
    // if true, we'll show the modal for assigning a quiz to a purchase
    const [showModal, setShowModal] = React.useState(false);

    // when we load a new purchase, clear the reminded flag
    React.useEffect(() => {
        setReminded(false);
    }, [purchase_id]);

    // fetch the quiz for the purchase
    React.useEffect(() => {
        const url = settings.api_server + "/quiz/admin/" + purchase_id;
        // if component is unmounted or purchase changes before response
        // arrives, cancel setting the quiz/error.
        let cancelled = false;
        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText))
            .then(
                (quizTemplate) => {
                    if (!cancelled) {
                        if (quizTemplate.success) {
                            setQuiz(quizTemplate.template);
                            setError(null);
                        }
                        else {
                            setQuiz(null);
                            setError("Error: " + quizTemplate.message);
                        }
                    }
                },
                (reason) => {
                    if (!cancelled) {
                        setQuiz(null);
                        setError("Error: " + reason);
                    }
                });
        return () => {cancelled = true;};
    }, [purchase_id, update]);

    // bypass the quiz
    function bypassQuiz() {
        const url = settings.api_server + "/quiz/admin/" + purchase_id;
        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(
                resp => {
                    if (!resp.ok)
                        console.log("Failed to bypass quiz: " + resp.status +
                            " " + resp.statusText);
                    else
                        setUpdate(Date.now());
                },
                reason => {
                    console.log("Failed to bypass quiz: " + reason);
                }
            );
    }

    // remind the user of the quiz
    function remindQuiz() {
        const url = settings.api_server + "/quiz/admin/" + purchase_id + "/remind";
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(
                resp => {
                    if (!resp.ok)
                        console.log("Failed to remind user of quiz: " +
                            resp.status + " " + resp.statusText);
                },
                reason => {
                    console.log("Failed to remind user of quiz: " + reason);
                }
            );
        setReminded(true);
    }

    let quizContents;
    if (error != null) {
        quizContents = error;
    }
    else if (quiz != null) {
        quizContents = <>
            {quiz.quiz_name} &nbsp;
            <Button size="sm" variant="outline-warning" onClick={bypassQuiz}>
                Remove
            </Button> &nbsp;
            <Button size="sm" variant="outline-info" onClick={remindQuiz}
                disabled={reminded}
            >
                {reminded ? "Reminder sent" : "Remind"}
            </Button>
        </>;
    }
    else {
        // note on the modal below: onHide is called when the close button or
        // backdrop is clicked, onExit is called when the modal closes, i.e.
        // when setShowModal(false) is called
        quizContents = <>
            None &nbsp;
            <Button size="sm" variant="outline-primary"
                onClick={() => setShowModal(true)}
            >
                Assign
            </Button>
            <Modal show={showModal} size="lg"
                onHide={() => setShowModal(false)}
                onExit={() => setUpdate(Date.now())}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Assign quiz
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: "0px"}}>
                    <QuizTemplateList purchase_id={purchase_id}
                        setShow={setShowModal}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }

    return quizContents;
}

/**
 * Renders a list of quiz templates, and buttons to assign them to the purchase.
 * When an assign button is clicked, it closes the modal it belongs to.
 * @param {Object} props
 * @param {string} props.purchase_id
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShow
 */
function QuizTemplateList({purchase_id, setShow}) {
    const [quizzes, setQuizzes] = React.useState(null);
    // wrapping this with 'fetched' prevents calling the endpoint infinitely
    const [fetched, setFetched] = React.useState(false);
    if (!fetched) {
        // fetch quiz templates
        const url = settings.api_server + "/quiz/admin/all";
        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(
                resp => {
                    if (resp.ok) {
                        return resp.json();
                    }
                    else {
                        return Promise.reject(`${resp.status} ${resp.statusText}`);
                    }
                }
            )
            .then(
                data => {
                    setQuizzes(data);
                    console.log("Found " + data.length + " quizzes");
                },
                reason => {
                    setQuizzes(null);
                    console.log("Failed to fetch quiz templates. Reason: " + reason);
                }
            );
        setFetched(true);
    }

    function assignQuiz(quiz_id) {
        const url = `${settings.api_server}/quiz/admin/${purchase_id}/${quiz_id}`;
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                else {
                    return Promise.reject(`${resp.status} ${resp.statusText}`);
                }
            })
            .then(data => {
                if (!data.success) {
                    return Promise.reject(`${data.message}`);
                }
            })
            .catch(reason => {
                console.log("Failed to assign quiz. Reason: " + reason);
            })
            .finally(() => setShow(false));
    }

    // List of quiz templates is an accordion, each item can be expanded to show
    // the questions in the quiz
    return <Accordion defaultActiveKey="0">
        {quizzes && quizzes.map(template => <Card key={template.quiz_id}>
            <Card.Header>
                <CustomAccordionToggle eventKey={template.quiz_id} iconBefore>
                    {template.quiz_name} ({template.questions.length} questions)
                    <Button style={{display: "inline-block", float: "right"}}
                        onClick={() => assignQuiz(template.quiz_id)}
                    >
                        Assign
                    </Button>
                </CustomAccordionToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={template.quiz_id}>
                <Card.Body>
                    <ol>
                        {template.questions.map(q => <li key={q.question_id}>
                            {q.question_text}
                        </li>)}
                    </ol>
                </Card.Body>
            </Accordion.Collapse>
        </Card>)}
    </Accordion>;
}
