import { settings } from "../settings";

const search_defaultState = {
    total_auction: "loading",
    makes: [],
    make_model: [],
    make_selected: settings.make_placeholder,
    models_selected: [],
    model_id_selected: [],
    start_year: "",
    end_year: "",
    fetching: false,
    results: null,
    min_grade: "",
    odo_min: "",
    odo_max: "",
    chassis: "",
    engine_min: "",
    engine_max: "",
    trans: "",
    gears: null,
    auction: "",
    auctionNumber: "",
    start_date: "",
    end_data: "",
    sortColumn: -4,
    page: 0,
    auctionHouseList: [],
    auctionHouseLoaded: false,
    auctionHouseLastUpdate: 0,
    showAuctionHouses: false,
    showSearch: true,
    baseinfoLastUpdate: 0,
    fetched_pages: [],
    numericCount: -1,
    ac: false,
    aw: false,
    le: false,
    sr: false,
    pw: false,
    ps: false,
    tv: false,
    camper: false,
    dump: false,
    crane: false,
    doubleCab: false,
    loader: false,
    bus: false,
    grade_star: false,
    grade_two: false,
    grade_three: false,
    grade_three_five: false,
    grade_four: false,
    grade_four_five: false,
    grade_five: false,
    grade_R: false,
    keyword: "",
    drivetrain: settings.drivetrain_placeholder
};

const searchHistory = (state = search_defaultState, action) => {
    switch (action.type) {
    case "HISTORY_BASE_INFO":

        // make a list of makes, then keep the list of everything
        var makes = [];
        // var total = 0;
        for (let i = 0; i < action.data.length; i++) {
        // is this model already in the list
            let inList = false;
            for (let k = 0; k < makes.length && !inList; k++) {
                if (makes[k].make === action.data[i].make) {
                    inList = true;
                    // add the amount
                    // makes[k].count += parseInt(action.data[i].TAG3, 10);
                }
            }
            if (!inList) {
                // makes.push( {'make': action.data[i].MARKA_NAME, 'count': parseInt(action.data[i].TAG3, 10)} )
                makes.push({ make: action.data[i].make });
            }
        // total += parseInt(action.data[i].TAG3, 10);
        }

        return {
            ...state,
            makes,
            make_model: action.data,
            baseinfoLastUpdate: (new Date()).getTime()
        // total_auction: total,
        };
    case "HISTORY_SET_FETCHING":
        return {
            ...state,
            fetching: true,
            showSearch: false
        };
    case "HISTORY_SET_RESULTS":
        return {
            ...state,
            results: action.results,
            fetching: false,
            page: 0,
            showSearch: false
        };
    case "HISTORY_CLEAR_RESULTS":
        return {
            ...state,
            results: search_defaultState.results
        };
    case "HISTORY_SELECT_MAKE":
        return {
            ...state,
            make_selected: action.make_name,
            models_selected: search_defaultState.models_selected
        };
    case "HISTORY_CLEAR_SELECTION":
        // total reset
        // consider not clearing auctionHouseList/auctionHouseLoaded
        return {
            ...search_defaultState,
            makes: state.makes, // don't clear the base info
            make_model: state.make_model
        };
    case "HISTORY_SELECT_MODELS":
        return {
            ...state,
            models_selected: action.models,
            model_id_selected: action.ids

        };
    case "HISTORY_SET_START_YEAR":
        return {
            ...state,
            start_year: action.start_year
        };
    case "HISTORY_SET_END_YEAR":
        return {
            ...state,
            end_year: action.end_year
        };
    case "HISTORY_MIN_GRADE":
        return {
            ...state,
            min_grade: action.min
        };
    case "HISTORY_SET_ODO_MIN":
        return {
            ...state,
            odo_min: action.odo_min
        };
    case "HISTORY_SET_ODO_MAX":
        return {
            ...state,
            odo_max: action.odo_max
        };
    case "HISTORY_SET_CHASSIS":
        return {
            ...state,
            chassis: action.chassis
        };
    case "HISTORY_SET_ENGINE_MIN":
        return {
            ...state,
            engine_min: action.engine_min
        };
    case "HISTORY_SET_ENGINE_MAX":
        return {
            ...state,
            engine_max: action.engine_max
        };
    case "HISTORY_SET_TRANS":
        return {
            ...state,
            trans: action.trans
        };
    case "HISTORY_SELECT_GEARS":
        return {
            ...state,
            gears: action.gears
        };
    case "HISTORY_SET_AUCTION":
        return {
            ...state,
            auction: action.auction
        };
    case "HISTORY_SET_START_DATE":
        return {
            ...state,
            start_date: action.start_date
        };
    case "HISTORY_SET_END_DATE":
        return {
            ...state,
            end_date: action.end_date
        };

    case "HISTORY_NEXT_PAGE":
        return {
            ...state,
            page: state.page + 1
        };
    case "HISTORY_PREV_PAGE":
        return {
            ...state,
            page: state.page - 1
        };

    case "HISTORY_SET_SORT_COLUMN":
        return {
            ...state,
            sortColumn: action.sortColumn
        };
    case "HISTORY_TOGGLE_SHOW_SEARCH":
        return {
            ...state,
            showSearch: !state.showSearch
        };
    case "HISTORY_SET_AUCTION_HOUSE_LIST":
        return {
            ...state,
            auctionHouseList: action.auctionHouseList,
            auctionHouseLoaded: true
        };
    case "HISTORY_TOGGLE_AUCTION_HOUSES":
        return {
            ...state,
            showAuctionHouses: !state.showAuctionHouses
        };
    case "HISTORY_TOGGLE_AUCTIONS":{
        /* find the auction house id, toggle the active status. */
        /* eslint-disable no-inner-declarations, no-case-declarations  */

        function toggleInList (auc) {
            // is this in the list?
            if (action.auctions.indexOf(auc.AUCTION) !== -1) {
                if ("turnOn" in action) { auc.selected = action.turnOn; } else { auc.selected = !auc.selected; }
            }
            return auc;
        }
        /* eslint-enable no-inner-declarations, no-case-declarations */
        var newAucList = state.auctionHouseList.map(toggleInList);

        return {
            ...state,
            auctionHouseList: newAucList,
            auctionHouseLastUpdate: (new Date()).getTime()
        };
    }
    case "SET_HISTORY_SEARCH_FROM_DICT":
        // rename fields that might be different
        if ("makeStr" in action.theDict) { action.theDict.make_selected = action.theDict.makeStr; }
        if ("models" in action.theDict) { action.theDict.models_selected = action.theDict.models; }
        return {
            ...search_defaultState, // first clear the search state
            ...action.theDict // then override with any provided info
        };
    default:
        return state;
    }
};

export default searchHistory;
