const setPreviousAuctionList = function (data) {
    return {
        type: "SET_PREV_AUCTION_LIST",
        auctions: data
    };
};
const setPreviousAuctionMessage = function (message) {
    return {
        type: "SET_PREV_AUCTION_MESSAGE",
        message
    };
};
const clearPreviousAuction = function () {
    return {
        type: "CLEAR_PREV_AUCTION"
    };
};

export { setPreviousAuctionList, setPreviousAuctionMessage, clearPreviousAuction };
