import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Container
} from "react-bootstrap";

class TodaysStats extends Component {
   
    render () {
       
        return (
            <Container>
                <p>More to come!</p>
            </Container>
        );
    }
}
const mapStateToProps = state => {
    return {
        profile: state.profile
    };
};

export default connect(mapStateToProps)(TodaysStats);
