import React, { Component } from "react";
import { connect } from "react-redux";


import {
    Alert,
    Modal,
    Button,
    Form
} from "react-bootstrap";

import { settings } from "../settings";

import AdminOnly from "../dashboard-components/AdminOnly";

import { hideRequestModal } from "../actions/image_request_list_actions";

import { setAuctionCarDetail, getAuctionCarData, clearAuctionData } from "../actions/auction_car_actions";


import RequestedImageUpload from "./requested_image_upload";
// this component will fetch the images
import ImageList from "./view_requested_car_images";
import AuctionCar from "../dashboard-components/ShowImages";
import CarDetails from "./subviews/shortCarInfo";


const DEFAULT_STATE = {
    pushing_data: false,
    errorMessage: null,
    response: "",
    lock_loaded: false,
    lock_data: null,
    notes: ""
};

class RequestedImageModal extends Component {

    
    constructor() {
        super();

        this.state = { ...DEFAULT_STATE };

        this.handleClose = this.handleClose.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.getRequestFromID = this.getRequestFromID.bind(this);
        this.getData = this.getData.bind(this);
        this.updateLock = this.updateLock.bind(this);
        this.unlock = this.unlock.bind(this);
        this.ignore = this.ignore.bind(this);
        this.saveData = this.saveData.bind(this);
        this.changeNotes = this.changeNotes.bind(this);
    }

    getData() {
        var request = this.getRequestFromID();

        if (request != null) {
            getAuctionCarData(request.vehicle_id);
        }

        // check for locks
        this.updateLock();
        this.lockUpdater = setInterval(this.updateLock, 20000);
    }

    handleClose() {
        this.unlock();
        this.props.clearAuctionData();
        this.setState({ ...DEFAULT_STATE });
        this.props.hideRequestModal();
    }

    ignore() {
        this.saveData("DELETE", "");
    }

    saveAndClose() {
        this.saveData("POST", this.state.notes);
    }

    changeNotes(e) {
        this.setState({ ...this.state, notes: e.target.value });
    }

    saveData(method, notes) {

        this.setState({ ...this.state, pushing_data: true });

        var url = settings.api_server + "/image_requests/complete/" + this.props.showModalWhich;
        fetch(url, {
            credentials: "include",
            method: method,
            body: JSON.stringify({ "notes": notes }),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Problem contacting server" });
                    throw new Error("Bad response from server");

                }
                else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ pushing_data: false, errorMessage: null, response: "" });
                    this.handleClose();
                    // refresh underlying list
                    if ("update" in this.props) {
                        this.props.update();
                    }
                }
                else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Could not complete request" });
                }
            }.bind(this));

    }

    getRequestFromID() {
        // find the required data from the list of requests
        var theRequest = null;
        // weird back button bug fix. If someone "backs" on this page this runs
        if ("request_list" in this.props && !(this.props.request_list === undefined)){
            for (var i = 0; i < this.props.request_list.length; i++) {
                if (this.props.request_list[i].image_request_id === this.props.showModalWhich)
                    theRequest = this.props.request_list[i];
            }
        }
        return theRequest;
    }

    updateLock() {

        // check if we own the lock
        var url = settings.api_server + "/image_requests/lock/" + this.props.showModalWhich;
        if (this.props.showModalWhich !== null && this.props.showModalWhich > -1) {
            fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "content-type": "application/json",
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    this.setState({ ...this.state, lock_loaded: true, lock_data: data });
                    // update "Locked by xyz" in parent component
                    if ("update" in this.props) {
                        this.props.update();
                    }
                }.bind(this));
        }
        else {
            // some cleanup
            clearInterval(this.lockUpdater);
        }
    }

    unlock() {
        // stop updateing the lock 
        clearInterval(this.lockUpdater);

        // free the lock
        if (this.props.showModalWhich !== null) {
            var url = settings.api_server + "/image_requests/lock/" + this.props.showModalWhich;

            fetch(url, {
                credentials: "include",
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    // update "Locked by xyz" in parent component
                    if ("update" in this.props) {
                        this.props.update();
                    }
                }.bind(this));
        }
    }

    render() {

        var theRequest = this.getRequestFromID();
        if (theRequest == null)
            return null;
        var buttonText = theRequest.completed ? "Re-notify" : "Complete";
        var lockText = "Loading lock data...";
        var lockColour = "light";
        if (this.state.lock_loaded) {
            if (this.state.lock_data.owner) {
                lockText = "Locked image request";
                lockColour = "success";
            }
            else {
                lockText = "Image requested locked by " + this.state.lock_data.locked_by;
                lockColour = "danger";
            }
        }

        // mr-auto is a bootstrap classname
        return (
            <Modal size='lg' show={this.props.showModal} onEnter={this.getData} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    <Alert variant={lockColour}>{lockText}</Alert>
                    <p>Upload images here. If you upload and close, the images will be saved here.</p>
                    {theRequest.completed ? <p>Images are immediately available to clients up upload, as the request has been already marked as complete</p>
                        : <p>Images will be available for the client on their auction page once you click "Complete".</p>
                    }
                    <AuctionCar vehicle_id={theRequest.vehicle_id} min={0} />
                    <RequestedImageUpload image_request_id={this.props.showModalWhich} />
                    <ImageList vehicle_id={theRequest.vehicle_id} user_id={theRequest.requester_id} />
                    <Form.Group controlId="notes">
                        <Form.Label>Notes about the images</Form.Label>
                        <Form.Control as="textarea" rows={3} value={this.state.notes} onChange={this.changeNotes} />
                    </Form.Group>
                    <CarDetails />
                    <p>Client will be emailed every time "{buttonText}" is clicked. You can upload more images an re-notify later.</p>
                </Modal.Body>
                <Modal.Footer>
                    {!theRequest.completed && <div className="mr-auto"><Button disabled={this.state.pushing_data} onClick={this.ignore}>Ignore</Button></div>}  <Button disabled={this.state.pushing_data} onClick={this.saveAndClose}>{buttonText}</Button> <Button disabled={this.state.pushing_data} onClick={this.handleClose}>Close window</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


const mapStateToProps = state => {
    return {

        request_list: state.image_request_list.request_list,
        current_image_list: state.image_request_list.current_image_list,
        num_images: state.image_request_list.num_images,
        showModal: state.image_request_list.showModal,
        showModalWhich: state.image_request_list.showModalWhich,
    };
};

const mapDispatchToProps = dispatch => ({
    hideRequestModal: () => dispatch(hideRequestModal()),
    clearAuctionData: () => dispatch(clearAuctionData()),
    setAuctionCarDetail: (car_data) => dispatch(setAuctionCarDetail(car_data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestedImageModal);