/**
 * This is the Task Manager widget on the Dashboard page, for the Task Manager
 * page see src/TaskManager.js
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {settings} from "../settings";

import {
    Button,
    Container,
    ListGroup
} from "react-bootstrap";

import "./DashWidget.css";

class TaskManager extends Component {

    constructor() {
        super();
        this.state = {loading: true, task_list: []};
    }

    componentDidMount() {
        const url = settings.api_server + "/task_widget/";
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({loading: false, task_list: data});
            }.bind(this));
    }

    /**
     * assumes date and time are seperated by 'T'
     * @param {string} uglyDate 
     */
    prettyDate(uglyDate) {
        const [date, time] = uglyDate.split("T", 2);
        const dot = time.indexOf("."); // remove fractions of seconds
        return date + " " + (dot >= 0 ? time.substring(0, dot) : time);
    }

    getTaskList() {
        return <ListGroup variant="flush" className="widgetList">
            {this.state.task_list.map((task) => {
                let cssClass = "";
                if (task.severity == "REMINDER") {
                    cssClass += "severityReminder";
                }
                let link = null;
                if (task.site_link) {
                    link = "#/" + task.site_link;
                }
                else if (task.vehicle_id) {
                    link = "#/auction_car/" + task.vehicle_id;
                }
                return <ListGroup.Item key={task.time}
                    className="widgetListItem"
                    action={link != null} href={link}
                >
                    <div className={cssClass}>
                        {this.prettyDate(task.time)} &nbsp; {task.text}
                    </div>
                </ListGroup.Item>;
            })}
        </ListGroup>;
    }

    render () {
        return (
            <Container>
                {this.state.loading ? <p>Loading...</p> : this.getTaskList()}
                <div style={{"textAlign":"right"}}>
                    <Button href="#/taskManager">Task Manager</Button>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile
    };
};

export default connect(mapStateToProps)(TaskManager);
