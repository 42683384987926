import React from "react";
import {Card, Spinner} from "react-bootstrap";

/**
 * A spinner which can be used to occupy part of a page that can't be rendered
 * yet because resources are being fetched
 * @param {Object} props
 * @param {string} [props.message]
 */
export default function LoadingSpinner(props) {
    const message = props.message ? props.message : "Please wait...";
    return <div className="container" style={{textAlign: "center", width: "100%"}}>
        <Card style={{maxWidth: "180px", padding: "10px", marginTop: "20px", marginLeft: "auto", marginRight: "auto"}}>
            <Card.Body style={{padding: "0px"}}>
                <div>{message}</div>
                <Spinner animation="border" role="status" variant="primary" style={{marginTop: "10px"}}/>
            </Card.Body>
        </Card>
    </div>;
}
