import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Table,
    Button,
    FormControl
} from "react-bootstrap";

import { updateShippingFee, deleteRow, addRow } from "../actions/shipping_fee_actions";

/*
receives a port as the argument in shipping_fees
*/
class ShowCalculation extends Component {
    render () {
        let theRest = "all sizes";
        if (this.props.shipping_fees.length > 1) {
            theRest = "Larger than " + this.props.shipping_fees[this.props.shipping_fees.length - 2].max_m3;
        }

        const rows = [];
        for (let i = 0; i < this.props.shipping_fees.length - 1; i++) {
            rows.push(
                <tr>
                    <td>
                        <FormControl
                            value={this.props.shipping_fees[i].max_m3}
                            onChange={(e) => this.props.updateShippingFee(this.props.shipping_fees[i].id, "max_m3", e.target.value)} />
                    </td>
                    <td>
                        <FormControl
                            value={this.props.shipping_fees[i].shipping_fee}
                            onChange={(e) => this.props.updateShippingFee(this.props.shipping_fees[i].id, "shipping_fee", e.target.value)} />

                    </td>
                    <td><Button variant="outline-danger" onClick={() => this.props.deleteRow(this.props.shipping_fees[i].id)}>Delete</Button></td>
                </tr>
            );
        }
        // last row is special!
        rows.push(
            <tr>
                <td>{theRest}</td>
                <td>
                    <FormControl
                        value={this.props.shipping_fees[this.props.shipping_fees.length - 1].shipping_fee}
                        onChange={(e) => this.props.updateShippingFee(this.props.shipping_fees[this.props.shipping_fees.length - 1].id, "shipping_fee", e.target.value)} />
                </td>
                <td><Button variant="outline-primary" onClick={() => this.props.addRow(this.props.id)}>Add size</Button></td>
            </tr>
        );
        return <Table>
            <thead>
                <tr>
                    <th>To m<sup>3</sup></th>
                    <th>Shipping fee (USD)</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            {rows}
        </Table>;
    }
}
ShowCalculation.propTypes = {
    shipping_fees: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired // the ID of the line item
};

const mapStateToProps = state => {
    state; // linter food.
    return {
    };
};

const mapDispatchToProps = dispatch => ({
    updateShippingFee: (id, field, value) => dispatch(updateShippingFee(id, field, value)),
    deleteRow: (id) => dispatch(deleteRow(id)),
    addRow: (port_id) => dispatch(addRow(port_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowCalculation);
