import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Modal,
} from "react-bootstrap";

import { settings } from "../../../settings";
import { setMessage } from "../../../actions/alert_actions";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import UserLookupTool from "../../../admin_views/UserLookupTool";

const DEFAULT_STATE = {
    chosenUser: null,
    chosenID: null,
    showModal: false,
};


class RequestTranslation extends Component {
    

    constructor() {
        super();
        this.state = { ...DEFAULT_STATE };
        this.save = this.save.bind(this);
        this.chosen = this.chosen.bind(this);
    }

    chosen(id, name) {
        this.setState({ ...this.state, chosenID: id, chosenName: name, users: [] });
    }



    save() {
        // send the request
        // close the modal
        var url = settings.api_server + "/translations/access/" + this.props.car_data.vehicle_id + "/" + this.state.chosenID;

        var postdata = {
            ...this.props.car_data
        };
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    // show the banner
                    this.props.setMessage("Request made for user");
                }
                else {
                    // show the message
                    this.props.setMessage("Request failed!");
                }
                // go away
                this.setState({ ...DEFAULT_STATE });
            }.bind(this));

    }

    render() {
        if (!this.props.isAdmin)
            return null;



        return <div>
            <Button
                block
                onClick={() => this.setState({ ...this.state, showModal: true })}>
                Create translation for user
            </Button>
            <Modal show={this.state.showModal} onHide={() => this.setState({ ...DEFAULT_STATE })}>
                <ModalHeader>
                    <Modal.Title>Request Translation for User</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    Choose user to request translation for:
                    <UserLookupTool chosenCallback={this.chosen} />
                    {this.state.chosenID === null ?
                        <p>Chosen: None</p> :
                        <p>Chosen: {this.state.chosenName}</p>
                    }
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={this.save} disabled={this.state.chosenID === null}>
                        Do Request
                    </Button>
                    <Button variant="secondary" onClick={() => this.setState({ ...DEFAULT_STATE })}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>;

    }
}


const stateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,

        car_data: state.auction_car.data,
        loading: state.auction_car.loading,
    };
};

const dispatches = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(stateToProps, dispatches)(RequestTranslation);
