import React, { Component } from "react";

import { settings } from "../../settings";

import {
    Container, Col, Row,
    Button,
    FormGroup, FormControl,
    Accordion, Card
} from "react-bootstrap";

import CustomAccordionToggle from "./CustomAccordionToggle";

export default class PasswordReset extends Component {
    constructor () {
        super();
        this.setPassword = this.setPassword.bind(this);

        this.state = { setting: false, message: "" };

        this.newPass = "";
    }

    setPassword () {
        const url = settings.api_server + "/user/changePasswd";
        // in post, so proxy servers can't see the data
        const postdata = { user_id: this.props.user_id, newPass: this.newPass.value };

        const newState = this.state;
        newState.setting = true;
        this.setState(newState);

        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    const newState = this.state;
                    newState.setting = false;
                    newState.message = "Password set failed";
                    this.setState(newState);
                } else { return response.json(); }
            })
            .then(function () {
                const newState = this.state;
                newState.setting = false;
                newState.message = "Password set";
                this.setState(newState);
            }.bind(this));
    }

    componentDidMount () {
        const newState = this.state;
        newState.setting = false;
        newState.message = "";
        this.setState(newState);
    }

    render () {
        return (
            <Accordion defaultActiveKey="passwordReset">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="passwordReset">
                            Change Password
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="passwordReset">
                        <Card.Body>
                            <FormGroup controlId="formHorzusername"
                                style={{marginBottom: 0}}>
                                <Row>
                                    <Col md={4}>Set password to:</Col>
                                    <Col md={8}>
                                        <FormControl onChange={this.handleChange} ref={ref => { this.newPass = ref; }} />
                                    </Col>
                                </Row>
                                <Row><Col md={4}></Col><Col>No ideas? Maybe use {Math.random().toString(36).slice(2)}</Col></Row>
                                <Row>
                                    <Col md={4}></Col>
                                    <Col><Button active={!this.state.setting} onClick={this.setPassword}>{this.state.setting ? "Setting password" : "Set"}</Button></Col>
                                </Row>
                                <Row>
                                    <Col md={4}></Col>
                                    <Col>{this.state.message}</Col>
                                </Row>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        );
    }
}