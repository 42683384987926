import React, { Component } from "react";

import { settings } from "../settings";

import {
    Table, Accordion, Container, Card
} from "react-bootstrap";
import CustomAccordionToggle from "../admin_views/user_admin_views/CustomAccordionToggle";

import "../user_views/ProfileTable.css";

class PerMonthUser extends Component {
    constructor () {
        super();
        this.state = { loaded: false, data: [] };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        const url = settings.api_server + "/reports/userTranslations/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.setState({ ...this.state, loaded: true, data });
            }.bind(this));
    }

    render () {
        const cardBody = !this.state.loaded ? <p>Loading...</p> : (
            <Table responsive size="sm" className="profileTable">
                <thead>
                    <tr>
                        <th>Year-Month</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.map(d => <tr key={parseInt(d.year, 10) + "-" + parseInt(d.month, 10)}>
                        <td>{parseInt(d.year, 10)}-{parseInt(d.month, 10)}</td>
                        <td>{d.count}</td>
                    </tr>)}
                </tbody>
            </Table>
        );
        return <Accordion defaultActiveKey="perMonth">
            <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                <Card.Header>
                    <CustomAccordionToggle eventKey="perMonth">
                        Translation Counts
                    </CustomAccordionToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="perMonth">
                    <Card.Body style={{padding: 0}}>
                        {cardBody}
                    </Card.Body>
                </Accordion.Collapse>
            </Container>
        </Accordion>;
    }
}

export default PerMonthUser;
