
export const translations_defaultState = {
    translationsLoaded: false,
    translationList: [],
    show_translation: false,
    show_translation_vehicle_id: null,
    main: { Auction: "", Lot: "", Year: "", Make: "", Model: "", Odometer: "", Trim: "", Colour: "", Grade: "", "Interior Grade": "", "Exterior Grade": "" },
    description: "",
    sales_points: "",
    notes: "",
    report: "",
    diagram_notes: "",
    exporter_notes: "",
    show_translation_data: null,
    give_request_back: false,
    lock_loaded: false,
    isLockOwner: false,
    who: null,
    when: null
};

const translations = (state = translations_defaultState, action) => {
    let newMain = null;
    let key = null;
    switch (action.type) {
    case "RESET_TRANSLATION_LIST":
        return {
            ...translations_defaultState
        };
    case "SET_TRANSLATION_LIST":
        return {
            ...state,
            translationList: action.translationList,
            translationsLoaded: true
        };
    case "SHOW_TRANSLATION":
        return {
            ...state,
            show_translation: true,
            show_translation_id: action.show_translation_id,
            show_translation_data: action.show_translation_data,
            show_translation_vehicle_id: action.vehicle_id
        };
    case "UNSHOW_TRANSLATION":
        // reset everything except the list...
        return {
            ...state,
            show_translation: false,
            show_translation_id: translations_defaultState.show_translation_id,
            show_translation_data: null,
            show_translation_vehicle_id: translations_defaultState.vehicle_id,
            main: translations_defaultState.main,
            /* MIKE */
            // mainDict: translations_defaultState.mainDict,
            description: translations_defaultState.description,
            sales_points: translations_defaultState.sales_points,
            notes: translations_defaultState.notes,
            report: translations_defaultState.report,
            diagram_notes: translations_defaultState.diagram_notes,
            exporter_notes: translations_defaultState.exporter_notes,

            isLockOwner: false,
            lock_loaded: false,
            who: null,
            when: null
        };
    case "CHANGE_TRANS_MAIN":
        return {
            ...state,
            main: action.text
        };
    case "CHANGE_TRANS_MAIN_ADD":
        newMain = state.main;
        key = action.key;
        var val = action.val;
        newMain[key] = val;
        return {
            ...state,
            main: { ...newMain }
        };
    case "CHANGE_TRANS_MAIN_DELETE":
        newMain = state.main;
        key = action.key;
        delete newMain[key];
        return {
            ...state,
            main: { ...newMain }
        };

    case "CHANGE_TRANS_DESCRIPTION":
        return {
            ...state,
            description: action.text
        };
    case "CHANGE_TRANS_SALES":
        return {
            ...state,
            sales_points: action.text
        };
    case "CHANGE_TRANS_NOTES":
        return {
            ...state,
            notes: action.text
        };
    case "CHANGE_EXPORTER_NOTES":
        return {
            ...state,
            exporter_notes: action.text
        };
    case "CHANGE_TRANS_REPORT":
        return {
            ...state,
            report: action.text
        };
    case "CHANGE_TRANS_DIAGRAM_NOTES":
        return {
            ...state,
            diagram_notes: action.text
        };
    case "CHANGE_ALL_TRANS_DATA":
        return {
            ...state,
            main: action.main === null ? "" : action.main, // don't send nulls
            description: action.desc === null ? "" : action.desc,
            sales_points: action.sales === null ? "" : action.sales,
            notes: action.notes === null ? "" : action.notes,
            report: action.report === null ? "" : action.report,
            diagram_notes: action.diagram === null ? "" : action.diagram,
            exporter_notes: action.exporter_notes === null ? "" : action.exporter_notes,
            translation_completed: action.translation_completed

        };
    case "TOGGLE_GIVE_BACK_TRANS_REQUEST":
        return {
            ...state,
            give_request_back: !state.give_request_back
        };
    case "LOCK_TRANSLATION":
        return {
            ...state,
            isLockOwner: action.isLockOwner,
            lock_loaded: true,
            who: action.who,
            when: action.when
        };
    default:
        return state;
    }
};

export default translations;
