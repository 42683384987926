const setAllPurchases = function (all) {
    return {
        type: "SET_ALL_PURCHASES",
        purchases: all
    };
};
const clearAllPurchases = function () {
    return {
        type: "CLEAR_ALL_PURCHASES"
    };
};

const showAddPayment = function (purchase_id, invoice_id) {
    return {
        type: "SHOW_ADD_PAYMENT",
        purchase_id,
        invoice_id
    };
};

const hideAddPayment = function () {
    return {
        type: "HIDE_ADD_PAYMENT"
    };
};

export { setAllPurchases, clearAllPurchases, showAddPayment, hideAddPayment };
