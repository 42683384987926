import React, { Component } from "react";
import logo from "./logo.png";
import "./App.css";

import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";

import { settings } from "./settings";

import {
    Alert,
    Button,
    Col, Row, Container,
    FormLabel,
    Form,
    Spinner
} from "react-bootstrap";

import { createProfile, fetch_profile } from "./actions/profile_actions";

// redux
import { connect } from "react-redux";

class Login extends Component {
    /*
  Login Container: the two paddings should disappear when the screen is small enough;
  Login form does a XHR POST to the webservice,
  */

    constructor (props) {
        super(props);
        this.state = {
            fetching: false,
            showMessage: false,
            message: ""
        };

        // user/pass. changed by ref of form
        this.username = "";
        this.password = "";

        this.loginTest = this.loginTest.bind(this);
        this.guestLogin = this.guestLogin.bind(this);
        this.checkSubmit = this.checkSubmit.bind(this);
    }

    componentDidMount () {
    // showing... check if logged in
        fetch_profile();
    }

    checkSubmit (e) {
        if (e.key === "Enter") {
            this.loginTest();
        }
    }

    loginTest () {
        const data = { username: this.username.value, password: this.password.value };
        console.log("test login");
        const url = settings.api_server + "/login";
        this.setState({ ...this.state, fetching: true });
        fetch(url, {
            body: JSON.stringify(data),
            cache: "no-cache",
            headers: {
                "content-type": "application/json",
                pragma: "no-cache",
                "cache-control": "no-store"
            },
            method: "POST",
            credentials: "include"
        }
        )
            .then(function (response) {
                if (response.status === 429) {
                    throw new Error("Too many attempts, try again later");
                }
                return response.json();
            })
            .then(function (myJson) {
                this.setState({ ...this.state, fetching: false });
                if (myJson.success) {
                    // set cookie with session provided
                    // redirect to site
                    // pass to redux
                    this.props.createProfile(myJson);
                    // move to next
                } else {
                    // set the "invalid username"
                    this.setState({ ...this.state, fetching: false, showMessage: true, message: "Unknown username or password" });
                }
            }.bind(this),
            error => {
                this.setState({ ...this.state, fetching: false, showMessage: true, message: error.message });
            }
            );
    }

    guestLogin () {
        const data = { username: "guest" };
        console.log("guest login");
        const url = settings.api_server + "/login";
        this.setState({ ...this.state, fetching: true });
        fetch(url, {
            body: JSON.stringify(data),
            cache: "no-cache",
            headers: {
                "content-type": "application/json",
                pragma: "no-cache",
                "cache-control": "no-store"
            },
            method: "POST",
            credentials: "include"
        }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                this.setState({ ...this.state, fetching: false });
                if (myJson.success) {
                    // set cookie with session provided
                    // redirect to site
                    // pass to redux
                    this.props.createProfile(myJson);
                    // move to next
                } else {
                    // set the "invalid username" - but should never run
                    this.setState({ ...this.state, fetching: false, showMessage: true, message: "Unknown username or password" });
                }
            }.bind(this));
    }

    render () {
        let message = "";
        if (this.state.showMessage) {
            message = <Alert variant="info">
                {this.state.message}
            </Alert>;
        }

        if (this.props.loggedIn) {
            // is there a redirect in the url?
            // create the search object
            const u = new URLSearchParams(this.props.location.search);
            if (u.has("redirect")) {
                // redirect to that page
                return (<Redirect to={u.get("redirect")} />);
            } else {
                // default redirect
                return (<Redirect to="/dashboard" />);
            }
        }

        return (
            <div className="App">

                <header className="App-header">
                    <img src={logo} alt="logo" />
                    <h1 className="App-title">Japanese Auto Auctions</h1>
                </header>
                <div style={{ marginTop: "100px" }}></div>
                <p className="App-intro">
                    Login to proceed
                </p>
                <div>
                    {this.fetchingProfile
                        ? <Spinner animation="border" role="status"/>
                        : <Container>
                            <Row>
                                <Col md={2}> </Col>
                                <Col md={8}>{message}</Col>
                                <Col md={2}> </Col>
                            </Row>
                            <Row>
                                <Col md={2}></Col>
                                <Col componentclass={FormLabel} md={4}> Username</Col>
                                <Col componentclass={FormLabel} md={4}>Password</Col>
                                <Col md={2}></Col>
                            </Row>
                            <Row>
                                <Col md={2}> </Col>

                                <Col md={4} sm={10}>
                                    <Form.Control type="text" placeholder="Enter username" ref={ref => { this.username = ref; }} />
                                </Col>

                                <Col sm={10} md={4} >
                                    <Form.Control type="password" placeholder="Enter password" onKeyDown={this.checkSubmit} ref={ref => { this.password = ref; }} />
                                </Col>

                                <Col md={2}> </Col>
                            </Row>
                            <div style={{ marginTop: "5px" }} > </div>
                            <Row>
                                <Col>
                                    <span style={{ textAlign: "center" }} >
                                        <Button disabled={this.state.fetching} type="submit" onClick={this.loginTest}>Sign in</Button>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span style={{ textAlign: "center" }} >
                                        or
                                    </span>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <span style={{ textAlign: "center" }} >
                                        <Button disabled={this.state.fetching} type="submit" onClick={this.guestLogin}>Continue as guest</Button> or <Button disabled={this.state.fetching} href="#/enroll">Create account</Button>
                                    </span>
                                </Col>

                            </Row>
                        </Container>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: state.profile.loggedIn,
    fetchingProfile: state.profile.fetchingProfile
});

const mapDispatchToProps = dispatch => ({
    createProfile: (d) => dispatch(createProfile(d))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(props => <Login {...props} />));
