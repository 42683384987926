import React, { Component } from "react";
import "./App.css";
import "./index.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { createProfile } from "./actions/profile_actions";

import {

    Container, Row, Col,

} from "react-bootstrap";

import MyStatsWidget from "./dashboard-components/MyStatsWidget";
import TodaysStats from "./dashboard-components/TodaysStats";
import TaskManager from "./dashboard-components/TaskManager";
import MyCars from "./dashboard-components/MyCars";
import HotCars from "./dashboard-components/hot_cars";
import DashNav from "./dashboard-components/DashNav";
import AlertsDisplay from "./dashboard-components/AlertsDisplay";
import ProfileShort from "./dashboard-components/profileShort";

class Dashboard extends Component {
    // check session on load
    componentWillUnmount() {
        if (!this.props.profileLoaded) {
            // fetch profile data from server
            // on fail, push to
        }
    }

    render() {
        return (
            <Container className="wideContainer">
                <PCANav isAdmin={this.props.isAdmin} currentPage="#/dashboard" />
                <DashNav />
                <Container fluid className="dropShadow cardSpace whiteBackground" >
                    <Row>
                        <Col md="4">
                            <span className="blackTitle">{this.props.firstname}  {this.props.lastname}</span>
                            <ProfileShort />
                        </Col>
                        <Col md="4">
                            <div className="minorTitle" style={{ "textAlign": "center" }} >My Stats</div>
                            <MyStatsWidget />
                        </Col>
                        <Col md="4">
                            <div className="minorTitle" style={{ "textAlign": "center" }}>Today</div>
                            <TodaysStats />
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col md="6" sm="12">
                        <Container fluid className="dropShadow cardSpace whiteBackground blueBorderParentContainer" >
                            <Container className="blueBorderContainer">
                                <span className="blackTitle">Task Manager</span>
                                <TaskManager />
                            </Container>
                        </Container>
                    </Col>
                    <Col md="6" sm="12">
                        <Container fluid className="dropShadow cardSpace whiteBackground blueBorderParentContainer" >
                            <Container className="blueBorderContainer">
                                <span className="blackTitle">My Cars</span>
                                <MyCars />
                            </Container>
                        </Container>
                    </Col>
                </Row>
                <Container className="dropShadow cardSpace whiteBackground  blueBorderParentContainer" fluid >
                    <Container fluid className="blueBorderContainer" >
                        <span className="blackTitle">Alerts</span>
                        <AlertsDisplay />
                    </Container>
                </Container>
                <Container fluid className="dropShadow cardSpace whiteBackground blueBorderParentContainer">
                    <Container fluid className="blueBorderContainer">
                        <span className="blackTitle">Popular Cars at Auction</span>
                        <HotCars />
                    </Container>
                </Container>
            </Container >
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    doLogin: (username, isAdmin) => dispatch(createProfile(username, isAdmin))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
