import React, { Component } from "react";

import { Link } from "react-router-dom";

import {
    Image,
    Table
} from "react-bootstrap";
// better way to import
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";

import LoadingSpinner from "../widgets/LoadingSpinner";

import { connect } from "react-redux";
import moment from "moment-timezone";

import {
    nextPage,
    prevPage,
    setSortColumn,
    clear_results,
    clear_pages,
    clearSelection
} from "../actions/search_actions";

import {
    doSearch,
    getCount
} from "../actions/search_history_actions";

import { search_results_defaultState } from "../reducers/search_results";

import Octicon, { TriangleDown, TriangleUp } from "@githubprimer/octicons-react";

import { prettyDate } from "../functions";

class AvtoHistoryResults extends Component {
    constructor () {
        super();
        this.items_per_page = 50; // must match back-end.
        this.getImages = this.getImages.bind(this);

        this.sortClick = this.sortClick.bind(this);
        this.sortByRate = this.sortByRate.bind(this);

        this.yearClick = this.yearClick.bind(this);
        this.makeClick = this.makeClick.bind(this);
        this.gradeClick = this.gradeClick.bind(this);
        this.odoClick = this.odoClick.bind(this);
        this.auctionClick = this.auctionClick.bind(this);
        this.chassisClick = this.chassisClick.bind(this);
    }

    // to clear the results if the user moves off this page
    componentWillUnmount () {
        this.props.clear_results();
        this.props.clearSelection();
    }

    getImages (imageList) {
    // parse the image string, which is inexplicibly # delimited.
    // return a maximum of 2
    // by default the are thumbnails
        if (imageList === null) {
            return [];
        }
        const style = { paddingRight: "5px" }; // space it out
        return imageList.split("#").slice(0, 2).map(img => <Image style={style} key={img} src={img} />);
    }

    sortByRate () {

    }

    sortClick (id) {
    // sort is by id. -'ve ids for reverse sort
        const newId = parseInt(id, 10);
        let sortColumn = 0;
        if (newId === Math.abs(this.props.sortColumn))
        // sort reverse
        { sortColumn = -1 * this.props.sortColumn; } else { sortColumn = newId; }
        this.props.setSortColumn(sortColumn);

        this.props.clear_pages();
    }

    yearClick () { this.sortClick(1); }
    makeClick () { this.sortClick(2); }
    odoClick () { this.sortClick(5); }
    auctionClick () { this.sortClick(4); }
    chassisClick () { this.sortClick(6); }
    gradeClick() { this.sortClick(3); }

    render () {
        // Haven't begun the search yet: don't show anything
        if (!this.props.loaded && !this.props.fetching) {
            return null;
        }
        // Not yet loaded but we have begun the search: show spinner
        else if (!this.props.loaded) {
            return <LoadingSpinner message="Fetching results..."/>;
        }

        // do we know the count?
        if (this.props.count === search_results_defaultState.count && !this.props.countFetching) {
            getCount(this.props.data);
        }
        // if the page number is in fetched_pages (do_search handles this), but
        // not the data... then fetch it! Show fetching
        if (!(this.props.page in this.props.pages)) {
            // we don't have this page
            // fetch it
            doSearch(this.props.data);
        }
        // if we're getting it. or don't have it (slight redundancy)
        if (this.props.fetching || !(this.props.page in this.props.pages)) {
            return <LoadingSpinner message="Fetching results..."/>;
        }

        const data = this.props.pages[this.props.page];

        const rows = data.map(function (result) {
            const time_to_auction = moment.tz(result.auction_time, "Asia/Tokyo");
            let colour = "auctionOK"; // CSS class to use

            if (time_to_auction.isBefore()) { // has this action passed?
                colour = "auctionPassed";
            } else if (time_to_auction.isBefore(moment().add(1, "hour"))) {
                colour = "auctionNear";
            }

            return <tr id={result.vehicle_id} key={result.vehicle_id} style={{ cursor: "pointer" }}>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.auction_house}<br />{result.lot}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.year}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.displacement}<br />{result.full_chassis_code}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.make}<br />{result.model}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{this.getImages(result.images)}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.grade}</Link></td>
                <td><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{result.transmission}<br />{result.mileage}</Link></td>
                <td className={colour}><Link target="_blank" to={"/auction_car_history/" + result.vehicle_id} className="nostyle" rel="noreferrer">{time_to_auction.fromNow()}<br />{prettyDate(result.auction_time)}</Link></td>
                <td>Starting bid: {result.start_price_num}<br />Final bid: {result.end_price} {result.result_en !== "" && "- " + result.result_en}</td>{ }

            </tr>;
        }.bind(this));

        // is there any results to show?
        // are we on the first page, and there is no data?
        if (this.props.page === 0 && data.length === 0) {
            return (
                <Container className="d-flex justify-content-center" >
                    <span>No Results. Try widening your search.</span>
                </Container>
            );
        }
        // container is a bootstrap class
        return (
            <div className="container">
                <Table striped bordered size="sm" hover>
                    <thead>
                        <tr>
                            <th>Auction info</th>
                            <th id={1} onClick={this.yearClick}>Year {this.props.sortColumn === 1 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -1 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 1 && "↕"} </th>
                            <th id={5} onClick={this.chassisClick}>Displacement<br />Chassis {this.props.sortColumn === 6 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -6 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 6 && "↕"} </th>
                            <th id={2} onClick={this.makeClick}>Make<br />Model {this.props.sortColumn === 2 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -2 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 2 && "↕"}</th>
                            <th>Images</th>
                            <th id={3} onClick={this.gradeClick}>Grade {this.props.sortColumn === 3 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -3 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 3 && "↕"}</th>
                            <th id={5} onClick={this.odoClick}>Odometer<br />Transmission {this.props.sortColumn === 5 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -5 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 5 && "↕"}</th>
                            <th id={4} onClick={this.auctionClick}>Auction time {this.props.sortColumn === 4 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -4 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 4 && "↕"}</th>
                            <th>Bid info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
                <Container className="d-flex justify-content-center" >
                    <span>
                        <Pagination>
                            <Pagination.Prev onClick={this.props.prevPage} disabled={this.props.page === 0}>
                                &larr; Previous Page
                            </Pagination.Prev>
                            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>Showing results {(this.items_per_page * this.props.page) + 1} to {Math.min(this.props.count, this.items_per_page * (this.props.page + 1))} of {this.props.count} </span>
                            <Pagination.Next onClick={this.props.nextPage} disabled={(this.props.page + 1) * this.items_per_page > this.props.count}>
                                Next Page &rarr;
                            </Pagination.Next>
                        </Pagination>
                    </span>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        results: state.search.results,
        sortColumn: state.search.sortColumn,
        page: state.search.page,
        fetched_pages: state.search.fetched_pages,
        data: state.search, // for requerying when sorting happens
        fetching: state.search.fetching,

        count: state.search_results.count,
        pages: state.search_results, // pages are indexed in a dict, numerically
        loaded: state.search_results.loaded,
        lastupdate: state.search_results.lastupdate

    };
};

const mapDispatchToProps = dispatch => ({
    // addCarImageLink: (link) => dispatch(addCarImageLink(link)),
    nextPage: () => dispatch(nextPage()),
    prevPage: () => dispatch(prevPage()),
    setSortColumn: (col) => dispatch(setSortColumn(col)),
    clear_results: () => dispatch(clear_results()),
    clear_pages: () => dispatch(clear_pages()),
    clearSelection: () => dispatch(clearSelection())
});

export default connect(mapStateToProps, mapDispatchToProps)(AvtoHistoryResults);
