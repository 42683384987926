/*
Utilities for fetching stats which are intended to make it easy to add more
stats to the frontend.
*/

import { settings } from "../settings";

// ""enumeration"" of stats
export const MyStats = {
    winPrices: "winPrices",
    purchaseDates: "purchaseDates",
    bidStrengths: "bidStrengths",
    bidWins: "bidWins",
    translationsPerWin: "translationsPerWin"
};

// Determines which stats are arrays of data
const myArrayStats = new Set([
    MyStats.winPrices,
    MyStats.purchaseDates,
    MyStats.bidStrengths,
    MyStats.translationsPerWin
]);

// Determines which stats are a single object, such as a count of auction wins
const myObjectStats = new Set([
    MyStats.bidWins
]);

// when fetching a stat where we only want the X most recent data points,
// fetch this many (more than this looks too busy IMHO)
const RECENT_STATS_AMT = 25;

const ENDPOINTS = new Map([
    [MyStats.winPrices, `/reports/winPrices/${RECENT_STATS_AMT}`],
    [MyStats.purchaseDates, "/purchaseDetail/purchases"],
    [MyStats.bidStrengths, `/reports/bidStrength/${RECENT_STATS_AMT}`],
    [MyStats.bidWins, `/reports/bidWins/${RECENT_STATS_AMT}`],
    [MyStats.translationsPerWin, `/reports/translationsPerWin/${RECENT_STATS_AMT}`]
]);

/**
 * An object which contains the state needed to fetch a single stat
 * @typedef statState
 * @property {boolean} loading
 * @property {string | null} error
 * @property {any[] | object} data array of stat data, or a single stat like a
 * count of recent auction wins
 */

/**
 * Returns an object to unpack into a Component's `state` in its constructor.
 * This sets up the properties needed to track the status of the fetch.
 * @param {string[]} stats
 * @returns {Object.<string, statState>}
 */
export function getInitialState(stats) {
    const initialState = {};
    for (const stat of stats) {
        initialState[stat] = {
            loading: true,
            error: null,
            data: null
        };
    }
    return initialState;
}

/**
 * A callback to be executed when a stat is successfully fetched. It should do
 * any coercion on the data that it needs, then update its state.
 * @callback successCallback
 * @param {string} statName
 * @param {any[] | object} data
 * @param {statState} successState
 * @returns {void}
 */

/**
 * A callback to be executed when a stat could not be retrieved. It should call
 * `this.setState{...this.state, [statName]: failState}`.
 * @callback failCallback
 * @param {string} statName
 * @param {statState} failState
 * @returns {void}
 */

/**
 * Accepts a list of [statName, callback], and an extra callback for failed
 * fetches.
 * @param {[string, successCallback][]} stats
 *    names of each stat to fetch, and the callbacks for when they're fetched
 * @param {failCallback} rejectCallback
 *    function called for each stat that couldn't be retrieved
 */
export function fetchStats(stats, rejectCallback) {
    for (const [statName, callback] of stats) {
        fetchStat(statName).then(
            data => {
                // If the data is not an array, we may have mistakenly called a
                // valid endpoint which doesn't return valid stats data
                if (myArrayStats.has(statName) && Array.isArray(data)) {
                    callback(statName, data, {loading: false, error: null, data: data});
                }
                else if (myObjectStats.has(statName)) {
                    callback(statName, data, {loading: false, error: null, data: data});
                }
                else {
                    console.log(`Expected array of stats data, got: ${data}`);
                    rejectCallback(statName, {loading: false, error: "Internal error: retrieved invalid stats data", data: null});
                }
            },
            reason => {
                console.log(`Was unable to fetch ${statName}. Reason: ${reason}`);
                rejectCallback(statName, {loading: false, error: reason, data: null});
            }
        );
    }
}

/**
 * @param {string} statName
 * @returns {Promise<any[]>}
 */
function fetchStat(statName) {
    const endpoint = ENDPOINTS.get(statName);
    if (typeof(endpoint) === "undefined") {
        return Promise.reject(`No known endpoint for stat ${statName}, check src/stats_components/StatFetcher.js to make sure the list of endpoints is up-to-date.`);
    }

    return fetch(settings.api_server + endpoint, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(response => response.ok ?
            response.json()
            :
            Promise.reject(response.status + " " + response.statusText)
        );
}

/**
 * @param {statState} stat
 */
export function isLoading(stat) {
    return stat.loading;
}

/**
 * @param {statState} stat
 */
export function hasError(stat) {
    return stat.error !== null;
}

/**
 * @param {statState} stat
 */
export function getError(stat) {
    return stat.error;
}

/**
 * Return true if the stat's data is supposed to be an array
 * @param {string} stat
 */
export function dataIsArray(stat) {
    return myArrayStats.has(stat);
}

/**
 * Return true if the stat's data is supposed to be an object
 * @param {string} stat
 */
export function dataIsObject(stat) {
    return myObjectStats.has(stat);
}
