
import { settings } from "../settings";

// showCreate:false, message: null, showUpdate:false, fetching:false, amount: '', comment:'', showConfirmBid:false, showConfirmUpdate:false, showConfirmCancel:false
const user_bid_modal = (state = settings.user_bid_modal_defaultState, action) => {
    switch (action.type) {
    case "UPDATE_BID_AMOUNT":
        // iif the amount is numeric, update it. Otherwise, discard.
        if (isNaN(action.amount)) { return state; }
        return {
            ...state,
            amount: action.amount
        };
    case "UPDATE_BID_COMMENT":
        return {
            ...state,
            comment: action.comment
        };
    case "SET_UPDATE_BID_DATA":
        return {
            ...state,
            comment: action.comment,
            amount: action.amount
        };
    case "CLEAR_BID_DATA":
        return {
            ...state,
            showCreate: false,
            message: null,
            showUpdate: false,
            fetching: false,
            amount: "",
            comment: "",
            showConfirmBid: false,
            showConfirmUpdate: false,
            showConfirmCancel: false
        };
    case "SHOW_CREATE_BID":
        return {
            ...state,
            showCreate: true,
            showConfirmBid: false
        };
    case "SHOW_CREATE_CONFIRM_BID": // assumes we want to close the create modal
        return {
            ...state,
            showCreate: false,
            showConfirmBid: true
        };
    case "SHOW_UPDATE_BID":
        return {
            ...state,
            showUpdate: true,
            showConfirmUpdate: false,
            showConfirmCancel: false
        };
    case "SHOW_UPDATE_CONFIRM_BID": // assumes we want to close the update modal
        return {
            ...state,
            showUpdate: false,
            showConfirmUpdate: true,
            showConfirmCancel: false
        };
    case "SET_BID_FETCHING":
        return {
            ...state,
            fetching: true
        };
    case "CLEAR_BID_FETCHING": // sets a message, too. Message can be null
        return {
            ...state,
            fetching: false,
            message: action.message
        };
    case "SHOW_CONFIRM_CANCEL":
        return {
            ...state,
            showUpdate: false,
            showConfirmUpdate: false,
            showConfirmCancel: true
        };

    default:
        return state;
    }
};

export default user_bid_modal;
