import * as Plot from "@observablehq/plot";
import * as React from "react";

// Create a plot, with the data being pairs containing a "date" (x-axis) and
// "value" (y-axis)
export default function HistoryPlot({
    plotData,
    width, height,
    // shown above the graph to describe the Y axis
    valueLabel,
    // shown before Y values in Plot.tip marks
    valuePrefix,
    // shown after Y values
    valueSuffix,
    // show values with this precision when hovering over them, if specified
    precision
}) {
    const divRef = React.useRef();

    React.useEffect(() => {
        plotData.sort((a, b) => a.date - b.date);

        // Prevent prefix and suffix from being "undefined"
        const prefix = typeof(valuePrefix) === "undefined" ? "" : valuePrefix;
        const suffix = typeof(valueSuffix) === "undefined" ? "" : valueSuffix;

        // find minimum date so we can draw a ruleX at that spot
        const minDate = Math.min(...plotData.map(d => d.date.valueOf()));

        const plot = Plot.plot({
            x: {ticks: 8, tickSize: 0, tickPadding: 1},
            y: {
                grid: true,
                ticks: 3,
                label: valueLabel,
                tickFormat: d => prefix + d + suffix
            },
            width: width,
            height: height,
            marks: [
                // draw y axis
                Plot.ruleY([0]),
                // draw the x axis
                Plot.ruleX([minDate]),
                // plot the data w/ a line and dots
                Plot.lineY(plotData, {x: "date", y: "value", stroke: "rgb(78,171,244)"}),
                Plot.dotY(plotData, {x: "date", y: "value", r: 2, stroke: "#212529", fill: "rgb(78,171,244)"}),
                // add tips on hover
                Plot.tip(plotData, Plot.pointer({
                    x: "date", y: "value",
                    title: (d) => (d.date.toDateString() + ": "
                        // if precision is undefined, toPrecision == toString
                        + prefix + d.value.toPrecision(precision) + suffix)
                }))
            ]
        });

        divRef.current.append(plot);
        return () => plot.remove();

    }, [plotData]);

    return <div ref={divRef}/>;
}
