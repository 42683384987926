import FileDrop from "react-file-drop";

import React, { Component } from "react";

import "../App.css";

import {
    Alert
} from "react-bootstrap";

import AdminOnly from "../dashboard-components/AdminOnly";

class ImageDropperBase extends Component {
    constructor (props) {
        super(props);
        this.imageQueue = [];
        this.state = { uploading: false, currentNumber: 0, total: 0, done: false, message: null };
        this.uploadImage = this.uploadImage.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.sendFile = this.sendFile.bind(this);
    }

    handleDrop (files, event) {
        event; // linter
        // add to the queue
        for (let i = 0; i < files.length; i++) {
            this.imageQueue.push(files[i]);
        }
        // update the length
        const newState = this.state;
        newState.total += files.length;
        this.setState(newState);

        // start processing the queue
        this.uploadImage();
    }

    uploadImage () {
    /*
        Uploads the next image in the queue, or sets the status to done
        */

        let newState;
        // first, are we done?
        if (this.imageQueue.length === 0) {
            newState = this.state;
            newState.done = true;
            newState.uploading = false;
            this.setState(newState);
        } else {
            // we have a queue.
            newState = this.state;
            newState.done = false;
            newState.uploading = true;
            newState.currentNumber += 1;
            this.setState(newState);

            // pop an item
            const image = this.imageQueue.pop();

            if (!image.type.startsWith("image/")) {
                // just skip if it's not an image
                this.uploadImage();
            } else {
                // this is an image. read it, and send it

                const read = new FileReader();
                read.readAsBinaryString(image);
                // callback for when the file is done reading
                read.onloadend = function () {
                    // encode to base64
                    const as64 = window.btoa(read.result);
                    this.sendFile(as64, image.name, image.type);
                    // this.sendFile(read.result, image.name, image.type);
                }.bind(this);
            }
        }
    }

    sendFile (fileData, name, imgtype) {
        const postdata = { imagedata: fileData, name, mimetype: imgtype };

        fetch(this.props.uploadTo, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    // push new link to redux
                    this.props.imageUploadedCallback(data.link);
                } else {
                    this.setState({ ...this.state, message: data.message });
                }
                // do the next
                this.uploadImage();
            }.bind(this));
    }

    render () {
        let status = null;
        if (this.state.uploading) {
            status = <p>Uploading {this.state.currentNumber} of {this.state.total}</p>;
        } else if (this.state.done) {
            status = <p>Done uploading</p>;
        }

        return (
            <div>
                {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}

                <FileDrop onDrop={this.handleDrop}>
                    <div id="react-file-drop">
                        <AdminOnly />

                        <h2>Drop car images here!</h2>
                        {status === null ? <p>Drag and drop the images you would like to upload here</p> : status}

                    </div>
                </FileDrop>
            </div>
        );
    }
}

export default ImageDropperBase;
