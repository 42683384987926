import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    setNotifyDrowpdown,
    clearNotifyDrowpdown,
    fetchNotify
} from "../actions/notify_actions";

import {
    Form
} from "react-bootstrap";

class NotifyDropdown extends Component {
    componentDidMount () {
        fetchNotify();
    }

    // clean up
    componentWillUnmount () {
        this.props.clearNotifyDrowpdown();
    }

    render () {
    // loaded, go.
        const options = this.props.parties.map(p => <option key={p.notify_id} value={p.notify_id}>{p.name}</option>);
        return <Form.Control as="select" value={this.props.dropdownSelection ?? ""} onChange={this.props.setNotifyDrowpdown} >
            <option key={-10} value={null}>Choose a notify party</option>
            {options}
        </Form.Control>;
    }
}

const mapStateToProps = state => {
    return {
        dropdownSelection: state.notify.dropdownSelection,
        parties: state.notify.parties
    };
};

const mapDispatchToProps = dispatch => ({
    setNotifyDrowpdown: (event) => dispatch(setNotifyDrowpdown(event)),
    clearNotifyDrowpdown: () => dispatch(clearNotifyDrowpdown())
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyDropdown);
