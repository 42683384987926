
const edit_user_defaultState = {
    open: false,
    deposit_modal_is_open: false,
    trans_req_is_open: false,
    add_bids_available_open: false,
    deposit_withdraw_modal_is_open: false
};

const edit_user = (state = edit_user_defaultState, action) => {
    switch (action.type) {
    case "OPEN_EDIT_USER":
        return {
            ...state,
            open: true
        };
    case "CLOSE_EDIT_USER":
        return {
            ...state,
            open: false
        };
    case "SHOW_DEPOSIT_MODAL":
        return {
            ...state,
            deposit_modal_is_open: true
        };
    case "CLOSE_DEPOSIT_MODAL":
        return {
            ...state,
            deposit_modal_is_open: false
        };
    case "OPEN_TRANS_REQUEST_MODAL":
        return {
            ...state,
            trans_req_is_open: true
        };
    case "CLOSE_TRANS_REQUEST_MODAL":
        return {
            ...state,
            trans_req_is_open: false
        };
    case "OPEN_BIDS_AVAILABLE_MODAL":
        return {
            ...state,
            add_bids_available_open: true
        };
    case "CLOSE_BIDS_AVAILABLE_MODAL":
        return {
            ...state,
            add_bids_available_open: false
        };
    case "SHOW_DEPOSIT_WITHDRAW_MODAL":
        return {
            ...state,
            deposit_withdraw_modal_is_open: true
        };
    case "HIDE_DEPOSIT_WITHDRAW_MODAL":
        return {
            ...state,
            deposit_withdraw_modal_is_open: false
        };
    default:
        return state;
    }
};

export default edit_user;
