
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { settings } from "./settings";

import {
    Container,
    Table,
    Button
} from "react-bootstrap";
import { prettyDate } from "./functions";

class AllAlerts extends Component {
    constructor () {
        super();
        this.fetchData = this.fetchData.bind(this);
        this.delete = this.delete.bind(this);

        this.state = { loading:true, data: [] };
    }

    componentDidMount () {
        this.fetchData();
    }

    fetchData () {
   
        const url = settings.api_server + "/alerts/all";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, loading:false, data:data});
            }.bind(this));
    }

    delete (e) {
        // delete this question
        // send the back-end DELETE, but delete the entry in a front-end way to
        // clean up immediately
        console.log(e);
        const url = settings.api_server + "/alerts/" + e.target.id;

        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
            });
        // delete the entry in the frontend
        let alert_id = parseInt(e.target.id, 10);
        let entries = this.state.data.filter(alert => alert.alert_id !== alert_id);
        this.setState({...this.state, data:entries});
        
    }

    render () {
        if (this.state.loading) { return <p>Loading...</p>; }

        let alerts = this.state.data.map(x => <tr  key={x.alert_id}> 
            <td>{x.message}</td>
            <td>{prettyDate(x.created_at)}</td>
            <td>{x.clear_at !== null &&  prettyDate(x.clear_at)}</td>
            <td><Button id={x.alert_id} onClick={this.delete}>Delete</Button></td>
        </tr>);
        if (alerts.length === 0) { alerts = <tr><td colSpan="4">None</td></tr>; }

        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />
                <Container className="whiteBackground dropShadow wideContainer">
                    <h1>All Alerts</h1>
                    <Table>
                        <thead>
                            <tr>
                                <th>Message</th>
                                <th>Created at</th>
                                <th>Clear at</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alerts}
                        </tbody>
                    </Table>
                </Container>
            </div>

        );
    }
}


export default AllAlerts;
