import { settings } from "../settings";
import { fetching, fetchComplete, makeSearchObject, set_results, set_count } from "./search_actions";

let basic_data_refresh = null;

function fetch_basic_data () {
    const url = settings.api_server + "/history/base_info";
    fetch(url, {
        cache: "no-cache",
        headers: {
            pragma: "no-cache",
            "cache-control": "no-store"
        },
        method: "GET",
        credentials: "include"
    }
    )
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling?
            settings.store.dispatch(base_info(myJson));
        })
        .catch(function (error) {
            console.log(error);
        });
}

// do the refresh
const base_info = function base_info (data) {
    return {
        type: "HISTORY_BASE_INFO",
        data
    };
};

const setSearchFromDict = function (theDict) {
    return {
        type: "SET_HISTORY_SEARCH_FROM_DICT",
        theDict
    };
};

const doSearch = function doSearch (query) {
    // check if we have this page
    const state = settings.store.getState();
    if (query.page in state.search_results) {
    // we have it, stop
        return;
    }

    if (state.search.fetching) {
    // we are already fetching
        return;
    }
    // dispatch that we are fetching
    settings.store.dispatch(fetching());

    // do the fetch
    const url = settings.api_server + "/history/query";
    const postData = makeSearchObject(query);
    // add in the known count
    postData.count = state.search_results.count;

    fetch(url, {
        credentials: "include",
        method: "POST", // this is only a post because it won't let me do GET with a body
        body: JSON.stringify(postData),
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling
            // need to set fetching ot false, list to null?
            settings.store.dispatch(set_results(myJson, query.page));
            // done fetching
            settings.store.dispatch(fetchComplete());
        });
};

const getCount = function (query) {
    // do the fetch
    const url = settings.api_server + "/history/count";
    const postData = makeSearchObject(query);

    fetch(url, {
        credentials: "include",
        method: "POST", // this is only a post because it won't let me do GET with a body
        body: JSON.stringify(postData),
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling
            // need to set fetching ot false, list to null?
            settings.store.dispatch(set_count(myJson));
        });
};

/*
  make sure the user is logged in. Start the profile fetcher if it's not running
  */
const baseInfoRefresher = function baseInfoRefresher () {
    // start the timer
    // fetch every 2 minutes
    if (basic_data_refresh === null) {
    // the basci info
        fetch_basic_data();
        basic_data_refresh = setInterval(fetch_basic_data, 10 * 60 * 1000);
    }
};

export {
    baseInfoRefresher,
    doSearch,
    getCount,
    setSearchFromDict
};
