import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setMessage, setPopup } from "../actions/alert_actions";
import { settings } from "../settings";
import { Link } from "react-router-dom";
import AdminOnly from "../dashboard-components/AdminOnly";

import Alert from "react-bootstrap/Alert";
import {
    Modal,
    Button,
    Form
} from "react-bootstrap";

import { newUser } from "../actions/user_actions";
import UserDataForm from "./UserDataForm";

class AddUser extends Component {
    constructor () {
        super();
        this.addUser = this.addUser.bind(this);

        this.state = { message: null };
    }

    addUser () {
    // TODO post the new user.
        const url = settings.api_server + "/admin/user";
        const postData = {
            username: this.props.user.profile.username,
            firstname: this.props.user.profile.firstname,
            lastname: this.props.user.profile.lastname,
            isvip: this.props.user.profile.isvip,
            address: this.props.user.profile.address,
            phone_number: this.props.user.profile.phone_number,
            ship_port: this.props.user.profile.ship_port,
            ship_method: this.props.user.profile.ship_method,
            ship_to_country: this.props.user.profile.ship_to_country,
            ship_notify: this.props.dropdownSelection
        };

        fetch(url, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const newState = this.state;
                if (data.success) {
                    // clear the boxes
                    newState.message = null;
                    this.setState(newState);

                    // set an alert
                    this.props.setMessage(<div>User has been added. <Link to={"/user_detail/" + data.user_id}>Click here to view</Link></div>);
                    this.props.closeCallback();
                } else {
                    // don't clear the boxes
                    // show the error message in the modal alert area
                    newState.message = data.message;
                    this.setState(newState);
                    // this.props.setPopup(data.message);
                }
            }.bind(this));
    }

    render () {
    // onEnter ensures there is a new profile for working with
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.closeCallback} onEnter={this.props.newUser}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    {this.state.message !== null && <Alert variant="danger">{this.state.message} </Alert>}
                    * Require Username(email), first name, and last name. A random password will be emailed to the user.
                    <Form>
                        <UserDataForm />

                        {this.state.message !== null && <Alert variant="danger">{this.state.message} </Alert>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.addUser}>Add user</Button> <Button onClick={this.props.closeCallback}>Done</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
AddUser.propTypes = {
    closeCallback: PropTypes.func,
    show: PropTypes.bool.isRequired
};
const mapStateToProps = state => {
    return {
    // for grabbing which notify party was selected
        dropdownSelection: state.notify.dropdownSelection,
        parties: state.notify.parties,
        user: state.user
    };
};

const mapDispatchToProps = dispatch => ({
    setMessage: (message) => dispatch(setMessage(message)),
    setPopup: (message) => dispatch(setPopup(message)),
    newUser: () => dispatch(newUser())
});
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
