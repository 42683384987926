import React, { Component } from "react";
import { connect } from "react-redux";
import "../App.css";

import {
    Button,
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";
import { removePort } from "../actions/shipping_fee_actions";
import EditShippingFees from "./EditShippingFees";

class ShippingFeeRow extends Component {
    constructor () {
        super();
        this.deletePort = this.deletePort.bind(this);

        this.state = { show: false, updating: false, message: null };
    }

    deletePort () {
        this.setState({ ...this.state, updating: true });

        const url = settings.api_server + "/m3/shipping_ports/" + this.props.rowdata.port;

        fetch(url, {
            method: "DELETE",
            credentials: "include",

            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data
                    this.props.removePort(this.props.rowdata.port);
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    render () {
        return <ListGroup.Item className="list-group-item-line" ><h3>Port: {this.props.rowdata.port}</h3>
            <b>Shipping Fees:</b> <EditShippingFees id={this.props.rowdata.port} shipping_fees={this.props.rowdata.shipping_fees} /> <br />
            <div className="float-right"><Button onClick={this.deletePort} disabled={this.state.updating} variant='outline-danger'>Delete Port</Button></div>

        </ListGroup.Item>;
    }
}

const mapStateToProps = state => {
    state; // avoid linter warning
    return {
    };
};

const mapDispatchToProps = dispatch => ({
    removePort: (port) => dispatch(removePort(port))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFeeRow);
