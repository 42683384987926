import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Table,
    Button,
    FormControl
} from "react-bootstrap";

import { updateShippingFee, deleteRow, addRow } from "../actions/m3_actions";

/*
receives a port as the argument in costs
*/
class EditCalculation extends Component {
    render () {
    /*
        Show the m3 cost per height
        these are given to us in order

        Special case: only 1, write "all heights"
        Otherwise write "taller than x"
        Data format
        [
            {
                "id": 4,
                "maxHeight": 1.65,
                "ocean_freight_usd": 65.81
            },
            {
                "id": 5,
                "maxHeight": 2.15,
                "ocean_freight_usd": 71.81
            },
            {
                "id": 6,
                "maxHeight": null,
                "ocean_freight_usd": 76.81
            }
        ]
        */
        let theRest = "all heights";
        if (this.props.costs.length > 1) {
            theRest = "Taller than " + this.props.costs[this.props.costs.length - 2].maxHeight;
        }

        const rows = [];
        for (let i = 0; i < this.props.costs.length - 1; i++) {
            rows.push(
                <tr key={this.props.costs[i].id}>
                    <td>
                        <FormControl
                            value={this.props.costs[i].maxHeight}
                            onChange={(e) => this.props.updateShippingFee(this.props.costs[i].id, "maxHeight", e.target.value)} />
                    </td>
                    <td>
                        <FormControl
                            value={this.props.costs[i].ocean_freight_usd}
                            onChange={(e) => this.props.updateShippingFee(this.props.costs[i].id, "ocean_freight_usd", e.target.value)} />

                    </td>
                    <td><Button variant="outline-danger" onClick={() => this.props.deleteRow(this.props.costs[i].id)}>Delete</Button></td>
                </tr>
            );
        }
        // last row is special!
        rows.push(
            <tr key="lastRow">
                <td>{theRest}</td>
                <td>
                    <FormControl
                        value={this.props.costs[this.props.costs.length - 1].ocean_freight_usd}
                        onChange={(e) => this.props.updateShippingFee(this.props.costs[this.props.costs.length - 1].id, "ocean_freight_usd", e.target.value)} />
                </td>
                <td><Button variant="outline-primary" onClick={() => this.props.addRow(this.props.id)}>Add height</Button></td>
            </tr>
        );
        return <Table>
            <thead>
                <tr>
                    <th>To height</th>
                    <th>Ocean Freight (USD)</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>;
    }
}
EditCalculation.propTypes = {
    costs: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired // the ID of the line item
};

const mapStateToProps = state => {
    state; // for linter
    return {
    };
};

const mapDispatchToProps = dispatch => ({
    updateShippingFee: (id, field, value) => dispatch(updateShippingFee(id, field, value)),
    deleteRow: (id) => dispatch(deleteRow(id)),
    addRow: (port_id) => dispatch(addRow(port_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCalculation);
