import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Table,
} from "react-bootstrap";

export default class ShowCalculation extends Component {
    render () {
        const display = [];
        let theRest = "All heights"; // default text if no lines
        for (let i = 0; i < this.props.costs.length - 1; i++) {
            const curr = this.props.costs[i];
            display.push(<tr key={curr.id}><td>{curr.maxHeight}</td><td>{curr.ocean_freight_usd}</td></tr>);
            theRest = "> " + curr.maxHeight;
        }
        const last = this.props.costs[this.props.costs.length - 1];
        display.push(<tr key={last.id}><td>{theRest}</td><td>{last.ocean_freight_usd}</td></tr>);
        return <Table striped bordered>
            <thead>
                <tr>
                    <th>To height (m)</th>
                    <th>m<sup>3</sup> cost</th>
                </tr>
            </thead>
            <tbody>
                {display}
            </tbody>
        </Table>;
    }
}
/*
Needs a list of costs
[
    {
        "id": 268,
        "maxHeight": 2.25,
        "ocean_freight_usd": 2
    },
    {
        "id": 269,
        "maxHeight": null,
        "ocean_freight_usd": 3
    }
]
*/
ShowCalculation.propTypes = {
    costs: PropTypes.array.isRequired
};
