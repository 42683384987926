import React, { Component } from "react";

import { connect } from "react-redux";

import { fetchItems } from "../../actions/config_item_actions";

import EditLineItemModal from "../../admin_views/config/EditConfigLineItemModal";

import {
    Container,
    Card,
    ListGroup
} from "react-bootstrap";

class ConfigItems extends Component {
    constructor () {
        super();
        this.chooseLineItem = this.chooseLineItem.bind(this);
        this.closeEdit = this.closeEdit.bind(this);

        this.state = { showEditLineItem: false, chosenLineItem: -1 };
    }

    componentDidMount () {
    // update the data, regardless of if there is something there
        fetchItems();
    }

    closeEdit () {
        this.setState({ ...this.state, showEditLineItem: !this.state.showEditLineItem });
    }

    chooseLineItem (e) {
        this.setState({ ...this.state, showEditLineItem: true, chosenLineItem: e.target.value });
    }

    render () {
        return <Container fluid>

            <p>Config items that are emails addresses can be a list of email addresses. Separate the email
                addresses with commas (,). Example "first@example.com, second@example.com"</p>
            <p>Email addresses can be left blank too, with no negative effects.</p>
            <Card>
                <Card.Header>List of All Configuration Items. Click to edit.</Card.Header>
                <Card.Body>
                    <ListGroup variant="flush">
                        {this.props.LineItems.map(d =>
                            <ListGroup.Item
                                action
                                onClick={this.chooseLineItem}
                                value={d.config_name}
                                key={d.config_name}>
                                {d.config_note}:<br />{d.config_value}
                            </ListGroup.Item>)}
                    </ListGroup>
                </Card.Body>
            </Card>

            <EditLineItemModal
                show={this.state.showEditLineItem}
                close={this.closeEdit}
                show_id={this.state.chosenLineItem} />
        </Container>;
    }
}

const mapStateToProps = state => {
    return {
        loaded: state.config_items.loaded,
        LineItems: state.config_items.config_items,

        loggedIn: state.profile.profileLoaded,
        isAdmin: state.profile.is_admin
    };
};

export default connect(mapStateToProps)(ConfigItems);
