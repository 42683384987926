
const saved_query_defaultState = {
    savedList: [],
    loaded: false,
    showSaveModal: false,
    subscribe: false,
    modalQueryName: "",
    lastUpdate: 0
};

const saved_query = (state = saved_query_defaultState, action) => {
    switch (action.type) {
    case "SET_SAVED_LIST":
        return {
            ...state,
            savedList: action.savedList,
            lastUpdate: new Date().getTime(),
            loaded: true
        };
    case "TOGGLE_SUBSCRIBE":
        return {
            ...state,
            subscribe: !state.subscribe
        };
    case "TOGGLE_SAVED_MODAL":
        return {
            ...state,
            showSaveModal: !state.showSaveModal
        };
    case "SAVED_QUERY_NAME":
        return {
            ...state,
            modalQueryName: action.modalQueryName
        };
    case "CLEAR_SAVED_QUERY": {
        return {
            ...state,
            modalQueryName: saved_query_defaultState.modalQueryName
        };
    }
    default:
        return state;
    }
};

export default saved_query;
