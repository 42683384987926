import { settings } from "../settings";

let fetched = false;

function fetch_translations () {
    // only fetch once
    if (!fetched) {
        const url = settings.api_server + "/name_translations";
        fetch(url, {
            cache: "no-cache",
            headers: {
                pragma: "no-cache",
                "cache-control": "no-store"
            },
            method: "GET",
            credentials: "include"
        }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                settings.store.dispatch(loadTranslations(myJson));
                fetched = true;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

// do the refresh
const loadTranslations = function loadTranslations (data) {
    return {
        type: "SET_MAKE_MODEL_TRANSLATIONS",
        make_translations: data.makes,
        model_translations: data.models
    };
};

export {
    fetch_translations,
    loadTranslations
};
