import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Modal
} from "react-bootstrap";

import { settings } from "../../settings";

import { fetchCarData } from "../../actions/car_detail_actions";

import {
    closeCloseConfirm
} from "../../actions/invoice_actions";
import { setMessage } from "../../actions/alert_actions";

class CloseInvoiceModalComponent extends Component {
    constructor () {
        super();
        this.completeInvoice = this.completeInvoice.bind(this);
    }

    completeInvoice () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.close_invoice_id + "/complete";
        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    // fetch the new data, close the modal
                    if (data.success) {
                        // reload the data
                        fetchCarData(this.props.purchase_id);
                        this.props.setMessage("Invoice " + this.props.close_invoice_id + " marked as complete");
                        this.props.closeCloseConfirm();
                    } else
                    // TODO - something more than this
                    { console.log("error closing the invoice"); }
                }.bind(this));
    }

    render () {
        return <Modal show={this.props.showCloseConfirm} onHide={this.props.closeCloseConfirm}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Closing an invoice is not reversible.</Modal.Body>
            <Modal.Footer><Button onClick={this.completeInvoice}>Yes, Close</Button> <Button onClick={this.props.closeCloseConfirm}>No, run away</Button></Modal.Footer>
        </Modal>;
    }
}
const closeInvoiceMapStateToProps = state => {
    return {
        showCloseConfirm: state.invoice.showCloseModal,
        close_invoice_id: state.invoice.close_invoice_id
    };
};

const closeInvoiceMapDispatchToProps = dispatch => ({
    closeCloseConfirm: () => dispatch(closeCloseConfirm()),
    setMessage: () => dispatch(setMessage())

});

export default connect(closeInvoiceMapStateToProps, closeInvoiceMapDispatchToProps)(CloseInvoiceModalComponent);
