import React, { Component } from "react";
import PropTypes from "prop-types";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup,
    Modal,
    Button,
    Badge
} from "react-bootstrap";

import { settings } from "../settings";
import { setPurchaseList } from "../actions/profile_actions";
// import PurchaseDetail from '../PurchaseDetail';

import {
    TranslateMake,
    TranslateModel
} from "../functions";

class AllPurchasesModal extends Component {
    render () {
        return (
            <Modal show={this.props.show} onHide={this.props.toggleCallback}>
                <Modal.Header>
                    <Modal.Title>All Purchases</Modal.Title>
                </Modal.Header>

                <Modal.Body>{this.props.items}</Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.toggleCallback}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class CurrentPurchases extends Component {
    constructor () {
        super();
        this.state = { showAll: false };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate (oldProps) {
        if (this.props.who !== oldProps.who) { this.updateData(); }
    }

    componentDidMount () {
        this.updateData();
    }

    updateData () {
        let url = settings.api_server + "/purchaseDetail/purchases";

        // admin can fetch anyone
        if (this.props.who >= 0) { url = url + "/" + this.props.who; }

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of purchases (even if it's empty)
                this.props.setPurchaseList(data);
            }.bind(this));
    }

    toggleModal () {
        const newState = this.state;
        newState.showAll = !newState.showAll;
        this.setState(newState);
    }

    render () {
        const showAmount = 2;
        // needs to return data in a <ListGroup>
        if (this.props.purchaseList == null) {
            return (<ListGroup><ListGroup.Item>loading...</ListGroup.Item></ListGroup>);
        }

        const bids = this.props.purchaseList.map(x => <ListGroup.Item action href={"#/purchase_detail/" + x.purchase_id} key={x.purchase_id} >{x.date_of_purchase} - {x.year} <TranslateMake make={x.make} /> <TranslateModel model={x.model} /> {x.open_invoices > 0 && <Badge>{x.open_invoices}</Badge>}</ListGroup.Item>);

        // show the view all if there is more than one purchase
        const shortList = [];
        for (let i = 0; i < bids.length && i < showAmount; i++) { shortList.push(bids[i]); }

        return (
            <ListGroup>
                {shortList}
                {this.props.purchaseList.length > showAmount && <ListGroup.Item action onClick={this.toggleModal}>View all {bids.length}</ListGroup.Item>}

                <AllPurchasesModal show={this.state.showAll} toggleCallback={this.toggleModal} items={bids} />
            </ListGroup>
        );
    }
}

CurrentPurchases.propTypes = {
    who: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        purchaseList: state.profile.purchaseList
    };
};

const mapDispatchToProps = dispatch => ({
    setPurchaseList: (purchase_list) => dispatch(setPurchaseList(purchase_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPurchases);
