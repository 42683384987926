import React, { Component } from "react";
import PCANav from "../dashboard-components/navbar";
import {
    Modal,
    Container,
    Card,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { setNewsItems } from "../actions/news_actions";
import { connect } from "react-redux";
import { settings } from "../settings";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";



class NewsEdit extends Component {

    constructor() {
        super();
        this.state = {
            message: "",
            viewmsg: "",
            expiry: "",
            priority: 1,
            showModal: false,
            news_id: -1,
            expireSwitch: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.fetchAllNews = this.fetchAllNews.bind(this);
        this.setNewsUpdate = this.setNewsUpdate.bind(this);
        this.makeNewsItem = this.makeNewsItem.bind(this);
        this.deleteNews = this.deleteNews.bind(this);
        this.previewMD = this.previewMD.bind(this);
    }

    componentDidMount() {
        this.fetchAllNews();
    }

    handleInputChange (e) {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            ...this.state,
            [name]: value
        });
    }
    handleCheckboxChange(e) {
        const checked = e.target.checked;
        const name = e.target.name;
        this.setState({
            [name]: checked,
        });
    }

    handleHide () {
        this.setState({ ...this.state, showModal: false });
    }

    handleShow () {
        this.setState({ ...this.state, showModal: true });
    }

    fetchAllNews (){

        var url = settings.api_server + "/news/admin";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then((data) => {
                this.props.setNewsItems(data);
            });
    }

    setNewsUpdate(id) {
        var newsArr = this.props.news_items.news_items;
        for (var i = 0; i < newsArr.length; i++) {
            if (newsArr[i]["news_id"] === id) {
                this.setState({
                    ...this.state,
                    message: newsArr[i]["markdown_message"],
                    expiry: newsArr[i]["msg_expiry"],
                    priority: newsArr[i]["priority"],
                    news_id: id,
                    showModal: true
                });
            }
        }
    }

    makeNewsItem () {
        this.handleHide();
        var id = this.state.news_id;
        var theMethod;
        var url = settings.api_server + "/news/admin";

        if (id > -1) {
            theMethod = "PUT";
            url += "/" + id;
        }
        else {
            theMethod = "POST";
        }

        var postData = {
            message: this.state.message,
            expiry: this.state.expireSwitch ? null : this.state.expiry,
            priority: this.state.priority
        };

        fetch(url, {
            credentials: "include",
            method: theMethod,
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.status >= 400) {
                    console.log("Received a bad response from the server");
                    throw new Error("Bad response from server");
                }
                this.setState({
                    ...this.state,
                    message: "",
                    expiry: "",
                    priority: 1,
                    showModal: false,
                    news_id: -1
                });
                return response.json();
            }).finally(
                () => {
                    this.fetchAllNews();
                }
            );
    }

    deleteNews(id) {
        var url = settings.api_server + "/news/admin/" + id;

        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .finally(
                this.fetchAllNews()
            );

    }

    previewMD () {
        var url = settings.api_server + "/news/admin/preview";
        console.log("I got here");

        var postData = {
            message: this.state.message
        };

        fetch(url, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    ...this.state,
                    viewmsg: data["html_msg"]
                });
            });

    }


    render() {

        return (
            < div >
                <PCANav isAdmin={this.props.isAdmin} />
                <Container className="around_container">
                    <Card className="mb-3">
                        <Button onClick={this.handleShow}>add New news Item</Button>
                    </Card>
                    {this.props.news_items.news_items.sort((a, b) => (a["priority"] > b["priority"]) ? 1 : -1).map((news) => {
                        var time_to_expire = moment.tz(news["msg_expiry"], "Asia/Tokyo");
                        var expireMessage = news["expired"] ? "Expired" : time_to_expire.fromNow();
                        if (expireMessage === "Invalid date")
                            expireMessage = "Never";
                        return (<Card className="mb-3" key={news["news_id"]}>
                            <Card.Body className="p-2">
                                <div dangerouslySetInnerHTML={{ __html: news["html_message"] }} />
                            </Card.Body>
                            <Card.Footer >
                                <Row>
                                    <Col className='md-1'>
                                        <Button variant="primary" onClick={() => this.setNewsUpdate(news["news_id"])}>Update</Button>{" "}

                                        <Button variant="danger" onClick={() => this.deleteNews(news["news_id"])}>Delete</Button>
                                    </Col>
                                    <Col className='md-1'>
                                        <p>Priority: {news["priority"]}</p>
                                    </Col>
                                    <Col className='md-2' >
                                        <p>Expiry: {news["msg_expiry"]}{" "}{expireMessage}</p>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>);
                    })

                    }
                </Container>

                <Modal
                    show={this.state.showModal}
                    onHide={this.handleHide}
                    size="lg"
                    centered
                >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            News Item Details:
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => e.preventDefault} >
                            <Form.Group >
                                <Form.Label>Priority (display order)</Form.Label>
                                <Form.Control as="select" defaultValue={this.state.priority} custom name="priority" onChange={(e) => this.handleInputChange(e)}>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9} >9</option>
                                    <option value={10}>10</option>
                                </Form.Control>
                                <Form.Label>Expiration Date</Form.Label>
                                <Form.Control disabled={this.state.expireSwitch} type="date" name={"expiry"} value={this.state.expiry} onChange={(e) => this.handleInputChange(e)} />
                                <Form.Check
                                    id="expireSwitch"
                                    label="No expiration"
                                    name="expireSwitch"
                                    onChange={(e) => this.handleCheckboxChange(e)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Message <a rel="noopener noreferrer" target="_blank" href="https://daringfireball.net/projects/markdown/">(markdown)</a></Form.Label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    name='message'
                                    value={this.state.message}
                                    onChange={(e) => this.handleInputChange(e)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Preview</Form.Label>
                                <div dangerouslySetInnerHTML={{ __html: this.state.viewmsg }} />
                            </Form.Group>
                            <Button onClick={this.makeNewsItem}>Submit</Button>{" "}
                            <Button onClick={this.previewMD}>Preview</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }
}
const mapStateToProps = state => {
    return {
        news_items: state.news_items,
    };
};

const mapDispatchToProps = dispatch => ({
    setNewsItems: (data) => dispatch(setNewsItems(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsEdit);