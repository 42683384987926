import React from "react";
import { settings } from "../../../settings";

import {
    Container, Row, Col
} from "react-bootstrap";

import LoadingSpinner from "../../../widgets/LoadingSpinner";
import LoadingError from "../../../widgets/LoadingError";
import StaffSalesTable from "./StaffSalesTable";
import StaffSalesCharts from "./StaffSalesCharts";

import "./StaffSales.css";

/**
 * @typedef {import("moment").Moment} Moment
 */

/**
 * @typedef {Object} UserInfo
 * @prop {number} user_id
 * @prop {string} username
 * @prop {string} firstname
 * @prop {string} lastname
 */

/**
 * Sub-component of StaffSales, loads and displays the report
 * @param {Object} props
 * @param {Moment | null} props.startDate
 * @param {Moment | null} props.endDate
 * @param {number | string} props.staffFilter either the user_id of the account
 * manager whose sales we want to display; or one of the special values "ALL",
 * "ANY" or "NONE". "ALL" = all purchases, "ANY" = purchases with any account
 * manager, "NONE" = purchases with no account manager.
 * @param {Map<number, UserInfo[]>} props.staff map user_ids to account managers
 */
export default function StaffSalesDisplay({
    startDate, endDate, staffFilter, staff
})
{
    const [purchases, setPurchases] = React.useState(null);
    const [error, setError] = React.useState(null);

    // Get purchases filtered by start date, end date, staff
    React.useEffect(() => {
        const queryParams = [];
        if (startDate !== null)
            queryParams.push("start=" + startDate.utc().startOf("month").unix());
        if (endDate !== null)
            queryParams.push("end=" + endDate.utc().endOf("month").unix());
        if (staffFilter !== null)
            queryParams.push("staff=" + staffFilter);

        const url = settings.api_server + "/reports/purchases" + (
            queryParams.length > 0 ?
                "?" + queryParams.join("&")
                :
                ""
        );
        fetch(url, {"credentials": "include"})
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                data.purchases
                :
                Promise.reject(data.message)
            )
            .then(
                purchases => {
                    setPurchases(purchases);
                    setError(null);
                },
                reason => {
                    console.log("Error getting purchases: " + reason);
                    setPurchases(null);
                    setError(reason);
                }
            );
    }, [startDate, endDate, staffFilter]);

    if (error !== null)
    {
        return <LoadingError message={error}/>;
    }
    else if (purchases === null)
    {
        return <LoadingSpinner/>;
    }
    else if (purchases.length === 0)
    {
        return <Container style={{padding: "20px"}}>
            <Row>
                <Col style={{textAlign: "center"}}>
                    No purchases matching the current criteria were found.
                </Col>
            </Row>
        </Container>;
    }
    else
    {
        // note: container uses flex-direction: row-reverse. So if there's room
        // for the table and charts side-by-side, the charts are on the right,
        // but if not, the charts are above the table.
        return <Container className="staff-sales-container">
            <Row xs={1} lg={2}>
                <Col>
                    <StaffSalesCharts purchases={purchases} staff={staff}/>
                </Col>
                <Col>
                    <StaffSalesTable purchases={purchases} staff={staff}/>
                </Col>
            </Row>
        </Container>;
    }
}
