import React, { Component } from "react";

import {
    Alert,
    FormControl,
    Col, Row,
    Button,
    Container
} from "react-bootstrap";

class BidLimitRow extends Component {
    render () {
        return <Row>
            <Col md={5}><FormControl value={this.props.deposit_minimum} onChange={(e) => this.props.changeMin(this.props.rowID, e.target.value)} /></Col>
            <Col md={5}><FormControl value={this.props.bid_maximum} onChange={(e) => this.props.changeMax(this.props.rowID, e.target.value)} /></Col>
            <Col md={2}><Button onClick={() => this.props.remove(this.props.rowID)}>-</Button></Col>
        </Row>;
    }
}

export default class BidLevels extends Component {
    constructor () {
        super();
        this.state = { loaded: false, limits: [], message: null };
        this.updateData = this.updateData.bind(this);
        this.changeMax = this.changeMax.bind(this);
        this.changeMin = this.changeMin.bind(this);
        this.newRow = this.newRow.bind(this);
        this.remove = this.remove.bind(this);
        this.push = this.push.bind(this);
    }

    newRow () {
        const updated = this.state.limits;
        updated.push({
            deposit_minimum: "",
            bid_maximum: ""
        });
        this.setState({ ...this.state, limits: updated });
    }

    changeMax (rowID, newValue) {
        const updated = this.state.limits;
        updated[rowID].bid_maximum = newValue;
        this.setState({ ...this.state, limits: updated });
    }

    changeMin (rowID, newValue) {
        const updated = this.state.limits;
        updated[rowID].deposit_minimum = newValue;
        this.setState({ ...this.state, limits: updated });
    }

    remove (rowID) {
        const updated = this.state.limits;
        updated.splice(rowID, 1);
        this.setState({ ...this.state, limits: updated });
    }

    componentDidMount () {
        this.updateData();
    }

    updateData () {
    // fetch the data

        fetch(this.props.fetchURL, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure
                this.setState({ ...this.state, loaded: true, limits: data });
            }.bind(this));
    }

    push () {
    // fetch the data
        fetch(this.props.updateURL, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(this.state.limits)
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure, don't overwrite that
                if (data.message !== null) { this.setState({ ...this.state, loaded: true, message: data.message }); } else { this.setState({ ...this.state, loaded: true, limits: data.data }); }
            }.bind(this));
    }

    render () {
    /*
        Load the data into inputs, making them immediately editable.
        Always show a + for a new row
        */
        if (!this.state.loaded) {
            return "loading...";
        }

        const rows = [];
        for (let i = 0; i < this.state.limits.length; i++) {
            rows.push(
                <BidLimitRow
                    key={i}
                    changeMax={this.changeMax}
                    changeMin={this.changeMin}
                    rowID={i}
                    deposit_minimum={this.state.limits[i].deposit_minimum}
                    bid_maximum={this.state.limits[i].bid_maximum}
                    remove={this.remove}
                />

            );
        }

        return <Container>
            <Row>
                <Col md={5}>Must have deposit minimum</Col>
                <Col md={5}>To bid up to amount</Col>
                <Col md={2}>&nbsp;</Col>
            </Row>
            {rows}
            <Row><Col md={12}><Button onClick={this.newRow}>+</Button></Col></Row>
            {this.state.message !== null && <Alert variant='danger'>{this.state.message}</Alert>}
            <Row><Col md={12}><Button onClick={this.push}>Save changes, update table</Button></Col></Row>
        </Container >;
    }
}
