import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";

import {
    Button,
    Container, Row, Col
} from "react-bootstrap";

import { settings } from "../settings";

class Role extends Component {
    constructor() {
        super();
        this.send = this.send.bind(this);
        this.state = { updating: false, message: null };
    }

    send(method) {
        const url = settings.api_server + "/user/role/" + this.props.user_id + "/" + this.props.role_name;

        this.setState({ ...this.state, updating: true });

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                this.props.refresh();
            }.bind(this))
            .catch(function (error) {
                this.setState({ ...this.state, message: error.message });
            }.bind(this))
            .finally(function () {
                this.setState({ ...this.state, updating: false });
            }.bind(this));
    }

    render() {
        // check if this role exists for this user.
        const has_role = this.props.roles.includes(this.props.role_name);
        return <Row key={"remove" + this.props.role_name}>
            <Col>{this.props.role_name}</Col>
            <Col>{this.props.role_desc}</Col>
            <Col><Button disabled={has_role} size='sm' onClick={() => this.send("PUT")}>Add role</Button><Button disabled={!has_role} size='sm' onClick={() => this.send("DELETE")}>Remove role</Button></Col>
        </Row>;
    }
}
Role.propTypes = {
    user_id: PropTypes.number,
    role_name: PropTypes.string,
    role_desc: PropTypes.string,
    refresh: PropTypes.func,
    all_roles: PropTypes.array
};

class RoleEditor extends Component {
    /*
    List of all roles, button toggles to turn on and off
    */
    constructor() {
        super();
        this.state = { all_roles: [] };
    }

    componentDidMount() {
        // get a list of all possible roles from the server
        const url = settings.api_server + "/user/role/all";

        fetch(url, {
            credentials: "include",
            method: "GET"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function (data) {
                this.setState({ ...this.state, all_roles: data });
            }.bind(this))
            .catch(function (error) {
                error; //linter
                /// todo something!

            });
    }

    render() {
        return (
            <div>
                <Container>
                    {this.state.all_roles.map(r => <Role key={"role-" + r} {...this.props} role_name={r[0]} role_desc={r[1]} all_roles={this.state.all_roles} />)}
                </Container>
            </div>
        );
    }
}
RoleEditor.propTypes = {
    user_id: PropTypes.number,
    roles: PropTypes.array,
    refresh: PropTypes.func // callback to refresh the information below
};

export default RoleEditor;
