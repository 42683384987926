import React, {Component} from "react";
import {Button} from "react-bootstrap";
import Octicon, {Pencil} from "@githubprimer/octicons-react";
import {viewEditUser} from "../../actions/edit_user_actions";
import { connect } from "react-redux";

class EditButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    // Open edit user dialog, stop propagation so accordion doesn't get toggled
    handleClick(event) {
        this.props.viewEditUser();
        event.stopPropagation();
    }

    render() {
        return <Button size="sm" onClick={this.handleClick}
            style={{position: "relative", top: "-2.5px", right: "-5px"}}>
            <Octicon icon={Pencil}/> Edit
        </Button>;
    }
}

const mapStateToProps = state => {
    state;
    return {};
};

const mapDispatchToProps = dispatch => ({
    viewEditUser: () => dispatch(viewEditUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditButton);
