import React, { Component } from "react";

import "./App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import AdminOnly from "./dashboard-components/AdminOnly";

import PCANav from "./dashboard-components/navbar";

import {
    Container
} from "react-bootstrap";
import ConfigItemList from "./admin_views/config/ConfigItemList";
import BidLevels from "./admin_views/BidLimits/GlobalBidLevels";

class ConfigItems extends Component {
    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <div><PCANav /> <p>Loading...</p> </div>; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <Container>
                    <h2>Edit configuration</h2>

                    <ConfigItemList />
                    <h2>Edit bid limits</h2>
                    <BidLevels />
                </Container>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loaded: state.config_items.loaded,
        LineItems: state.config_items.config_items,

        loggedIn: state.profile.profileLoaded,
        isAdmin: state.profile.is_admin
    };
};

export default connect(mapStateToProps)(ConfigItems);
