/*
 * calculate just the m3 stuff.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


import { settings } from "../../settings";

import {
    Alert,
    Button,
    Form, FormGroup,
    FormControl,
    Row, Col
} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";


import {
    setCarDetail,
    setCarHeight,
    setCarWidth,
    setCarLength,
    setShipM3,
    setShipM3Number,
    setBolsterFee,
    setOceanFreight,
    setTotalCost,
    setSingleField
} from "../../actions/car_detail_actions";
import { fetchM3, fetchM3Calc, clearCurrentM3 } from "../../actions/m3_actions";
import ShowCalculation from "../../m3views/showM3Calculation";
import ShowShippingFees from "../../shipping_fee_views/ShowShippingFees";
import GuessShipping from "./GuessShipping";

class EditM3Component extends Component {
    constructor() {
        super();
        this.state = {
            message: null,
            showPotentialPorts: false,
            showM3calc: false,
            showExportCalc: false,
            showShippingFeesCalc: false
        };
        this.updateM3 = this.updateM3.bind(this);
        this.setM3 = this.setM3.bind(this);
        this.save = this.save.bind(this);

        this.setHeight = this.setHeight.bind(this);
        this.setLength = this.setLength.bind(this);
        this.setWidth = this.setWidth.bind(this);
        this.copyPortFees = this.copyPortFees.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
    }

    componentDidMount() {
        // check if the m3 data is loaded
        // We need it to know known port names
        if (!this.props.m3Loaded) { fetchM3(); }
        if (this.props.car_data !== null &&
            this.props.car_data.ship_port !== null &&
            this.props.car_data.height !== null &&
            this.props.car_data.width !== null &&
            this.props.car_data.length !== null) { fetchM3Calc(this.props.car_data.ship_port, this.props.car_data.height, this.props.car_data.width, this.props.car_data.length); }
    }

    componentWillUnmount() {
        this.props.clearCurrentM3();
    }

    calculateTotal() {
        return Math.floor(
            (Number.parseFloat(this.props.car_data.height, 10) *
                Number.parseFloat(this.props.car_data.width, 10) *
                Number.parseFloat(this.props.car_data.length, 10))
            *
            (this.props.car_data.ship_m3_cost + this.props.car_data.baf_usd + this.props.car_data.other_usd) * this.props.car_data.exchange_rate)
            +
            this.props.car_data.export_shipping_fee
            +
            this.props.car_data.bl_fee;
    }

    save() {
        const url = settings.api_server + "/purchaseDetail/" + this.props.car_data.purchase_id + "/shipping";

        const postdata = {
            ...this.props.car_data
        };
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const newState = this.state;
                if (data.success) {
                    // push to redux
                    this.props.setCarDetail(data.data);
                    // clear message (even if there's not one)
                    newState.message = null;
                    this.setState(newState);
                    this.props.close(); // passed a 'close' path
                } else {
                    // show the message
                    newState.message = data.message;
                    this.setState(newState);
                }
            }.bind(this));
    }

    setM3() {
        // set both the m3 cost and the bolster fee.
        //this.props.setShipM3Number(this.props.m3_cost);
        this.props.setOceanFreight(this.props.ocean_freight_usd);

        // arguably logic, but literally a multiplication...
        // exchange rate is guaranteed to be numeric,
        // the rest is guarded by this file!
        //this.props.setTotalCostDirect(Math.floor(this.props.total * this.props.car_data.exchange_rate));
    }

    setHeight(e) {
        this.props.setCarHeight(e);
        this.updateM3();
    }

    setWidth(e) {
        this.props.setCarWidth(e);
        this.updateM3();
    }

    setLength(e) {
        this.props.setCarLength(e);
        this.updateM3();
    }

    updateM3() {
        fetchM3Calc(this.props.car_data.ship_port, this.props.car_data.height, this.props.car_data.width, this.props.car_data.length);
    }

    copyPortFees(port) {
        this.props.setSingleField("baf_usd", port.baf_usd);
        this.props.setSingleField("bl_fee", port.bl_fee);
        this.props.setSingleField("other_usd", port.other_usd);
    }

    render() {
        let i = 0;
        // loading, don't bother loading up the modal
        if (this.props.car_data.loading) { return (<div />); }

        let m3Calc = null;
        let port = null;
        let portObj = null;
        if (this.props.m3Loaded && (this.ship_port !== null || this.props.car_data.ship_port !== null)) {
            // get the m3 calculation formula for the selected port
            port = this.props.car_data.ship_port;
            for (i = 0; i < this.props.m3Data.length && m3Calc === null; i++) {
                if (this.props.m3Data[i].port === port) {
                    portObj = this.props.m3Data[i];
                    m3Calc = <span><b>Calculation: <Link to="/m3">See m<sup>3</sup> edit page for more info</Link></b> <ShowCalculation costs={this.props.m3Data[i].costs} /></span>;
                }
            }
        }


        return <div>
            <h3>Calculate m<sup>3</sup> size</h3>
            <FormGroup as={Row} controlId="height">
                <Form.Label column sm={4} md={4}>
                    <b>Height (m)</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Height" onChange={this.setHeight} value={this.props.car_data.height} />
                </Col>
            </FormGroup>
            <FormGroup as={Row} controlId="width">
                <Form.Label column sm={4} md={4}>
                    <b>Width (m)</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Width" onChange={this.setWidth} value={this.props.car_data.width} />
                </Col>
            </FormGroup>
            <FormGroup as={Row} controlId="length">
                <Form.Label column sm={4} md={4}>
                    <b>Length (m)</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Length" onChange={this.setLength} value={this.props.car_data.length} />
                </Col>
            </FormGroup>
            {this.props.m3_calc_message !== null &&
                <Row>
                    <Col md={4}></Col>
                    <Col sm={8} md={8}><Alert>{this.props.m3_calc_message}</Alert></Col>
                </Row>
            }
            {this.props.total !== null &&
                <Row>
                    <Col sm={4} md={4}></Col>
                    <Col sm={8} md={8}>Calculated cost per  m<sup>3</sup>: ${this.props.ocean_freight_usd}USD<br />USD, total: ${this.props.total.toFixed(2)}USD, ¥{Math.floor(this.props.total * this.props.car_data.exchange_rate)}
                        <Button size="xsmall" onClick={this.setM3}>Copy to below</Button>&nbsp;
                        <Button size="xsmall" onClick={() => this.setState({ ...this.state, showM3calc: !this.state.showM3calc })}>Show calculation table</Button>
                    </Col>
                </Row>
            }
            {m3Calc !== null && this.state.showM3calc &&
                <Row>
                    <Col sm={4} md={4}><b>M3 Cost for {port}</b></Col>
                    <Col sm={8} md={8}>{m3Calc}</Col>
                </Row>
            }
            <h3>Shipping costs</h3>
            <FormGroup as={Row} controlId="ship_m3_cost">
                <Form.Label column sm={4} md={4}>
                    <b>Shipping M3 cost (USD)</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Shipping M3 cost " value={this.props.car_data.ship_m3_cost} onChange={(e) => this.props.setOceanFreight(e.target.value)} />
                </Col>
            </FormGroup>

            <h3>Export Port Fees</h3>
            {
                !isNaN(Number.parseFloat(this.props.car_data.height, 10) * Number.parseFloat(this.props.car_data.width, 10) * Number.parseFloat(this.props.car_data.length, 10))
                    ?
                    /* Could be a number, or string. Parse it either way */
                    <Row>
                        <Col sm={4} md={4}></Col>
                        <Col sm={8} md={8}>
                            <GuessShipping
                                m3={Number.parseFloat(this.props.car_data.height, 10) * Number.parseFloat(this.props.car_data.width, 10) * Number.parseFloat(this.props.car_data.length, 10)}
                                port={this.props.car_data.exportPort}
                            /> <Button onClick={() => this.setState({ ...this.state, showExportCalc: !this.state.showExportCalc })}>Show calculation table</Button>
                        </Col>
                    </Row>
                    :
                    <p>Enter height, width, and length for an estimate</p>
            }
            <Row>
                <Col sm={4} md={4}></Col>
                <Col sm={8} md={8}>
                    <Collapse in={this.state.showExportCalc}>
                        <div>
                            <ShowShippingFees port={this.props.car_data.exportPort} ports={this.props.exportPorts} />
                        </div>
                    </Collapse>
                </Col>
            </Row>
            <FormGroup as={Row} controlId="shippingFee">
                <Form.Label column sm={4} md={4}>
                    <b>Export Shipping fee</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Export shipping fee" onChange={e => this.props.setSingleField("export_shipping_fee", e.target.value)} value={this.props.car_data.export_shipping_fee} />
                </Col>
            </FormGroup>

            <h3>Shipping fees <Button size="sm" onClick={() => this.setState({ ...this.state, showShippingFeesCalc: !this.state.showShippingFeesCalc })}>show</Button></h3>
            <Collapse in={this.state.showShippingFeesCalc}>
                <div>
                    {portObj !== null &&
                        <div>
                            Fees for {portObj.port}
                            <ul>
                                <li>BL Fee (USD): {portObj.bl_fee}</li>
                                <li>BAF Fee (USD): {portObj.baf_usd}</li>
                                <li>Other Fee (USD): {portObj.other_usd}</li>
                            </ul>
                            <Button onClick={() => this.copyPortFees(portObj)}>Copy to below</Button>
                        </div>
                    }

                    <FormGroup as={Row} controlId="BL Fee">
                        <Form.Label column sm={4} md={4}>
                            <b>BL Fee (¥)</b>
                        </Form.Label>
                        <Col sm={8} md={8}>
                            <FormControl autoComplete="off" placeholder="BL Fee" onChange={e => this.props.setSingleField("bl_fee", e.target.value)} value={this.props.car_data.bl_fee} />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="BAF Fee (USD)">
                        <Form.Label column sm={4} md={4}>
                            <b>BAF Fee (USD)</b>
                        </Form.Label>
                        <Col sm={8} md={8}>
                            <FormControl autoComplete="off" placeholder="BAF Fee (USD)" onChange={e => this.props.setSingleField("baf_usd", e.target.value)} value={this.props.car_data.baf_usd} />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="Other Fee (USD)">
                        <Form.Label column sm={4} md={4}>
                            <b>Other Fee (USD)</b>
                        </Form.Label>
                        <Col sm={8} md={8}>
                            <FormControl autoComplete="off" placeholder="Other Fee (USD)" onChange={e => this.props.setSingleField("other_usd", e.target.value)} value={this.props.car_data.other_usd} />
                        </Col>
                    </FormGroup>
                </div>
            </Collapse>
            <Row>
                <Col sm={4} md={4}>Calculated from above</Col>
                <Col sm={8} md={8}>

                    <p>(car's m<sup>3</sup> size) x [(Ocean freight + BAF + other) x (exchange rate)] + (shipping fee) + (BL fee)</p>

                    <p>
                        {Number.parseFloat(this.props.car_data.height, 10) * Number.parseFloat(this.props.car_data.width, 10) * Number.parseFloat(this.props.car_data.length, 10)}
                        &nbsp;x&nbsp;
                        [({this.props.car_data.ship_m3_cost} + {this.props.car_data.baf_usd} + {this.props.car_data.other_usd} ) x {this.props.car_data.exchange_rate}]
                        &nbsp;+&nbsp;
                        {this.props.car_data.export_shipping_fee}
                        &nbsp;+&nbsp;
                        {this.props.car_data.bl_fee} = {this.calculateTotal()}
                    </p>
                    <p><Button onClick={() => this.props.setSingleField("ship_m3_total_cost", this.calculateTotal())}>Copy to below</Button></p>
                </Col>
            </Row>
            <FormGroup as={Row} controlId="Total">
                <Form.Label column sm={4} md={4}>
                    <b>Computed total JPY (editable)</b>
                </Form.Label>
                <Col sm={8} md={8}>
                    <FormControl autoComplete="off" placeholder="Total shipping" value={this.props.car_data.ship_m3_total_cost} onChange={this.props.setTotalCost} />
                </Col>
            </FormGroup>

            {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}
            <div className="float-right">
                <Button variant="secondary" onClick={this.props.close}>
                    Close
                </Button> &nbsp;
                <Button variant="primary" onClick={this.save}>
                    Save Changes
                </Button>
            </div>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        car_data: state.car_detail.car_info,
        isAdmin: state.profile.is_admin,
        purchase_modal_is_open: state.car_detail.purchase_modal_is_open,
        m3Data: state.m3Data.m3Data,
        m3Loaded: state.m3Data.loaded,
        m3_cost: state.m3Data.m3_cost,
        ocean_freight_usd: state.m3Data.ocean_freight_usd,
        total: state.m3Data.total,
        m3_calc_message: state.m3Data.m3_calc_message,
        last_car_update: state.car_detail.last_car_update, // for triggering updates.

        dropdownSelection: state.notify.dropdownSelection, // notify party
        parties: state.notify.parties,

        exportPorts: state.shipping_fees.export_ports,
    };
};

const mapDispatchToProps = dispatch => ({
    setCarHeight: (e) => dispatch(setCarHeight(e)),
    setCarWidth: (e) => dispatch(setCarWidth(e)),
    setCarLength: (e) => dispatch(setCarLength(e)),
    setCarDetail: (data) => dispatch(setCarDetail(data)),
    clearCurrentM3: () => clearCurrentM3(),

    setShipM3: (e) => dispatch(setShipM3(e)),
    setShipM3Number: (n) => dispatch(setShipM3Number(n)),

    setBolsterFee: (n) => dispatch(setBolsterFee(n)),
    setOceanFreight: (n) => dispatch(setOceanFreight(n)),

    setTotalCost: (e) => dispatch(setTotalCost(e.target.value)),
    setTotalCostDirect: (e) => dispatch(setTotalCost(e)),

    setSingleField: (k, v) => dispatch(setSingleField(k, v)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditM3Component);
