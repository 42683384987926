import React, { Component } from "react";
import { connect } from "react-redux";

import { selectMake } from "../actions/search_actions";

import { FormControl } from "react-bootstrap";

import { settings } from "../settings";

import { translateMakeF } from "../functions";

class AvtoMakes extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            makes: []
        };
    }

    handleChange (e) {
        this.props.selectMake(e.target.value);
    }

    componentWillUnmount () {
    // clear this part of the search
        this.props.selectMake(settings.make_placeholder);
    }

    render () {
    // Names match the avto names.

        // use this list as a starter. Any unknown names get pushed into Other
        const others = [];

        // consider moving this to settings.js
        const bins = settings.bins;

        const display = {};
        const countries = settings.keys;
        const knownMakes = settings.knownMakes;
        for (var i = 0; i < countries.length; i++) {
            display[countries[i]] = [];
        }

        for (i = 0; i < this.props.makes.length; i++) {
            if (knownMakes.indexOf(this.props.makes[i].make) === -1) {
                // unkown make, includes the 'OTHER' bin. We add in the other/other bin last
                if (this.props.makes[i].make !== "OTHERS") { others.push({ make_key: this.props.makes[i].make, display: "· " + translateMakeF(this.props.makes[i].make) }); }
            } else {
                // else, find the list it should live in
                // no nice way to iterate over keys of a dict
                for (let c = 0; c < countries.length; c++) {
                    if (bins[countries[c]].indexOf(this.props.makes[i].make) !== -1) { display[countries[c]].push({ make_key: this.props.makes[i].make, display: "· " + translateMakeF(this.props.makes[i].make) }); }
                }
            }
        }

        others.sort();
        others.push({ make_key: "OTHERS", display: "- Other" });

        // make the clean list.
        // make it in order

        let renderList = [];
        for (i = 0; i < countries.length; i++) {
            // show the heading
            renderList.push({ make_key: countries[i], display: countries[i] });
            display[countries[i]].sort();
            renderList = renderList.concat(display[countries[i]]);
        }

        return (
            <FormControl as="select" placeholder="select" onChange={this.handleChange} value={this.props.make_selected}>
                <option key="0" value="">{settings.make_placeholder}</option>
                {
                    renderList.map(function (make) {
                        return <option key={make.make_key} value={make.make_key}>{make.display}</option>;
                    })
                }
                <option key={settings.others} value={settings.others}>Others</option>
                {
                    others.map(function (make) {
                        return <option key={make.make_key} value={make.make_key}>{make.display}</option>;
                    })
                }
            </FormControl>
        );
    }
}

const mapStateToProps = state => {
    return {
        makes: state.search_history.makes,
        make_selected: state.search.make_selected,
        make_trans_loaded: state.make_model_translation.loaded
    };
};

const mapDispatchToProps = dispatch => ({
    selectMake: (make) => dispatch(selectMake(make))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvtoMakes);
