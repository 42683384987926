import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import { settings } from "../settings";

import { setThreadList, setFetchingThread, setShowThreadId, clearThreadList } from "../actions/questions_actions";

import { LastSeenUTC } from "../functions";
import QuestionModal from "./question_answer_modal";
import Octicon, {Sync} from "@githubprimer/octicons-react";

import {
    Row,Col,
    Button,
    Container
} from "react-bootstrap";

class Questions extends Component {
    // will be set to the timeout ID when we call setTimeout for auto-updates
    updateTimer = null;

    /**
     * @param {Object} props
     * @param {number} [props.updateInterval] The amount of time (ms) between
     * automatically re-fetching the data. If not provided, auto-updates won't
     * occur.
     * @param {number[]} [props.userWhitelist] If provided, only include
     * questions from users in this list. Otherwise, include all users.
     */
    constructor (props) {
        super(props);
        // if fetching, the refresh button is disabled
        this.state = { fetching: false };
        this.fetchQuestions = this.fetchQuestions.bind(this);
    }

    componentDidMount () {
        this.fetchQuestions();
    }

    componentWillUnmount () {
        this.props.clearThreadList();
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
    }

    // update the questions, set a timeout to update them again in the future
    fetchQuestions () {
        // if already fetching, ignore
        if (this.state.fetching) {
            return;
        }
        // in case this was called for a reason besides a timeout, we'll cancel
        // any pending timeout before registering a new one
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }

        this.setState({...this.state, fetching: true});

        const url = settings.api_server + "/questions/thread/";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setThreadList(data);
                if ("updateInterval" in this.props) {
                    this.updateTimer = setTimeout(this.fetchQuestions, this.props.updateInterval);
                }
                this.setState({...this.state, fetching: false});
            }.bind(this));
    }


    render () {
        if (!this.props.loaded) { return <p>Loading...</p>; }

        const questions = this.props.question_list
            // only include if it's in the whitelist, or there's no whitelist
            .filter((q) => this.props.userWhitelist?.includes(q.owner_id) ?? true)
            // time comes in as UTC, convert to Japan time
            .map((q) => <Row className="theHand"
                onClick={() => this.props.setShowThreadId(q.thread_id)}
                value={q.thread_id}
                key={q.thread_id}
            >
                <Col value={q.thread_id}>{q.asker_name}</Col>
                <Col value={q.thread_id}>{q.car}</Col>
                <Col value={q.thread_id}>{LastSeenUTC(q.last_post)}</Col>
            </Row>);
        const numQuestions = questions.length;
        if (questions.length === 0) {
            questions.push(<Row key={0}><Col md="12">No questions</Col></Row>);
        }

        return (<Container className="whiteBackground dropShadow">
            <Row>
                <Col>
                    <h2 className="greyTitle">Questions</h2>
                </Col>
                <Col md="2" className="text-end">
                    <h2 className="greyTitle">
                        {numQuestions}
                    </h2>
                </Col>
                <Col md="1" className="text-end" style={{padding: "0px"}}>
                    <Button size="sm"
                        onClick={this.fetchQuestions}
                        style={{
                            marginRight: "5px",
                            marginTop: "5px"
                        }}
                    >
                        <Octicon icon={Sync}/>
                    </Button>
                </Col>
            </Row>
            {questions}<QuestionModal update={this.fetchQuestions} />
            <Row><Col md={12} className="text-end"><Button href="#/question_list" variant="secondary">View all Questions</Button></Col></Row>
        </Container>);
    }
}
const mapStateToProps = state => {
    return {

        loaded: state.questions.question_list_loaded,
        question_list: state.questions.question_list,

        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setThreadList: (the_list) => dispatch(setThreadList(the_list)),
    setFetchingThread: () => dispatch(setFetchingThread()),
    setShowThreadId: (id) => dispatch(setShowThreadId(id)),
    clearThreadList: () => dispatch(clearThreadList())

});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
