import React, { Component } from "react";
import PropTypes from "prop-types";


import { settings } from "../../settings";

import {
    Col, Row,
    Button,
    FormGroup, FormControl
} from "react-bootstrap";

export default class SetFeeDiscount extends Component {
    constructor (props) {
        super();
        
        this.state = { setting: false, message: "", service_fee_discount: props.service_fee_discount };

        this.newPass = "";
        this.setFee = this.setFee.bind(this);
    }

    setFee () {
        const url = settings.api_server + "/user/set_discount/" + this.props.user_id;
        const postdata = { service_fee_discount: this.state.service_fee_discount };

        
        this.setState({...this.state, setting:true});

        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({...this.state, setting: false, message: "Could not set fee"});
                    throw new Error("Bad response from server");
                } 
                else
                {
                    return response.json();
                }
            }.bind(this))
            .then(function () {
                this.setState({...this.state, setting:false, message: "Fee Set"});
            }.bind(this));
    }

    render () {
        return (
            <FormGroup controlId="formHorzusername">
                <Row>
                    <Col md={4}><b>Service fee discount:</b></Col>
                    <Col md={8}>
                        <FormControl onChange={(e) => this.setState({...this.state, service_fee_discount:e.target.value})} value={this.state.service_fee_discount} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}></Col>
                    <Col><Button disabled={this.state.setting} onClick={this.setFee}>{this.state.setting ? "Setting discount" : "Set"}</Button></Col>
                </Row>
                <Row>
                    <Col md={4}></Col>
                    <Col>{this.state.message}</Col>
                </Row>
            </FormGroup>
        );
    }
}
SetFeeDiscount.propTypes = {
    user_id: PropTypes.number.isRequired,
    service_fee_discount: PropTypes.number
};