import React, { Component } from "react";
import "../App.css";

import {
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";
import { LastSeen } from "../functions";

import "./HotCars.css";

class HotCar extends Component {
    // this is all of the stuff that wasn't in the design document, so it can
    // be disabled if we want to stick to only showing cars' images
    getMoreInfo() {
        return <p>
            {this.props.car.make + " " + this.props.car.model}
            <br/>
            Auction {LastSeen(this.props.car.auction_time)}
            <br/>
            Viewed {this.props.car.rank} times
        </p>;
    }

    render () {
        const images = this.props.car.images.split("#");
        // image properties
        const altText = this.props.car.make + " " + this.props.car.model;
        const carLink = "#/auction_car/" + this.props.car.vehicle_id;

        // Stupid AVTO logic.
        // if there is only 1 images, it's the car
        // if there's more than one, the first image is the auction sheet,
        // and the rest are the car
        let image = null;
        if (images.length > 1) {
            image = <img alt={altText} className="myCarsImage" src={images[1]}/>;
        } else if (images.length > 0) {
            image = <img alt={altText} className="myCarsImage" src={images[0]}/>;
        }

        return <ListGroup.Item action href={carLink} className="myCarsListItem">
            {image}
            {this.getMoreInfo() /* can be removed to only show images,
                                   like in the design document */}
        </ListGroup.Item>;
    }
}

class HotCars extends Component {
    constructor () {
        super();
        this.state = { loaded: false, cars: [] };
    }

    componentDidMount () {
        // fetch the data
        const url = settings.api_server + "/hot_cars/";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure
                this.setState({ ...this.state, loaded: true, cars: data });
            }.bind(this));
    }

    render () {
        return (
            <ListGroup horizontal className="myCarsList">
                {
                    this.state.cars.map(c =>
                        <HotCar key={c.vehicle_id} car={c} rank={c.rank}/>
                    )
                }
            </ListGroup>
        );
    }
}

export default HotCars;
