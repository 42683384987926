
import { settings } from "../settings";

const purchases = (state = settings.all_purchases_defaultState, action) => {
    switch (action.type) {
    case "SET_ALL_PURCHASES":
        return {
            ...state,
            loaded: true,
            purchases: action.purchases,
            lastUpdate: (new Date()).getTime()
        };
    case "CLEAR_ALL_PURCHASES":
        return {
            ...settings.all_purchases_defaultState
        };
    case "SHOW_ADD_PAYMENT":
        return {
            ...state,
            addPaymentPurchaseID: action.purchase_id,
            addPaymentInvoiceID: action.invoice_id,
            showAddPayment: true
        };
    case "HIDE_ADD_PAYMENT":
        return {
            ...state,
            showAddPayment: false,
            addPaymentPurchaseID: null,
            addPaymentInvoiceID: null
        };
    default:
        return state;
    }
};

export default purchases;
