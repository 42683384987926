import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setCarImageLinks } from "../actions/car_detail_actions";
import { settings } from "../settings";
import ImageList from "./image_list_viewer";

class CarImages extends Component {
    componentDidMount () {
        const url = settings.api_server + "/car_images/" + this.props.purchase_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of image UUIDs (even if it's empty)

                this.props.setCarImageLinks(data);
            }.bind(this));
    }

    render () {
    // var links = this.props.image_links.map(x => settings.api_server + '/car_images/' + this.props.purchase_id + '/' + x);
        const links = this.props.image_links.map(x => ({ link: settings.api_server + "/car_images/" + this.props.purchase_id + "/" + x, uuid: x }));

        // Container fluid so it shrinks down into the Card
        return (
            <ImageList image_links={links} />
        );
    }
}

CarImages.propTypes = {
    purchase_id: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        image_links: state.car_detail.image_links,
        num_images: state.car_detail.num_images
    };
};

const mapDispatchToProps = dispatch => ({
    setCarImageLinks: (link) => dispatch(setCarImageLinks(link))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarImages);
