import React from "react";
import PropTypes from "prop-types";

import "../App.css";
import { connect } from "react-redux";

import { addImage } from "../actions/image_request_list_actions";
import { settings } from "../settings";

import ImageDropperBase from "./image_upload_base";

// Higher-order object
// wraps the uploader, lets us feed it implementation-specific stuff
// https://stackoverflow.com/questions/41285135/subclassing-in-react
// https://medium.com/@franleplant/react-higher-order-components-in-depth-cf9032ee6c3e#.5riykqsso
// https://reactjs.org/docs/higher-order-components.html
const RequestedImageDropper = (WrappedComponent) => {
    class ImageDropper extends ImageDropperBase {
        render () {
            const uploadData = {
                uploadTo: settings.api_server + "/image_requests/upload/" + this.props.image_request_id,
                imageUploadedCallback: this.props.addImage
            };
            return <WrappedComponent {...this.props} {...uploadData} />;
        }
    }

    ImageDropper.propTypes = {
        image_request_id: PropTypes.number.isRequired
    };

    const mapStateToProps = state => {
        state;
        return {
        };
    };

    const mapDispatchToProps = dispatch => ({
        addImage: (link) => dispatch(addImage(link))
    });
    return connect(mapStateToProps, mapDispatchToProps)(ImageDropper);
};

export default RequestedImageDropper(ImageDropperBase);
