import React, { Component } from "react";
import { connect } from "react-redux";
import { settings } from "../settings";
import commaNumbers from "../widgets/commaNumbers";
import {
    Button,
    Container,
    ListGroup
} from "react-bootstrap";

import "./DashWidget.css";

class MyCars extends Component {

    constructor() {
        super();
        this.state = {loading: true, car_list: []};
    }

    componentDidMount() {
        const url = settings.api_server + "/purchaseDetail/myCarsWidget";
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(response => {
                if(!response.ok) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function(data) {
                this.setState({loading: false, car_list: data});
            }.bind(this));
    }

    getCarRow(car) {
        // ensure nothing is displayed as "null" when unavailable
        let carString;
        if (!car.year && !car.make && !car.model) {
            carString = "(Car info unavailable)";
        }
        else {
            carString = (car.year ? car.year + " " : "") +
                (car.make ? car.make + " " : "") +
                (car.model ? car.model : "");
        }
        const priceString = car.invoices.length > 0 ?
            "¥" + commaNumbers(car.invoices[0].invoiced_amount) + " "
            :
            "";
        return <>
            <div style={{float: "left", display: "inline-block"}}>
                {carString}&nbsp;
            </div>
            <div style={{float: "right", display: "inline-block"}}>
                {priceString} &nbsp; {car.date_of_purchase}
            </div>
        </>;
    }

    getCarList() {
        return <ListGroup variant="flush" className="widgetList">
            {this.state.car_list.map((car) =>
                <ListGroup.Item key={car.vehicle_id} className="widgetListItem"
                    action href={"#/purchase_detail/" + car.purchase_id}>
                    {this.getCarRow(car)}
                </ListGroup.Item>)}
        </ListGroup>;
    }

    render () {
        return (
            <Container>
                {this.state.loading ? <p>Loading...</p> : this.getCarList()}
                <div style={{"textAlign":"right"}}>
                    <Button href="#/myCars">Enter My Cars</Button>
                </div>
            </Container>
        );
    }
}
const mapStateToProps = state => {
    return {
        profile: state.profile
    };
};

export default connect(mapStateToProps)(MyCars);
