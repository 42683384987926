import React, { Component } from "react";
import { connect } from "react-redux";

import { setCarInclude } from "../../actions/car_detail_actions";
import {
    openCloseConfirm,
    showAddLineItemModal,
    openReleaseConfirm
} from "../../actions/invoice_actions";
import { setMessage } from "../../actions/alert_actions";

import InvoiceCard from "../../admin_views/invoiceCard";

class InvoiceListComponent extends Component {
    render () {
        if (this.props.car_data_loading) { return null; }

        // make a Card for each invoice.
        const cards = [];
        for (let i = 0; i < this.props.car_data.invoices.length; i++) {
            const inv = this.props.car_data.invoices[i];

            cards.push(<InvoiceCard invoiceData={inv} key={i}/>);
        }

        return <div>
            {cards}
            <div id="forceRefreshTextBox" style={{ display: "none" }}>{this.props.last_invoice_update}</div>

        </div>;
    }
}
const invoiceMapStateToProps = state => {
    return {
        car_data_loading: state.car_detail.loading,
        car_data: state.car_detail.car_info,
        last_car_update: state.car_detail.last_car_update,
        last_invoice_update: state.invoice.last_invoice_update,
        purchase_id: state.invoice.purchase_id
    };
};

const invoiceMapDispatchToProps = dispatch => ({
    openCloseConfirm: (invoice_id) => dispatch(openCloseConfirm(invoice_id)),
    showAddLineItem: (invoice_id) => dispatch(showAddLineItemModal(invoice_id)),
    openReleaseConfirm: (invoice_id) => dispatch(openReleaseConfirm(invoice_id)),
    setCarInclude: (purchase_id, invoice_id, newSetting) => dispatch(setCarInclude(purchase_id, invoice_id, newSetting)),
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(invoiceMapStateToProps, invoiceMapDispatchToProps)(InvoiceListComponent);
