import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { fetchItems } from "../../actions/config_item_actions";

import {
    Col, Row,
    Modal,
    FormLabel, Form,
    Button
} from "react-bootstrap";
import { settings } from "../../settings";

class EditLineItem extends Component {
    constructor () {
        super();

        this.changeLineItemText = this.changeLineItemText.bind(this);

        this.updateLineItem = this.updateLineItem.bind(this);
        this.loadLineItem = this.loadLineItem.bind(this);

        this.state = { show: false, item_text: "", updating: false, message: null };
    }

    onShow () {
        this.loadLineItem();
    }

    loadLineItem () {
    // find the LineItem id, put into the state
        for (let i = 0; i < this.props.line_items.length; i++) {
            if (this.props.line_items[i].config_name === this.props.show_id) {
                this.setState({ ...this.state, item_text: this.props.line_items[i].config_value });
            }
        }
    }

    updateLineItem () {
        this.setState({ ...this.state, updating: true });
        const url = settings.api_server + "/config_items/" + this.props.show_id;
        const postData = { config_value: this.state.item_text };
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data with redux
                    fetchItems(false);

                    // close the modal
                    this.props.close();
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    changeLineItemText (e) {
        this.setState({ ...this.state, item_text: e.target.value });
    }

    render () {
        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.close} onEnter={this.loadLineItem}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit config item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>New value</Form.Label>
                            </Col>
                        </Row><Row>
                            <Col sm={12} md={12}>
                                <Form.Control type="text" value={this.state.item_text} onChange={this.changeLineItemText} />
                            </Col>
                        </Row>
                    </Form>
                    {this.state.message !== null && <p>{this.state.message}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.updateLineItem}>Save changes</Button> <Button onClick={this.props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
EditLineItem.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    show_id: PropTypes.number.isRequired
};
const mapStateToPropsUpdate = state => {
    return {
        loaded: state.config_items.loaded,
        line_items: state.config_items.config_items

    };
};

export default connect(mapStateToPropsUpdate)(EditLineItem);
