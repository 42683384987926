
import { settings } from "../settings";

// actions for user that we are currently viewing (not current user - that is profile)

const user = (state = settings.viewUserDefaultState, action) => {
    switch (action.type) {
    case "SET_VIEW_USER":
        // check if the data is already loaded. If yes... do nothing
        // otherwise, return to default
        if (action.user_id !== state.user_id) {
            return {
                ...settings.viewUserDefaultState,
                user_id: action.user_id,
                lastUpdate: (new Date()).getTime()
            };
        }
        // do nothing!
        return { ...state };
    case "SET_GL_LINE_ITEMS":
        return {
            ...state,
            line_items: action.line_items,
            gl_loaded: true
        };
    case "SET_VIEW_USER_PROFILE":
        return {
            ...state,
            profile_loaded: true,
            profile: action.profile,
            lastUpdate: (new Date()).getTime()
        };
    case "NEW_USER_PROFILE":
        return {
            ...state,
            profile_loaded: true,
            profile: settings.new_profile,
            lastUpdate: (new Date()).getTime()
        };
    case "ADD_VIEW_USER_BALANCE":
        state.profile.balance += action.amount;
        return {
            ...state
        };
    case "EDIT_VIEW_USER_DATA":
        // edit something in the user dict
        var newState = { ...state }; // shallow copy
        newState.profile[action.item] = action.value;
        newState.lastUpdate = (new Date()).getTime();
        return newState;
    case "CLEAR_VIEW_USER":
        return {
            ...settings.viewUserDefaultState,
            lastUpdate: (new Date()).getTime()
        };
    default:
        return state;
    }
};

export default user;
