import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    ListGroup,
    Container,
    Button,
    Row, Col
} from "react-bootstrap";

import { settings } from "../settings";
import { setMessage } from "../actions/alert_actions";

import { setWatchedCarList } from "../actions/profile_actions";

class WatchedCarRow extends Component {
    constructor (props) {
        super(props);
        this.unSubscribe = this.unSubscribe.bind(this);
        this.state = { loaded: false, images: [] };
    }

    componentDidMount () {
        // fetch the data
        const url = settings.api_server + "/search/auction_car_images/" + this.props.car.vehicle_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status === 429) {
                    // silently fail
                    return { images: [] };
                }
                if (response.status >= 400) {
                    settings.store.dispatch(setMessage("Problem loading watch list"));
                    return { images: [] };
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure
                this.setState({ ...this.state, loaded: true, images: data.images });
            }.bind(this));
    }

    unSubscribe (e) {
        // stop clicking through
        e.stopPropagation();
        // since this is a link...
        e.preventDefault();

        // Just delete. This is in the list view, and that's all we care about
        const url = settings.api_server + "/watch/" + this.props.car.vehicle_id;
        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh (apparently regardless of error)
                this.props.refresh();
            }.bind(this));
    }

    render () {
        const style = {
            maxWidth: "100%",
            maxHeight: "100%"
        };
        let image = null;
        // Stupid AVTO logic.
        // if there is only 1 images, it's the car
        // if there's more than one, the first image is the auction sheet,
        // and the rest are the car
        if (this.state.images.length > 1) {
            image = <img style={style} alt="Auction car" src={this.state.images[1]} />;
        } else if (this.state.images > 0) {
            image = <img style={style} alt="Auction car" src={this.state.images[0]} />;
        }

        return <ListGroup.Item action key={this.props.car.watch_id} href={"#/auction_car/" + this.props.car.vehicle_id}>
            <Row>
                <Col key={0} sm={4} md={4} lg={4}> {image} </Col>
                <Col className="alignColVertical" sm={8} md={8} lg={8} >{this.props.car.car_details} {this.props.car.auction_time} &nbsp;<Button size="sm" variant="outline-danger" onClick={this.unSubscribe}>Remove</Button> </Col>
            </Row>

        </ListGroup.Item>;
    }
}
WatchedCarRow.propTypes = {
    car: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired
};

class WatchedCars extends Component {
    constructor () {
        super();
        this.state = { loaded: false, bids: [] };
        this.updateData = this.updateData.bind(this);
    }

    componentDidUpdate (oldProps) {
        if (this.props.who !== oldProps.who) { this.updateData(); }
    }

    componentDidMount () {
        this.updateData();
    }

    updateData () {
        let url = settings.api_server + "/watch/fetch";

        if (this.props.who >= 0) { url = url + "/" + this.props.who; }

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    return []; // return an empty list on failure
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setWatchedCarList(data);
            }.bind(this));
    }

    render () {
        // needs to return data in a <ListGroup>
        if (this.props.guest) {
            return (<Container fluid><p>Users with accounts can watch cars at auction!</p></Container>);
        }
        if (!this.props.watchedCarsLoaded) {
            return (<ListGroup><ListGroup.Item>loading...</ListGroup.Item></ListGroup>);
        }

        const cars = this.props.watchedCarList.map((x, index) => <WatchedCarRow car={x.watch_info} key={index + "_" + x.watch_info.car_details} refresh={this.updateData} />);
        return (
            <ListGroup>
                {cars.slice(0, 5)}
                {cars.length > 0 && <ListGroup.Item action href="/#/taskManager">See all {cars.length} watched cars</ListGroup.Item>}
            </ListGroup>
        );
    }
}

WatchedCars.propTypes = {
    who: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        watchedCarList: state.profile.watchedCarList,
        watchedCarsLoaded: state.profile.watchedCarsLoaded,
        guest: state.profile.guest
    };
};

const mapDispatchToProps = dispatch => ({
    setWatchedCarList: (bid_list) => dispatch(setWatchedCarList(bid_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchedCars);
