import React, { Component } from "react";

import { Link } from "react-router-dom";
import { settings } from "../settings";

import PCANav from "../dashboard-components/navbar";
import {
    Container,
    Table,
    Row,
    Col
} from "react-bootstrap";

import PerWeek from "./per_week_data";

/*
 A single month to show
 */
class TranslationMonth extends Component {
    render () {
        const rows = this.props.month.data.map(
            d => <tr key={d.user_id + "month_" + d.firstname}>
                <td><Link to={"/user_detail/" + d.user_id}>{d.firstname} {d.lastname}</Link></td>
                <td>{d.count}</td>
            </tr>
        );
        return <div>
            Report for {this.props.month.for}:

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Who</th>
                        <th>How many</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>;
    }
}

/*
 * Show all the months of tables
 * Render as panels, so it can go mobile
 */
class TranslationTables extends Component {
    constructor () {
        super();
        this.state = { loaded: false, data: [] };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
    // this is actually flexible. Could make a dropdown for it.
        const url = settings.api_server + "/reports/userTranslations/months/6";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.setState({ ...this.state, loaded: true, data });
            }.bind(this));
    }

    render () {
        if (!this.state.loaded) { return <p>Loading...</p>; }

        // data is in order, from newest to oldest.
        // render each in its own column
        // which will overflow prettily into rows
        const toShow = this.state.data.map(month => <Col key={"month_" + month} md={6}><TranslationMonth month={month} /></Col>);
        return <Row>{toShow}</Row>;
    }
}

class TranslationCounts extends Component {
    render () {
        return <div>
            <PCANav isAdmin={this.props.isAdmin} />
            <h1>&nbsp;Translation request report:</h1>
            <Container>
                <h2>Top this week</h2>
                <PerWeek />
                <h2>Top per month</h2>
                <TranslationTables />
            </Container>
        </div>;
    }
}

export default TranslationCounts;
