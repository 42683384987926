import React, { Component } from "react";
import "../App.css";
import "../user_views/ProfileTable.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { settings } from "../settings";

import {
    Alert,
    Table,
    Button,
    Modal
} from "react-bootstrap";

import { prettyDate } from "../functions";
import { setLineItems, fetch_set_line_items } from "../actions/user_actions";
import commaNumbers from "../widgets/commaNumbers";

class DepositRow extends Component {
    constructor () {
        super();
        this.state = { showConfirm: false, working: false, message: null };
        this.delete = this.delete.bind(this);
    }

    delete () {
    // delete this line item
    // refresh
        const url = settings.api_server + "/ledger/balance/item/" + this.props.data.entry_id;

        this.setState({ ...this.state, working: true });

        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    return { message: "Bad response", success: false };
                } else { return response.json(); }
            })
            .then(function (data) {
                if (data.success) {
                    this.props.refresh();
                    this.setState({ ...this.state, message: null, working: false, showConfirm: false });
                } else {
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    render () {
        let crossoutData = "";
        if (this.props.data.deleted) {
            crossoutData = "crossedOutText";
        }
        return <tr className={crossoutData} key={this.props.data.entry_id}>
            <td>{prettyDate(this.props.data.transaction_date)}</td>
            <td>{this.props.data.used_in_transaction != null && <Link to={"/purchase_detail/" + this.props.data.used_in_transaction}>{this.props.data.used_in_invoice}</Link>} </td>
            <td>{this.props.data.account}</td>
            <td>{this.props.data.reference}</td>
            <td> {this.props.data.note !== null && this.props.data.note.length > 0 && this.props.data.note}</td>
            <td className="rightAlignTextbox">{this.props.data.amount < 0 && commaNumbers(this.props.data.amount)}</td>
            <td className="rightAlignTextbox">{this.props.data.amount >= 0 && commaNumbers(this.props.data.amount)}</td>
            <td className="rightAlignTextbox">{commaNumbers(this.props.total)}</td>
            {
                this.props.isAdmin &&
                <td><Button size="sm" disabled={this.props.data.deleted} onClick={() => this.setState({ ...this.state, showConfirm: true })}>Delete</Button></td>
            }

            <Modal show={this.state.showConfirm}>
                <Modal.Header>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete?</p>
                    {this.state.message !== null &&
                        <Alert variant="danger">{this.state.message}</Alert>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={this.delete} disabled={this.state.working}>Delete</Button>
                    <Button variant="secondary" onClick={() => this.setState({ ...this.state, showConfirm: false })} disabled={this.state.working}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </tr>;
    }
}

class LineItems extends Component {
    constructor () {
        super();
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount () {
        this.refresh();
    }

    refresh () {
        fetch_set_line_items(this.props.who, this.props.setLineItems);
    }

    render () {
    // needs to return data in a <ListGroup>
        if (!this.props.loaded) { return (<p>loading...</p>); }
        if (this.props.line_items.length === 0) { return (<p>No transactions</p>); }

        const line_items = [];
        let total = 0;
        for (let i = 0; i < this.props.line_items.length; i++) {
            // this could be done with a window function in a query.
            if (!this.props.line_items[i].deleted) {
                total += this.props.line_items[i].amount;
            }

            line_items.push(<DepositRow key={"item" + i} data={this.props.line_items[i]} total={total} refresh={this.refresh} isAdmin={this.props.isAdmin}/>);
        }
        let show = [];
        if (this.props.count > 0) {
            for (let i = 0; i < this.props.count; i++) { show.push(line_items[i]); }
        } else { show = line_items; }

        // only show the last column if this is an admin
        return (
            <Table size="sm" responsive className="profileTable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Invoice</th>
                        <th>Account</th>
                        <th>Reference</th>
                        <th>Note</th>
                        <th>Withdrawal</th>
                        <th>Deposit</th>
                        <th>Balance</th>
                        {this.props.isAdmin && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {line_items}
                </tbody>
            </Table>
        );
    }
}

LineItems.propTypes = {
    who: PropTypes.number.isRequired,
    count: PropTypes.number
};

LineItems.defaultProps = {
    count: 10
};

const mapStateToProps = state => {
    return {
        line_items: state.user.line_items,
        loaded: state.user.gl_loaded,
        who: state.user.user_id,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setLineItems: (user_id, line_items) => dispatch(setLineItems(user_id, line_items))
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItems);
