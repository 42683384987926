import React from "react";
import {Card} from "react-bootstrap";

/**
 * This widget is meant to be used when a resource could not be fetched, and has
 * a similar look to LoadingSpinner so it can be rendered in the same places
 * when loading fails.
 * @param {Object} props
 * @param {any} [props.message]
 */
export default function LoadingError(props) {
    const message = props.message ? props.message : "Loading failed";
    return <div className="container" style={{textAlign: "center", width: "100%"}}>
        <Card style={{maxWidth: "180px", padding: "10px", marginTop: "20px", marginLeft: "auto", marginRight: "auto", backgroundColor: "#FDD"}}>
            <Card.Body style={{padding: "0px"}}>
                <div style={{color: "red"}}>Error</div>
                <div>{message}</div>
            </Card.Body>
        </Card>
    </div>;
}
