import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuctionCarDetails from "../auction_car_views/AuctionCarDetails";
import {
    Alert,
    ListGroup,
    Modal,
    Button,
    Form, FormGroup, FormLabel,
    FormControl,
    Col, Row
} from "react-bootstrap";

import AdminOnly from "../dashboard-components/AdminOnly";
import { settings } from "../settings";
import {
    openBidModal,
    closeBidModal,
    loadBidData,
    loadUserBalance,
    clearBidData,
    toggleGiveBidCountBack,
    toggleTopUp,
    toggleRedoQuiz
} from "../actions/bid_actions";
import { setMessage } from "../actions/alert_actions";
import AuctionCarImages from "../auction_car_views/AuctionCarImages";
import { getAuctionCarData, clearAuctionData } from "../actions/auction_car_actions";

class BidCloseModal extends Component {
    constructor () {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.switchToBid = this.switchToBid.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.clickChange = this.clickChange.bind(this);
        this.updateBalance = this.updateBalance.bind(this);
        this.ignoreClick = this.ignoreClick.bind(this);

        this.notes = null;
        this.amount = null;
        this.winner = null;
        this.sold = null;
        this.use_deposit = null;

        this.default_state = {
            winnerChecked: false,
            soldChecked: false,
            message: null,
            working: false
        };
        this.state = { ...this.default_state };
    }

    clickChange () {
        const setState = this.state;
        setState.soldChecked = this.sold.checked;
        // un-check "winner" if "sold" is unchecked
        if (!this.sold.checked)
            this.winner.checked = false;
        setState.winnerChecked = this.winner.checked;
        this.setState(setState);
    }

    handleClose () {
    // clean up
        this.setState(this.default_state);
        this.props.closeBidModal();
    }

    componentDidMount () {
    // just opened. Load data.
        this.updateData();
    }

    componentWillUnmount () {
        this.props.clearAuctionData();
    }

    componentDidUpdate (oldProps) {
        if (this.props.bid_id !== oldProps.bid_id) { this.updateData(); }
    }

    switchToBid (e) {
    // switch to this bid
        const setState = this.state;
        setState.winnerChecked = false;
        setState.soldChecked = false;
        this.setState(setState);
        this.props.clearBidData();
        this.props.openBidModal(e.target.value);
    }

    ignoreClick () {
    // click was saved
        this.setState({ ...this.state, working: true });
        const url = settings.api_server + "/bids/admin/" + this.props.bid_id;

        const postData = {
            give_bid_count_back: this.props.give_bid_count_back
        };

        fetch(url, {
            credentials: "include",
            method: "DELETE", // deletes this bid!
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, working: false });
                if (data.success) {
                    // update the underlying list
                    if (this.props.updateCB) { this.props.updateCB(); }

                    // clear the state
                    this.setState(this.default_state);
                    this.props.closeBidModal();
                } else {
                    // error mesage
                    const setState = this.state;
                    setState.message = data.message;
                    this.setState(setState);
                }
            }.bind(this));
    }

    saveClick () {
    // click was saved
        this.setState({ ...this.state, working: true });
        const url = settings.api_server + "/bids/admin/" + this.props.bid_id;

        let deposit = 0;
        // if there's a non-empty amount specified, use it
        if (this.use_deposit && this.use_deposit.value) {
            deposit = this.use_deposit.value;
        }

        const postData = {
            note: this.notes.value,
            amount: this.amount.value,
            sold: this.sold.checked,
            won: this.sold.checked && this.winner.checked,
            deduct_from_deposit: deposit,
            give_bid_count_back: this.props.give_bid_count_back,
            top_up: this.props.top_up,
            redo_quiz: this.props.redo_quiz
        };

        fetch(url, {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, working: false });
                if (data.success) {
                    // update the underlying list
                    if (this.props.updateCB) { this.props.updateCB(); }

                    // we won, put up a messsage
                    if (this.winner.checked) { this.props.setMessage(<div>Purchase created <Link to={"editPurchase/" + data.purchase_id}>Click here to view</Link></div>); }
                    // close the modal

                    // clear the state
                    this.setState(this.default_state);
                    this.props.closeBidModal();
                } else {
                    const setState = this.state;
                    setState.message = data.message;
                    this.setState(setState);
                    // Possible user error is checking "sold", entering amount,
                    // unchecking "sold" and submitting. If that happened, clear
                    // the field since it'll be greyed-out
                    if (!postData.sold && postData.amount.length > 0)
                    {
                        this.amount.value = "";
                    }
                }
            }.bind(this));
    }

    updateData () {
        if (this.props.openModal) {
            let url = settings.api_server + "/bids/admin/" + this.props.bid_id;

            if (this.props.who >= 0) { url = url + "/" + this.props.who; }

            fetch(url, {
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    // push to redux
                    // data should be a list of bids (even if it's empty)
                    this.props.loadBidData(data);
                    getAuctionCarData(data.this_bid.vehicle_id);
                    this.updateBalance();
                }.bind(this));
        } else {
            this.props.clearAuctionData();
        }
    }

    updateBalance () {
        if (this.props.bid_data_loaded) {
            const url = settings.api_server + "/ledger/" + this.props.bid_data.this_bid.customer_id + "/total";

            fetch(url, {
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    // push to redux
                    this.props.loadUserBalance(data.amount);
                }.bind(this));
        }
    }

    render () {
        let display = null;
        if (!this.props.bid_data_loaded) { display = <p>Loading...</p>; } else {
            let other_bids_list = <ListGroup.Item>No other bids on this car</ListGroup.Item>;
            if (this.props.bid_data.other_bids.length > 0) {
                other_bids_list = [];
                for (let i = 0; i < this.props.bid_data.other_bids.length; i++) {
                    const x = this.props.bid_data.other_bids[i];
                    other_bids_list.push(<ListGroup.Item action variant={x.amount >= this.props.bid_data.this_bid.amount ? "danger" : null} value={x.bid_id} key={x.bid_id} onClick={this.switchToBid}>{x.username}, bid id {x.bid_id}, amount {x.amount} JPY</ListGroup.Item>);
                }
            }

            let user_balance = <p>Balance loading...</p>;
            if (this.props.user_balance_loaded) { user_balance = <p>Current balance: {this.props.user_balance} JPY</p>; }

            var alert = "";
            if (this.state.message !== null) { alert = <Alert variant="warning"><strong>Error!</strong>  {this.state.message}</Alert>; }
            let bidsRemaining = this.props.bid_data.this_bid.users_bids_available;
            if (bidsRemaining < 0) {
                bidsRemaining = "Infinite";
            }
            display = <div>
                <h3>Bid info</h3>
                <p>{this.props.bid_data.this_bid.username} bid amount {this.props.bid_data.this_bid.amount}</p>
                <p>{bidsRemaining} bids remaining, Account manager: {this.props.bid_data.this_bid.acct_manager === null ? <b>Account manager required</b>:this.props.bid_data.this_bid.acct_manager_name}</p>
                <p>Note: {this.props.bid_data.this_bid.customer_note !== null && this.props.bid_data.this_bid.customer_note.length > 0 ? this.props.bid_data.this_bid.customer_note : "None provided"}</p>
                <p>Private Note: {this.props.bid_data.this_bid.private_note !== null && this.props.bid_data.this_bid.private_note.length > 0 ? this.props.bid_data.this_bid.private_note : "None provided"}</p>
                <p><Link to={"/auction_car/" + this.props.bid_data.this_bid.vehicle_id}>Click here to view car</Link></p>
                <h3>Other bids on this car</h3>
                <ListGroup>{other_bids_list}</ListGroup>
                <h3>Images of car</h3>
                <AuctionCarImages />
                <h3>Car Details</h3>
                <AuctionCarDetails id={this.props.bid_data.this_bid.avto_link} />
                <h3>Close the bid</h3>
                <Form>
                    <FormGroup as={Row} controlId="sold">
                        <Col componentclass={FormLabel} sm={5}>
                            Car sold
                        </Col>
                        <Col sm={7}>
                            <Form.Check type="checkbox"
                                label={"Check if the car sold at auction"}
                                onChange={this.clickChange}
                                ref={ref => { this.sold = ref; }}/>
                        </Col>
                    </FormGroup>
                    {/* Only enable remaining controls if car sold */}
                    <FormGroup as={Row} controlId="amount">
                        <Col componentclass={FormLabel} sm={5}>
                            Winning Bid amount
                        </Col>
                        <Col sm={7}>
                            <FormControl ref={ref => { this.amount = ref; }}
                                disabled={!this.state.soldChecked}/>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="winner">
                        <Col componentclass={FormLabel} sm={5}>
                            Secured car
                        </Col>
                        <Col sm={7}>
                            <Form.Check type="checkbox"
                                label={"Check if we won!"}
                                onChange={this.clickChange}
                                ref={ref => { this.winner = ref; }}
                                disabled={!this.state.soldChecked}/>
                        </Col>
                    </FormGroup>
                    {this.state.winnerChecked && <>
                        <FormGroup as={Row} controlId="use_deposit">
                            <Col componentclass={FormLabel} sm={5}>
                                Amount of deposit to use against purchase
                            </Col>
                            <Col sm={7}>
                                {user_balance}
                                <FormControl ref={ref => { this.use_deposit = ref; }}
                                    disabled={!this.state.soldChecked}/>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="redo_quiz">
                            <Col componentclass={FormLabel} sm={5}>
                                Autocomplete quiz
                            </Col>
                            <Col sm={7}>
                                <Form.Check
                                    type="checkbox"
                                    label="Re-use previous quiz answers if available"
                                    checked={this.props.redo_quiz}
                                    onChange={this.props.toggleRedoQuiz}
                                />
                            </Col>
                        </FormGroup>
                    </>}
                    <FormGroup as={Row} controlId="notes">
                        <Col componentclass={FormLabel} sm={5}>
                            Notes (optional, only goes to winner):
                        </Col>
                        <Col sm={7}>
                            <FormControl ref={ref => { this.notes = ref; }}
                                disabled={!this.state.soldChecked}/>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="bidCountCheck">
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Give bid count back (bids allowed go up by 1 for all bidders)"
                                checked={this.props.give_bid_count_back}
                                onChange={this.props.toggleGiveBidCountBack}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="topUpCheck">
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Top up requests for winning user"
                                checked={this.props.top_up}
                                onChange={this.props.toggleTopUp}
                                disabled={this.winner && !this.winner.checked}
                            />
                        </Col>
                    </FormGroup>
                    {alert}
                    <FormGroup as={Row} controlId="save">
                        <Col md={2}>
                            <Button onClick={this.saveClick} disabled={this.state.working}>Save</Button>
                        </Col>
                        <Col>
                            Note: all bidders on this car will be notified
                        </Col>
                    </FormGroup>

                    <FormGroup as={Row} controlId="ignore">
                        <Col md={2}>
                            <Button onClick={this.ignoreClick} disabled={this.state.working}>Ignore</Button>
                        </Col>
                        <Col>
                            Ignores only this bid. If there are other bids, on this car, they will remain.
                            Respects "gives count back" checkbox.
                        </Col>
                    </FormGroup>
                </Form>

            </div>;
        }

        return (
            <Modal show={this.props.openModal} onHide={this.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Close bid
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    {alert}
                    {display}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

BidCloseModal.propTypes = {
    updateCB: PropTypes.func
};

BidCloseModal.defaultProps = {
    updateCB: null
};

const mapStateToProps = state => {
    return {
        openModal: state.bid_modal.bid_modal_open,
        bid_id: state.bid_modal.bid_id,
        is_admin: state.profile.is_admin,
        bid_data_loaded: state.bid_modal.bid_data_loaded,
        bid_data: state.bid_modal.bid_data,
        user_balance: state.bid_modal.user_balance,
        user_balance_loaded: state.bid_modal.user_balance_loaded,
        give_bid_count_back: state.bid_modal.give_bid_count_back,
        top_up: state.bid_modal.top_up,
        redo_quiz: state.bid_modal.redo_quiz
    };
};

const mapDispatchToProps = dispatch => ({
    closeBidModal: () => dispatch(closeBidModal()),
    openBidModal: (bid_id) => dispatch(openBidModal(bid_id)),
    loadBidData: (bid_data) => dispatch(loadBidData(bid_data)),
    loadUserBalance: (amount) => dispatch(loadUserBalance(amount)),
    clearBidData: () => dispatch(clearBidData()),
    setMessage: (message) => dispatch(setMessage(message)),
    clearAuctionData: () => dispatch(clearAuctionData()),
    toggleGiveBidCountBack: () => dispatch(toggleGiveBidCountBack()),
    toggleTopUp: () => dispatch(toggleTopUp()),
    toggleRedoQuiz: () => dispatch(toggleRedoQuiz())
});

export default connect(mapStateToProps, mapDispatchToProps)(BidCloseModal);
