import React, {Component} from "react";
import { connect } from "react-redux";

import "./App.css";
import "./index.css";

import { Container } from "react-bootstrap";

import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";

class Copyright extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const iscLink = "https://github.com/observablehq/plot/blob/main/LICENSE";
        // current year for copyright notice
        const year = new Date().getFullYear();

        return <Container className="wideContainer">
            <PCANav isAdmin={this.props.isAdmin} currentPage="#/copyright"/>
            <DashNav/>
            <Container fluid className="dropShadow cardSpace whiteBackground"
                style={{paddingBottom: "2rem"}}
            >
                <h1 style={{textAlign: "center"}}>Copyright</h1>
                
                <p>
                    Copyright © 2023-{year} Pacific Coast Auto Imports.
                    All rights reserved.
                </p>
                
                <p>
                    This site contains content generated with Observable Plot,
                    © 2024 Observable, Inc.
                    <br/>
                    Observable Plot is licensed under the <a href={iscLink}
                        target="_blank" rel="noreferrer"
                    >
                        ISC License.
                    </a>
                </p>
            </Container>
        </Container>;
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        isAdmin: state.profile.is_admin
    };
};

export default connect(mapStateToProps)(Copyright);
