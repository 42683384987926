import React, { Component } from "react";
import { connect } from "react-redux";

import {
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";

import commaNumbers from "../widgets/commaNumbers";

class BidLimits extends Component {
    constructor () {
        super();
        this.state = { loaded: false, limits: [] };
    }

    componentDidMount () {
    // fetch the data
        const url = settings.api_server + "/bids/limits";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns an empty array on failure
                this.setState({ ...this.state, loaded: true, limits: data });
            }.bind(this));
    }

    render () {
    // if they are not a guest, say what their bid limit is
        let myDisplay = null;
        let myLimit = 0;
        if (!this.props.guest && this.state.loaded) {
            for (let i = 0; i < this.state.limits.length; i++) {
                if (this.props.deposit < this.state.limits[i].deposit_minimum && this.state.limits[i].deposit_minimum > myLimit) { myLimit = this.state.limits[i].deposit_minimum; }
            }
            myDisplay = <ListGroup.Item>Your bid maximum is {commaNumbers(myLimit)}¥</ListGroup.Item>;
        }

        return (
            <ListGroup>
                {this.state.limits.map(c => <ListGroup.Item key={c.bid_maximum}>
                    To bid up to {commaNumbers(c.bid_maximum)}¥ you require a deposit of {commaNumbers(c.deposit_minimum)}¥ or more.
                </ListGroup.Item>)}
                {myDisplay}
            </ListGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        guest: state.profile.guest,
        deposit: state.profile.balance
    };
};



export default connect(mapStateToProps)(BidLimits);
