const m3Data_defaultState = {
    m3Data: [],
    m3UpdateTime: null,
    loaded: false,
    m3_cost: null,
    bolster: null,
    total: null,
    m3_calc_message: null
};

const m3Data = (state = m3Data_defaultState, action) => {
    switch (action.type) {
    case "SET_M3":
        return {
            ...state,
            m3Data: action.m3Data,
            m3UpdateTime: new Date().getTime(),
            loaded: true
        };
    case "CHOSEN_PORT":
        return {
            ...state,
            m3_cost: action.m3_cost,
            ocean_freight_usd: action.ocean_freight_usd,
            total: action.total,
            m3_calc_message: action.message
        };
    case "CLEAR_CHOSEN":
        return {
            ...state,
            m3_cost: m3Data_defaultState.m3_cost,
            bolster: m3Data_defaultState.bolster,
            total: m3Data_defaultState.total,
            m3_calc_message: m3Data_defaultState.m3_calc_message
        };
    case "UPDATE_M3_PORT_DATA":
        /*
            gets a port name, a value in that port to update
            key and a value to update for that port
            */
        return {
            ...state,
            m3Data:
                    // find the port, update it. Leave the rest
                    state.m3Data.map(port => {
                        if (port.port == action.port_name) {
                            return { ...port, [action.field]: action.value };
                        } else {
                            return port;
                        }
                    })
        };
    case "UPDATE_M3_SHIPPING_DATA":
        return {
            ...state,
            m3UpdateTime: new Date().getTime(),
            m3Data: state.m3Data.map(port => {
                // check if this fee id matches
                return {
                    ...port,
                    costs: port.costs.map(c => {
                        if (c.id === action.fee_id) {
                            return {
                                ...c,
                                [action.field]: action.value
                            };
                        } else { return c; }
                    })
                };
            })
        };
    case "REMOVE_PORT":
        return {
            ...state,
            m3Data: state.m3Data.filter(port => port.port !== action.port_name)
        };
    case "DELETE_M3_SHIPPING_DATA":
        return {
            ...state,
            m3UpdateTime: new Date().getTime(),
            m3Data: state.m3Data.map(port => {
                // check if this fee id matches
                return {
                    ...port,
                    costs: port.costs.filter(c => c.id !== action.fee_id)
                };
            })
        };
    case "ADD_M3_SHIPPING_DATA":
        return {
            ...state,
            m3Data: state.m3Data.map(port => {
                // find the port, add the row
                const costs = [...port.costs];
                if (port.port === action.port_id) {
                    // add a new row with a "random" id
                    // put it first
                    costs.splice(
                        0,
                        0,
                        { id: new Date().getTime(), maxHeight: 0, ocean_freight_usd: 0 }
                    );
                    return {
                        ...port,
                        costs
                    };
                }
                return port;
            })
        };
    default:
        return state;
    }
};

export default m3Data;
