import React, { Component } from "react";
import { Pagination } from "react-bootstrap";

const itemsPerPage = 50;

class MyCarPager extends Component {
    /**
     * @param {Object} props
     * @param {number} props.count number of purchases
     * @param {number} props.page current page
     * @param {() => void} props.prevPage function to switch page
     * @param {() => void} props.nextPage function to switch page
     */
    constructor(props) {
        super(props);
    }

    render() {
        const lastPage = Math.max(1, Math.ceil(this.props.count / itemsPerPage));

        return (
            <div className="dropShadow whiteBackground">
                <div style={{textAlign: "center"}}>Page</div>
                <Pagination className="justify-content-center">
                    <Pagination.Prev onClick={this.props.prevPage}
                        disabled={this.props.page <= 0}>
                        ◀
                    </Pagination.Prev>
                    <span className="pager-middle">
                        {this.props.page + 1} of {lastPage}
                    </span>
                    <Pagination.Next onClick={this.props.nextPage}
                        disabled={this.props.page + 1 == lastPage}>
                        ▶
                    </Pagination.Next>
                </Pagination>
            </div>
        );
    }
}

export default MyCarPager;
export {itemsPerPage};
