import { settings } from "../settings";

const fetch_invoice = function fetch_invoice (purchase_id, invoice_id) {
    const url = settings.api_server + "/invoice/admin/purchase/" + purchase_id + "/" + invoice_id + "/lineItem";
    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            // data should be a list of line items (even if it's empty)
            settings.store.dispatch(addInvoiceData(invoice_id, data));
        });
};

const openCloseConfirm = function openCloseConfirm (invoice_id) {
    return {
        type: "OPEN_CLOSE_CONFIRM",
        invoice_id
    };
};
/*
  Close the close confirm modal
  */

const closeCloseConfirm = function closeCloseConfirm () {
    return {
        type: "CLOSE_CLOSE_CONFIRM"
    };
};

const openReleaseConfirm = function openReleaseConfirm (invoice_id) {
    return {
        type: "OPEN_RELEASE_CONFIRM",
        invoice_id
    };
};
/*
  Close the close confirm modal
  */

const closeReleaseConfirm = function closeReleaseConfirm () {
    return {
        type: "CLOSE_RELEASE_CONFIRM"
    };
};

const setPurchaseId = function setPurchaseId (purchase_id) {
    return {
        type: "SET_PURCHASE_ID",
        purchase_id
    };
};

const addInvoiceData = function addInvoiceData (invoice_id, line_items) {
    return {
        type: "ADD_INVOICE_DATA",
        invoice_id,
        line_items
    };
};

const resetInvoice = function resetInvoice () {
    return {
        type: "RESET_INVOICE"
    };
};

const showAddLineItemModal = function showAddLineItemModal (add_line_item_invoice_id) {
    return {
        type: "SHOW_LINE_ITEM_MODAL",
        add_line_item_invoice_id
    };
};

const hideAddLineItemModal = function hideAddLineItemModal () {
    return {
        type: "HIDE_LINE_ITEM_MODAL"
    };
};

export {
    fetch_invoice,
    openCloseConfirm,
    closeCloseConfirm,
    setPurchaseId,
    resetInvoice,
    addInvoiceData,
    showAddLineItemModal,
    hideAddLineItemModal,
    openReleaseConfirm,
    closeReleaseConfirm
};
