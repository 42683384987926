import React, { Component } from "react";
import { connect } from "react-redux";

import { settings } from "../settings";
import {
    Form, Row, Col,
    Button,
    Table
} from "react-bootstrap";

const DEFAULT_STATE = { make: "", chassis_code: "", message: null, data: null, working: false };

class ShowData extends Component {
    render () {
        const engineData = [];
        // this gets rid of a / between these values
        if (this.props.data.engineno) { engineData.push(this.props.data.engineno); }
        if (this.props.data.transcode) { engineData.push(this.props.data.transcode); }
        const engineStr = engineData.join("/");

        return <Row>
            <Col>
                <Table striped bordered hover size="sm" responsive="md">
                    <tbody>
                        <tr><td>Year/Model/Trim</td><td>{this.props.data.year} {this.props.data.modelname} {this.props.data.gradecode}</td></tr>
                        <tr><td>Month of production</td><td>{this.props.data.month}</td></tr>
                        <tr><td>Engine/Transmission</td><td>{engineStr}</td></tr>
                        <tr><td>Model code</td><td>{this.props.data.modelcode}</td></tr>
                        <tr><td>Colour code</td><td>{this.props.data.colorcode}</td></tr>
                        <tr><td>Other info</td><td>{this.props.data.code}</td></tr>
                    </tbody>
                </Table>
            </Col>
        </Row>;
    }
}

class ProductionData extends Component {
    constructor (props) {
        super(props);
        this.changeMake = this.changeMake.bind(this);
        this.changeChassis = this.changeChassis.bind(this);
        this.goClick = this.goClick.bind(this);

        this.state = { ...DEFAULT_STATE };

        // fill with data, if we have it
        if (this.props.data !== null) {
            this.state.make = this.props.data.make;
            this.state.chassis_code = this.props.data.chassis_code;
        }
    }

    componentDidUpdate () {
    // update the names in the textboxes based
    // on state if and only if they ahve not been changed.
    // this allows us to fill the search with values, if given
        if (this.props.data !== null &&
            this.state.make === DEFAULT_STATE.make &&
            this.state.chassis_code === DEFAULT_STATE.chassis_code) {
            this.setState({
                ...DEFAULT_STATE,
                make: this.props.data.make,
                chassis_code: this.props.data.full_chassis_code
            });
        }
    }

    goClick () {
        const url = settings.api_server + "/production_data/";

        const postData = {
            make: this.state.make,
            chassis_code: this.state.chassis_code
        };
        this.setState({ ...this.state, working: true });
        fetch(url, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    this.setState({ ...this.state, message: null, data: data.data, working: false });
                } else {
                    this.setState({ ...this.state, message: data.message, data: null, working: false });
                }
            }.bind(this));
    }

    changeMake (e) {
        this.setState({ ...this.state, make: e.target.value });
    }

    changeChassis (e) {
        this.setState({ ...this.state, chassis_code: e.target.value });
    }

    render () {
        return <Form>
            <Row>
                <Col>
                    <Form.Control placeholder="Vehicle Make" onChange={this.changeMake} value={this.state.make} />
                </Col>
                <Col>
                    <Form.Control placeholder="Chassis Number" onChange={this.changeChassis} value={this.state.chassis_code} />
                </Col>
                <Col>
                    <Button disabled={this.state.working} onClick={this.goClick}>Search</Button>
                </Col>
            </Row>
            {this.state.working && <p>Loading...</p>}
            {this.state.message !== null &&
                <Row><Col>{this.state.message}</Col></Row>
            }
            {this.state.data !== null &&
                <Row><Col><ShowData data={this.state.data} /></Col></Row>
            }
        </Form>;
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure
    };
};


export default connect(mapStateToProps)(ProductionData);
