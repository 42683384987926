
import React, { Component } from "react";
import PropTypes from "prop-types";

import { settings } from "../settings";

export default class RecentPurchases extends Component {
    constructor () {
        super();
        this.state = {
            loaded: false,
            total: 0,
            perMonth: 0,
            perYear: 0
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let url = settings.api_server + "/purchaseDetail/stats";

        // admins can load any user. No argument loads the current user.
        if (this.props.who !== -1) {
            url = url + "/" + this.props.who;
        }

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    loaded: true,
                    ...data // unpack right into the state
                });
            });
    }

    render () {
        if (!this.state.loaded) {
            return <p>Loading recent purchase counts...</p>;
        }

        return <>
            Purchased {this.state.total} vehicles. {this.state.perYear} in the last year,
            and {this.state.perMonth} in the last month.
        </>;
    }
}
RecentPurchases.propTypes = {
    who: PropTypes.integer
};

RecentPurchases.defaultProps = {
    who: -1
};
