
import { settings } from "../settings";
import { alphabetically } from "../functions";

const profile_defaultState = {
    fetchingProfile: true,
    loaded: false,
    username: null,
    is_admin: false,
    loggedIn: false,
    profileLoaded: false,
    translation_requests_available: 0,
    bidsLoaded: false,
    purchases: null,
    watchedCarsLoaded: false,
    guest: true,
    showPasswordChange: false,
    nextDue: null,
    bid_sort_col: "bid_datetime",
    bid_sort_desc: false,
    showAddressChange: false
};

const profile = (state = profile_defaultState, action) => {
    switch (action.type) {
    case "SET_PROFILE":
        // set some meta-information
        var profile = {
            ...action.data,
            is_bid_admin: action.data.roles.includes("FOUNDER") || action.data.roles.includes("BIDS"),
            is_queue_admin: action.data.roles.includes("FOUNDER") || action.data.roles.includes("QUEUES"),
            is_accounts_admin: action.data.roles.includes("FOUNDER") || action.data.roles.includes("ACCOUNTS"),
            is_acct_manager: action.data.roles.includes("FOUNDER") || action.data.roles.includes("ACCOUNT_MANAGER"),
            is_founder_admin: action.data.roles.includes("FOUNDER"),
            isvip: action.data.roles.includes("VIP")

        };
        return {
            ...state,
            ...profile,
            profileLoaded: true,
            loggedIn: true,
            fetchingProfile: false
        };
    case "FAILED_LOGIN":
        return {
            ...settings.profile_defaultState,
            loggedIn: false,
            fetchingProfile: false
        };
    case "SET_FULL_PROFILE":
        return {
            ...state,
            expandedProfile: true
        };
    case "SET_BID_LIST":
        return {
            ...state,
            bidsLoaded: true,
            bidList: action.bids
        };
    case "SET_PURCHASE_LIST":
        return {
            ...state,
            purchaseList: action.purchaseList
        };
    case "SET_WATCHED_CAR_LIST":
        return {
            ...state,
            watchedCarList: action.watchedCarList,
            watchedCarsLoaded: true
        };
    case "SHOW_PASSWORD_CHANGE":
        return {
            ...state,
            showPasswordChange: true
        };
    case "HIDE_PASSWORD_CHANGE":
        return {
            ...state,
            showPasswordChange: false
        };
    case "LOGOUT":
        return {
            ...settings.profile_defaultState
        };
    case "SORT_BID_COLUMN":{ 
        let desc = false;
        if (state.bid_sort_col === action.key) {
            desc = !state.bid_sort_desc;
        }
        // do the sort
        const bidsSort = state.bidList;
        bidsSort.sort(alphabetically(desc, action.key));
        return {
            ...state,
            bid_sort_col: action.key,
            bid_sort_desc: desc,
            bidList: bidsSort
        };
    }
    case "TOGGLE_ADDRESS_CHANGE":
        return {
            ...state,
            showAddressChange: !state.showAddressChange
        };
    default:
        return state;
    }
};

export default profile;
