import { settings } from "../settings";

const fetchShippingFee = function () {
    const url = settings.api_server + "/m3/shipping_ports";

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            settings.store.dispatch(setShippingFees(data));
        });
};

const setShippingFees = function (fees) {
    return {
        type: "SET_SHIPPING_FEES",
        fees
    };
};

const removePort = function (port_name) {
    return {
        type: "REMOVE_SHIPPING_PORT",
        port_name
    };
};

const updateShippingFee = function (fee_id, field, value) {
    return {
        type: "UPDATE_SHIPPING_FEE_SHIPPING_DATA",
        fee_id,
        field,
        value
    };
};

const deleteRow = function (fee_id) {
    return {
        type: "DELETE_SHIPPING_FEE_SHIPPING_DATA",
        fee_id
    };
};

const addRow = function (port_id) {
    return {
        type: "ADD_SHIPPING_FEE_SHIPPING_DATA",
        port_id
    };
};

export {
    setShippingFees,
    fetchShippingFee,
    removePort,
    updateShippingFee,
    deleteRow,
    addRow
};
