import React from "react";
import {
    Container, Row, Col, Form, Button, InputGroup
} from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import Octicon, {X, Alert} from "@githubprimer/octicons-react";

import { settings } from "../../../settings";
import StaffSalesDisplay from "./StaffSalesDisplay";

import "./StaffSales.css";

const STAFF_ROLE = "ACCOUNT_MANAGER";

/**
 * A display of staff sales over a configurable time period.
 */
export default function StaffSales()
{
    // Can set a custom date range for the report. Null start date means we get
    // data since the dawn of time, null end date is equivalent to now.
    // Use range of one year by default.
    const [startDate, setStartDate] = React.useState(moment().subtract(1, "y"));
    const [endDate, setEndDate] = React.useState(moment());
    // Can select an account manager user_id to see only their sales.
    // Special values:
    // ALL = all purchases with or without an account manager
    // ANY = all purchases with any account manager
    // NONE = all purchases with no account manager
    const [staffFilter, setStaffFilter] = React.useState("ALL");

    // Load all staff with the account manager role. Only happens on mount,
    // since we only use the results to display the names of account managers.
    // The results are stored as a map, mapping user_ids to user objects.
    // If the fetch fails, show a warning, and use an empty map so there don't
    // have to be checks for it being null scattered throughout the code.
    const [staff, setStaff] = React.useState(new Map());
    const [staffErr, setStaffErr] = React.useState(null);
    React.useEffect(() => {
        const url = settings.api_server + "/user/usersWithRole/" + STAFF_ROLE;
        fetch(url, {"credentials": "include"})
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                data.users
                :
                Promise.reject(data.message)
            )
            .then(
                users => {
                    setStaff(new Map(users.map(u => [u.user_id, u])));
                    setStaffErr(null);
                },
                reason => {
                    console.log("Failed to fetch staff for sales report: "
                        + reason);
                    setStaff(new Map());
                    setStaffErr(reason);
                }
            );
    }, []);

    // For displaying account managers in the staff filter dropdown
    const getStaffName = (s) => `${s.firstname[0]}. ${s.lastname} (${s.username})`;

    return <Container className="staff-sales-main">
        <h2>Staff Sales</h2>
        <Row className="sales-report-controls">
            <Col md="auto">
                From:
            </Col>
            <Col md={3}>
                <InputGroup className="sales-report-date-select">
                    <Datetime
                        inputProps={{placeholder: "All time"}}
                        dateFormat={"YYYY-MM"}
                        timeFormat={false}
                        value={startDate}
                        onChange={(val) => setStartDate(
                            typeof(val) === "string" ? null : val
                        )}
                    />
                    <InputGroup.Append>
                        <Button variant="primary" size="sm"
                            onClick={() => setStartDate(null)}
                        >
                            <Octicon icon={X} verticalAlign="middle"/>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col md="auto">
                To:
            </Col>
            <Col md={3}>
                <InputGroup className="sales-report-date-select">
                    <Datetime
                        inputProps={{placeholder: "Now"}}
                        dateFormat={"YYYY-MM"}
                        timeFormat={false}
                        value={endDate}
                        onChange={(val) => setEndDate(
                            typeof(val) === "string" ? null : val
                        )}
                    />
                    <InputGroup.Append>
                        <Button variant="primary" size="sm"
                            onClick={() => setEndDate(null)}
                        >
                            <Octicon icon={X} verticalAlign="middle"/>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col md="auto">
                Manager:
            </Col>
            <Col md={3}>
                <Form.Control as="select" disabled={staffErr !== null}
                    onChange={(e) => {
                        setStaffFilter(e.target.value);
                    }}
                >
                    {staffErr === null && <>
                        <option value="ALL">All</option>
                        <option value="ANY">Any account manager</option>
                        <option value="NONE">No account manager</option>
                        {[...staff.values()].map(s => <option
                            key={s.user_id}
                            value={s.user_id}
                        >
                            {getStaffName(s)}
                        </option>)}
                    </>}
                    {staffErr !== null &&
                        <option value="ALL">Error</option>
                    }
                </Form.Control>
            </Col>
        </Row>
        {staffErr !== null &&
            <Row className="staff-sales-error">
                <Col style={{textAlign: "center"}}>
                    <br/>
                    <p>
                        <Octicon icon={Alert}/> An error occurred while loading
                        the list of account managers: {staffErr}
                    </p>
                </Col>
            </Row>
        }
        <StaffSalesDisplay
            startDate={startDate}
            endDate={endDate}
            staff={staff}
            staffFilter={staffFilter}
        />
    </Container>;
}
