import React, { Component } from "react";
import "./App.css";

import { connect } from "react-redux";

import {
    Container
} from "react-bootstrap";

import PCANav from "./dashboard-components/navbar";

import AllTranslationsList from "./translations/AllTranslationsList";

/* Scope is handled server-side  - admin gets all, users get theirs

No redux needed. This is really self-contained.
*/
class AllTranslations extends Component {
    render () {
        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />

                <Container>
                    {/* only load the child page once the user is loaded in */}
                    {this.props.userLoaded ? "Loading..." : <AllTranslationsList isAdmin={this.props.isAdmin} /> }
                </Container>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,
        userLoaded: state.profile.loaded

    };
};

export default connect(mapStateToProps)(AllTranslations);
