import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Modal
} from "react-bootstrap";

import { settings } from "../../settings";

import { fetchCarData } from "../../actions/car_detail_actions";

import {
    closeReleaseConfirm
} from "../../actions/invoice_actions";
import { setMessage } from "../../actions/alert_actions";

class ReleaseInvoiceModalComponent extends Component {
    constructor () {
        super();
        this.releaseInvoice = this.releaseInvoice.bind(this);
    }

    releaseInvoice () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.release_invoice_id + "/release";
        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    // fetch the new data, close the modal
                    if (data.success) {
                        // fetch new info
                        fetchCarData(this.props.purchase_id);
                        this.props.setMessage("Invoice released to client");
                        this.props.closeReleaseConfirm();
                    } else
                    // TODO - something more than this
                    { console.log("error closing the invoice"); }
                }.bind(this));
    }

    render () {
        return <Modal show={this.props.showReleaseConfirm} onHide={this.props.closeReleaseConfirm}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Releasing an invoice is not reversible.</Modal.Body>
            <Modal.Footer><Button onClick={this.releaseInvoice}>Yes, release</Button> <Button onClick={this.props.closeReleaseConfirm}>No, run away</Button></Modal.Footer>

        </Modal>;
    }
}
const releaseInvoiceMapStateToProps = state => {
    return {
        showReleaseConfirm: state.invoice.showReleaseModal,
        release_invoice_id: state.invoice.release_invoice_id,
        purchase_id: state.invoice.purchase_id
    };
};

const releaseInvoiceMapDispatchToProps = dispatch => ({
    closeReleaseConfirm: () => dispatch(closeReleaseConfirm()),
    setMessage: (message) => dispatch(setMessage(message))

});

export default connect(releaseInvoiceMapStateToProps, releaseInvoiceMapDispatchToProps)(ReleaseInvoiceModalComponent);
