/**
 * This component contains the controls for filtering Task Manager items (which
 * type of new data is available, date of new data)
 */

import React, {Component} from "react";

import {Container, Row, Col, Button} from "react-bootstrap";

const newDataName = "newDataType";
const timeOptionName = "timeOption";

const newDataTypes = ["Images", "Translations", "Questions"];
const timeOptions = ["Today", "Tomorrow", "Any Time"];
const defaultTimeOption = "Any Time";

// Required prop: function to update filters ("setFilters")
class TaskPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                time: null
            }
        };
        for (let dt of newDataTypes) {
            this.state.filters["new" + dt] = false;
        }
        this.onDataChange = this.onDataChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    onDataChange(event) {
        const newFilters = this.state.filters;
        newFilters[event.target.value] = event.target.checked;
        this.setState({...this.state, filters: newFilters});
    }

    onTimeChange(event) {
        let newVal = null;
        if (event.target.value == "Today" || event.target.value == "Tomorrow") {
            newVal = event.target.value;
        }
        const newFilters = {...this.state.filters, time: newVal};
        this.setState({...this.state, filters: newFilters});
    }

    updateFilters() {
        this.props.setFilters(this.state.filters);
    }

    resetFilters() {
        const newFilters = {time: null};
        for (let d of newDataTypes) {
            document.getElementById(`new${d}Btn`).checked = false;
            newFilters["new" + d] = false;
        }

        for (let t of timeOptions) {
            document.getElementById(`${t}Btn`).checked = false;
        }
        document.getElementById(`${defaultTimeOption}Btn`).checked = true;
        
        this.setState({...this.state, filters: newFilters});
        this.props.setFilters(newFilters);
    }

    render() {
        const newDataButtons = newDataTypes.map(type => {
            const btnID = "new" + type + "Btn";
            return (
                <span key={"new" + type + "Div"} style={{whiteSpace: "nowrap", textAlign: "right"}}>
                    <label htmlFor={btnID}>{"New " + type}&nbsp;</label>
                    <input type='checkbox' id={btnID} name={newDataName}
                        value={"new" + type} onChange={this.onDataChange}
                        defaultChecked={false}/>
                </span>
            );
        });

        const timeButtons = timeOptions.map(option => {
            const btnID = option + "Btn";
            return (
                <span key={option} style={{whiteSpace: "nowrap", textAlign: "right"}}>
                    <label htmlFor={btnID}>{option} &nbsp;</label>
                    <input type='radio' id={btnID} name={timeOptionName}
                        value={option} onChange={this.onTimeChange}
                        defaultChecked={option == defaultTimeOption}/>
                </span>
            );
        });

        return (
            <Container className='dropShadow cardSpace whiteBackground'
                style={{marginTop: "15px", maxWidth: "310px", float: "right", textAlign: "right"}}>
                <Row>
                    <Col style={{paddingRight: "0"}}>
                        {newDataButtons[0]}
                    </Col>
                    <Col style={{paddingLeft: "0"}}>
                        {timeButtons[0]}
                    </Col>
                </Row>
                <Row>
                    <Col style={{paddingRight: "0"}}>
                        {newDataButtons[1]}
                    </Col>
                    <Col style={{paddingLeft: "0"}}>
                        {timeButtons[1]}
                    </Col>
                </Row>
                <Row>
                    <Col style={{paddingRight: "0"}}>
                        {newDataButtons[2]}
                    </Col>
                    <Col style={{paddingLeft: "0"}}>
                        {timeButtons[2]}
                    </Col>
                </Row>
                <span style={{whiteSpace: "nowrap"}}>
                    <Button size='sm' variant='success' style={{marginBottom: "5px"}}
                        onClick={this.updateFilters}>Go</Button>
                    <Button size='sm' variant='danger' style={{marginBottom: "5px"}}
                        onClick={this.resetFilters}>Reset</Button>
                </span>
            </Container>
        );
    }
}

export default TaskPanel;
