import React from "react";
import { connect } from "react-redux";
import { showTranslation } from "../../actions/translation_actions";
import { ListGroup, Row, Col, Button } from "react-bootstrap";

import { prettyDateTime } from "../../functions";

/**
 * @typedef {Object} Translation
 * @prop {number} translation_id
 * @prop {string} vehicle_id
 * @prop {string} main stringified JSON with custom translation fields
 * @prop {string} description description of the car
 * @prop {string} auction_date
 */

/**
 * @typedef {Object} TranslationResult
 * @prop {string[]} standard_fields
 * @prop {string[]} custom_fields
 * @prop {Translation} translation
 * @prop {number[]} requesters
 */

/**
 * Returns a ListGroup.Item for a translation fetched by /translations/search
 * (i.e. it contains matched fields in addition to the translation data)
 * @param {Object} props
 * @param {string} props.searchText the text that was searched for. If empty,
 * matched fields aren't shown, because an empty string matches all fields of
 * all translations.
 * @param {TranslationResult} props.translation
 * @param {boolean} [props.extended] if true, show extended info
 * @param {(id: number, car_id: string) => void} props.showTranslation function
 * to show translation modal, injected by Redux
 */
function TranslationLookupItem({
    searchText,
    translation,
    showTranslation,
    extended = false,
})
{
    const allMatches = [
        ...translation.standard_fields,
        ...translation.custom_fields
    ];

    let summary = "";
    if (searchText)
    {
        switch (allMatches.length)
        {
        case 1:
            summary = `1 match found in "${allMatches[0]}"`;
            break;
        case 2:
            summary = `2 matches found in "${allMatches[0]}" and "${allMatches[1]}"`;
            break;
        case 3:
            summary = `3 matches found in "${allMatches[0]}", "${allMatches[1]}" and "${allMatches[2]}"`;
            break;
        default:
            summary = `${allMatches.length} matches found`;
            break;
        }
    }

    const requestedBy = translation.requesters.length == 1 ?
        "Requested by 1 person"
        :
        "Requested by " + translation.requesters.length + " people";

    return <ListGroup.Item>
        <Row>
            <Col>
                <b>{translation.translation.description}</b>
                {summary && <><br/>{summary}</>}
            </Col>
            {extended && <>
                <Col md="auto">
                    Auction: {prettyDateTime(
                        translation.translation.auction_date, true, false
                    )}
                </Col>
                <Col md="auto">
                    {requestedBy}
                </Col>
            </>}
            <Col md="auto">
                <Button
                    variant="outline-info"
                    onClick={() => showTranslation(
                        translation.translation.translation_id,
                        translation.translation.vehicle_id
                    )}
                >
                    View
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>;
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    showTranslation: (id, car_id) => dispatch(showTranslation(id, null, car_id))
});

/**
 * Returns a ListGroup.Item for a translation fetched by /translations/search
 * (i.e. it contains matched fields in addition to the translation data)
 * @param {Object} props
 * @param {string} props.searchText the text that was searched for. If empty,
 * matched fields aren't shown, because an empty string matches all fields of
 * all translations.
 * @param {TranslationResult} props.translation
 * @param {boolean} [props.extended] if true, show extended info
 */
export default connect(
    mapStateToProps, mapDispatchToProps
)(TranslationLookupItem);
