import moment from "moment-timezone";

import React, { Component } from "react";

function getTheTime(){
    let parsed = new Date();
    let theTime = moment(parsed).tz("Asia/Tokyo").format("MMMM Do HH:mm");
    return theTime;
}

class TimeInJapan extends Component {

    constructor(props) {
        super(props);
        this.state = { time: getTheTime() };
    }


    tick() {

        this.setState({ time: getTheTime() });
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 20000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                {this.state.time}
            </div>
        );
    }
}

export default TimeInJapan;