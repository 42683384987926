import React, { Component } from "react";
import { connect } from "react-redux";

import { settings } from "../settings";
import { dayToNum } from "../functions";

import { setAuction, setAuctionHouseList, toggleAuctionHouses, auctionHouseToggle, auctionHouseToggleMany } from "../actions/search_actions";

import {
    Container, Col, Row,
    ListGroup,
    Collapse
} from "react-bootstrap";

class AuctionComponent extends Component {
    constructor () {
        super();
        this.clicked = this.clicked.bind(this);
    }

    clicked () {
        this.props.auctionHouseToggle(this.props.auction.auction_name);
    }

    /*
    Gets one auctions, displays it with a Form.Check
    */
    render () {
        let colour = null;
        // udpate time is kludge to force update
        if (this.props.auction.selected && this.props.auctionHouseLastUpdate > 0) { colour = "success"; }
        return (
            <ListGroup.Item action onClick={this.clicked} variant={colour}>{this.props.auction.auction_name} ({this.props.auction.count})</ListGroup.Item>
        );
    }
}
const auctionMapStateToProps = state => {
    return {
        auctionHouseLastUpdate: state.search.auctionHouseLastUpdate

    };
};

const auctionMapDispatchToProps = dispatch => ({
    auctionHouseToggle: (s) => dispatch(auctionHouseToggle(s))
});

const Auction = connect(auctionMapStateToProps, auctionMapDispatchToProps)(AuctionComponent);

class AuctionHouses extends Component {
    constructor (props) {
        super(props);
        this.clicked = this.clicked.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidMount () {
        if (!this.props.loaded) {
            // fetch the data
            const url = settings.api_server + "/search/auctions";
            fetch(url, {
                cache: "no-cache",
                headers: {
                    pragma: "no-cache",
                    "cache-control": "no-store"
                },
                method: "GET",
                credentials: "include"
            }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (myJson) {
                    // TODO - error handling?
                    this.props.setAuctionHouseList(myJson);
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    onToggle () {

    // just to keep a warning out
    }

    clicked (e) {
        const auctions = [];

        // Check to see if all the auctions on is day are selected.
        // If yes, turn them off. If even one is off, turn them on
        let turnOn = false;
        console.log("go");
        for (let i = 0; i < this.props.auctions.length; i++) {
            const d = dayToNum(this.props.auctions[i].day);
            // the cast is so we can use ===, for the linter to shut up.
            if (!isNaN(e.target.value) && d === parseInt(e.target.value, 10)) {
                // send this name
                auctions.push(this.props.auctions[i].auction_name);
                console.log(this.props.auctions[i].selected);

                // if we're turning on already, or if there is one not selected
                // then turn them all on
                turnOn = turnOn || !this.props.auctions[i].selected;
            }
        }
        this.props.auctionHouseToggleMany(auctions, turnOn);
    }

    render () {
    // break up the list into dates
    // parse the passed date, get the day of week.

        // isoWeekdayreturns 1-7 where 1 is Monday and 7 is Sunday
        // used same concept, using functions/dayToNum
        let weekend=[];
        const byDate = { 0:weekend, 1: [], 2: [], 3: [], 4: [], 5: [], 6: weekend };

        // comes in ordered alphabetically
        for (let i = 0; i < this.props.auctions.length; i++) {
            const d = dayToNum(this.props.auctions[i].day);

            //only populates list if the number of auctions is more than 0
            if(this.props.auctions[i].count > 0)
                byDate[d].push(<Auction key={this.props.auctions[i].auction_name} auction={this.props.auctions[i]} />);
        }

        return (

            <Row>
                <Col md={12} className="italicTitle theHand" onClick={this.props.toggleAuctionHouses}>Auction Dates</Col>
                <Collapse in={this.props.show}>
                    <div className="around_container">
                        <Container fluid>
                            <Row>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={1} onClick={this.clicked}>Monday</ListGroup.Item>
                                    {byDate[1]}
                                </ListGroup></Col>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={2} onClick={this.clicked}>Tuesday</ListGroup.Item>
                                    {byDate[2]}
                                </ListGroup></Col>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={3} onClick={this.clicked}>Wednesday</ListGroup.Item>
                                    {byDate[3]}
                                </ListGroup></Col>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={4} onClick={this.clicked}>Thursday</ListGroup.Item>
                                    {byDate[4]}
                                </ListGroup></Col>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={5} onClick={this.clicked}>Friday</ListGroup.Item>
                                    {byDate[5]}
                                </ListGroup></Col>
                                <Col md={2}><ListGroup variant="flush">
                                    <ListGroup.Item action value={6} onClick={this.clicked}>Weekend/Other</ListGroup.Item>
                                    {byDate[6]}
                                </ListGroup></Col>
                            </Row>
                        </Container>
                    </div>
                </Collapse>
            </Row>

        );
    }
}

const mapStateToProps = state => {
    return {
        auctions: state.search.auctionHouseList,
        loaded: state.search.auctionHouseLoaded,
        show: state.search.showAuctionHouses
    };
};

const mapDispatchToProps = dispatch => ({
    setAuction: (s) => dispatch(setAuction(s)),
    setAuctionHouseList: (l) => dispatch(setAuctionHouseList(l)),
    toggleAuctionHouses: () => dispatch(toggleAuctionHouses()),
    auctionHouseToggleMany: (auctions, turnOn) => dispatch(auctionHouseToggleMany(auctions, turnOn))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionHouses);
