import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Alert,
    FormControl,
    Modal,
    Button,
    Container,
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";

import AdminOnly from "../dashboard-components/AdminOnly";
// one of these will fetch the images (whichever dispatches first), not us
import ProbablyAuctionSheet from "../widgets/ProbablyAuctionSheet";
import AuctionCarImages from "../auction_car_views/AuctionCarImages";

import { getAuctionCarData, clearAuctionData, setTranslation } from "../actions/auction_car_actions";
import { setCurrentThread, clearCurrentThread } from "../actions/questions_actions";

import AuctionCarDetails from "../auction_car_views/AuctionCarDetails";
import Translation from "../auction_car_views/Tranlsation";

class QuestionModal extends Component {
    constructor () {
        super();
        this.loadData = this.loadData.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.changeResponse = this.changeResponse.bind(this);
        this.ignore = this.ignore.bind(this);
        this.fetchTranslation = this.fetchTranslation.bind(this);
        this.state = { pushing_data: false, errorMessage: null, response: "" };
    }

    componentDidMount () {
    // fetch this thread's data
    }

    handleClose () {
        this.props.clearCurrentThread();
        this.props.clearAuctionData();
    }

    ignore () {
        this.setState({ ...this.state, pushing_data: true });

        const url = settings.api_server + "/questions/thread/" + this.props.show_thread_id + "/ignore";
        fetch(url, {
            credentials: "include",
            method: "PUT"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: null });
                    this.handleClose();
                    // refresh underlying list
                    if ("update" in this.props) {
                        this.props.update();
                    }
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Problem loading data" });
                }
            }.bind(this));
    }

    fetchTranslation (auction_id) {
        const url = settings.api_server + "/translations/access/" + auction_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a translation data
                this.props.setTranslation(data.found, data, data.translation_completed);
            }.bind(this));
    }

    saveAndClose () {
        this.setState({ ...this.state, pushing_data: true });

        const postdata = {
            text: this.state.response
        };

        const url = settings.api_server + "/questions/thread/" + this.props.show_thread_id;
        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ pushing_data: false, errorMessage: null, response: "" });
                    this.handleClose();
                    // refresh underlying list
                    if ("update" in this.props) {
                        this.props.update();
                    }
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Could not save translation" });
                }
            }.bind(this));
    }

    loadData () {
    // called onEntering

        const url = settings.api_server + "/questions/thread/" + this.props.show_thread_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.props.setCurrentThread(data.conversation);
                // get this car's auction data
                getAuctionCarData(data.vehicle_id);

                this.fetchTranslation(data.vehicle_id);
            }.bind(this));
    }

    changeResponse (e) {
        this.setState({ ...this.state, response: e.target.value });
    }

    render () {
    // don't show if not an admin
        if (!this.props.isAdmin) { return null; }

        let body = <p>Loading</p>;

        const style = { paddingTop: "10px" };

        if (this.props.loaded) {
            const convo = this.props.thread_data.map((t) => <ListGroup.Item className="whitespace" key={t.text + t.author_name}><b>{t.author_name} says:</b> {t.text}</ListGroup.Item>);
            body = <div>
                <Container fluid>
                    <div><AuctionCarDetails /></div>
                    <ProbablyAuctionSheet />
                    <Translation />
                    <h3>Conversation</h3>
                    <ListGroup>{convo}</ListGroup>
                    <p>Reply:</p>
                    <FormControl rows="5" as="textarea" onChange={this.changeResponse} value={this.state.response} placeholder="Write your reply here" />
                    {
                        this.state.errorMessage !== null && <Alert>{this.state.errorMessage}</Alert>
                    }
                </Container>
                <div style={style}>
                    <AuctionCarImages />
                </div>
            </div>;
        }

        return (
            <Modal size='lg' show={this.props.show_thread_id !== -1} onHide={this.handleClose} onEntering={this.loadData}>
                <Modal.Header closeButton>
                    <Modal.Title>Answer question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pushing_data} onClick={this.saveAndClose}>Reply</Button> <Button disabled={this.state.pushing_data} onClick={this.ignore}>Ignore</Button> <Button disabled={this.state.pushing_data} onClick={this.props.clearCurrentThread}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {

        thread_data: state.questions.thread_data,
        show_thread_id: state.questions.show_thread_id,
        loaded: state.questions.thread_loaded,

        isAdmin: state.profile.is_admin

    };
};

const mapDispatchToProps = dispatch => ({
    clearCurrentThread: () => dispatch(clearCurrentThread()),
    setCurrentThread: (t) => dispatch(setCurrentThread(t)),
    clearAuctionData: () => dispatch(clearAuctionData()),

    // translation data
    setTranslation: (req, trans, com) => dispatch(setTranslation(req, trans, com))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal);
