import { settings } from "../settings";
import { setMessage } from "./alert_actions";

let basic_data_refresh = null;

function fetch_basic_data () {
    const url = settings.api_server + "/search/base_info";
    fetch(url, {
        cache: "no-cache",
        headers: {
            pragma: "no-cache",
            "cache-control": "no-store"
        },
        method: "GET",
        credentials: "include"
    }
    )
        .then(function (response) {
            if (response.status === 429) {
                // set the banner to say there was a problem
                settings.store.dispatch(setMessage("Too many requests, rate limiting results."));
                throw new Error("Too many requests");
            }
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling?
            settings.store.dispatch(base_info(myJson));
        })
        .catch(function (error) {
            // tODO remove?
            console.log(error);
        });
}

// do the refresh
const base_info = function base_info (data) {
    return {
        type: "BASE_INFO",
        data
    };
};

const makeSearchObject = function (query) {
    const postData = {};

    if ("make_selected" in query && query.make_selected !== settings.make_placeholder) {
    // store the actual string (before making arrays and stuff)
        postData.makeStr = query.make_selected;
        if (settings.countries.indexOf(query.make_selected) >= 0) {
            // check if the make is a group
            // known group
            postData.make = settings.bins[query.make_selected];
        } else if (query.make_selected === settings.others) {
            // others
            postData.make = query.makes.map(x => x.make).filter(make => settings.knownMakes.indexOf(make) === -1);
        } else {
            postData.make = [query.make_selected];
        }
    }
    if ("models_selected" in query && query.models_selected !== null) {
    // make sure none of the entires are the placeholder
        const cleanList = query.models_selected.filter(item => item !== settings.model_placeholder);
        if (cleanList.length > 0) { postData.models = cleanList; }
    }
    if (typeof query.model_id_selected !== "undefined" &&
        "model_id_selected" in query && query.model_id_selected !== null) {
        const idList = query.model_id_selected.filter(item => item !== settings.model_placeholder);
        if (idList.length > 0) { postData.model_ids = idList; }
    }
    if ("start_year" in query && query.start_year !== "") { postData.start_year = query.start_year; }
    if ("end_year" in query && query.end_year !== "") { postData.end_year = query.end_year; }

    if ("min_grade" in query && query.min_grade !== "" && query.min_grade !== null) { postData.min_grade = query.min_grade; }

    if (!isNaN(query.odo_min) && query.odo_min !== "") { postData.odo_min = query.odo_min; }
    if (!isNaN(query.odo_max) && query.odo_max !== "") { postData.odo_max = query.odo_max; }
    if ("chassis" in query && query.chassis !== "" && query.chassis !== null) { postData.chassis = query.chassis; }
    if ("engine_min" in query && query.engine_min !== "") { postData.engine_min = query.engine_min; }
    if ("engine_max" in query && query.engine_max !== "") { postData.engine_max = query.engine_max; }
    if ("trans" in query && query.trans !== "")
    // numeric: 1=mt, 2=at
    { postData.trans = query.trans; }
    if ("gears" in query && query.gears !== null && query.gears !== settings.gear_placeholder) {
    // make sure none of the entires are the placeholder
        const gearList = query.gears.filter(item => item !== settings.gear_placeholder);
        if (gearList.length > 0) { postData.gears = gearList; }
    }
    if (query.drivetrain !== settings.drivetrain_placeholder && query.drivetrain !== null) {
        postData.drivetrain = query.drivetrain;
    }
    if (("auction" in query && query.auction !== "") || ("auctionHouseList" in query && query.auctionHouseList.length > 0)) {
    // send a comma-separated set.
    // auctions can be typed in a box, or picked from below

        // just add the typed value to the house list
        // if the user typed a comma, it'll be put in as two ORs, which is fine
        const auctions = query.auctionHouseList.filter(a => a.selected).map(a => a.auction_name);
        if (query.auction !== "") { auctions.push(query.auction); }

        postData.auction = auctions.join(",");
    }

    if ("sold_price_min" in query && query.sold_price_min !== "") {
        postData.sold_price_min = query.sold_price_min;
    }
    if ("sold_price_max" in query && query.sold_price_max !== "") {
        postData.sold_price_max = query.sold_price_max;
    }

    // toggles for options
    postData.ac = query.ac;
    postData.aw = query.aw;
    postData.le = query.le;
    postData.sr = query.sr;
    postData.pw = query.pw;
    postData.ps = query.ps;
    postData.tv = query.tv;

    // add the car options to an array of strings
    const options = [];
    if (query.camper) { options.push("camping"); }
    if (query.dump) { options.push("dump"); }
    if (query.crane) { options.push("crane"); }
    if (query.doubleCab) { options.push("wcab"); }
    if (query.loader) { options.push("loader"); }
    if (query.bus) {
        options.push("microbus");
        options.push("bus");
    }
    if (query.keyword !== "") {
        postData.keyword = query.keyword;
    }
    postData.options = options;

    const grades = [];
    if (query.grade_star) { grades.push("***"); }
    if (query.grade_two) { grades.push("2"); }
    if (query.grade_three) { grades.push("3"); }
    if (query.grade_three_five) { grades.push("3.5"); }
    if (query.grade_four) { grades.push("4"); }
    if (query.grade_four_five) { grades.push("4.5"); }
    if (query.grade_five) { grades.push("5"); }
    if (query.grade_R) {
        grades.push("R");
        grades.push("RA");
    }
    postData.grades = grades;

    if ("start_date" in query && query.start_date !== "")
    // TODO - validate date with moment?
    { postData.start_date = query.start_date; }
    if ("end_date" in query && query.end_date !== "") { postData.end_date = query.end_date; }
    if ("auctionNumber" in query && query.auctionNumber !== "") { postData.auctionNumber = query.auctionNumber; }
    if ("numericCount" in query && query.numericCount !== "" && query.numericCount !== -1) { postData.numericCount = query.numericCount; }
    if ("page" in query && query.page !== "" && query.page !== -1) { postData.page = query.page; }
    if (!isNaN(query.sortColumn)) { postData.sort = query.sortColumn; }
    return postData;
};

const doSearch = function doSearch (query) {
    // check if we have this page
    const state = settings.store.getState();
    if (query.page in state.search_results) {
    // we have it, stop
        return;
    }

    if (state.search.fetching) {
    // we are already fetching
        return;
    }

    // dispatch that we are fetching
    settings.store.dispatch(fetching());

    // do the fetch
    const url = settings.api_server + "/search/query";
    const postData = makeSearchObject(query);
    // add in the known count
    postData.count = state.search_results.count;

    fetch(url, {
        credentials: "include",
        method: "POST", // this is only a post because it won't let me do GET with a body
        body: JSON.stringify(postData),
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status === 429) {
                // set the banner to say there was a problem
                settings.store.dispatch(setMessage("Too many requests, rate limiting results, try again later."));
                throw new Error("Too many requests");
            }
            if (response.status !== 200) {
                settings.store.dispatch(show_results_error("Could not load results"));
                throw new Error("Problems");
            }
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling
            // need to set fetching ot false, list to null?
            settings.store.dispatch(set_results(myJson, query.page));
            // done fetching
            settings.store.dispatch(fetchComplete());
        },
        error => { error; } // silence error message for 429
        );
};

const getCount = function (query) {
    // do the fetch
    const url = settings.api_server + "/search/count";
    const postData = makeSearchObject(query);
    settings.store.dispatch(fetching_count());
    fetch(url, {
        credentials: "include",
        method: "POST", // this is only a post because it won't let me do GET with a body
        body: JSON.stringify(postData),
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status === 429) {
                // set the banner to say there was a problem
                settings.store.dispatch(setMessage("Too many requests, rate limiting results, try again later."));
                throw new Error("Too many requests");
            }
            if (response.status !== 200) {
                settings.store.dispatch(setMessage("Error loading count data."));
                throw new Error("Problems");
            }
            return response.json();
        })
        .then(function (myJson) {
            // TODO - error handling
            // need to set fetching ot false, list to null?
            settings.store.dispatch(set_count(myJson));
        },
        error => { error; } // silence error message for 429
        );
};

/*
  make sure the user is logged in. Start the profile fetcher if it's not running
  */
const baseInfoRefresher = function baseInfoRefresher () {
    // start the timer
    if (basic_data_refresh === null) {
    // the make model info
        fetch_basic_data();
        basic_data_refresh = setInterval(fetch_basic_data, 60 * 60 * 20);
    }
};

const set_results = function (results, page) {
    return {
        type: "SET_RESULTS",
        results,
        page
    };
};

const set_count = function (results) {
    return {
        type: "SET_COUNT",
        count: results.count
    };
};

const show_results_section = function () {
    return {
        type: "SHOW_RESULTS"
    };
};

const show_results_error = function (errorText) {
    return {
        type: "SHOW_RESULTS_ERROR",
        errorText
    };
};

const clear_results = function () {
    return {
        type: "CLEAR_RESULTS"
    };
};

const clear_pages = function () {
    return {
        type: "CLEAR_PAGES"
    };
};

const fetching = function () {
    return {
        type: "SET_FETCHING"
    };
};

const fetchComplete = function () {
    return {
        type: "FETCH_COMPLETE"
    };
};

const selectMake = function selectMake (make_name) {
    return {
        type: "SELECT_MAKE",
        make_name
    };
};

const clearSelection = function selectMake () {
    return {
        type: "CLEAR_SELECTION"
    };
};

const selectModels = function selectModels (models, ids) {
    return {
        type: "SELECT_MODELS",
        models,
        ids
    };
};

const changeAuctionNumber = function (number) {
    return {
        type: "SET_AUCTION_NUMBER",
        auctionNumber: number
    };
};

const setMinGrade = function (min) {
    return {
        type: "MIN_GRADE",
        min

    };
};

const toggleGradeStar = function () {
    return {
        type: "TOGGLE_GRADE_STAR"
    };
};
const toggleGrade2 = function () {
    return {
        type: "TOGGLE_GRADE_2"
    };
};
const toggleGradeThree = function () {
    return {
        type: "TOGGLE_GRADE_3"
    };
};
const toggleGradeThreeFive = function () {
    return {
        type: "TOGGLE_GRADE_THREE_3_5"
    };
};
const toggleGradeFour = function () {
    return {
        type: "TOGGLE_GRADE_4"
    };
};
const toggleGradeFourFive = function () {
    return {
        type: "TOGGLE_GRADE_4_5"
    };
};
const toggleGradeFive = function () {
    return {
        type: "TOGGLE_GRADE_5"
    };
};
const toggleGradeR = function () {
    return {
        type: "TOGGLE_GRADE_R"
    };
};

const setStartYear = function setStartYear (start) {
    return {
        type: "SET_START_YEAR",
        start_year: start
    };
};

const setEndYear = function setEndYear (end) {
    return {
        type: "SET_END_YEAR",
        end_year: end
    };
};

const setOdoMin = function setOdoMin (reading) {
    return {
        type: "SET_ODO_MIN",
        odo_min: reading
    };
};

const setOdoMax = function setOdoMax (reading) {
    return {
        type: "SET_ODO_MAX",
        odo_max: reading
    };
};

const setChassis = function setChassis (chassis) {
    return {
        type: "SET_CHASSIS",
        chassis
    };
};

const setEngineMin = function setEngineMin (reading) {
    return {
        type: "SET_ENGINE_MIN",
        engine_min: reading
    };
};

const setEngineMax = function setEngineMax (reading) {
    return {
        type: "SET_ENGINE_MAX",
        engine_max: reading
    };
};

const setTrans = function setTrans (t) {
    return {
        type: "SET_TRANS",
        trans: t
    };
};
const selectGears = function selectGears (gears) {
    return {
        type: "SELECT_GEARS",
        gears
    };
};

const setAuction = function setAuction (auc) {
    return {
        type: "SET_AUCTION",
        auction: auc
    };
};

const setStartDate = function setStartDate (d) {
    return {
        type: "SET_START_DATE",
        start_date: d
    };
};
const setEndDate = function setEndDate (d) {
    return {
        type: "SET_END_DATE",
        end_date: d
    };
};

const setKeyword = function (k) {
    return {
        type: "SET_KEYWORD",
        keyword: k
    };
};

const setDrivetrain = function (d) {
    return {
        type: "SET_DRIVETRAIN",
        drivetrain: d
    };
};

// note: sale prices are multiples of 1000 JPY
const setSoldPriceMin = function (d) {
    return {
        type: "SET_SOLD_PRICE_MIN",
        sold_price_min: d
    };
};

// note: sale prices are multiples of 1000 JPY
const setSoldPriceMax = function (d) {
    return {
        type: "SET_SOLD_PRICE_MAX",
        sold_price_max: d
    };
};

const nextPage = function () {
    return {
        type: "NEXT_PAGE"
    };
};

const prevPage = function () {
    return {
        type: "PREV_PAGE"
    };
};

const setNumericCount = function (count) {
    return {
        type: "SET_NUMERIC_COUNT",
        count
    };
};

const setSortColumn = function setSortColumn (col) {
    return {
        type: "SET_SORT_COLUMN",
        sortColumn: col
    };
};

const toggleViewSearch = function toggleViewSearch () {
    return {
        type: "TOGGLE_SHOW_SEARCH"
    };
};

const setAuctionHouseList = function setAuctionHouseList (auctions) {
    /*
    Auctions is a list of auctions objects. Add a 'selected' option to each one.
    */
    auctions = auctions.map(function (a) { a.selected = false; return a; });
    return {
        type: "SET_AUCTION_HOUSE_LIST",
        auctionHouseList: auctions
    };
};

const toggleAuctionHouses = function togglerAuctionHouses () {
    return {
        type: "TOGGLE_AUCTION_HOUSES"
    };
};

// Helper to toggle just one auction
const auctionHouseToggle = function auctionHouseToggle (id) {
    return {
        type: "TOGGLE_AUCTIONS",
        auctions: [id]
    };
};
// toggle many aucitons
// toggles them to all on , or all off
const auctionHouseToggleMany = function auctionHouseToggle (auctions, turnOn) {
    return {
        type: "TOGGLE_AUCTIONS",
        auctions,
        turnOn
    };
};

const setSearchFromDict = function (theDict) {
    return {
        type: "SET_SEARCH_FROM_DICT",
        theDict
    };
};

const toggleAC = function () {
    return {
        type: "TOGGLE_AC"
    };
};

const toggleAW = function () {
    return {
        type: "TOGGLE_AW"
    };
};

const toggleLE = function () {
    return {
        type: "TOGGLE_LE"
    };
};

const toggleSR = function () {
    return {
        type: "TOGGLE_SR"
    };
};

const togglePW = function () {
    return {
        type: "TOGGLE_PW"
    };
};

const togglePS = function () {
    return {
        type: "TOGGLE_PS"
    };
};

const toggleTV = function () {
    return {
        type: "TOGGLE_TV"
    };
};

const toggleCamper = function () {
    return {
        type: "TOGGLE_CAMPER"
    };
};
const toggleDump = function () {
    return {
        type: "TOGGLE_DUMP"
    };
};
const toggleCrane = function () {
    return {
        type: "TOGGLE_CRANE"
    };
};
const toggleDoubleCab = function () {
    return {
        type: "TOGGLE_DOUBLE_CAB"
    };
};
const toggleLoader = function () {
    return {
        type: "TOGGLE_LOADER"
    };
};
const toggleBus = function () {
    return {
        type: "TOGGLE_BUS"
    };
};
const fetching_count = function () {
    return {
        type: "FETCHING_COUNT"
    };
};

const force_show = function () {
    return {
        type: "FORCE_SHOW"
    };
};

export {
    fetching,
    fetchComplete,
    set_results,
    set_count,
    getCount,
    selectMake,
    clearSelection,
    baseInfoRefresher,
    selectModels,
    setStartYear,
    setEndYear,
    doSearch,
    setMinGrade,
    toggleGradeStar,
    toggleGrade2,
    toggleGradeThree,
    toggleGradeThreeFive,
    toggleGradeFour,
    toggleGradeFourFive,
    toggleGradeFive,
    toggleGradeR,
    show_results_section,
    show_results_error,
    clear_results,
    clear_pages,
    setOdoMin,
    setOdoMax,
    setChassis,
    setEngineMin,
    setEngineMax,
    setTrans,
    selectGears,
    setAuction,
    setStartDate,
    setEndDate,
    nextPage,
    prevPage,
    setSortColumn,
    toggleViewSearch,
    setAuctionHouseList,
    toggleAuctionHouses,
    auctionHouseToggle,
    auctionHouseToggleMany,
    makeSearchObject,
    setSearchFromDict,
    changeAuctionNumber,
    setNumericCount,
    toggleAC,
    toggleAW,
    toggleLE,
    toggleSR,
    togglePW,
    togglePS,
    toggleTV,
    toggleCamper,
    toggleDump,
    toggleCrane,
    toggleDoubleCab,
    toggleLoader,
    toggleBus,
    force_show,
    setKeyword,
    setDrivetrain,
    setSoldPriceMin,
    setSoldPriceMax,
};
