import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Modal,
    Button,
    FormControl
} from "react-bootstrap";

import {
    fetchCarData,
    setAddNoteText,
    clearAddNote
} from "../actions/car_detail_actions";
import { settings } from "../settings";

class AddNoteComponent extends Component {
    constructor () {
        super();
        this.save = this.save.bind(this);
    }

    save () {
    // push up to the server
        const url = settings.api_server + "/purchaseDetail/" + this.props.purchase_id + "/notes";

        // name change stuff is vestigial, or for future. Who knows.
        const postData = { text: this.props.text };
        fetch(url, {
            credentials: "include",
            body: JSON.stringify(postData),
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh the underlying data
                if (data.success) {
                    fetchCarData(this.props.purchase_id);
                    this.props.close();
                } else {
                    console.log("!!!! could not save note");
                }
            }.bind(this));
    }

    render () {
        return <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Header closeButton>
                <Modal.Title>Add a note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl type="text" value={this.props.text} onChange={this.props.changeText} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.close}>
                    Close
                </Button>
                <Button variant="primary" onClick={this.save}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
const AddNoteMapStateToProps = state => {
    return {
    // car data, existing notes
        car_data_loading: state.car_detail.loading,
        car_data: state.car_detail.car_info,

        purchase_id: state.invoice.purchase_id,

        show: state.car_detail.showAddNoteModal,
        text: state.car_detail.addNoteText
    };
};

const AddNoteMapDispatchToProps = dispatch => ({
    close: () => dispatch(clearAddNote()),
    changeText: (e) => dispatch(setAddNoteText(e.target.value)) // gets event, send the value

});

export default connect(AddNoteMapStateToProps, AddNoteMapDispatchToProps)(AddNoteComponent);
