import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    Alert,
    Modal,
    Button,
    Form, FormGroup, FormLabel,
    FormControl,
    Col, Row
} from "react-bootstrap";
import * as Datetime from "react-datetime";

import { settings } from "../settings";
import { setMessage } from "../actions/alert_actions";
import { closeDepositModal } from "../actions/edit_user_actions";
import { addToViewBalance, update_view_profile, setLineItems, fetch_set_line_items } from "../actions/user_actions";
import AdminOnly from "../dashboard-components/AdminOnly";

class EditDepositModal extends Component {
    constructor () {
        super();
        this.handlePayment = this.handlePayment.bind(this);
        this.getPayTypes = this.getPayTypes.bind(this);
        this.accountTypeChange = this.accountTypeChange.bind(this);
        this.state = {
            message: null,
            working: false,
            typeOptions: [],
            typeChoice: null,
            date: new Date()
        };

        this.amount = null;
        this.note = null;
        this.translations = null;
        this.account = null;
        this.reference = null;
    }

    componentDidMount () {
        this.getPayTypes();
    }

    accountTypeChange (e) {
        this.setState({ ...this.state, typeChoice: e.target.value });
    }

    getPayTypes () {
        const url = settings.api_server + "/config_items/payment_types";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    console.log(response);
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const options = data.config_value.split(",");
                this.setState({ ...this.state, typeOptions: options });
            }.bind(this));
    }

    handlePayment () {
        this.setState({ ...this.state, working: true });
        /*
         {'user': {'user_id': 1, 'username': 'Testname McGee'},
         'date': '2021-07-29T17:03:29.446Z',
         'payType': 'Transferwise',
         'amount': '1324', 'invoiceList': [
             {'invoice_id': '2012021002', 'owing': 139980, 'amount': '1324'},
             {'invoice_id': '2012021003', 'owing': 159960, 'amount': 0}],
         'note': 'Here is a wonderful note'}
        */
        const postData = {
            user: { user_id: this.props.user_id },
            date: this.state.date,
            payType: this.state.typeChoice,
            amount: this.amount.value,
            invoiceList: [],
            note: this.note.value,
            topUpRequests: this.translations.checked
        };

        const url = settings.api_server + "/invoice/admin/payment";

        fetch(url, {
            credentials: "include",
            body: JSON.stringify(postData),
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, message: response });
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function () {
                // reset modal
                this.setState({ ...this.state, working: false });
                // update the underlying profile
                update_view_profile(this.props.user_id);
                // and gl entries
                fetch_set_line_items(this.props.user_id, this.props.setLineItems);

                this.props.setMessage(<div>{this.amount.value} JPY added to {this.props.firstname} {this.props.lastname}</div>);

                // close the modal
                this.props.closeDepositModal();
            }.bind(this));
    }

    render () {
        const paymentType = this.state.typeOptions.map(x => <Form.Check key={x} onChange={this.accountTypeChange} value={x} type='radio' label={x} name='payType' id={x} />);
        return (
            <Modal show={this.props.isOpen} onHide={this.props.closeDepositModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add or remove deposit</Modal.Title></Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    <p>How much to add or remove? Current balance is {this.props.balance} JPY</p>
                    {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                    <p>Negative numbers for returning deposits</p>
                    <Form>
                        <FormGroup as={Row} controlId="amount">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Amount JPY</b>
                            </Col>
                            <Col sm={8} md={8}>
                                <FormControl placeholder="Amount" ref={ref => { this.amount = ref; }} />
                            </Col>
                        </FormGroup>

                        <FormGroup as={Row} controlId="dater">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Date</b>
                            </Col>
                            <Col sm={8} md={8}>
                                {<Datetime closeOnSelect value={this.state.date} onChange={(e) => this.setState({ ...this.state, date: e._d })} dateFormat="YYYY-MM-DD" timeFormat={false} />}
                            </Col>
                        </FormGroup>

                        <FormGroup as={Row} controlId="account">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Type</b>
                            </Col>
                            <Col sm={8} md={8}>
                                {paymentType}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="reference">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Note/Reference (ID of the transfer, if applicable)</b>
                            </Col>
                            <Col sm={8} md={8}>
                                <FormControl placeholder="Note" ref={ref => { this.note = ref; }} />
                            </Col>
                        </FormGroup>

                        <FormGroup as={Row} controlId="count">
                            <Col componentclass={FormLabel} sm={4} md={4}>
                                <b>Top up bid and translation request count</b>
                            </Col>
                            <Col sm={8} md={8}>
                                <Form.Check defaultChecked placeholder="Translations" ref={ref => { this.translations = ref; }} />
                            </Col>
                        </FormGroup>
                    </Form>

                </Modal.Body>
                <Modal.Footer><Button disabled={this.props.working} onClick={this.handlePayment}>Save</Button> <Button disabled={this.props.working} onClick={this.props.closeDepositModal}>Close</Button></Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        balance: state.user.profile.balance,
        isOpen: state.edit_user.deposit_modal_is_open,
        user_id: state.user.user_id,
        firstname: state.user.profile.firstname,
        lastname: state.user.profile.lastname
    };
};

const mapDispatchToProps = dispatch => ({
    closeDepositModal: () => dispatch(closeDepositModal()),
    setMessage: (message) => dispatch(setMessage(message)),
    addToViewBalance: (amount) => dispatch(addToViewBalance(amount)),
    setLineItems: (user_id, line_items) => dispatch(setLineItems(user_id, line_items))

});

export default connect(mapStateToProps, mapDispatchToProps)(EditDepositModal);
