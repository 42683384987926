import { Redirect } from "react-router";
import React, { Component } from "react";
import { connect } from "react-redux";

class AdminOnly extends Component {
    render () {
        if (!this.props.loggedIn) { return null; }
        // fetch is complete. check the admin. redirect if not
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,
        loggedIn: state.profile.profileLoaded
    };
};

export default connect(mapStateToProps)(AdminOnly);
