import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import PCANav from "../dashboard-components/navbar";

import {
    Col, Row, Container,
    Card
} from "react-bootstrap";

import AdminOnly from "../dashboard-components/AdminOnly";

class Admin extends Component {
    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <PCANav />; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <Container>

                    <h2>Site Administration</h2>

                    <Row className="show-Container">

                        <Col md={6}>
                            <Card id="trans-Card" className="flush_card">
                                <Card.Header>
                                    <Card.Title>
                                        Administration
                                    </Card.Title>
                                </Card.Header>

                                <Card.Body>
                                    <ul>

                                        <li><Link to='/m3'>Adjust M<sup>3</sup> pricing</Link></li>
                                        <li><Link to='/shipping_fees'>Adjust Export fee per M<sup>3</sup> pricing</Link></li>
                                        <li><Link to='/config_items'>Edit configuration items</Link></li>
                                        <li><Link to='/editNews'>Edit news items</Link></li>
                                        <li><Link to='/default_line_items'>Edit invoice default line items</Link></li>
                                        <li>Reports</li>
                                        <ul>
                                            <li><Link to='/reports/translations'>Translation Requests</Link></li>
                                            <li>More to come...</li>
                                        </ul>
                                    </ul>
                                </Card.Body>

                            </Card>

                        </Col>

                        <Col md={6}>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        isAdmin: state.profile.is_admin,
        fetchingProfile: state.profile.fetchingProfile
    };
};

export default connect(mapStateToProps)(Admin);
