import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { ShowNotes, CarDetail } from "./invoice_views/invoice_components";

import {
    Container, Row, Col,
    Card,
    Button
} from "react-bootstrap";

import { settings } from "./settings";

import { setCarDetail, openEditPurchaseModal, fetchCarData, showAddNoteModal } from "./actions/car_detail_actions";
import PCANav from "./dashboard-components/navbar";
import FileUpload from "./admin_views/image_upload";
import CarImages from "./dashboard-components/view_car_images";
import DocUploader from "./admin_views/document_views/DocUploader";
import EditPurchaseModal from "./admin_views/car_purchase/edit_purchase_modal";
import AddNoteModal from "./invoice_views/addNoteModal";
import {
    resetInvoice,
    addInvoiceData,
    setPurchaseId
} from "./actions/invoice_actions";

import EditM3Modal from "./admin_views/invoice_subviews/m3calculatorModal";
import AddLineItemModal from "./admin_views/car_purchase/AddLineItemModal";
import CloseInvoiceModal from "./admin_views/car_purchase/CloseInvoiceModal";
import ReleaseInvoiceModal from "./admin_views/car_purchase/ReleaseInvoiceModal";
import InvoiceList from "./admin_views/car_purchase/InvoiceList";

import CarPendingQuizAdmin from "./quiz_views/CarPendingQuizAdmin";
import CarCompletedQuizzes from "./quiz_views/CarCompletedQuizzes";

import PurchaseNotesButton from "./admin_views/car_purchase/SearchPurchaseNotes";

class EditPurchase extends Component {
    constructor () {
        super();

        this.addInvoice = this.addInvoice.bind(this);
        this.openCalc = this.openCalc.bind(this);
        this.closeCalc = this.closeCalc.bind(this);

        this.state = { showM3Calc: false };
    }

    componentDidMount () {
        fetchCarData(this.props.match.params.purchase_id);
        this.props.setPurchaseId(this.props.match.params.purchase_id);
    }

    componentWillUnmount () {
        this.props.resetInvoice();
    }

    openCalc () {
        this.setState({ ...this.state, showM3Calc: true });
    }

    closeCalc () {
        this.setState({ ...this.state, showM3Calc: false });
        // Refresh
        fetchCarData(this.props.match.params.purchase_id);
    }

    addInvoice () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.match.params.purchase_id;
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh
                // TODO - returns error message on error, display
                fetchCarData(this.props.match.params.purchase_id);
            }.bind(this));
    }

    // Return the contents of the page to be shown when the purchase is loading
    // or was not found.
    renderNoPurchase(message) {
        return <div>
            <PCANav/>
            <Container>
                <Row className="show-Container">
                    <Col>
                        <Card>
                            <Card.Header>
                                Purchase Detail
                            </Card.Header>
                            <Card.Body>
                                {message}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>;
    }

    render () {
    // Don't show anything while loading
        if (!this.props.loggedIn) { return <PCANav />; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        if (this.props.car_data_loading) {
            return this.renderNoPurchase("Loading");
        }

        if (!this.props.car_data.success) {
            return this.renderNoPurchase(this.props.car_data.message);
        }

        // set the m3 calculator button
        // default button, wrap it with an overlay if we need to.
        const needs = [];
        if (this.props.car_data.exchange_rate === null) {
            needs.push("exchange rate");
        }
        if (this.props.car_data.ship_port === null) {
            needs.push("port to ship to");
        }

        let text = "";
        if (needs.length > 0) {
            text = "- Requires: " + needs.join(", ");
        }
        return (
            <div>
                <PCANav />
                <Container>
                    <Row className="show-Container">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Purchase Detail <div className="float-right"><Button size="sm" onClick={this.props.openEditPurchaseModal}>Edit Transaction Info</Button></div></Card.Header>
                                <p>Purchased by: <Link to={"/user_detail/" + this.props.car_data.owner.user_id}>{this.props.car_data.owner.firstname} {this.props.car_data.owner.lastname}</Link></p>

                                <h3>Car information:</h3>
                                <CarDetail data={this.props.car_data} />
                                <Button disabled={needs.length > 0} onClick={this.openCalc}>Show m<sup>3</sup> calculator {text}</Button>
                                <EditM3Modal show={this.state.showM3Calc} handleClose={this.closeCalc} />
                                <Container>
                                    <Row>
                                        <Col>
                                            <h3>Quizzes</h3>
                                            <p>
                                                <b>Quiz required: </b>
                                                <CarPendingQuizAdmin
                                                    purchase_id={this.props.match.params.purchase_id}
                                                />
                                            </p>
                                            <p>
                                                <b>Completed quizzes: </b>
                                                <CarCompletedQuizzes
                                                    purchase_id={this.props.match.params.purchase_id}
                                                />
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                                <iframe
                                    src={settings.api_server + "/purchaseDetail/print/header/" + this.props.match.params.purchase_id + "?update=" + this.props.last_car_update}
                                    title="Preview of header"
                                    height="500px"
                                ></iframe>

                            </Card>
                        </Col>
                    </Row>
                    <Row md={12}>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header>Invoices <span className="float-right"><Button size='sm' onClick={this.addInvoice}>Add new invoice</Button></span></Card.Header>

                                <InvoiceList />

                            </Card>
                            <Card>
                                <Card.Header>
                                    Notes <Button size="sm"
                                        onClick={this.props.showAddNoteModal}
                                    >
                                        Add a note
                                    </Button>
                                    {/* (opens a modal to search the notes) */}
                                    &nbsp;<PurchaseNotesButton
                                        purchase_id={this.props.match.params.purchase_id}
                                    />
                                </Card.Header>
                                {this.props.car_data_loading ? <p>Loading</p> : <ShowNotes notes={this.props.car_data.notes} />}
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {/* Displays list of documents, allows modifying
                                them and uploading new ones */}
                            <DocUploader
                                purchase_id={this.props.match.params.purchase_id}
                            />
                        </Col>
                    </Row>
                    <Row className="show-Container">
                        <Col md={12}>
                            <h2>Upload images here</h2>
                            <FileUpload purchase_id={this.props.match.params.purchase_id} />
                        </Col>
                    </Row>
                    <Row className="show-Container">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Car Images</Card.Header>
                                <Card.Body><CarImages purchase_id={this.props.match.params.purchase_id} /></Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <EditPurchaseModal />
                <CloseInvoiceModal purchase_id={this.props.match.params.purchase_id} />
                <AddLineItemModal />
                <ReleaseInvoiceModal />
                <AddNoteModal />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        car_data_loading: state.car_detail.loading,
        car_data: state.car_detail.car_info,
        last_car_update: state.car_detail.last_car_update,
        isAdmin: state.profile.is_admin,
        purchase_modal_is_open: state.purchases.purchase_modal_is_open,
        loggedIn: state.profile.profileLoaded
    };
};

const mapDispatchToProps = dispatch => ({
    setCarDetail: (car_data) => dispatch(setCarDetail(car_data)),
    openEditPurchaseModal: () => dispatch(openEditPurchaseModal()),
    resetInvoice: () => dispatch(resetInvoice()),
    addInvoiceData: (invoice_id, data) => dispatch(addInvoiceData(invoice_id, data)),
    setPurchaseId: (id) => dispatch(setPurchaseId(id)),
    showAddNoteModal: () => dispatch(showAddNoteModal())

});

export default connect(mapStateToProps, mapDispatchToProps)(EditPurchase);
