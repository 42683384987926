import React, { Component } from "react";
import PropTypes from "prop-types";

import "../App.css";
import "../user_views/ProfileTable.css";

import { settings } from "../settings";

import {
    Table
} from "react-bootstrap";

import { prettyDate } from "../functions";
import { Link } from "react-router-dom";
import CommaNumber from "../widgets/commaNumbers";

class InvoiceRow extends Component {
    render () {
        return <tr>
            <td>{this.props.data.created_on ? prettyDate(this.props.data.created_on) : null}</td>
            <td><Link to={"/purchase_detail/" + this.props.data.purchase_id}>{this.props.data.invoice_id}</Link></td>
            <td>{this.props.data.account}</td>
            <td>{this.props.data.amount <= 0 && CommaNumber(this.props.data.amount)}</td>
            <td>{this.props.data.amount > 0 && CommaNumber(this.props.data.amount)}</td>
            <td>{CommaNumber(this.props.total)}</td>
        </tr>;
    }
}

function sortBy (a, b) {
    /*
    Sort the data by:
    date, then show invoice before payments
    */
    if (a.created_on < b.created_on) { return -1; }
    if (a.created_on > b.created_on) { return 1; }
    // if same date... show invoices first
    if (a.account === "Invoice") { return -1; }
    return 0;
}

class InvoiceLedger extends Component {
    /*
    Load invoice data. Some is grouped! But the backend handles that
    */
    constructor () {
        super();
        this.state = { loading: true, message: null, data: [] };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let url = settings.api_server + "/invoice/summary";
        // -1 gets the current session. Passed a number, fetch it.
        // but that's admin-only
        if (this.props.who >= 0) {
            url = url + "/" + this.props.who;
        }

        fetch(url, {
            credentials: "include",
            method: "GET"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function (data) {
                data.sort(sortBy);
                this.setState({ ...this.state, loading: false, data });
            }.bind(this))
            .catch(function (reason) {
                this.setState({ ...this.state, message: reason });
            }.bind(this));
    }

    render () {
        let line_items = [];
        if (this.state.loading) {
            line_items = <tr><td colSpan={7}>Loading...</td></tr>;
        } else if (this.state.data.length === 0) {
            line_items = <tr><td colSpan={7}>None</td></tr>;
        } else {
            let total = 0;
            for (let i = 0; i < this.state.data.length; i++) {
                total += this.state.data[i].amount;
                line_items.push(<InvoiceRow
                    // use invoice_id for key since invoice_view_id isn't
                    // returned by backend
                    key={this.state.data[i].invoice_id + "_" + this.state.data[i].amount}
                    data={this.state.data[i]}
                    total={total} />);
            }
        }
        return <Table responsive size="sm" className="profileTable">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>Account</th>
                    <th>Payment</th>
                    <th>Invoice Amount</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                {line_items}
            </tbody>
        </Table>;
    }
}
InvoiceLedger.propTypes = {
    who: PropTypes.number.isRequired
};

export default InvoiceLedger;
