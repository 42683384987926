import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button,
} from "react-bootstrap";

import { userAddImages, clearImages } from "../../actions/image_request_list_actions";

import { settings } from "../../settings";

class RequestImages extends Component {
    /*
    The button only shows up for USS auctions.
    Then, is only active if the user can bid
  */

    constructor () {
        super();
        this.fetch = this.fetch.bind(this);
        this.request = this.request.bind(this);
    }

    request () {
        const url = settings.api_server + "/image_requests/vehicle/" + this.props.id;

        fetch(url, {
            method: "POST",
            credentials: "include"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh data
                this.fetch();
            }.bind(this));
    }

    fetch () {
    // get the requested status

        const url = settings.api_server + "/image_requests/vehicle/" + this.props.id;

        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh data
                this.props.setImageRequested(data.requestedImageList, data.hasRequestedImages, data.notes);
            }.bind(this));
    }

    componentDidMount () {
        this.fetch();
    }

    componentWillUnmount () {
        this.props.clearImages();
    }

    render () {
        if (this.props.loading) { return null; }

        // if the auction house starts with USS
        if (this.props.data.auction_house.startsWith("USS")) {
            if (!this.props.can_request_translation) {
                return <p>Add available translation requests to request high-res images</p>;
            }

            // If the user has not requested quick images, make them do that first.
            // temporarily pulled due to them not being available
            /*if (!this.props.quickImageRequested) {
                return <p>
                    <OverlayTrigger overlay={
                        <Tooltip id="tooltip-disabled">
                            You must request quick images before requesting full-resolution images
                        </Tooltip>}>
                        <div>
                            <Button block disabled={true} style={{ pointerEvents: "none" }}>Request full-resolution images</Button>
                        </div>
                    </OverlayTrigger>
                </p>;
            }*/
            // show the button
            // if it's not loaded, or not allowed
            const disabled = this.props.requestedImagesLoaded && this.props.hasRequestedImages;
            let text = "Request full-resolution images";
            if (this.props.hasRequestedImages) { text = "Full-resolution images requested. You will be emailed when it is fulfilled"; }
            return <p><Button block disabled={disabled} onClick={this.request}>{text}</Button></p>;
        }
        // Otherwise, don't show
        return null;
    }
}
const requestImageState = state => {
    return {
        can_request_translation: state.profile.can_request_translation,

        quickImageRequested: state.image_request_list.quickImageRequested,

        hasRequestedImages: state.image_request_list.hasRequestedImages,
        requestedImagesLoaded: state.image_request_list.request_list,

        data: state.auction_car.data,
        loading: state.auction_car.loading
    };
};

const requestImageDispatch = dispatch => ({
    setImageRequested: (list, isRequested, notes) => dispatch(userAddImages(list, isRequested, notes)),
    clearImages: () => dispatch(clearImages())

});
export default connect(requestImageState, requestImageDispatch)(RequestImages);
