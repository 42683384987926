import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col, Row,
    FormCheck
} from "react-bootstrap";

import {
    toggleCamper,
    toggleDump,
    toggleCrane,
    toggleDoubleCab,
    toggleLoader,
    toggleBus
} from "../actions/search_actions";

class VehicleTypesComponent extends Component {
    render () {
        return <div>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="Camper"
                        label="Camper"
                        checked={this.props.camper}
                        onChange={this.props.toggleCamper}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="Dump"
                        label="Dump"
                        checked={this.props.dump}
                        onChange={this.props.toggleDump}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="crane"
                        label="Crane"
                        checked={this.props.crane}
                        onChange={this.props.toggleCrane}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="doubleCab"
                        label="Double Cab"
                        checked={this.props.doubleCab}
                        onChange={this.props.toggleDoubleCab}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="loader"
                        label="Loader"
                        checked={this.props.loader}
                        onChange={this.props.toggleLoader}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormCheck
                        type="switch"
                        id="bus"
                        label="Bus"
                        checked={this.props.bus}
                        onChange={this.props.toggleBus}
                    />
                </Col>
            </Row>

        </div>;
    }
}

const mapStateToProps = state => {
    return {
        camper: state.search.camper,
        dump: state.search.dump,
        crane: state.search.crane,
        doubleCab: state.search.doubleCab,
        loader: state.search.loader,
        bus: state.search.bus
    };
};
const mapDispatchToProps = dispatch => ({
    toggleCamper: () => dispatch(toggleCamper()),
    toggleDump: () => dispatch(toggleDump()),
    toggleCrane: () => dispatch(toggleCrane()),
    toggleDoubleCab: () => dispatch(toggleDoubleCab()),
    toggleLoader: () => dispatch(toggleLoader()),
    toggleBus: () => dispatch(toggleBus())

});
const VehicleTypes = connect(mapStateToProps, mapDispatchToProps)(VehicleTypesComponent);
export default VehicleTypes;
