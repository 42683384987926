
import { settings } from "../settings";

const alert = (state = settings.fx_defaultState, action) => {
    switch (action.type) {
    case "SET_FX":
        return {
            ...state,
            loaded: true,
            fx_rates: action.fx_rates
        };
    default:
        return state;
    }
};

export default alert;
