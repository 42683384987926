import React, { Component } from "react";

import { Link } from "react-router-dom";
import { settings } from "../settings";

import {
    Table
} from "react-bootstrap";

class PerWeek extends Component {
    constructor () {
        super();
        this.state = { loaded: false, data: [] };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        const url = settings.api_server + "/reports/userTranslations/week";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.setState({ ...this.state, loaded: true, data });
            }.bind(this));
    }

    render () {
        if (!this.state.loaded) { return <p>Loading...</p>; }

        // data is in order, from newest to oldest.
        // render each in its own column
        // which will overflow prettily into rows
        const toShow = this.state.data.map(d => <tr key={d.user_id + "report_" + d.firstname} >
            <td><Link to={"/user_detail/" + d.user_id}>{d.firstname} {d.lastname}</Link></td>
            <td>{d.count}</td>
        </tr>);
        return <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Who</th>
                    <th>How many</th>
                </tr>
            </thead>
            <tbody>
                {toShow}
            </tbody>
        </Table>;
    }
}

export default PerWeek;
