import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import PCANav from "../dashboard-components/navbar";
import UserLookup from "../dashboard-components/UserLookup";
import PaymentTool from "../dashboard-components/PaymentTool";
import {
    Col, Row, Container,
    Card
} from "react-bootstrap";
import OpenPurchases from "../admin_views/open_purchases";
import AdminOnly from "../dashboard-components/AdminOnly";
import TranslationLookup from "../admin_views/TranslationLookup";
import InvoiceLookup from "../admin_views/invoice_lookup";
import RecentPayments from "../admin_views/RecentPayments";
import OutstandingInvoices from "../admin_views/outstanding_invoices/OutstandingInvoices";
import NoAcctManagerList from "../admin_views/NoAcctManagerList";
import PurchaseNoteLookup from "../admin_views/PurchaseNoteLookup";

class Admin extends Component {
    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <PCANav />; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <Container>

                    <h2>Account Administration</h2>

                    <Row className="show-Container">
                        <Col md={12}>
                            <PaymentTool />
                        </Col>
                        <Col md={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title >
                                        Recent Payments
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="flush_card">
                                    <RecentPayments />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <li><Link to='/balances'>View all balances</Link></li>
                                    <li><Link to='/notify'>View notify party address book</Link></li>
                                    <li><Link to='/default_line_items'>Edit invoice default line items</Link></li>
                                    <li><Link to='/view_bids'>View all bids</Link></li>
                                </Card.Body>
                            </Card>
                            <UserLookup />
                            <TranslationLookup/>
                            <Card>
                                <Card.Header>
                                    <Card.Title >
                                        Invoice Lookup
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="flush_card">
                                    <InvoiceLookup />
                                </Card.Body>
                            </Card>
                            <PurchaseNoteLookup/>
                        </Col>

                        <Col md={6}>

                            <Card >
                                <Card.Header>
                                    <Card.Title >
                                        Overdue Invoices
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="flush_card">
                                    <OutstandingInvoices />
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        Users with deposits and no account manager
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="flush_card"
                                    style={{paddingTop: "0px", paddingBottom: "0px"}}
                                >
                                    <NoAcctManagerList/>
                                </Card.Body>
                            </Card>
                            <Card >
                                <Card.Header>
                                    <Card.Title >
                                        Open Accounts
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="flush_card">
                                    <OpenPurchases />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        isAdmin: state.profile.is_admin,
        fetchingProfile: state.profile.fetchingProfile
    };
};

export default connect(mapStateToProps)(Admin);
