import React, {Component} from "react";
import { awaitingShipping, awaitingReportAsync } from "./car_status";
import { Container, Row, Col } from "react-bootstrap";

/**
 * Subcomponent, counts the number of purchases awaiting a report.
 * Handled here because it requires making its own async calls.
 */
function AwaitingReportCounter({purchases}) {
    const [numWaiting, setNumWaiting] = React.useState(0);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        // guard against purchases not being loaded
        const purchaseList = purchases ?? [];
        // wait for the result of checking all purchases
        Promise.all(purchaseList.map(p => awaitingReportAsync(p.purchase_id)))
            .then(
                // success: count ones awaiting a report
                values => {
                    setNumWaiting(values.reduce(
                        (count, curr) => count + curr,
                        0 // initial counter
                    ));
                    setError(null);
                },
                // failure: store error
                reason => {
                    setNumWaiting(0);
                    setError(reason);
                    console.log("Error getting number of cars awaiting a " +
                        "report: " + reason);
                }
            );
    }, [purchases]);

    return <span>
        {error === null ? numWaiting : "?"}
    </span>;
}

// Displays the total number of cars, number awaiting shipping and number
// awaiting report.
// Props: list of purchases ("purchases")
class MyCarCounter extends Component {
    constructor(props) {
        super(props);
    }

    // Return the total number of purchases. If the purchases aren't loaded,
    // return 0.
    getTotalCars() {
        return this.props.purchases?.length ?? 0;
    }

    // Return the count of cars awaiting shipping. If the purchases aren't
    // loaded, return 0.
    getAwaitingShipping() {
        return this.props.purchases?.reduce(
            (count, purchase) => count + awaitingShipping(purchase),
            0 // initial count
        ) ?? 0; // if purchases is null/undefined, return 0
    }

    render() {
        return (
            <Container className="dropShadow whiteBackground carCounterGrid">
                <Row>
                    <Col>Total Cars:</Col>
                    <Col md="auto">{this.getTotalCars()}</Col>
                </Row>
                <Row>
                    <Col>Awaiting Shipping:</Col>
                    <Col md="auto">{this.getAwaitingShipping()}</Col>
                </Row>
                <Row>
                    <Col>Awaiting Report:</Col>
                    <Col md="auto">
                        <AwaitingReportCounter
                            purchases={this.props.purchases}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default MyCarCounter;
