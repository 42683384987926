import React, {Component} from "react";
import {Button} from "react-bootstrap";
import "./MyCarsPanel.css";

const radioFilters = ["Last Month", "This Month", "Awaiting Shipping"];

class MyCarPanel extends Component {

    constructor(props) {
        super(props);
        
        this.filter = null;
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onGo = this.onGo.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    // Set up event listeners so pressing enter/return triggers the "Go" button
    componentDidMount() {
        const goButton = document.querySelector("#goButton");
        const ids = ["#Last\\ Month", "#This\\ Month", "#Awaiting\\ Shipping",
            "#fromField", "#toField", "#searchField"];

        for (let id of ids) {
            document.querySelector(id).addEventListener("keyup", event => {
                if (event.key != "Enter") return;
                goButton.click();
                event.preventDefault();
            });
        }
    }

    onFilterChange(event) {
        this.filter = event.target.value;
    }

    onGo() {
        this.props.update({
            filter: this.filter,
            from: document.getElementById("fromField").value,
            to: document.getElementById("toField").value,
            search: document.getElementById("searchField").value.toLowerCase()
        });
    }

    onReset() {
        radioFilters.forEach(f => document.getElementById(f).checked = false);
        this.filter = null;
        ["fromField", "toField", "searchField"].forEach(f =>
            document.getElementById(f).value = "");
        this.onGo();
    }

    getRadioInputs() {
        return radioFilters.map(f => {
            return <div key={f}>
                <label htmlFor={f}>{f}&nbsp;</label>
                <input type="radio" id={f} name={"panelFilters"} value={f}
                    onChange={this.onFilterChange} defaultChecked={false}/>
            </div>;
        });
    }

    getFromField() {
        return <input type="text" id="fromField" name="fromField"
            placeholder="YYYY-MM-DD"/>;
    }

    getToField() {
        return <input type="text" id="toField" name="toField"
            placeholder="YYYY-MM-DD"/>;
    }

    getSearchField() {
        return <input type="text" id="searchField" name="searchField"
            placeholder="car name, auction, etc."/>;
    }

    render() {
        return (
            <div id="myCarsPanel" className="dropShadow whiteBackground">
                <div id="myCarsFilters">
                    {this.getRadioInputs()}
                </div>
                <table id="myCarsInputTable"><tbody>
                    <tr>
                        <td>From</td>
                        <td id="fromFieldTD">{this.getFromField()}</td>
                        <td>To</td>
                        <td id="toFieldTD">{this.getToField()}</td>
                        <td rowSpan="2">
                            <Button id='goButton' size='sm' variant='success' onClick={this.onGo}>
                                Go
                            </Button>
                            &nbsp;
                            <Button size='sm' variant='danger' onClick={this.onReset}>
                                Reset
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>Search</td><td colSpan="3" id="searchFieldTD">
                            {this.getSearchField()}
                        </td>
                    </tr>
                </tbody></table>
            </div>
        );
    }
}

export default MyCarPanel;
