import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";

import {
    Container, Row, Col,
    Card
} from "react-bootstrap";

import { setAuctionCarDetail, getAuctionCarHistoryData } from "./actions/auction_car_actions";
import PCANav from "./dashboard-components/navbar";

import AuctionCarImages from "./auction_car_views/AuctionCarImages";
import AuctionCarHistoryDetails from "./auction_car_views/AuctionCarHistoryDetails";
import Translation from "./auction_car_views/Tranlsation";

class AuctionCar extends Component {
    componentDidMount () {
        getAuctionCarHistoryData(this.props.match.params.auction_id);
    }

    render () {
        return (
            <div>
                <PCANav />
                <Container>
                    <Row className="show-Container">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Car Images</Card.Header>
                                <Card.Body>
                                    <AuctionCarImages />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Translation />
                    <Row className="show-Container">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Car Details</Card.Header>
                                <AuctionCarHistoryDetails />
                            </Card>
                        </Col>
                    </Row>

                    <Row md={12}>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading
    };
};

const mapDispatchToProps = dispatch => ({
    setAuctionCarDetail: (car_data) => dispatch(setAuctionCarDetail(car_data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCar);
