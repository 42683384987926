/*
 * calculate just the m3 stuff.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";


import {
    Button,


} from "react-bootstrap";

import {
    setSingleField
} from "../../actions/car_detail_actions";


class GuessShipping extends Component {
    render(){
        let fees = null;
        for (let i = 0; i < this.props.ports.length; i++){
            if (this.props.port === this.props.ports[i].port){
                fees = this.props.ports[i].shipping_fees;
                break;
            }
        }

        if (fees === null)
            return null;
        
        // find the ~~fish~~ fee
        // these are always in order by max_m3
        // if we are less than the max_m3, we found it
        // if we hit null, it's the catch all
        let fee = fees[fees.length - 1].shipping_fee; // default (catch all)
        for(let i = 0; i < fees.length - 1; i++){
            if (fees[i].max_m3 > this.props.m3){
                fee = fees[i].shipping_fee;
                break;
            }
        }

        return <span>Calculated fee for {this.props.m3} m<sup>3</sup> ¥{fee} <Button onClick={() => this.props.setShippingFee(fee)}>Copy to below</Button></span>;
    }
}

GuessShipping.propTypes = {
    port: PropTypes.string.isRequired,
    m3: PropTypes.number.isRequired
};



const mapStateToProps = state => {
    return {
        ports: state.shipping_fees.export_ports,
    };
};

const mapDispatchToProps = dispatch => ({
    setShippingFee: (v) => dispatch(setSingleField("export_shipping_fee", v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuessShipping);
