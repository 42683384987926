import React, { Component } from "react";
import {Form} from "react-bootstrap";

const OPT_TRUE = "Yes", OPT_FALSE = "No";
const OPT_DEFAULT = OPT_TRUE;

class IsDealerChooser extends Component {
    constructor() {
        super();
        this.state = {"options":[OPT_TRUE, OPT_FALSE], "selection": OPT_DEFAULT};
        this.sendUpdate = this.sendUpdate.bind(this);
        this.changed = this.changed.bind(this);
    }

    componentDidMount() {
        this.sendUpdate(this.state["selection"] == OPT_TRUE ? true : false);
    }

    /** @param {boolean} newValue */
    sendUpdate(newValue) {
        this.props.changeFilter(this.props.which, {"type": "is_dealer", "which": newValue});
    }

    changed(e)
    {
        this.setState({...this.state, selection: e.target.value});
        this.sendUpdate(e.target.value == OPT_TRUE ? true : false);
    }

    render() {
        const options = [OPT_TRUE, OPT_FALSE].map(opt =>
            <option key={opt} value={opt}>{opt}</option>
        );
        return <Form>
            <Form.Control as="select" onChange={this.changed} value={this.props.selection}>
                {options}
            </Form.Control>
        </Form>;
    }
}

export default IsDealerChooser;