import React, { Component } from "react";

import {
    Modal
} from "react-bootstrap";
import M3Calculator from "./m3calculator";

class M3CalculatorModal extends Component {
    render () {
        return <Modal size="xl" show={this.props.show}>
            <Modal.Header>
                <Modal.Title>Calculate m<sup>3</sup> Costs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <M3Calculator close={this.props.handleClose} />
            </Modal.Body>
        </Modal>;
    }
}



export default M3CalculatorModal;
