import React, { Component } from "react";
import "../App.css";

import {
    toggleAddressChange,
    fetch_profile
} from "../actions/profile_actions";

import { connect } from "react-redux";

import { settings } from "../settings";

import {
    Modal,
    Button,
    FormControl,
    Alert,
    Col, Row
} from "react-bootstrap";

class UpdateAddress extends Component {
    constructor () {
        super();
        this.newPass = "";
        this.save = this.save.bind(this);
        this.update = this.update.bind(this);

        this.state = { address: "", country: "", phone: "", working: false, message: null };
    }

    update (e) {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    }

    componentDidMount () {
    // set the starting values from the ones
    // that are passed
        this.setState({
            ...this.state,
            address: this.props.profile.address,
            country: this.props.profile.ship_to_country,
            phone: this.props.profile.phone_number
        });
    }

    save () {
        const url = settings.api_server + "/user/address";
        // in post, so proxy servers can't see the data
        const postdata = {
            address: this.state.address,
            country: this.state.country,
            phone: this.state.phone
        };

        const newState = this.state;
        newState.setting = true;
        this.setState(newState);

        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    // throw new Error("Bad response from server");
                    const newState = this.state;
                    newState.setting = false;
                    newState.message = "Password set failed";
                    this.setState(newState);
                } else { return response.json(); }
            })
            .then(function (data) {
                if (data.success) {
                    this.setState({ ...this.state, working: false });
                    this.props.toggleAddressChange();
                    // refresh
                    fetch_profile();
                } else {
                    this.setState({ ...this.state, working: false, message: data.message });
                }
            }.bind(this));
    }

    render () {
        return <Modal
            show={this.props.showAddressChange}
            onHide={this.props.toggleAddressChange}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Update Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                <Row>
                    <Col md={2}>Address</Col>
                    <Col md={10}><FormControl id="address" as="textarea" rows={10} value={this.state.address} onChange={this.update} /></Col>
                </Row>
                <Row>
                    <Col md={2}>Country</Col>
                    <Col md={10}><FormControl id="country" value={this.state.country} onChange={this.update} /></Col>
                </Row>
                <Row>
                    <Col md={2}>Phone Number</Col>
                    <Col md={10}><FormControl id="phone" value={this.state.phone} onChange={this.update} /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.working} onClick={this.save}>{this.state.working ? "Saving" : "Save"}</Button> <Button onClick={this.props.toggleAddressChange}>Close without setting</Button>
            </Modal.Footer>
        </Modal>;
    }
}

const subscribedMapStateToProps = state => {
    return {
        showAddressChange: state.profile.showAddressChange,
        profile: state.profile
    };
};
const subscribedMapDispatchToProps = dispatch => ({
    toggleAddressChange: () => dispatch(toggleAddressChange())
});
export default connect(subscribedMapStateToProps, subscribedMapDispatchToProps)(UpdateAddress);
