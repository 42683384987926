
import { settings } from "../settings";

const make_model_translation = (state = settings.make_model_translation_defaultState, action) => {
    switch (action.type) {
    case "SET_MAKE_MODEL_TRANSLATIONS":
        return {
            ...state,
            make_translations: action.make_translations,
            model_translations: action.model_translations,
            loaded: true
        };
    default:
        return state;
    }
};

export default make_model_translation;
