import { combineReducers } from "redux";
import profile from "./profile";
import car_detail from "./car_detail";
import translations from "./translations";
import purchases from "./purchases";
import user from "./user";
import bid_modal from "./bid_modal";
import alert from "./alert";
import edit_user from "./edit_user";
import search from "./search";
import auction_car from "./auction_car";
import invoice from "./invoice";
import m3Data from "./m3";
import make_model_translation from "./make_model_translation";
import notify from "./notify";
import saved_queries from "./saved_query";
import search_history from "./search_history";
import fx from "./fx";
import user_bid_modal from "./user_bid_modal";
import default_line_items from "./default_line_items";
import config_items from "./config_items";
import questions from "./questions";
import previous_auctions from "./previous_auctions";
import search_results from "./search_results";
import allPurchases from "./allPurchases";
import image_request_list from "./request_list";
import news_items from "./news_items";
import shipping_fees from "./shipping_fees";
import acct_managers from "./acct_managers";

export default combineReducers({
    profile,
    car_detail,
    translations,
    purchases,
    user,
    bid_modal,
    alert,
    edit_user,
    search,
    auction_car,
    invoice,
    m3Data,
    make_model_translation,
    notify,
    saved_queries,
    search_history,
    fx,
    user_bid_modal,
    default_line_items,
    config_items,
    questions,
    previous_auctions,
    search_results,
    allPurchases,
    image_request_list,
    news_items,
    shipping_fees,
    acct_managers
});
