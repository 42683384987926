import React, { Component } from "react";
import logo from "./logo.png";
import "./App.css";

import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";

import { settings } from "./settings";

import {
    Alert,
    Button,
    Col, Row, Container,
    Form
} from "react-bootstrap";

const defaultState = {
    fetching: false,
    enrollSuccess: false,
    message: null,
    email: "",
    firstname: "",
    lastname: "",
    token: null
};

export default class Enroll extends Component {
    constructor (props) {
        super(props);

        this.state = { ...defaultState };

        this.register = this.register.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeFirstName = this.changeFirstName.bind(this);
        this.changeLastName = this.changeLastName.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount () {
        loadReCaptcha(settings.recaptchaKey);
    }

    changeEmail (e) {
        this.setState({ ...this.state, email: e.target.value });
    }

    changeFirstName (e) {
        this.setState({ ...this.state, firstname: e.target.value });
    }

    changeLastName (e) {
        this.setState({ ...this.state, lastname: e.target.value });
    }

    verifyCallback (recaptchaToken) {
        console.log(recaptchaToken, "<= your recaptcha token");
        this.setState({ ...this.state, token: recaptchaToken });
    }

    register () {
        const data = {
            username: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            token: this.state.token
        };
        const url = settings.api_server + "/enroll/";
        this.setState({ ...this.state, fetching: true });
        fetch(url, {
            body: JSON.stringify(data),
            cache: "no-cache",
            headers: {
                "content-type": "application/json",
                pragma: "no-cache",
                "cache-control": "no-store"
            },
            method: "POST",
            credentials: "include"
        }).then(function (response) {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 429) {
                return { success: false, message: "Too many attempts. Try again later." };
            } else {
                return { success: false, message: "Unknown failure" };
            }
        }).then(function (myJson) {
            if (myJson.success) {
                this.setState({ ...this.state, enrollSuccess: true, fetching: false });
            } else {
                this.setState({ ...this.state, message: myJson.message, fetching: false });
            }
        }.bind(this));
    }

    render () {
        if (this.state.enrollSuccess) {
            return <div>
                <p>Enrollment success. Check your listed email address for activation email.</p>
                <p><a href="/">Return to login page</a></p>
            </div>;
        }

        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} alt="logo" />
                    <h1 className="App-title">Japanese Auto Auctions</h1>
                </header>
                <div style={{ marginTop: "70px" }}></div>
                <p className="App-intro">

                </p>
                <div>
                    <Container>
                        {this.state.message !== null && <Alert variant="warning">{this.state.message}</Alert>}
                        <Row>
                            <Col md={4}>Email address:</Col>

                            <Col md={8}>
                                <Form.Control type="text" placeholder="Enter email" onChange={this.changeEmail} value={this.props.email} />
                            </Col>

                        </Row>
                        <Row>
                            <Col md={4} >
                                First name:
                            </Col>

                            <Col md={8}><Form.Control type="text" placeholder="First name" onChange={this.changeFirstName} value={this.state.firstname} /></Col>
                        </Row>
                        <Row>
                            <Col md={4} >
                                Last name:
                            </Col>

                            <Col md={8}><Form.Control type="text" placeholder="Last name" onChange={this.changeLastName} value={this.state.lastname} /></Col>
                        </Row>
                        <Row>
                            <ReCaptcha
                                sitekey={settings.recaptchaKey}
                                verifyCallback={this.verifyCallback}
                            />
                        </Row>
                        <div style={{ marginTop: "5px" }} > </div>
                        <Row>

                            <Col>
                                <span style={{ textAlign: "center" }} >
                                    <Button disabled={this.state.fetching} type="submit" onClick={this.register}>Create account</Button>
                                </span>
                            </Col>

                        </Row>

                    </Container>

                </div>
            </div>
        );
    }
}
