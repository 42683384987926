import { settings } from "../settings";

const fetch_questions = function (auction_id) {
    const url = settings.api_server + "/questions/" + auction_id;

    const result = fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                // throw new Error("Bad response from server");
                console.log("failure fetching questions");
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            // do the dispatch
            settings.store.dispatch(setCurrentThread(data));
        });
    return result;
};

const add_question = function (auction_id, text, title) {
    const postdata = {
        text,
        title
    };
    const url = settings.api_server + "/questions/" + auction_id;
    const result = fetch(url, {
        body: JSON.stringify(postdata),
        credentials: "include",
        method: "POST",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                console.log("failure fetching questions");
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            if (data.success) {
                fetch_questions(auction_id);
            }
        });

    return result;
};

const setCount = function (count) {
    return {
        type: "SET_QUESTION_COUNT",
        count
    };
};
const setThreadList = function (threads) {
    return {
        type: "SET_QUESTION_THREAD_LIST",
        question_list: threads
    };
};

const clearThreadList = function () {
    return {
        type: "CLEAR_THREAD_LIST"
    };
};

const setFetchingThread = function () {
    return {
        type: "SET_FETCHING_THREAD"
    };
};

const setShowThreadId = function (id) {
    return {
        type: "SET_SHOW_THREAD_ID",
        show_thread_id: id
    };
};
/* used for both the auction car view, and admin-side */
const setCurrentThread = function (thread_data) {
    return {
        type: "SET_CURRENT_THREAD",
        thread_data
    };
};
const clearCurrentThread = function () {
    return {
        type: "CLEAR_CURRENT_THREAD"
    };
};
const toggleAskModal = function () {
    return {
        type: "TOGGLE_ASK_MODAL"
    };
};
export { fetch_questions, add_question, setCount, setThreadList, setCurrentThread, clearCurrentThread, setFetchingThread, setShowThreadId, toggleAskModal, clearThreadList };
