import React, { Component } from "react";
import { connect } from "react-redux";

import { settings } from "../settings";

import {
    Form,
    Card,
    Button,
    ListGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import AddFromDeposit from "./addFromDepositModal";
import { setCarInclude } from "../actions/car_detail_actions";
import {
    fetch_invoice,
    openCloseConfirm,
    showAddLineItemModal,
    openReleaseConfirm
} from "../actions/invoice_actions";

import ShowBalanceEditable from "./showBalanceEditable";

class InvoiceCard extends Component {
    constructor () {
        super();
        this.closeTest = this.closeTest.bind(this);
        this.addLineItem = this.addLineItem.bind(this);
        this.openRelease = this.openRelease.bind(this);
        this.toggleCarInclude = this.toggleCarInclude.bind(this);
        this.copyShipping = this.copyShipping.bind(this);
        this.addImportItems = this.addImportItems.bind(this);
        this.get_pdf = this.get_pdf.bind(this);
        this.get_customs_pdf = this.get_customs_pdf.bind(this);
        this.get_customs_w_shipping_pdf = this.get_customs_w_shipping_pdf.bind(this);
        this.toggleIncludeLineItem = this.toggleIncludeLineItem.bind(this);
        this.getCustomInvoicePDF = this.getCustomInvoicePDF.bind(this);

        this.showDepositModal = this.showDepositModal.bind(this);
        this.hideDepositModal = this.hideDepositModal.bind(this);
        this.state = {
            show_deposit_modal: false,
            default_line_items: []
        };
        // reference to ShowBalanceEditable
        this.showBalanceRef = null;

        // unique line item IDs to include in custom invoice PDF
        /** @type {Set<number>} */
        this.includedLineItems = new Set();
    }

    // Fetch default line items so we can provide a button to add them
    componentDidMount () {
        const url = settings.api_server + "/default_line_items/";
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Error retrieving default line items");
                }
                return response.json();
            })
            .then(function(data) {
                this.setState({...this.state, default_line_items: data});
            }.bind(this));
    }

    showDepositModal () {
        this.setState({ ...this.state, show_deposit_modal: true });
    }

    hideDepositModal () {
        this.setState({ ...this.state, show_deposit_modal: false });
    }

    openRelease () {
        this.props.openReleaseConfirm(this.props.invoiceData.invoice_id);
    }

    addLineItem () {
        this.props.showAddLineItem(this.props.invoiceData.invoice_id);
    }

    closeTest () {
        this.props.openCloseConfirm(this.props.invoiceData.invoice_id);
    }

    get_pdf () {
        const url = settings.api_server + "/purchaseDetail/print/pdf_invoice/" + this.props.purchase_id + "/" + this.props.invoiceData.invoice_id + "?" + Math.floor(Math.random() * 99);
        window.open(url, "_blank", "noopener,noreferrer");
    }

    get_customs_pdf () {
        const url = settings.api_server + "/purchaseDetail/print/customs_invoice/" + this.props.purchase_id + "/" + this.props.invoiceData.invoice_id;
        window.open(url, "_blank");
    }

    get_customs_w_shipping_pdf () {
        const url = settings.api_server + "/purchaseDetail/print/customs_invoice_shipping/" + this.props.purchase_id + "/" + this.props.invoiceData.invoice_id;
        window.open(url, "_blank");
    }

    copyShipping () {
    // Copy the shipping data from the purchase data
    // add the line item to this invoice
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.invoiceData.invoice_id + "/loadShipping";

        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (!data.success) { this.props.setMessage(data.message); } else
                // fetch the new data
                { fetch_invoice(this.props.purchase_id, this.props.invoiceData.invoice_id); }
            }.bind(this));
    }

    // Post a new line item to the current invoice, don't wait for response
    /** @returns {Promise<Response>} */
    postLineItem(item_type, detail, amount) {
        const url = settings.api_server + "/invoice/admin/purchase/"
            + this.props.purchase_id + "/" + this.props.invoiceData.invoice_id
            + "/lineItem";

        const postData = {
            item_type: item_type,
            detail: detail,
            amount: amount
        };

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(postData)
        });
    }

    // Add any default line items for the car's destination port
    addImportItems () {
        // make a request for each item, update when we're done
        Promise.all(
            this.state.default_line_items
                // filter to items which apply to this purchase
                .filter(i => i.import_port != null &&
                    i.import_port == this.props.car_data.ship_port)
                // map to promises which we'll wait on before re-fetching
                .map(i => this.postLineItem(i.item_type, i.detail, i.amount))
        )
            // re-fetch invoice when all requests are done
            .then(function() {
                fetch_invoice(this.props.purchase_id, this.props.invoiceData.invoice_id);
            }.bind(this));
    }

    toggleCarInclude (e) {
        this.props.setCarInclude(this.props.purchase_id, this.props.invoiceData.invoice_id, e.target.checked);
    }

    wrapInTooltip (button, tooltipText) {
        return <OverlayTrigger overlay={
            <Tooltip id="tooltip-disabled">{tooltipText}</Tooltip>
        }>
            <span className="d-inline-block">
                {button}
            </span>
        </OverlayTrigger>;
    }
    
    // If shipping cost is calculated, return a button to add it to the invoice.
    // Otherwise, return a disabled button whose tooltip says what's needed.
    shippingCostButton() {
        let prereqs = null;
        if (this.props.car_data.ship_m3_total_cost === null &&
            this.props.car_data.ship_m3_total_cost !== "")
        {
            prereqs = "Total cost must be set first";
        }

        const invoiceID = this.props.invoiceData.invoice_id;

        // if it's ready, return a functional button
        if (prereqs === null) {
            return <Button id={invoiceID} onClick={this.copyShipping} size="sm">
                Add shipping cost from above
            </Button>;
        }
        else {
            return <OverlayTrigger overlay={
                <Tooltip id="tooltip-disabled">{prereqs}</Tooltip>
            }>
                <span className="d-inline-block">
                    <Button disabled id={invoiceID} size="sm" style={{pointerEvents: "none"}}>
                        Add shipping cost from above
                    </Button>
                </span>
            </OverlayTrigger>;
        }
    }

    // If there are any default line items which apply to the purchase's
    // ship_port, return a button to add them to the invoice. Otherwise, return
    // a disabled button with a tooltip.
    importItemsButton() {
        // default line items which apply to this car's destination port
        const importItems = this.state.default_line_items
            .filter(i => i.import_port != null && !this.props.car_data_loading
                && i.import_port == this.props.car_data.ship_port);

        // button to add port items, disabled if nothing to add or still loading
        const innerImportBtn = <Button id={"line_items_" + this.props.invoiceData.invoice_id}
            onClick={this.addImportItems} size="sm"
            disabled={importItems.length == 0 || this.props.car_data_loading}
        >
            Add defaults for import port
        </Button>;

        // overlay: explain why button is disabled, or explain what button does
        let importOverlayText;
        if (this.props.car_data_loading) {
            importOverlayText = "Loading port...";
        }
        else if (importItems.length == 0) {
            importOverlayText = "No items to add";
        }
        else {
            importOverlayText = "Add all line items which are normally added by default for the import port";
        }
        // final button to add import line items
        return this.wrapInTooltip(innerImportBtn, importOverlayText);
    }

    // The buttons on the card header: "add amount from deposit", "add shipping
    // cost", "add defaults for import port"
    cardHeaderButtons() {
        if (this.props.invoiceData.purchase_complete)
            return "(Closed)";

        return <div className="float-right">
            <Button size="sm" onClick={this.showDepositModal}>
                Add amount from deposit
            </Button>
            {this.shippingCostButton()}
            {this.importItemsButton()}
        </div>;
    }

    // return buttons for adding a line item, releasing to client, and closing
    invoiceActionButtons() {
        // can't modify a completed invoice
        if (this.props.invoiceData.purchase_complete)
            return null;

        const invoiceID = this.props.invoiceData.invoice_id;
        const releaseButton = this.props.invoiceData.released_to_client ?
            <Button variant="outline-danger" id={invoiceID} disabled>
                Released to client
            </Button>
            :
            <Button variant="outline-danger" id={invoiceID} onClick={this.openRelease}>
                Release invoice to client
            </Button>;

        return <span>
            <Button variant="outline-primary" id={invoiceID} onClick={this.addLineItem}>
                Add a line item
            </Button>
            &nbsp;
            {releaseButton}
            &nbsp;
            <Button variant="outline-danger" id={invoiceID} onClick={this.closeTest}>
                Complete Invoice
            </Button>
            <br/>
        </span>;
    }

    // different buttons for different types of PDF
    pdfButtons() {
        const purchaseID = this.props.purchase_id;
        const invoiceID = this.props.invoiceData.invoice_id;

        return <>
            <Button variant="outline-secondary" key={"pdf_invoice"}
                href={settings.api_server + "/purchaseDetail/print/pdf_invoice/" +
                    purchaseID + "/" + invoiceID}
                download
            >
                Download PDF Invoice
            </Button>
            &nbsp;
            <Button variant="outline-secondary" key={"custom_invoice_pdf"}
                onClick={this.getCustomInvoicePDF}
            >
                Custom Invoice PDF
            </Button>
            &nbsp;
            <Button variant="outline-secondary" key={"customs_invoice_shipping"}
                href={settings.api_server + "/purchaseDetail/print/customs_invoice_shipping/" +
                    purchaseID + "/" + invoiceID}
                download
            >
                Vehicle and Shipping Customs PDF
            </Button>
            &nbsp;
        </>;
    }

    toggleIncludeLineItem(itemID, checked) {
        if (checked) {
            this.includedLineItems.add(itemID);
        }
        else {
            this.includedLineItems.delete(itemID);
        }
    }

    // send a request to get the custom invoice PDF, open it in a new tab
    getCustomInvoicePDF() {
        const purchaseID = this.props.purchase_id;
        const invoiceID = this.props.invoiceData.invoice_id;
        // join included line items into comma-seperated string for query string
        const itemString = [...this.includedLineItems].join();
        const url = settings.api_server + "/purchaseDetail/print/custom_invoice_pdf/" +
            purchaseID + "/" + invoiceID + (itemString.length > 0 ? "?items=" + itemString : "");
        // send request, open in new tab
        window.open(url, "_blank");
    }

    render () {
        const inv = this.props.invoiceData;

        return <Card key={inv.invoice_id}>
            <Card.Header>
                <b>Invoice {inv.invoice_id} {this.cardHeaderButtons()}</b>
            </Card.Header>
            <ShowBalanceEditable invoice_id={inv.invoice_id}
                complete={inv.purchase_complete}
                toggle_include_line_item={this.toggleIncludeLineItem}
            />
            <AddFromDeposit show={this.state.show_deposit_modal}
                invoice_id={inv.invoice_id}
                complete={inv.purchase_complete}
                close={this.hideDepositModal}
            />
            <ListGroup.Item>
                <Form.Check id={"include_car_details_" + inv.invoice_id}
                    type="checkbox"
                    onChange={this.toggleCarInclude}
                    checked={inv.include_car_details}
                    label={<span>Include car info on invoice</span>}
                />
            </ListGroup.Item>
            <ListGroup.Item>
                {this.invoiceActionButtons()}
                {this.pdfButtons()}
            </ListGroup.Item>
        </Card>;
    }
}
const mapStateToProps = state => {
    return {
        car_data_loading: state.car_detail.loading,
        car_data: state.car_detail.car_info,
        isAdmin: state.profile.is_admin,
        purchase_modal_is_open: state.purchases.purchase_modal_is_open,
        loggedIn: state.profile.profileLoaded,
        purchase_id: state.invoice.purchase_id
    };
};

const mapDispatchToProps = dispatch => ({
    openCloseConfirm: (invoice_id) => dispatch(openCloseConfirm(invoice_id)),
    showAddLineItem: (invoice_id) => dispatch(showAddLineItemModal(invoice_id)),
    openReleaseConfirm: (invoice_id) => dispatch(openReleaseConfirm(invoice_id)),
    setCarInclude: (purchase_id, invoice_id, newSetting) => dispatch(setCarInclude(purchase_id, invoice_id, newSetting))

});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCard);
