import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";
import { connect } from "react-redux";

// import {settings} from './settings';

import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";

import {
    Card,
    Button
} from "react-bootstrap";
import AvtoHistoryResults from "./avto-components/avto_history_results";
import { clearSelection, clear_results, toggleViewSearch } from "./actions/search_actions";
import { doSearch, getCount } from "./actions/search_history_actions";

// share the base info with the other search
import { baseInfoRefresher } from "./actions/search_history_actions";

import HistorySearch from "./avto-components/HistorySearch";

class Search extends Component {
    constructor () {
        super();
        this.doBasicSearch = this.doBasicSearch.bind(this);
    }

    componentDidMount () {
    // make sure we have make/model data
        baseInfoRefresher();
    }

    doBasicSearch () {
        this.props.clear_results();
        // have to send makes to filter to 'other'
        doSearch(this.props.data);
        getCount(this.props.data);
    }

    kludge () {
    // do nothing. Gets rid of warning on Card
    }

    render () {
        return (
            <div>
                <PCANav currentPage="#/history"/>
                <Collapse in={this.props.showSearch}>
                    <div>
                        <Container className="wideContainer">
                            <span className="whiteTitle">Auction History</span>
                        </Container>
                        <HistorySearch searchCallback={this.doBasicSearch} />
                    </div>
                </Collapse>

                <Card>

                    <Card.Body>
                        <Button onClick={this.props.toggleViewSearch}>Show search options</Button>&nbsp;
                        <Button onClick={this.props.clearSelection}>Start again</Button>&nbsp;
                    </Card.Body>

                </Card>
                <AvtoHistoryResults />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        results: state.search.results,
        fetching: state.search.fetching,
        showSearch: state.search.showSearch,
        data: state.search
    };
};

const mapDispatchToProps = dispatch => ({
    clearSelection: () => dispatch(clearSelection()),
    clear_results: () => dispatch(clear_results()),
    toggleViewSearch: () => dispatch(toggleViewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
