import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import { settings } from "../settings";

import Image from "react-bootstrap/Image";

import { fetchImages } from "../actions/image_request_list_actions";

/* import {
  GlassMagnifier,
} from "react-image-magnifiers"; */

class ProbablyAuctionSheet extends Component {
    componentDidMount () {
        if (this.props.data) {
            fetchImages(this.props.data.vehicle_id);
        }
    }
    componentDidUpdate(oldProps) {
        if (this.props.data
            && (!oldProps.data
                || this.props.data.vehicle_id != oldProps.data.vehicle_id))
        {
            fetchImages(this.props.data.vehicle_id);
        }
    }

    render () {
        if (this.props.failure || this.props.data === null || !("images" in this.props.data) || this.props.data.images === null) { return null; }

        if (this.props.loading) { return <p>Loading...</p>; }

        let imageUrl;
        // if there's a suggestion, use it!
        if (this.props.suggestion !== null) {
            imageUrl = this.props.suggestion;
        } else {
            // chop up the image links, which are thumbnails by default
            // inexplicably # delimited
            const image_list = this.props.data.images.split("#");

            // divide requested images into auction sheets and non-auction sheets
            const sheets = this.props.requestedImageList.filter(x => x.is_auction_sheet);
            const requested = this.props.requestedImageList.filter(x => !x.is_auction_sheet);

            // Put all images in a list.
            // We need to find which one is the auction sheet and put it first.
            const allImages = [];
            // if there are explictly-marked auction sheets, use them
            sheets.map(x => allImages.push(settings.api_server + "/image_requests/images/" + x.link));
            // put the images from the car data next b/c if it's a non-USS
            // auction the first image will be the auction sheet
            image_list.map(x => allImages.push(x));
            // fall back on any other requested images
            requested.map(x => allImages.push(settings.api_server + "/image_requests/images/" + x.link));

            // do we have anything
            if (allImages.length === 0 && this.props.num_images) {
                return null; // render nothing if there's nothing
            } else {
                // take the first image
                imageUrl = allImages[0];
                // take off the query string, if there is one
                // avto-only
                // imageUrl = imageUrl.split("?")[0];
            }
        }
        // magnifier using https://adamrisberg.github.io/react-image-magnifiers/
        /* return (
      <GlassMagnifier
        imageSrc={imageUrl}
        imageAlt="Auction sheet"
        square={true}
        magnifierSize={"50%"}
      /> */

        return (
            <Image src={imageUrl}
                alt="Auction sheet"
                fluid
                className="auctionSheetStretch"
            />
        );
    }
}

ProbablyAuctionSheet.defaultProps = {
    suggestion: null
};

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure,

        /* requested images, remember to load them, too! */
        requestedImageList: state.image_request_list.current_image_list,
        num_images: state.image_request_list.num_images // to force updates after loading requested images
    };
};

export default connect(mapStateToProps)(ProbablyAuctionSheet);
