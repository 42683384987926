import { settings } from "../settings";
import { setMessage } from "./alert_actions";

const getAuctionCarData = function (auction_id) {
    const url = settings.api_server + "/search/auction_car/" + auction_id;
    settings.store.dispatch(fetchingCar());
    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status === 429) {
                // set a message about rate-limiting.
                settings.store.dispatch(setMessage("Too many requests, rate limiting results, try again later."));
                // result is an array. force an empty list.
                return [];
            }
            if (response.status >= 400) {
                settings.store.dispatch(setMessage("Problem loading car from auction."));
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            // data should be a list car data, with one car TODO - handle the 'no' case
            if (data.length > 0) { settings.store.dispatch(setAuctionCarDetail(data[0])); } else { settings.store.dispatch(AuctionCar404()); }
        })
        .catch(function (error) {
            console.log(error);
        });
};

const getAuctionCarHistoryData = function (auction_id) {
    const url = settings.api_server + "/history/auction_car/" + auction_id;
    settings.store.dispatch(fetchingCar());
    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status === 429) {
                // set a message about rate-limiting.
                settings.store.dispatch(setMessage("Too many requests, rate limiting results, try again later."));
                // result is an array. force an empty list.
                return [];
            } else if (response.status >= 400) {
                throw new Error("Bad response from server"); // TODO -find out what happens if/when an auction car doesn't exist, handle
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            // data should be a list car data, with one car TODO - handle the 'no' case
            if (data.length > 0) { settings.store.dispatch(setAuctionCarDetail(data[0])); } else { settings.store.dispatch(AuctionCar404()); }
        })
        .catch(function (error) {
            console.log(error);
        });
};

const fetchingCar = function () {
    return {
        type: "AUCTION_CAR_FETCHING"
    };
};

const AuctionCar404 = function () {
    return {
        type: "AUCTION_CAR_DATA_NOT_FOUND"
    };
};

const setAuctionCarDetail = function (data) {
    return {
        type: "SET_AUCTION_CAR_DATA",
        data
    };
};

const setBidData = function (has_bid, amount, isCancelled, canBid, bidMessage, comment) {
    return {
        type: "BID_LOADED",
        has_bid,
        amount,
        isCancelled,
        canBid,
        bidMessage,
        comment
    };
};

const setWatchData = function (isWatched) {
    return {
        type: "WATCH_LOADED",
        isWatched

    };
};

const setTranslation = function (requested_translation, translation, translation_completed) {
    return {
        type: "TRANSLATION_LOADED",
        requested_translation,
        translation_completed,
        translation // translation is an object with the tranlsations
    };
};

const clearAuctionData = function () {
    return {
        type: "CLEAR_AUCTION_CAR_DATA"
    };
};

export {
    setAuctionCarDetail,
    getAuctionCarHistoryData,
    setBidData,
    setWatchData,
    setTranslation,
    getAuctionCarData,
    clearAuctionData
};
