import React from "react";
import { Button, ToggleButton } from "react-bootstrap";
import { settings } from "../../settings";
import "./DocUploader.css";

import DocumentDesc from "./DocumentDesc";

/**
 * @typedef {Object} DocInfo
 * @prop {string} url
 * @prop {string} desc
 * @prop {string} added_time
 * @prop {boolean} is_vehicle_report
 */

/**
 * A row in the table returned by DocUploader.js
 * Displays the description and flags for the document and allows them to be
 * edited. Also provides buttons to download the document and delete it.
 * @param {Object} props
 * @param {DocInfo} props.doc
 * @param {(needsRefresh: boolean) => void} props.refreshCallback to call if the
 * document is deleted and the parent component needs to update.
 */
export default function DocumentRow({doc, refreshCallback}) {
    // So we don't have to re-fetch everything when toggling the vehicle report
    // flag, we'll store it as our own state which gets updated when a new
    // document is loaded.
    const [isReport, setIsReport] = React.useState(doc.is_vehicle_report);
    React.useEffect(() => setIsReport(doc.is_vehicle_report), [doc]);

    function deleteDoc() {
        fetch(settings.api_server + doc.url, {
            method: "DELETE",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(body => body.success ?
                Promise.resolve()
                :
                Promise.reject(body.message)
            )
            .catch(reason => console.log("Error deleting document: " + reason))
            .finally(() => refreshCallback(true));
    }

    function downloadDoc() {
        fetch(settings.api_server + doc.url, {
            method: "GET",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.blob()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                blob => {
                    // To download the blob, create an object URL for it, then
                    // create and auto-click a download link to it.
                    // https://stackoverflow.com/questions/25547475/save-to-local-file-from-blob
                    const blobURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobURL;
                    link.download = doc.desc.endsWith(".pdf") ?
                        doc.desc : doc.desc + ".pdf";
                    link.target = "_blank";
                    link.rel = "noreferrer";
                    link.type = "application/pdf";
                    link.click();
                },
                reason => {
                    console.log("Error downloading PDF: " + reason);
                }
            );
    }

    function toggleVehicleReport() {
        const newVal = !isReport;
        fetch(settings.api_server + doc.url, {
            method: "PATCH",
            credentials: "include",
            body: JSON.stringify({is_vehicle_report: newVal}),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(body => body.success ?
                Promise.resolve()
                : Promise.reject(body.message)
            )
            .then(
                () => setIsReport(newVal),
                error => console.log("Error toggling vehicle report: " + error)
            )
            // tell DocUploader to refresh so it updates the report status
            .finally(() => refreshCallback(true));
    }

    const sep = doc.added_time.indexOf("T");
    const date = doc.added_time.slice(0, sep);
    const time = doc.added_time.slice(sep + 1, doc.added_time.indexOf("."));

    return <tr className="documentRow">
        {/* returns a <td> */}
        <DocumentDesc doc={doc}/>
        <td>
            {date} {time}
        </td>
        <td>
            {/* If more flags are added, a vertical ButtonGroup could be used
                if it looks better than a bunch of unrelated buttons */}
            <ToggleButton
                className="docRowButton"
                size="sm"
                type="checkbox"
                variant="info"
                checked={isReport}
                onChange={toggleVehicleReport}
            >
                &nbsp;Vehicle&nbsp;Report
            </ToggleButton>
        </td>
        <td>
            <Button
                className="docRowButton"
                size="sm"
                variant="primary"
                onClick={downloadDoc}
            >
                Download
            </Button>
            <Button
                className="docRowButton"
                size="sm"
                variant="danger"
                onClick={deleteDoc}
            >
                Delete
            </Button>
        </td>
    </tr>;
}
