import { settings } from "../settings";

// actions for user that we are currently viewing (not current user - that is profile)

const fetch_set_line_items = function (user_id, cb) {
    let url = settings.api_server + "/ledger/";

    if (user_id >= 0) { url = url + user_id; }

    const result = fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                // throw new Error("Bad response from server");
                console.log("failure fetching gl");
                return [];
            }
            return response.json();
        })
        .then(function (data) {
            // do the dispatch
            cb(data);
        });
    return result;
};

const update_view_profile = function (user_id) {
    let url = settings.api_server + "/getProfilePageData";

    if (user_id >= 0) {
        url = url + "/" + user_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                settings.store.dispatch(setViewProfile(data));
            });
    }
};

const setLineItems = function setLineItems (line_items) {
    return {
        type: "SET_GL_LINE_ITEMS",
        line_items
    };
};
const setUser = function setUser (user_id) {
    return {
        type: "SET_VIEW_USER",
        user_id
    };
};
const newUser = function () {
    return {
        type: "NEW_USER_PROFILE"
    };
};
const setViewProfile = function setViewProfile (data) {
    return {
        type: "SET_VIEW_USER_PROFILE",
        profile: data
    };
};

const addToViewBalance = function addToViewBalance (amount) {
    return {
        type: "ADD_VIEW_USER_BALANCE",
        amount
    };
};

// general call
const editViewUser = function (item, value) {
    return {
        type: "EDIT_VIEW_USER_DATA",
        item,
        value
    };
};

const clearViewUser = function () {
    return {
        type: "CLEAR_VIEW_USER"
    };
};

const changeEmail = function (e) {
    return editViewUser("username", e.target.value);
};

const changeFirstname = function (e) {
    return editViewUser("firstname", e.target.value);
};

const changeLastname = function (e) {
    return editViewUser("lastname", e.target.value);
};

const changeIsVip = function (e) {
    return editViewUser("isvip", e.target.checked);
};

const changeAddress = function (e) {
    return editViewUser("address", e.target.value);
};
const changeAddressCity = function (e) {
    return editViewUser("city", e.target.value);
};
const changeAddressCountry = function (e) {
    return editViewUser("country", e.target.value);
};

const changePhoneNumber = function (e) {
    return editViewUser("phone_number", e.target.value);
};

const changeCountry = function (e) {
    return editViewUser("ship_to_country", e.target.value);
};

const changePort = function (thePort) {
    // port gets a string
    return editViewUser("ship_port", thePort);
};

const changeNotify = function (e) {
    return editViewUser("ship_notify", e.target.value);
};

const changeMethod = function (e) {
    return editViewUser("ship_method", e.target.value);
};

export {
    fetch_set_line_items,
    setLineItems,
    setUser,
    newUser,
    clearViewUser,
    setViewProfile,
    addToViewBalance,
    update_view_profile,
    editViewUser,
    changeFirstname,
    changeLastname,
    changeIsVip,
    changeAddress,
    changeAddressCity,
    changeAddressCountry,
    changePhoneNumber,
    changeCountry,
    changePort,
    changeNotify,
    changeMethod,
    changeEmail

};
