import React from "react";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import {
    Container, Row, Col
} from "react-bootstrap";

export default function StaffSalesCharts({purchases, staff})
{
    // Transform the data to make it plottable
    const transformed = purchases.map(p => ({
        ...p,
        // need to replace null with a value, otherwise it won't be plotted
        acct_manager: p.acct_manager ?? -1,
        // need to use actual Dates instead of strings
        date_of_purchase: new Date(p.date_of_purchase)
    }));

    // Needed for the following staffColor function
    const staffList = [...staff.values()].sort(
        (a, b) => a.user_id - b.user_id
    );
    // Function for associating account managers with fill colors
    const staffColor = (item) => {
        // Depending on the type of plot, the data will be in a different format
        const managerID = ("acct_manager" in item ? item.acct_manager : item.data[0]);
        // Use the index of the manager in the list of staff to generate a color
        const managerIndex = staffList.findIndex((s) => s.user_id === managerID);
        // Add one to the length because 'no account manager' isn't in the list.
        // `hsla` is a CSS hue-saturation-lightness-alpha color.
        // Adding 30 makes the hues start at orange instead of red, so none of
        // the managers look like some kind of warning/error.
        return `hsla(${360 * managerIndex / (staffList.length + 1) + 30}, 100%, 60%, 0.5)`;
    };

    return <Container>
        <Row><h3>Cars sold</h3></Row>
        <Row>
            <Col className="sales-chart-col">
                <CarsSoldPerMonth purchases={transformed} staff={staff}
                    colorFunc={staffColor}/>
            </Col>
            <Col className="sales-chart-col">
                <CarsSoldPerStaff purchases={transformed} staff={staff}
                    colorFunc={staffColor}/>
            </Col>
        </Row>
        <Row><h3>Sale prices</h3></Row>
        <Row>
            <Col className="sales-chart-col">
                <PricesPerMonth purchases={transformed} staff={staff}
                    colorFunc={staffColor}/>
            </Col>
            <Col className="sales-chart-col">
                <PricesPerStaff purchases={transformed} staff={staff}
                    colorFunc={staffColor}/>
            </Col>
        </Row>
    </Container>;
}

// Function for displaying money with three digits
const siFormat = d3.format(".3~s");
function moneyDisplay(amt)
{
    return "¥" + siFormat(amt);
}

// Common marks for bar graphs (frame, grid, and X axis)
function barGraphCommon()
{
    return [
        Plot.frame({anchor: "bottom"}),
        Plot.frame({anchor: "left"}),
        Plot.axisX(
            {
                fontSize: 16,
                textAnchor: "start",
                tickFormat: "  %b\n  %Y",
                tickPadding: -8,
                tickSize: 12
            }
        ),
        Plot.gridY({stroke: "black", strokeOpacity: 0.25, strokeWidth: 1})
    ];
}

// Bar graph: monthly car sales, grouped by account manager (color coded)
function CarsSoldPerMonth({purchases, staff, colorFunc})
{
    const divRef = React.useRef();

    // Function for displaying a tip when hovering over a bar
    const barTip = (purchaseItems) => {
        const mgr = staff.get(purchaseItems[0].acct_manager);
        const name = mgr === undefined ?
            "(No manager)"
            :
            mgr.firstname + " " + mgr.lastname;
        return `${name}: ${purchaseItems.length}`;
    };

    // Use an effect to create the plot because it will fire after this
    // component has mounted and the divRef is initialized.
    React.useEffect(() => {
        const plot = Plot.plot({
            marginBottom: 40,
            marginRight: 0,
            x: {label: null},
            y: {label: null},
            marks: [
                Plot.rectY(purchases, Plot.binX(
                    {
                        y: "count",
                        title: {reduce: barTip}
                    },
                    {
                        x: "date_of_purchase",
                        interval: "month",
                        fill: colorFunc,
                        tip: {fontSize: 18}
                    }
                )),
                Plot.text(purchases, Plot.binX(
                    {
                        y: "count"
                    },
                    {
                        x: "date_of_purchase",
                        interval: "month",
                        text: d => d.length,
                        dy: -10,
                        fontSize: 18
                    }
                )),
                Plot.axisY(
                    {
                        fontSize: 18
                    }
                ),
                ...barGraphCommon()
            ]
        });
        divRef.current.append(plot);
        return () => plot.remove();
    }, [purchases]);

    return <div className="staff-sales-chart" ref={divRef}/>;
}

// Bar graph: total car sale prices per month, grouped by account manager
function PricesPerMonth({purchases, staff, colorFunc})
{
    const divRef = React.useRef();

    // Given an array of purchases, return the total of the sale prices
    const priceReducer = (purchases) => purchases
        .map(p => p.bid?.sold_for ?? p.bid?.amount ?? 0)
        .reduce((a, x) => a + x, 0);

    // Function for displaying a tip when hovering over a bar
    const barTip = (purchaseItems) => {
        const mgr = staff.get(purchaseItems[0].acct_manager);
        const name = mgr === undefined ?
            "(No manager)"
            :
            mgr.firstname + " " + mgr.lastname;
        return `${name}: ${moneyDisplay(priceReducer(purchaseItems))}`;
    };

    React.useEffect(() => {
        const plot = Plot.plot({
            marginBottom: 60,
            marginLeft: 70,
            marginRight: 0,
            marginTop: 50,
            x: {label: null},
            y: {label: null},
            marks: [
                Plot.rectY(purchases, Plot.binX(
                    {
                        y: priceReducer,
                        title: {reduce: barTip}
                    },
                    {
                        x: "date_of_purchase",
                        interval: "month",
                        fill: colorFunc,
                        tip: {fontSize: 18}
                    }
                )),
                Plot.text(purchases, Plot.binX(
                    {
                        y: priceReducer
                    },
                    {
                        x: "date_of_purchase",
                        interval: "month",
                        text: d => moneyDisplay(priceReducer(d)),
                        dy: -24,
                        fontSize: 16,
                        fontWeight: 500,
                        rotate: -60
                    }
                )),
                Plot.axisY(
                    {
                        fontSize: 18,
                        tickFormat: moneyDisplay
                    }
                ),
                ...barGraphCommon()
            ]
        });
        divRef.current.append(plot);
        return () => plot.remove();
    }, [purchases]);

    return <div className="staff-sales-chart" ref={divRef}/>;
}

function CarsSoldPerStaff({purchases, staff, colorFunc})
{
    // count how many sales were made per account manager
    /** @type {Map<number, number>} */
    const salesPerStaff = new Map();
    for (const p of purchases)
    {
        const mgr = p.acct_manager;
        salesPerStaff.set(mgr, (salesPerStaff.get(mgr) ?? 0) + 1);
    }
    const plotData = [...salesPerStaff.entries()];

    return <PieChart plotData={plotData} staff={staff} viewW={100} viewH={100}
        colorFunc={colorFunc}/>;
}

function PricesPerStaff({purchases, staff, colorFunc})
{
    // Sum the sale prices of all purchases for each account manager
    /** @type {Map<number, number>} */
    const pricesPerStaff = new Map();
    for (const p of purchases)
    {
        const mgr = p.acct_manager;
        const amount = p.bid?.sold_for ?? p.bid?.amount ?? 0;
        pricesPerStaff.set(mgr, (pricesPerStaff.get(mgr) ?? 0) + amount);
    }
    const plotData = [...pricesPerStaff.entries()];

    // Use slightly wider view width, because the labels can be a bit long
    return <PieChart plotData={plotData} staff={staff} viewW={110} viewH={100}
        labelFormat={moneyDisplay} colorFunc={colorFunc}/>;
}

/**
 * Function for rendering either of the pie charts on this page
 * @param {Object} props
 * @param {number} props.viewW SVG viewport width
 * @param {number} props.viewH SVG viewport height
 * @param {(n: number) => string} [props.labelFormat] optional function to
 * format the numerical values in the labels
 */
function PieChart({plotData, staff, viewW, viewH, labelFormat, colorFunc})
{
    // Labels for staff: first initial, last name (needs to be compact)
    const getStaffLabel = d => {
        if (staff.has(d.data[0]))
        {
            const mgr = staff.get(d.data[0]);
            return mgr.firstname.slice(0, 1) + ". " + mgr.lastname;
        }
        return "(None)";
    };

    // If not provided, use an identity for the formatting function
    labelFormat ??= n => n;

    // radius of pie chart
    const radius = Math.min(viewW, viewH) / 2 - 5;
    // how far from the center each label is placed
    const labelOff = radius * 0.75;
    // color of slice edges
    const STROKE = "#777";

    // arc generators
    const sliceArcGen = d3.arc()
        .innerRadius(0)
        .outerRadius(radius);
    const labelArcGen = d3.arc()
        .innerRadius(labelOff)
        .outerRadius(labelOff);
    // pie generator
    const pieGen = d3.pie().value(d => d[1]);
    // Each arc will contain a `data` field with the [manager, amount] pair
    const chartArcs = pieGen(plotData);
    // SVG container
    const pieSVG = d3.create("svg")
        .attr("viewBox", [-viewW/2, -viewH/2, viewW, viewH]);
    // add slices
    pieSVG.append("g")
        .attr("stroke", STROKE)
        .attr("stroke-width", "0.5px")
        .selectAll()
        .data(chartArcs)
        .join("path")
        .attr("fill", colorFunc)
        .attr("d", sliceArcGen);
    // add labels
    pieSVG.append("g")
        .attr("text-anchor", "middle")
        .selectAll()
        .data(chartArcs)
        .join("text")
        .attr("transform", d => `translate(${labelArcGen.centroid(d)})`)
        .call(text => text.append("tspan")
            .text(d => `${getStaffLabel(d)}: ${labelFormat(d.data[1])}`)
            .attr("font-size", "6px")
        );

    return <div className="staff-sales-pie-chart"
        ref={ref => {
            if (ref)
            {
                while (ref.firstChild)
                    ref.removeChild(ref.firstChild);
                ref.appendChild(pieSVG.node());
            }
        }}
    />;
}
