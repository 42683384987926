import React, { Component } from "react";
import PropTypes from "prop-types";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup
} from "react-bootstrap";

import { Link } from "react-router-dom";

import { settings } from "../settings";
import {
    resetTranslations,
    setTranslationList,
    showTranslation
} from "../actions/translation_actions";

class AvailableTranslations extends Component {
    constructor (props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler (e) {
    // open a modal with this translation's data
    // find the translation from the id
        for (let i = 0; i < this.props.translationList.length; i++) {
            if (parseInt(this.props.translationList[i].translation_id, 10) === parseInt(e.target.value, 10)) { this.props.showTranslation(e.target.value, this.props.translationList[i], this.props.translationList[i].vehicle_id); }
        }
    }

    componentDidMount () {
        let url = settings.api_server + "/translations/";

        // admin can fetch anyone
        if (this.props.who >= 0) { url = url + "/" + this.props.who; }

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of purchases (even if it's empty)
                this.props.setTranslationList(data);
            }.bind(this));
    }

    componentWillUnmount () {
        this.props.resetTranslations();
    }

    render () {
    // needs to return data in a <ListGroup>
        if (this.props.translationList == null) {
            return (<ListGroup><ListGroup.Item>loading...</ListGroup.Item></ListGroup>);
        }

        let bids = [];
        if (this.props.useLink) { bids = this.props.translationList.map(x => <ListGroup.Item action key={x.translation_id} href={"#/auction_car/" + x.vehicle_id}>{x.description} {x.main === null ? "Pending" : "Complete"} </ListGroup.Item>); } else { bids = this.props.translationList.map(x => <ListGroup.Item action key={x.translation_id} value={x.translation_id} onClick={this.clickHandler}>{x.description} {x.main === null ? "Pending" : "Complete"} </ListGroup.Item>); }

        return (
            <ListGroup variant="flush">
                {bids}
                <ListGroup.Item action><Link to="all_translations">View all...</Link></ListGroup.Item>
            </ListGroup>
        );
    }
}

AvailableTranslations.defaultProps = {
    useLink: false
};
AvailableTranslations.propTypes = {
    who: PropTypes.number.isRequired,
    useLink: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        translationList: state.translations.translationList,
        translationsLoaded: state.translations.translationsLoaded

    };
};

const mapDispatchToProps = dispatch => ({
    resetTranslations: () => dispatch(resetTranslations()),
    setTranslationList: (trans_list) => dispatch(setTranslationList(trans_list)),
    showTranslation: (id, text, auction_id) => dispatch(showTranslation(id, text, auction_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTranslations);
