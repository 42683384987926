import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
    Container,
    ListGroup,
    Button,
    Collapse,
    Row, Col
} from "react-bootstrap";

import { settings } from "../settings";
import { setSavedList } from "../actions/saved_query_actions";
import { setSearchFromDict, force_show, clear_results } from "../actions/search_actions";

/*
Adds a drop down surrounding the saved queries
Assumes it in a grid, does rows.

*/
export class SavedQueryDropdown extends Component {
    constructor () {
        super();
        this.state = { isOpen: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle () {
        this.setState({ ...this.state, isOpen: !this.state.isOpen });
    }

    render () {
        return <Row>
            <Col className='italicTitle theHand' md={12} onClick={this.toggle}>Saved Searches</Col>
            <Collapse in={this.state.isOpen}>
                <Col md={12}>
                    <SavedQueriesExport doRedirect={false} />
                </Col>
            </Collapse>
        </Row>;
    }
}

class QueryItemComponent extends Component {
    constructor () {
        super();
        this.clicked = this.clicked.bind(this);
        this.delete = this.delete.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.state = { redirect: false };
    }

    clicked () {
    // clear the results
        this.props.clearResults();
        // load the search criteria
        this.props.setSearchFromDict(this.props.item.query);
        // show the results (if it's not already shown)
        this.props.force_show();

        // move to the search screen
        // only matters if it's not on the search screen already
        this.setState({ ...this.state, redirect: true });
    }

    delete (e) {
        const url = settings.api_server + "/saved/" + this.props.item.save_id;
        // don't click the underlying div
        e.stopPropagation();
        // do the delete
        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                this.props.update();
            }.bind(this));
    }

    subscribe (e) {
        const url = settings.api_server + "/saved/" + this.props.item.save_id + "/subscribe";
        let method = "PUT";
        if (this.props.item.subscribed) { method = "DELETE"; }
        // don't click the underlying div
        e.stopPropagation();
        // do the delete
        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                this.props.update();
            }.bind(this));
    }

    render () {
    // the widget is not on the search page, redirect to it
        if (this.props.doRedirect && this.state.redirect) { return <Redirect push to={"/search"} />; }

        let subscribeText = "Subscribe";
        if (this.props.item.subscribed) { subscribeText = "Unsubscribe"; }
        // force to div, because of the nested buttons
        // have to add the hand, since it is not automatic any more
        return <ListGroup.Item className="theHand" as='div' action key={this.props.item.save_id} onClick={this.clicked} >{this.props.item.name} <div className="float-right"><Button size="sm" variant="outline-success" onClick={this.subscribe}>{subscribeText}</Button>&nbsp;<Button size="sm" variant="outline-danger" onClick={this.delete}>Delete</Button></div></ListGroup.Item>;
    }
}

const itemStateToProps = state => {
    state; //linter
    return {

    };
};

const itemDispatchToProps = dispatch => ({
    clearResults: () => dispatch(clear_results()),
    setSearchFromDict: (theDict) => dispatch(setSearchFromDict(theDict)),
    force_show: () => dispatch(force_show())
});

const QueryItem = connect(itemStateToProps, itemDispatchToProps)(QueryItemComponent);

class SavedQueries extends Component {
    constructor () {
        super();
        this.update = this.update.bind(this);
    }

    componentDidMount () {
        this.setState({ ...this.state, redirect: false });
        this.update();
    }

    update () {
    // fetch the data
        const url = settings.api_server + "/saved/";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.props.setSavedList(data);
            }.bind(this))
            .catch(function () {
                // ignore errors
            });
    }

    render () {
    // needs to return data in a <ListGroup>

        if (!this.props.loaded) {
            return (<ListGroup><ListGroup.Item>loading...</ListGroup.Item></ListGroup>);
        }

        if (this.props.guest) { return <Container fluid><p>Users with accounts can save their favourite queries!</p></Container>; }
        if (this.props.list.length === 0) { return <Container fluid><p>Save some queries, they will appear here!</p></Container>; }

        const list = this.props.list.map(x => <QueryItem key={x.save_id} item={x} update={this.update} doRedirect={this.props.doRedirect} />);

        return (

            <ListGroup>
                {list}
            </ListGroup>

        );
    }
}

const mapStateToProps = state => {
    return {
        list: state.saved_queries.savedList,
        loaded: state.saved_queries.loaded,
        lastUpdate: state.saved_queries.lastUpdate,
        guest: state.profile.guest
    };
};

const mapDispatchToProps = dispatch => ({
    setSavedList: (saved_list) => dispatch(setSavedList(saved_list)),
    setSearchFromDict: (theDict) => dispatch(setSearchFromDict(theDict))
});
const SavedQueriesExport = connect(mapStateToProps, mapDispatchToProps)(SavedQueries);
export default SavedQueriesExport;
