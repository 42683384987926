import React, { Component } from "react";
import { settings } from "../../settings";
import { Row, Col, Form } from "react-bootstrap";


class PortOfExport extends Component {
    constructor() {
        super();
        this.state = { "ports": ["Loading..."], chosenPort: "Loading...", fetched:false };
        this.sendUpdate = this.sendUpdate.bind(this);
        this.get = this.get.bind(this);
        this.changed = this.changed.bind(this);
    }

    get() {
        // Fetch all countries
        const url = settings.api_server + "/user/getAllPorts";

        fetch(url, {
            credentials: "include",
            method: "GET",
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {

                    // data is ["Braziltown", "Vancouver, Canada"]
                    // Chooses the first one
                    this.setState({
                        ...this.state,
                        chosenPort: data[0],
                        ports: data,
                        fetched: true
                    });
                    this.sendUpdate(data[0]);
                }.bind(this)
            );
    }

    componentDidMount() {
        this.get();
        this.sendUpdate("");
    }

    sendUpdate(newPort) {
        // this is a non-filter, really.
        // send nothing, and it will get filtered out.
        this.props.changeFilter(this.props.which, {"type": "port_of_export", "which": newPort});
    }

    changed(e) {
        this.setState({ ...this.state, chosenPort: e.target.value });
        this.sendUpdate(e.target.value);
    }

    render() {
        // build the dropdown
        let options = this.state.ports.map(port =>
            <option key={port} value={port}>{port}</option>
        );
        return <Form>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Port</Form.Label>
                <Col sm={10}>
                    <Form.Control disabled={!this.state.fetched} as="select" placeholder="select" onChange={this.changed} value={this.props.chosenPort}>
                        {options}
                    </Form.Control>
                </Col>
            </Form.Group>
        </Form>;
    }
}

export default PortOfExport;