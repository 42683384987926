
import { settings } from "../settings";

const questions = (state = settings.questions_defaultState, action) => {
    switch (action.type) {
    case "SET_QUESTION_COUNT":
        return {
            ...state,
            count: action.count
        };
    case "SET_QUESTION_THREAD_LIST":
        return {
            ...state,
            question_list_loaded: true,
            question_list: action.question_list
        };
    case "CLEAR_THREAD_LIST":
        return {
            ...state,
            question_list_loaded: false,
            question_list: null
        };
    case "SET_FETCHING_THREAD":
        return {
            ...state,
            fetching_thread: true
        };
    case "SET_CURRENT_THREAD":
        return {
            ...state,
            thread_loaded: true,
            fetching_thread: false,
            thread_data: action.thread_data
        };
    case "SET_SHOW_THREAD_ID":
        return {
            ...state,
            show_thread_id: action.show_thread_id
        };
    case "CLEAR_CURRENT_THREAD":
        return {
            ...state,
            thread_data: null,
            thread_loaded: false,
            show_thread_id: -1
        };
    case "TOGGLE_ASK_MODAL":
        return {
            ...state,
            showAskModal: !state.showAskModal
        };
    default:
        return state;
    }
};

export default questions;
