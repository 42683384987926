const shipping_fees_defaultState = {
    export_ports: [],
    loaded: false
};

const shipping_fees = (state = shipping_fees_defaultState, action) => {
    switch (action.type) {
    case "SET_SHIPPING_FEES":
        return {
            ...state,
            export_ports: action.fees,
            loaded: true
        };
    case "CLEAR_SHIPPING_FEES":
        return {
            ...shipping_fees_defaultState
        };

    case "UPDATE_SHIPPING_PORT_DATA":
        /*
            gets a port name, a value in that port to update
            key and a value to update for that port
            */
        return {
            ...state,
            export_ports:
                    // find the port, update it. Leave the rest
                    state.export_ports.map(port => {
                        if (port.port == action.port_name) {
                            return { ...port, [action.field]: action.value };
                        } else {
                            return port;
                        }
                    })
        };
    case "UPDATE_SHIPPING_FEE_SHIPPING_DATA":
        return {
            ...state,
            m3UpdateTime: new Date().getTime(),
            export_ports: state.export_ports.map(port => {
                // check if this fee id matches
                return {
                    ...port,
                    shipping_fees: port.shipping_fees.map(c => {
                        if (c.id === action.fee_id) {
                            return {
                                ...c,
                                [action.field]: action.value
                            };
                        } else { return c; }
                    })
                };
            })
        };
    case "REMOVE_SHIPPING_PORT":
        return {
            ...state,
            export_ports: state.export_ports.filter(port => port.port !== action.port_name)
        };
    case "DELETE_SHIPPING_FEE_SHIPPING_DATA":
        return {
            ...state,
            export_ports: state.export_ports.map(port => {
                // check if this fee id matches
                return {
                    ...port,
                    shipping_fees: port.shipping_fees.filter(c => c.id !== action.fee_id)
                };
            })
        };
    case "ADD_SHIPPING_FEE_SHIPPING_DATA":
        return {
            ...state,
            export_ports: state.export_ports.map(port => {
                // find the port, add the row
                const shipping_fees = [...port.shipping_fees];
                if (port.port === action.port_id) {
                    // add a new row with a "random" id
                    // put it first
                    shipping_fees.splice(
                        0,
                        0,
                        { id: new Date().getTime(), max_m3: 0, shipping_fee: 0 }
                    );
                    return {
                        ...port,
                        shipping_fees
                    };
                }
                return port;
            })
        };
    default:
        return state;
    }
};

export default shipping_fees;
