import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import PCANav from "../dashboard-components/navbar";

import {
    Col, Row, Container,
    Form
} from "react-bootstrap";
import TranslationQueue from "../admin_views/TranslationQueue";
import AdminBidQueue from "../admin_views/BidQueue/AdminBidQueue";
import AdminOnly from "../dashboard-components/AdminOnly";
import Questions from "../admin_views/questions";
import ImageRequestQueue from "../admin_views/ImageRequestQueue";
import AlertTool from "../admin_views/AlertTool";
import { settings } from "../settings";

// The amount of time between auto-updates for the child components, to be
// passed as a prop. Value is in milliseconds.
const CHILD_UPDATE_INTERVAL = 5000;

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // when the user is an account manager, if clientsOnly is true, all
            // queues will be filtered to only include requests from that
            // account manager's clients
            clientsOnly: false,
            clientIDs: null,
            clientFetchError: null,
        };
    }

    fetchClients() {
        const clientsURL = settings.api_server + "/user/account_manager/clients";
        fetch(clientsURL, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                data.clients
                : Promise.reject(data.message)
            )
            .then(
                clients => {
                    this.setState({...this.state, clientIDs: clients});
                },
                reason => {
                    console.log("Failed to fetch clients. Reason: " + reason);
                    this.setState({...this.state, clientFetchError: reason});
                }
            );
    }

    // If the current user is an account manager, fetch a list of their clients
    componentDidMount() {
        if (this.props.isAccountManager) {
            this.fetchClients();
        }
    }

    // Refreshing the page can result in isAccountManager being false when
    // componentDidMount is called, so if it becomes true after the fact, fetch
    // the clients
    componentDidUpdate(prevProps) {
        if (this.props.isAccountManager && !prevProps.isAccountManager) {
            this.fetchClients();
        }
    }

    clientFilterControl() {
        let content = null;
        if (this.state.clientFetchError) {
            content = "An error occurred while loading your clients: "
                + this.state.clientFetchError;
        }
        else {
            content = <Form.Check
                type="switch"
                label="Filter to my Clients"
                id="clientFilterToggle"
                onChange={() => this.setState({
                    ...this.state,
                    clientsOnly: !this.state.clientsOnly
                })}
                checked={this.state.clientsOnly}
            />;
        }
        return <Col md={12}>
            <Container className="cardSpace whiteBackground dropShadow"
                style={{
                    width: "fit-content",
                    marginLeft: "0px",
                    paddingLeft: "10px", paddingRight: "10px"
                }}
            >
                {content}
            </Container>
        </Col>;
    }

    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <PCANav />; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        const clientList = this.state.clientsOnly ?
            this.state.clientIDs : null;

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <Container className="wideContainer">
                    <h2 className="whiteTitle">Request Monitoring</h2>
                    <Row>
                        {this.props.isAccountManager && this.clientFilterControl()}
                        <Col className="cardSpace" md={6}>
                            <Questions updateInterval={CHILD_UPDATE_INTERVAL} userWhitelist={clientList}/>
                        </Col>
                        <Col className="cardSpace" md={6}>
                            <ImageRequestQueue updateInterval={CHILD_UPDATE_INTERVAL} userWhitelist={clientList}/>
                        </Col>
                        <Col className="cardSpace" md={6}>
                            <TranslationQueue updateInterval={CHILD_UPDATE_INTERVAL} userWhitelist={clientList}/>
                        </Col>
                        <Col className="cardSpace" md={6}>
                            {/* Auto-updates would make users prone to
                            accidentally clicking "Confirm" on the wrong bid
                            when the list gets shifted, so it just uses the
                            refresh button. (Not passing an updateInterval
                            disables auto-updates.) */}
                            <AdminBidQueue userWhitelist={clientList}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="cardSpace" md={12}>
                            <AlertTool />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        isAdmin: state.profile.is_admin,
        fetchingProfile: state.profile.fetchingProfile,
        isAccountManager: state.profile.is_acct_manager,
    };
};

export default connect(mapStateToProps)(Admin);
