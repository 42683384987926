import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    Alert,
    Container, Row, Col,
    Button,
    Modal,
    FormControl
} from "react-bootstrap";

import { settings } from "../settings";

import {
    fetch_invoice
} from "../actions/invoice_actions";

class AddFromDeposit extends Component {
    constructor () {
        super();
        this.saveEdit = this.saveEdit.bind(this);
        this.editAmount = this.editAmount.bind(this);
        this.state = { amount: "", message: null, pushing: false };
    }

    saveEdit () {
        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.purchase_id + "/" + this.props.invoice_id + "/useDeposit";

        // name change stuff is vestigial, or for future. Who knows.
        this.setState({ ...this.state, pushing: true });
        const postData = { amount: this.state.amount };
        fetch(url, {
            credentials: "include",
            body: JSON.stringify(postData),
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh the underlying data
                this.setState({ ...this.state, pushing: false });
                if (data.success) {
                    fetch_invoice(this.props.purchase_id, this.props.invoice_id);
                    this.setState({ ...this.state, message: null });
                    this.props.close();
                } else {
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    editAmount (e) {
        this.setState({ ...this.state, amount: e.target.value });
    }

    render () {
        if (!(this.props.invoice_id in this.props.invoice_data)) { return null; }
        return (
            <Modal show={this.props.show} >
                <Modal.Header>Move deposit to invoice</Modal.Header>
                <Modal.Body>{this.props.car_data.owner.firstname} {this.props.car_data.owner.lastname} has a balance of {this.props.car_data.owner.deposit_balance}.
                    <Container fluid>
                        {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                        <Row><Col md={3}>Amount to use:</Col><Col md={9}><FormControl type="text" value={this.state.newAmount} onChange={this.editAmount} /></Col></Row>
                    </Container>
                    {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pushing} onClick={this.saveEdit}>Apply</Button> <Button disabled={this.state.pushing} onClick={this.props.close} variant="danger">Discard changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
AddFromDeposit.propTypes = {
    purchase_id: PropTypes.string.isRequired,
    invoice_id: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};
const mapStateToProps = state => {
    return {
        purchase_id: state.invoice.purchase_id,
        invoice_data: state.invoice.invoice_data,
        car_data_loading: state.car_detail.loading,
        car_data: state.car_detail.car_info,
        last_invoice_update: state.invoice.last_invoice_update
    };
};

export default connect(mapStateToProps)(AddFromDeposit);
