/* This page shows the user their stats in finer detail than the MyStatsSummary
 * widget in the Dashboard, including graphs.
*/

import React, { Component } from "react";
import "./App.css";
import "./index.css";
import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";
import { Container, Row, Col } from "react-bootstrap";
import LoadingSpinner from "./widgets/LoadingSpinner";
import LoadingError from "./widgets/LoadingError";
import HistoryPlot from "./stats_components/HistoryPlot";
import FrequencyPlot from "./stats_components/FrequencyPlot";
import PieChart from "./stats_components/PieChart";

import * as Fetcher from "./stats_components/StatFetcher";

// For plots: how many months of data there has to be before we switch from
// month intervals to year intervals.
const MONTH_INTERVAL_BREAKPOINT = 24;

// Number of milliseconds in one month, needed to calculate the time spanned by
// stats data in months
const MONTH_MILLIS = 1000 * 60 * 60 * 24 * 365.25 / 12;

/**
 * @typedef {import("./stats_components/StatFetcher").successCallback} successCallback
 * @typedef {import("./stats_components/StatFetcher").statState} statState
 */

export default class MyStatsPage extends Component {
    constructor(props) {
        super(props);

        this.fetchRejected = this.fetchRejected.bind(this);
        this.storeWinPrices = this.storeWinPrices.bind(this);
        this.storePurchaseDates = this.storePurchaseDates.bind(this);
        this.storeBidStrength = this.storeBidStrength.bind(this);
        this.storeTranslations = this.storeTranslations.bind(this);
        this.storeBidWins = this.storeBidWins.bind(this);
        this.renderWinPrices = this.renderWinPrices.bind(this);
        this.renderPurchaseDates = this.renderPurchaseDates.bind(this);
        this.renderBidStrength = this.renderBidStrength.bind(this);
        this.renderTranslations = this.renderTranslations.bind(this);
        this.renderBidWins = this.renderBidWins.bind(this);

        // Map stats to the callbacks for manipulating and storing them
        /** @type {[string, successCallback][]} */
        this.STATS_STORES = [
            [Fetcher.MyStats.winPrices, this.storeWinPrices],
            [Fetcher.MyStats.purchaseDates, this.storePurchaseDates],
            [Fetcher.MyStats.bidStrengths, this.storeBidStrength],
            [Fetcher.MyStats.translationsPerWin, this.storeTranslations],
            [Fetcher.MyStats.bidWins, this.storeBidWins]
        ];
        // Map stats to the functions for rendering them (also determines the
        // order the plots are shown)
        /** @type {[string, (statName: string) => React.JSX.Element][]} */
        this.STATS_DISPLAYS = [
            [Fetcher.MyStats.purchaseDates, this.renderPurchaseDates],
            [Fetcher.MyStats.winPrices, this.renderWinPrices],
            [Fetcher.MyStats.bidStrengths, this.renderBidStrength],
            [Fetcher.MyStats.translationsPerWin, this.renderTranslations],
            [Fetcher.MyStats.bidWins, this.renderBidWins]
        ];

        this.state = {...Fetcher.getInitialState(this.STATS_STORES.map(s => s[0]))};
    }

    componentDidMount() {
        Fetcher.fetchStats(this.STATS_STORES, this.fetchRejected);
    }

    /**
     * Callback for stats that couldn't be fetched
     * @param {string} statName
     * @param {statState} failState
     */
    fetchRejected(statName, failState) {
        console.log(`Failed to fetch stat "${statName}"`);
        this.setState({...this.state,
            [statName]: failState
        });
    }

    /**
     * Callback for when win prices have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeWinPrices(statName, data, successState) {
        // convert date ISO strings to Date objects
        const coerced = data.map(item => ({date: new Date(item.date), value: item.value}));
        // get average JPY, round to nearest integer
        const average = data.length == 0 ?
            "N/A"
            :
            "¥" + Math.round(data.reduce((sum, x) => sum + x.value, 0) / data.length);
        this.setState({...this.state,
            [statName]: {
                ...successState,
                data: coerced,
                average: average
            }
        });
    }

    /**
     * Callback for when purchase dates have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date_of_purchase: string}[]} data
     * @param {statState} successState
     */
    storePurchaseDates(statName, data, successState) {
        this.setState({...this.state,
            [statName]: {
                ...successState,
                // convert to array of dates
                data: data.map(d => new Date(d.date_of_purchase))
            }
        });
    }

    /**
     * Callback for when bid strengths have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeBidStrength(statName, data, successState) {
        // convert ISO strings to Dates, convert values in [0, 1] to percentages
        const coerced = data.map(item => (
            {date: new Date(item.date), value: item.value * 100}
        ));
        // get average percentage
        let average = "N/A";
        if (coerced.length > 0) {
            average = coerced.reduce((sum, x) => sum + x.value, 0) / coerced.length;
            // round to 4 sig. digits, add percent sign
            average = average.toPrecision(4) + "%";
        }
        this.setState({...this.state,
            [statName]: {
                ...successState,
                data: coerced,
                average: average
            }
        });
    }

    /**
     * Callback for when translations per win are fetched
     * @param {string} statName so we don't have to hard-code it
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeTranslations(statName, data, successState) {
        const coerced = data.map(item => (
            {date: new Date(item.date), value: item.value}
        ));
        const average = data.length == 0 ?
            "N/A"
            :
            (data.reduce((sum, x) => sum + x.value, 0) / data.length)
                // only one digit after the decimal
                .toFixed(1);
        this.setState({...this.state,
            [statName]: {
                ...successState,
                data: coerced,
                average: average
            }
        });
    }

    /**
     * Callback for when recent bid wins are fetched
     * @param {string} statName so we don't have to hard-code it
     * @param {{bids: number, won: number}} data
     * @param {statState} successState
     */
    storeBidWins(statName, data, successState) {
        this.setState({...this.state,
            [statName]: {
                ...successState,
                bids: data.bids,
                won: data.won,
                lost: data.bids - data.won
            }
        });
    }

    // Return ALL THE STATS!
    getAllStats() {
        // show two plots per row for larger devices
        return <Row xs={1} lg={2}>
            {this.STATS_DISPLAYS.map(([statName, renderFn]) => {
                if (Fetcher.isLoading(this.state[statName])) {
                    return <Col key={statName}>
                        <LoadingSpinner message="Loading..."/>
                    </Col>;
                }
                else if (Fetcher.hasError(this.state[statName])) {
                    return <Col key={statName}>
                        <LoadingError message={Fetcher.getError(this.state[statName])}/>
                    </Col>;
                }
                else {
                    return renderFn(statName);
                }
            })}
        </Row>;
    }

    /**
     * Return a JSX element to display the win prices
     * @param {string} statName so we don't have to hard-code it
     */
    renderWinPrices(statName) {
        const plot = <HistoryPlot plotData={this.state[statName].data}
            valueLabel="Auction win prices"
            valuePrefix="¥"/>;
        const numPoints = this.state[statName].data.length;
        const avgSpan = <span>
            <b>Average auction win price</b> (last {numPoints}): <code style={{color: "black"}}>
                {this.state[statName].average}
            </code>
        </span>;

        return this.renderStatAndPlot(statName, avgSpan, plot);
    }

    /**
     * Return a JSX element to display the purchase dates
     * @param {string} statName so we don't have to hard-code it
     */
    renderPurchaseDates(statName) {
        // depending on timespan of data, use month or year interval
        // get oldest and newest dates as timestamps
        const sortedDates = this.state[statName].data.map(d => d.valueOf()).sort();
        // if we don't have any data, use timespan of 0
        const dataLength = this.state.totalPurchases == 0 ? 0 :
            sortedDates.at(-1) - sortedDates[0];
        const interval = dataLength / MONTH_MILLIS >= MONTH_INTERVAL_BREAKPOINT ?
            "year" : "month";

        const plot = <FrequencyPlot plotData={this.state[statName].data}
            label="Auctions won" interval={interval}
            showText/>;
        const totalSpan = <span>
            <b>Auctions won:</b> <code style={{color: "black"}}>
                {this.state[statName].data.length}
            </code>
        </span>;

        return this.renderStatAndPlot(statName, totalSpan, plot);
    }

    /**
     * Return JSX element to display bid strengths
     * @param {string} statName
     */
    renderBidStrength(statName) {
        const plot = <HistoryPlot plotData={this.state[statName].data}
            valueLabel="Bid strength"
            valueSuffix="%"
            precision={4}/>;
        const numPoints = this.state[statName].data.length;
        const avgSpan = <span>
            <b>Average bid strength</b> (last {numPoints}): <code style={{color: "black"}}>
                {this.state[statName].average}
            </code>
        </span>;

        return this.renderStatAndPlot(statName, avgSpan, plot);
    }

    /**
     * Return plot of translation requests per win
     * @param {string} statName
     */
    renderTranslations(statName) {
        const plot = <HistoryPlot plotData={this.state[statName].data}
            valueLabel="Translation requests"/>;
        const numPoints = this.state[statName].data.length;
        const avgSpan = <span>
            <b>Average translations per win</b> (last {numPoints}): <code style={{color: "black"}}>
                {this.state[statName].average}
            </code>
        </span>;

        return this.renderStatAndPlot(statName, avgSpan, plot);
    }

    /**
     * Return pie chart of recent bids won and lost
     * @param {string} statName
     */
    renderBidWins(statName) {
        const plotData = [
            {
                "name": "won",
                "value": this.state[statName].won,
                "color": "#6F6"
            },
            {
                "name": "lost",
                "value": this.state[statName].lost,
                "color": "#F77"
            }
        ];
        const plot = <PieChart plotData={plotData}
            minHeight="250px" labelDist={0.5}/>;
        const titleSpan = <span>
            <b>Recent bid results</b> (last {this.state[statName].bids})
        </span>;

        return this.renderStatAndPlot(statName, titleSpan, plot);
    }

    /**
     * @param {React.JSX.Element} stat
     * @param {React.JSX.Element} plot
     */
    renderStatAndPlot(key, stat, plot) {
        return <Col key={key} style={{textAlign: "center"}}>
            <div>
                {stat}
            </div>
            <div>
                {plot}
            </div>
        </Col>;
    }

    render() {
        return <Container className="wideContainer">
            <PCANav isAdmin={this.props.isAdmin} currentPage="#/dashboard"/>
            <DashNav which="#/MyStats"/>
            <Container>
                <div className="whiteTitle">
                    My Stats
                </div>
                <Container className="whiteBackground dropShadow">
                    {this.getAllStats()}
                </Container>
            </Container>
        </Container>;
    }
}
