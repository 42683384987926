
import { settings } from "../settings";

const alert = (state = settings.previous_auctions_defaultState, action) => {
    switch (action.type) {
    case "SET_PREV_AUCTION_LIST":
        return {
            ...state,
            auctions: action.auctions,
            loaded: true,
            message: null
        };
    case "SET_PREV_AUCTION_MESSAGE":
        return {
            ...state,
            message: action.message,
            loaded: true
        };
    case "CLEAR_PREV_AUCTION":
        return {
            ...settings.previous_auctions_defaultState
        };
    default:
        return state;
    }
};

export default alert;
