import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Modal,
    FormControl,
    Alert
} from "react-bootstrap";

import { settings } from "../../../settings";
import { setMessage } from "../../../actions/alert_actions";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import UserLookupTool from "../../../admin_views/UserLookupTool";

import FxView from "../../../widgets/FxView";

const DEFAULT_STATE = {
    chosenUser: null,
    chosenID: null,
    showModal: false,
    amount: "",
    comment: "",
    message: null
};

class RequestBid extends Component {
    

    constructor() {
        super();
        this.state = { ...DEFAULT_STATE };
        this.save = this.save.bind(this);
        this.chosen = this.chosen.bind(this);
        this.updateAmount = this.updateAmount.bind(this);
    }

    chosen(id, name) {
        this.setState({ ...this.state, chosenID: id, chosenName: name, users: [] });
    }

    updateAmount(e) {
        if (!isNaN(e.target.value)) {
            this.setState({ ...this.state, amount: e.target.value });
        }
    }

    save() {
        // send the request
        // close the modal
        var url = settings.api_server + "/bids/bid/" + this.props.car_data.vehicle_id + "/" + this.state.chosenID;

        var postdata = {
            amount: this.state.amount,
            comment: this.state.comment,
        };
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    // show the banner
                    this.props.setMessage("Request made for user");
                    // go away
                    this.setState({ ...DEFAULT_STATE });
                }
                else {
                    // show the message locally
                    // go away
                    this.setState({ ...this.state, message: data.message });
                }

            }.bind(this));

    }

    render() {
        if (!this.props.isAdmin)
            return null;



        return <div>
            <Button
                block
                onClick={() => this.setState({ ...this.state, showModal: true })}>
                Create bid for user
            </Button>
            <Modal show={this.state.showModal} onHide={() => this.setState({ ...DEFAULT_STATE })}>
                <ModalHeader>
                    <Modal.Title>Request bid for User</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    Choose user to bid translation for:
                    <UserLookupTool chosenCallback={this.chosen} />
                    {this.state.chosenID === null ?
                        <p>Chosen: None</p> :
                        <p>Chosen: {this.state.chosenName}</p>
                    }
                    <p>Amount x1000¥</p>
                    <FormControl
                        onChange={this.updateAmount}
                        value={this.state.amount}
                    />
                    {this.props.amount !== null && <div>Approximately <FxView amount={this.state.amount} thousands={true} /></div>}
                    <p>Comment</p>
                    <FormControl
                        onChange={(e) => this.setState({ ...this.state, comment: e.target.value })}
                        value={this.state.comment}
                    />
                    {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={this.save} disabled={this.state.chosenID === null || this.state.amount.length === 0}>
                        Do Request
                    </Button>
                    <Button variant="secondary" onClick={() => this.setState({ ...DEFAULT_STATE })}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>;

    }
}


const stateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,

        car_data: state.auction_car.data,
        loading: state.auction_car.loading,
    };
};

const dispatches = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(stateToProps, dispatches)(RequestBid);
