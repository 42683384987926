/* not a react component! */
/* eslint react/display-name: "off" */

// Convert a number, into a pretty number
// 1000 -> 1,000
// assumes only the values to the left of the decimal place should have commas
export default function (theNumber) {
    if (isNaN(theNumber)) { return null; }

    let built = "";
    // make a copy of the number
    let working = Math.floor(Math.abs(theNumber));
    let count = 0;
    while (working !== 0) {
    // add a comma, if needed
        if (count > 0 && count % 3 === 0) { built = " " + built; }
        built = "" + (working % 10) + built;
        count++;
        working = Math.floor(working / 10);
    // take the last number
    // add it to the string
    }
    // add the -'ve sign back in, if need be
    if (theNumber < 0) { built = "-" + built; }
    return built;
}
