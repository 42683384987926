import React, { Component } from "react";

import "./App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import AdminOnly from "./dashboard-components/AdminOnly";

import { setMessage } from "./actions/alert_actions";

import PCANav from "./dashboard-components/navbar";

import {
    fetchNotify,
    setChosenNotify,
    setChosenNotifyFromEvent,
    clearCurrentNotify,
    updateName,
    updateEmail,
    updateAddress,
    updateNote,
    updatePhone,
    showAddNotify,
    hideAddNotify
} from "./actions/notify_actions";

import {
    Alert,
    Container, Col, Row,
    Card, Modal,
    Form,
    FormGroup,
    Button,
    ListGroup
} from "react-bootstrap";
import { settings } from "./settings";

class NotifyRowComponent extends Component {
    render () {
        return (
            <ListGroup.Item action onClick={this.props.setChosenNotifyFromEvent} value={this.props.rowdata.notify_id}>
                <b>Name:</b>{this.props.rowdata.name}<br />
                <b>Address:</b><br />
                <span className="whitespace">{this.props.rowdata.address} </span><br />
                <b>Email:</b> {this.props.rowdata.email} <br />
                <b>Phone:</b> {this.props.rowdata.phone} <br />
                <b>Note:</b> {this.props.rowdata.note}
            </ListGroup.Item>
        );
    }
}
const notifyRowmapStateToProps = state => {
    return {
        chosen: state.notify.chosen,
        parties: state.notify.parties,
        loggedIn: state.profile.profileLoaded,
        isAdmin: state.profile.is_admin
    };
};

const notifyRowmapDispatchToProps = dispatch => ({
    setChosenNotifyFromEvent: (e) => dispatch(setChosenNotifyFromEvent(e))
});
const NotifyRow = connect(notifyRowmapStateToProps, notifyRowmapDispatchToProps)(NotifyRowComponent);

class EditNotifyComponent extends Component {
    constructor () {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.state = { updating: false, message: null };
    }

    handleClose () {
        const url = settings.api_server + "/notify/" + this.props.chosen;
        const postData = {
            name: this.props.name,
            email: this.props.email,
            address: this.props.address,
            phone: this.props.phone,
            note: this.props.note
        };
        this.setState({ ...this.state, updating: true });
        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data
                    fetchNotify();
                    // close the modal
                    this.props.clearCurrentNotify();
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    render () {
        return <Modal size="lg" show={this.props.chosen > 0} onHide={this.props.clearCurrentNotify}>
            <Modal.Header closeButton>
                <Modal.Title>Create new Notify Party</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message !== null && <Alert>{this.state.message}</Alert>}
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Name:</Form.Label></Col>
                    <Col><Form.Control value={this.props.name} onChange={this.props.updateName} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Email:</Form.Label></Col>
                    <Col><Form.Control value={this.props.email} onChange={this.props.updateEmail} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Address:</Form.Label></Col>
                    <Col><Form.Control as="textarea" rows="5" value={this.props.address} onChange={this.props.updateAddress} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Name:</Form.Label></Col>
                    <Col><Form.Control value={this.props.note} onChange={this.props.updateNote} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Name:</Form.Label></Col>
                    <Col><Form.Control value={this.props.phone} onChange={this.props.updatePhone} /></Col>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>

                <Button disabled={this.state.updating} variant="primary" onClick={this.handleClose}>
                    Save Changes
                </Button>
                <Button disabled={this.state.updating} variant="secondary" onClick={this.props.clearCurrentNotify}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
const editNotifyRowmapStateToProps = state => {
    return {
        chosen: state.notify.chosen,
        name: state.notify.name,
        address: state.notify.address,
        note: state.notify.note,
        email: state.notify.email,
        phone: state.notify.phone

    };
};

const editNotifyRowmapDispatchToProps = dispatch => ({
    updateName: (e) => dispatch(updateName(e)),
    updateEmail: (e) => dispatch(updateEmail(e)),
    updateAddress: (e) => dispatch(updateAddress(e)),
    updateNote: (e) => dispatch(updateNote(e)),
    updatePhone: (e) => dispatch(updatePhone(e)),
    clearCurrentNotify: () => dispatch(clearCurrentNotify())
});
const EditNotify = connect(editNotifyRowmapStateToProps, editNotifyRowmapDispatchToProps)(EditNotifyComponent);

class AddNotifyComponent extends Component {
    constructor () {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.state = { updating: false, message: null };
    }

    handleClose () {
        const url = settings.api_server + "/notify/";
        const postData = {
            name: this.props.name,
            email: this.props.email,
            address: this.props.address,
            phone: this.props.phone,
            note: this.props.note
        };
        this.setState({ ...this.state, updating: true });
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // create banner
                    this.props.setMessage(<div>Created new notify party "{this.props.name}"</div>);
                    // update underlying data
                    fetchNotify();
                    // close the modal
                    this.props.hideAddNotify();
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    render () {
        return <Modal size="lg" show={this.props.show} onHide={this.props.hideAddNotify}>
            <Modal.Header closeButton>
                <Modal.Title>Create new Notify Party</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.message !== null && <Alert>{this.state.message}</Alert>}

                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Name:</Form.Label></Col>
                    <Col><Form.Control value={this.props.name} onChange={this.props.updateName} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Email:</Form.Label></Col>
                    <Col><Form.Control value={this.props.email} onChange={this.props.updateEmail} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Address:</Form.Label></Col>
                    <Col><Form.Control as="textarea" rows="5" value={this.props.address} onChange={this.props.updateAddress} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Note:</Form.Label></Col>
                    <Col><Form.Control value={this.props.note} onChange={this.props.updateNote} /></Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <Col md={3}><Form.Label>Phone:</Form.Label></Col>
                    <Col><Form.Control value={this.props.phone} onChange={this.props.updatePhone} /></Col>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={this.handleClose}>
                    Save Changes
                </Button>
                <Button variant="secondary" onClick={this.props.hideAddNotify}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
const addNotifyRowmapStateToProps = state => {
    return {
        chosen: state.notify.chosen,
        name: state.notify.name,
        address: state.notify.address,
        note: state.notify.note,
        email: state.notify.email,
        phone: state.notify.phone,
        show: state.notify.showAdd
    };
};

const addNotifyRowmapDispatchToProps = dispatch => ({
    updateName: (e) => dispatch(updateName(e)),
    updateEmail: (e) => dispatch(updateEmail(e)),
    updateAddress: (e) => dispatch(updateAddress(e)),
    updateNote: (e) => dispatch(updateNote(e)),
    updatePhone: (e) => dispatch(updatePhone(e)),
    clearCurrentNotify: () => dispatch(clearCurrentNotify()),
    hideAddNotify: () => dispatch(hideAddNotify()),
    setMessage: (m) => dispatch(setMessage(m))
});
const AddNotify = connect(addNotifyRowmapStateToProps, addNotifyRowmapDispatchToProps)(AddNotifyComponent);

class NotifyParty extends Component {
    constructor () {
        super();
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow () {
        this.setState({ ...this.state, showAddModal: !this.state.showAddModal });
    }

    componentDidMount () {
    // update the data, regardless of if there is something there
        fetchNotify();
    }

    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <div><PCANav /> <p>Loading...</p> </div>; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <Container>
                    <h2>Notify Party Address Book <Button onClick={this.props.showAddNotify} size="sm">Add Party</Button></h2>

                    <AddNotify />
                    <EditNotify />
                    <Container>
                        <Row>
                            <Col md={2}></Col>
                            <Col md={8}>
                                <Card>
                                    <Card.Header>Parties</Card.Header>
                                    <Card.Body>
                                        {this.props.parties.map(d => <NotifyRow key={d.notify_id} rowdata={d} />)}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={2}></Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        chosen: state.notify.chosen,
        parties: state.notify.parties,
        loggedIn: state.profile.profileLoaded,
        lastUpdate: state.notify.updateTime,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    showAddModal: (cID) => dispatch(setChosenNotify(cID)),
    showAddNotify: () => dispatch(showAddNotify())
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyParty);
