import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import moment from "moment-timezone";

import {
    Table
} from "react-bootstrap";

import {
    TranslateMake,
    TranslateModel,
    prettyDate
} from "../functions";

import { OdoHelp } from "../widgets/mouseovers";

class AuctionCarDetails extends Component {
    render () {
        if (this.props.loading) { return <p>Loading...</p>; }

        if (this.props.failure) { return <p>Could not load car details. Car could have been removed from auction.</p>; }

        // var info = parseAVTOInfoString(this.props.data.info);
        const info = null; // Aleado do not publish these, yet.
        const time_to_auction = moment.tz(this.props.data.auction_time, "Asia/Tokyo");

        const grade = null;
        /* if (info["Rate int"] !== null && info["Rate ext"] !== null) {
            grade = "Interior: " + info["Rate int"] + " Exterior: " + info["Rate ext"];
            // delete the rate stuff from the info object for display
            delete info["Rate int"];
            delete info["Rate ext"];
        } */
        // make a display string for Information
        const infoArray = [];
        for (const key in info) {
            if (key !== "Rate int" && key !== "Rate ext") { infoArray.push(key + ": " + info[key]); }
        }

        return (
            <Table striped bordered size="sm" hover>
                <tbody>
                    <tr><td><b>Starting bid</b></td><td>{this.props.data.start_price_num}</td></tr>
                    <tr><td><b>Final bid</b></td><td>{this.props.data.end_price}</td></tr>
                    <tr><td><b>Result</b></td><td>{this.props.data.result_en}</td></tr>
                    <tr><td><b>Grade</b></td><td>{this.props.data.grade} {grade}</td></tr>
                    <tr><td><b>Year</b></td><td>{this.props.data.year}</td></tr>
                    <tr><td><b>Make</b></td><td><TranslateMake make={this.props.data.make} /></td></tr>
                    <tr><td><b>Model</b></td><td><TranslateModel model={this.props.data.model} /></td></tr>
                    <tr><td><b>Chassis code:</b></td><td>{this.props.data.full_chassis_code}</td></tr>
                    <tr><td><b>Odometer reading</b></td><td>{this.props.data.mileage_numeric} <OdoHelp /></td></tr>
                    <tr><td><b>Trim</b></td><td>{this.props.data.trim}</td></tr>
                    <tr><td><b>Transmission</b></td><td>{this.props.data.transmission}</td></tr>
                    <tr><td><b>Displacement</b></td><td>{this.props.data.displacement}</td></tr>
                    <tr><td><b>Auctioned at</b></td><td>{this.props.data.auction_house}</td></tr>
                    <tr><td><b>Auction time</b></td><td>{prettyDate(this.props.data.auction_time)} JST, {time_to_auction.fromNow()}</td></tr>
                    <tr><td><b>Auction lot</b></td><td>{this.props.data.lot}</td></tr>
                    <tr><td><b>Colour</b></td><td>{this.props.data.colour}</td></tr>
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure
    };
};


export default connect(mapStateToProps)(AuctionCarDetails);
