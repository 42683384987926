import React, { Component } from "react";

import {
    ListGroupItem,
    Button
} from "react-bootstrap";

import { settings } from "../../settings";

export default class OutstandingRow extends Component {
    constructor () {
        super();

        this.state = { working: false };
        this.click = this.click.bind(this);
    }

    click (e) {
    // push it up
    // refresh
        e.stopPropagation();
        e.preventDefault();

        const url = settings.api_server + "/invoice/admin/purchase/" + this.props.data.purchase_id + "/" + this.props.data.invoice_id + "/remind";

        this.setState({ working: true });
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                this.props.refresh();
                this.setState({ working: false });
            }.bind(this));
    }

    render () {
        const desc = [];
        if (this.props.data.year !== null) {
            desc.push(this.props.data.year);
        }
        if (this.props.data.make !== null) {
            desc.push(this.props.data.make);
        }
        if (this.props.data.model !== null) {
            desc.push(this.props.data.model);
        }
        const text = desc.join(" ");
        // on click, send them to #/purchase_detail/fa4e3333-56fd-47d0-8a5b-bfb81eb88008
        return <ListGroupItem action href={"/#/purchase_detail/" + this.props.data.purchase_id}>
            {this.props.data.username} {text}
            <ul>
                <li>Due {this.props.data.due_on_date}</li>
                <li>Last notified {this.props.data.last_notified === null ? "never" : this.props.data.last_notified}</li>
                <li>Amount ¥{this.props.data.owing}</li>
            </ul>
            <div className="float-right">
                <Button disabled={this.state.working}
                    onClick={this.click}
                    variant="outline-danger">
                    Re-notify
                </Button>
            </div>
        </ListGroupItem>;
    }
}
