import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup,
    Card,
    FormControl,
    Button,
    Row, Col,
    Form, FormGroup
} from "react-bootstrap";

import { settings } from "../settings";
import AddUser from "../admin_views/AddUser";

class UserLookup extends Component {
    constructor () {
        super();
        this.state = { users: [], showNewUserModal: false, showEditUserModal: false, viewUser: null };

        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNewUserClose = this.handleNewUserClose.bind(this);
        this.showAddUser = this.showAddUser.bind(this);
        this.queryString = "";
    }

    handleNewUserClose () {
        this.setState({ ...this.state, showNewUserModal: false });
    }

    showAddUser () {
        this.setState({ ...this.state, showNewUserModal: true });
    }

    handleChange () {
    // TODO - put a timeout on this
        if (this.queryString.value.length > 0) { this.search(this.queryString.value); }
    }

    search (query) {
        const url = settings.api_server + "/user/getUsers/" + query;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const newState = this.state;
                newState.users = data;
                this.setState(newState);
            }.bind(this));
    }

    render () {
        const userData = this.state.users.map(x => {
            console.log(`${x.user_id}: ${x.username}`);
            return (
                <ListGroup.Item action href={"#/user_detail/" + x.user_id}
                    id={x.user_id} key={x.user_id}>
                    <Row>
                        <Col md={6}>
                            {x.username}
                        </Col>
                        <Col md={6}>
                            {x.firstname} {x.lastname}
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        });

        return (
            <Card id="collapsible-Card-example-2" >
                <Card.Header>
                    <Card.Title>
                        User lookup tool
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <FormGroup controlId="formHorizontalButton">
                            <Row>
                                <Col md={12}>
                                    <Button variant="outline-secondary" href='/#allUsers'>Show all users</Button>&nbsp;
                                    <Button variant="outline-secondary" onClick={this.showAddUser}>Add User</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup controlId="formHorzusername">
                            <Row>
                                <Col md={4}>Search for user:</Col>
                                <Col md={8}>
                                    <FormControl autoComplete="off" onChange={this.handleChange} ref={ref => { this.queryString = ref; }} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Card.Body>
                <ListGroup>
                    {userData}
                </ListGroup>

                <AddUser show={this.state.showNewUserModal} closeCallback={this.handleNewUserClose} />
            </Card>
        );
    }
}

const mapStateToProps = state => {
    state;
    return {
    };
};

export default connect(mapStateToProps)(UserLookup);
