/**
 * This file contains functions which check if a car purchase is at a current
 * stage. See documentation/purchase_states.md for the stages.
 */

import { settings } from "../settings";

/**
 * @typedef {Object} InvoiceObj this is incomplete but has the relevant fields
 * @property {string | null} due_on_date
 * @property {number} amount unpaid amount
 * @property {string} invoice_id
 * @property {boolean} purchase_complete
 * @property {boolean} released_to_client
 */

/**
 * @typedef {Object} PurchaseObj this is incomplete but has the relevant fields
 * @property {string} purchase_id
 * @property {string} vehicle_id
 * @property {InvoiceObj[]} invoices
 * @property {string | null} arrival_estimate
 * @property {string | null} date_of_purchase
 * @property {string | null} documents_sent
 * @property {string | null} export_date
 * @property {string | null} export_estimate
 * @property {string} make
 * @property {string} model
 * @property {string | null} ship_port
 * @property {number | null} quiz_required
 * @property {string | null} vessel_name
 * @property {number} year
 */

/**
 * After the car is purchased, a quiz may be immediately assigned which the
 * customer has to fill out, determining which services they want, how much
 * deposit to put towards the car, etc. This is the first stage in the
 * purchase/shipping process.
 * If a quiz is required, it's tracked in the backend by setting the
 * quiz_required field to the ID of the quiz template they need to complete.
 * @param {PurchaseObj} purchase
 */
export function quizRequired(purchase)
{
    return purchase.quiz_required !== null;
}

/**
 * After the purchase is made (and the customer fills out the quiz, if
 * applicable), an invoice is generated which may be modified before being
 * released to the client. This function returns true if the invoice for a
 * purchase hasn't been released yet.
 * Note that the backend will not return unreleased invoices if a non-admin is
 * using the site, so if the list of invoices is empty, that means we're a
 * non-admin and the invoice hasn't been released. We still check the
 * released_to_client flag in case the current user is an admin.
 * @param {PurchaseObj} purchase
 */
export function creatingInvoice(purchase)
{
    return purchase.invoices.length === 0 ||
        purchase.invoices.find(
            inv => !inv.released_to_client
        ) !== undefined;
}

/**
 * Return true if there is an invoice associated with the purchase which has
 * been released to the client, and hasn't been marked as completed. Payments
 * are required to be made before we can proceed with creating a vehicle report
 * (if requested by the customer) and shipping the car.
 * Non-admins don't have access to unreleased invoices, so checking that flag is
 * redundant unless the current user is an admin.
 * @param {PurchaseObj} purchase
 */
export function paymentRequired(purchase)
{
    return purchase.invoices.find(
        inv => !inv.purchase_complete && inv.released_to_client
    ) !== undefined;
}

/**
 * The export_date field is filled out when the export date is known. However,
 * this field can also contain "ASAP" or other text. Currently there isn't a
 * flag in the database that gets set when the car actually ships.
 * This function attempts to parse export_date as a date, and checks if the date
 * has passed. If the date is in the past, return false. If the date is in the
 * future, or it can't be parsed as a date, return true.
 * TODO: resolve issue #448, and update this function.
 * @param {PurchaseObj} purchase
 */
export function awaitingShipping(purchase)
{
    const asTime = Date.parse(purchase.export_date);
    const now = Date.now();
    // if we can't parse the date, or if it's in the future, consider the car
    // to be awaiting shipping
    if (isNaN(asTime) || now < asTime)
    {
        return true;
    }
    else
    {
        return false;
    }
}

/**
 * Return true if the documents_sent field in the purchase is not NULL (i.e. a
 * date was recorded for when the documents were sent)
 * @param {PurchaseObj} purchase
 */
export function documentsSent(purchase)
{
    return purchase.documents_sent !== null;
}

/**
 * Call the /purchaseDetail/myCars/reportStatus endpoint to determine if we are
 * awaiting a report. Returns a promise resolving to true/false, or a rejected
 * promise with an error message.
 * @param {string} purchase_id
 */
export async function awaitingReportAsync(purchase_id)
{
    const url = settings.api_server + "/purchaseDetail/myCars/reportStatus/"
        + purchase_id;
    const resp = await fetch(url, {
        credentials: "include"
    });
    const data = await (resp.ok ?
        resp.json()
        :
        Promise.reject(resp.status + resp.statusText)
    );
    return await (data.success ?
        Promise.resolve(data.wants_report && !data.report_uploaded)
        :
        Promise.reject(data.message)
    );
}
