import { settings } from "../settings";

const news_items = function (state = settings.news_items, action) {
    switch (action.type) {
    case "SET_NEWS":
        return {
            ...state,
            news_items: action.news_items
        };
    default:
        return state;
    }
};
export default news_items;
