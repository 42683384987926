import {settings} from "../settings";

const ACCT_MANAGER_ROLE = "ACCOUNT_MANAGER";

const fetchAcctManagers = function() {
    const url = settings.api_server + "/user/usersWithRole/" + ACCT_MANAGER_ROLE;
    fetch (url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(
            (response) => {
                if (response.ok)
                    return response.json();
                else
                    return Promise.reject(response.status + response.statusText);
            }
        )
        .then(
            (body) => {
                if (body["success"])
                    return Promise.resolve(setAcctManagers(body["users"]));
                else
                    return Promise.reject("Internal error, operation unsuccessful");
            }
        )
        .then(
            settings.store.dispatch,
            (reason) => {
                console.log("Failed to fetch account managers");
                console.log(reason);
                throw new Error(reason);
            }
        );
};

const setAcctManagers = function(acct_managers_list) {
    return {
        type: "SET_ACCT_MANAGER_LIST",
        acct_managers_list
    };
};

const clearAcctManagers = function() {
    return {
        type: "CLEAR_ACCT_MANAGER_LIST"
    };
};

export {
    fetchAcctManagers, setAcctManagers, clearAcctManagers
};
