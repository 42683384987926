import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Container, Row, Col,
    Button
} from "react-bootstrap";

class ImageCell extends Component {
    constructor (props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = { deleting: false };
    }

    delete () {
        this.props.deleteCB(this.props.uuid);
        this.setState({ ...this.state, deleting: true });
    }

    toggle () {
        this.props.toggleIsAuctionSheetCB(this.props.uuid);
    }

    render () {
    // make a Container of images
        const style = {
            maxWidth: "100%",
            maxHeight: "100%"
        };
        if (this.state.deleting) { return null; }

        return <Col xs={12} sm={6} md={4} lg={3}>
            <img style={style} alt="Auction car" src={this.props.src} />
            {this.props.deleteCB !== null &&
                <Button variant="outline-danger" size="sm" onClick={this.delete}>delete</Button>
            }

            {this.props.toggleIsAuctionSheetCB !== null && this.props.is_auction_sheet &&
                <Button variant="outline-warning" size="sm" onClick={this.toggle}>Unmark Auction sheet</Button>
            }
            {this.props.toggleIsAuctionSheetCB !== null && !this.props.is_auction_sheet &&
                <Button variant="outline-secondary" size="sm" onClick={this.toggle}>Mark as Auction sheet</Button>
            }
        </Col>;
    }
}
ImageCell.defaultProps = {
    is_auction_sheet: false
};

class ImageList extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        // Note: the key needs to be the UUID. If it is the index, it causes a
        // bug when deleting an image:
        // 1) ImageCell with key=x sets its state.deleting to true.
        // 2) ImageList is re-rendered after the image is deleted, causing the
        //    others to shift into its place. A new image is assigned key=x.
        // 3) The new ImageCell with key=x is given the same state object as
        //    the image which just got deleted, so its state.deleting is true.
        // The end result is one image actually gets deleted, and another one
        // hides when it should be shown. Using the UUID for the key fixes this.
        const rows = this.props.image_links.map(img => <ImageCell
            key={img.uuid}
            src={img.link}
            uuid={img.uuid}
            deleteCB={this.props.deleteCB}
            toggleIsAuctionSheetCB={this.props.toggleIsAuctionSheetCB}
            is_auction_sheet={img.is_auction_sheet ?? false}
        />);

        // Container fluid so it shrinks down into the Card
        return (
            <Container fluid>
                <Row><Col md={12}>{this.props.image_links.length} images</Col></Row>
                <Row>{rows}</Row>
            </Container>
        );
    }
}

ImageList.propTypes = {
    image_links: PropTypes.array.isRequired
};

ImageList.defaultProps = {
    deleteCB: null,
    toggleIsAuctionSheetCB: null
};

export default ImageList;
