import React, { Component } from "react";
import { connect } from "react-redux";

import { settings } from "../../settings";

import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import { showUpdate, setFetching, clearFetching, clearBidData } from "../../actions/user_bid_modal_actions";

import { setMessage } from "../../actions/alert_actions";

class CancelBidConfirmModal extends Component {
    constructor () {
        super();
        this.cancelBid = this.cancelBid.bind(this);
    }

    cancelBid () {
        const url = settings.api_server + "/bids/bid/" + this.props.id;

        this.props.setFetching();
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // refresh data
                if (data.success) {
                    this.props.setMessage("Bid deleted");
                    this.props.clearBidData();
                    this.props.fetchBid();
                } else {
                    this.props.clearFetching(data.message);
                    this.props.showUpdate();
                }
            }.bind(this));
    }

    render () {
        return (
            <Modal size='lg' show={this.props.show} onHide={this.props.clearBidData}>
                <Modal.Header>Bid on this car</Modal.Header>
                <Modal.Body>
                    {this.props.message !== null && <Alert variant="info">{this.props.message}</Alert>}

                Are you sure you want to cancel this bid?
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.props.fetching} onClick={this.cancelBid}>Yes, cancel</Button> <Button disabled={this.props.fetching} onClick={this.props.showUpdate}>Close without cancelling</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amount: state.user_bid_modal.amount,
        comment: state.user_bid_modal.comment,
        fetching: state.user_bid_modal.fetching,
        message: state.user_bid_modal.message,
        show: state.user_bid_modal.showConfirmCancel,
        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const mapDispatchToProps = dispatch => ({
    clearBidData: () => dispatch(clearBidData()), // for closing the modal
    showUpdate: () => dispatch(showUpdate()),
    setFetching: () => dispatch(setFetching()),
    clearFetching: (msg) => dispatch(clearFetching(msg)),
    setMessage: (msg) => dispatch(setMessage(msg))

});

export default connect(mapStateToProps, mapDispatchToProps)(CancelBidConfirmModal);
