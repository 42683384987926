import { settings } from "../settings";

const fetchNotify = function () {
    const url = settings.api_server + "/notify/";

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            settings.store.dispatch(setNotifyData(data));
        });
};

const setNotifyData = function (notifyData) {
    return {
        type: "SET_NOTIFY",
        parties: notifyData
    };
};

const setChosenNotify = function (notify_id) {
    return {
        type: "CHOSEN_NOTIFY",
        notify_id
    };
};

const setChosenNotifyFromEvent = function (event) {
    return {
        type: "CHOSEN_NOTIFY",
        notify_id: event.target.value
    };
};

const clearCurrentNotify = function () {
    return {
        type: "CLEAR_NOTIFY_CHOSEN"
    };
};

const updateName = function (event) {
    return {
        type: "NOTIFY_UPDATE_NAME",
        name: event.target.value
    };
};

const updateAddress = function (event) {
    return {
        type: "NOTIFY_UPDATE_ADDRESS",
        address: event.target.value
    };
};

const updateEmail = function (event) {
    return {
        type: "NOTIFY_UPDATE_EMAIL",
        email: event.target.value
    };
};

const updateNote = function (event) {
    return {
        type: "NOTIFY_UPDATE_NOTE",
        note: event.target.value
    };
};

const updatePhone = function (event) {
    return {
        type: "NOTIFY_UPDATE_PHONE",
        phone: event.target.value
    };
};

const showAddNotify = function () {
    return {
        type: "NOTIFY_SHOW_ADD"
    };
};

const hideAddNotify = function () {
    return {
        type: "NOTIFY_HIDE_ADD"
    };
};

const setNotifyDrowpdownFromId = function (theId) {
    return {
        type: "NOTIFY_DROPDOWN_SET",
        notify_id: theId
    };
};
const setNotifyDrowpdown = function (event) {
    return {
        type: "NOTIFY_DROPDOWN_SET",
        notify_id: event.target.value
    };
};

const clearNotifyDrowpdown = function () {
    return {
        type: "NOTIFY_DROPDOWN_CLEAR"
    };
};

export {
    fetchNotify,
    setChosenNotify,
    setChosenNotifyFromEvent,
    clearCurrentNotify,
    updateName,
    updateEmail,
    updateAddress,
    updateNote,
    updatePhone,
    showAddNotify,
    hideAddNotify,
    setNotifyDrowpdown,
    setNotifyDrowpdownFromId,
    clearNotifyDrowpdown
};
