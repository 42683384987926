import React, { Component } from "react";
import PropTypes from "prop-types";
import "./DashNav.css";


import {
    Nav
} from "react-bootstrap";

class DashNav extends Component {

    render() {

        return (
            <Nav id="DashNavContainer" className="justify-content-center dashNav" activeKey={this.props.which}>
                <Nav.Link href="#/BidManager">Bid<br />Manager</Nav.Link>

                <Nav.Link href="#/DepositBalance">Deposit<br />Balance</Nav.Link>
                <Nav.Link href="#/AccountManager">Account<br />Manager</Nav.Link>
                <Nav.Link href="#/taskManager">Task<br />Manager</Nav.Link>
                <Nav.Link href="#/profile">Account<br />Settings</Nav.Link>
                <Nav.Link href="#/MyCars">My<br />Cars</Nav.Link>
                <Nav.Link href="#/MyStats">My<br />Stats</Nav.Link>
            </Nav>
        );
    }
}
DashNav.propTypes = {
    which: PropTypes.string
};

export default DashNav;