
import { settings } from "../settings";

const default_line_items = (state = settings.default_line_items_defaultState, action) => {
    switch (action.type) {
    case "SET_DEFAULT_LINE_ITEMS":
        return {
            ...state,
            loaded: true,
            line_items: action.items
        };
    default:
        return state;
    }
};

export default default_line_items;
