import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";

import {
    Container, Card
} from "react-bootstrap";

import { setBidList } from "./actions/profile_actions";
import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";
import InvoiceLedger from "./ledger_views/invoiceLedger";

class AccountManager extends Component {
    render() {
        return <Container className="wideContainer">
            <PCANav currentPage="#/dashboard" />
            <DashNav  which="#/AccountManager"/>
            <Container>
                <div className="whiteTitle">
                    Account Manager
                </div>
                <Container className="whiteBackground dropShadow wideContainer"
                    style={{"paddingTop": "10px", "paddingBottom": "10px"}}
                >
                    <Container className="blueBorderContainer" fluid
                        style={{padding: 0, marginBottom: "10px"}}
                    >
                        <Card.Header className="italicTitle">
                            Invoice Account Ledger
                        </Card.Header>
                        <InvoiceLedger who={-1}/>
                    </Container>
                </Container>
            </Container>
        </Container>;
    }
}

// These are here to shut up the linter.
const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountManager);
