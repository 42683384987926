
export const search_results_defaultState = {
    loaded: false,
    count: -1,
    lastupdate: 0,
    countFetching: false,
    showResults: false,
    errorText: null
}; // numeric pages

const results = (state = search_results_defaultState, action) => {
    switch (action.type) {
    // a dict of pages
    // numeric keys for the pages
    case "SHOW_RESULTS":
        return {
            ...state,
            showResults: true
        };
    case "SHOW_RESULTS_ERROR":
        return {
            ...state,
            errorText: action.errorText,
            loaded: true
        };
    case "SET_RESULTS":
        var out = {
            ...state,
            lastupdate: (new Date()).getTime(), // poke it..
            loaded: true,
            showResults: true
        };
        out[action.page] = action.results;
        return out;
    case "FETCHING_COUNT":
        return {
            ...state,
            countFetching: true
        };
    case "FORCE_SHOW":
        return {
            ...state,
            loaded: true
        };
    case "SET_COUNT":
        return {
            ...state,
            count: action.count,
            countFetching: false
        };
    case "CLEAR_RESULTS":
        return {
            ...search_results_defaultState
        };
    case "CLEAR_PAGES":
        return {
            ...search_results_defaultState, // basically reset. Allowlist for the rest
            count: state.count, // keep the number of pages, we're only clearing the contents
            loaded: state.loaded,
            showResults: true // keep showing the results
        };
    default:
        return state;
    }
};

export default results;
