const acct_managers_default_state = {
    acct_managers_list: [],
    loaded: false
};

const acct_managers = (state = acct_managers_default_state, action) => {
    switch (action.type) {
    case "SET_ACCT_MANAGER_LIST":
        console.log("Setting account managers.");
        console.log(action);
        return {
            ...state,
            acct_managers_list: action.acct_managers_list,
            loaded: true
        };
    case "CLEAR_ACCT_MANAGER_LIST":
        return {
            ...acct_managers_default_state
        };
    default:
        return state;
    }
};

export default acct_managers;
