import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import moment from "moment-timezone";

import {
    Button
} from "react-bootstrap";

import {
    setTranslation
} from "../../actions/auction_car_actions";

import { settings } from "../../settings";

class Translation extends Component {
    constructor () {
        super();
        this.fetchTranslation = this.fetchTranslation.bind(this);
        this.requestTranslation = this.requestTranslation.bind(this);
    }

    requestTranslation () {
        const url = settings.api_server + "/translations/access/" + this.props.id;

        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // refresh data
                this.fetchTranslation();
            }.bind(this));
    }

    fetchTranslation () {
        const url = settings.api_server + "/translations/access/" + this.props.id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a translation data
                this.props.setTranslation(data.found, data, data.translation_completed);
            }.bind(this));
    }

    componentDidMount () {
    /*
        Fetch if the user has translations for this car
        */
        this.fetchTranslation();
    }

    render () {
        if (!this.props.loaded || !this.props.dataLoaded || !this.props.userDataLoaded) { return <p>Loading translation info...</p>; }

        const time_to_auction = moment.tz(this.props.carData.auction_time, "Asia/Tokyo");
        let left = ", " + this.props.translation_requests_available + " left";
        if (this.props.translation_requests_available < 0) { left = null; } // don't show if admin or VIP (or other, controlled by back end)
        if (!this.props.requested_translation) {
            // can the user request a translation?
            if (!this.props.can_request_translation) { return <p>Insufficient translation requests</p>; }
            if (time_to_auction.isBefore()) // has this time passed?
            { return <p>Auction has passed, can not request translation</p>; }
            return <p><Button block onClick={this.requestTranslation}>Request translation{left}</Button></p>;
        }

        if (this.props.translation !== null && !this.props.translation_completed)
        // requested, not yet filled
        { return <p>Translation requested and pending</p>; }

        return null; // show nothing if translation filled
    }
}
const translationState = state => {
    return {
        loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation_completed: state.auction_car.translation_completed,
        translation: state.auction_car.translation,

        dataLoaded: !state.auction_car.loading, // auction car data for auction time
        carData: state.auction_car.data,
        translation_requests_available: state.profile.translation_requests_available,
        can_request_translation: state.profile.can_request_translation,

        userDataLoaded: state.profile.profileLoaded, // user data for bid check stuff
        can_bid: state.profile.can_bid

    };
};

const translationDispatch = dispatch => ({
    setTranslation: (req, trans, com) => dispatch(setTranslation(req, trans, com))
});

export default connect(translationState, translationDispatch)(Translation);
