
import { settings } from "../settings";

const purchases = (state = settings.purchasesDefaultState, action) => {
    switch (action.type) {
    case "SET_OPEN_PURCHASES_LIST":
        return {
            ...state,
            purchaseList: action.purchaseList,
            purchasesLoaded: true
        };
    default:
        return state;
    }
};

export default purchases;
