import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Image, Spinner} from "react-bootstrap";
import {settings} from "../settings";
import {isToday} from "../functions";
import deleteBtn from "../images/delete.png";
import checkBtn from "../images/check.png";

import formatMoney from "../widgets/commaNumbers";
import moment from "moment-timezone";

// Required props: watch object (watch), function to send car's time (setTime)
// function to trigger table updating (forceUpdate)
class TaskRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCar: true,
            carFail: false,
            car: null,
            deleteDialog: false
        };

        const data = this.props.watch;
        this.watch = data["watch_info"];
        this.bid = data["bid"];
        this.imageReq = data["image_request"];
        this.question = data["question"];
        this.translation = data["translation_info"];

        this.deleteClicked = this.deleteClicked.bind(this);
        this.deleteCancelled = this.deleteCancelled.bind(this);
        this.deleteWatch = this.deleteWatch.bind(this);
    }

    componentDidMount() {
        // get car info
        const url = settings.api_server + "/search/auction_car/" + this.watch["vehicle_id"];
        fetch (url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function(response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function(data) {
                if (data.length > 0) {
                    this.setState({...this.state, loadingCar: false, carFail: false, car: data[0]});
                    this.props.setTime(data[0]["vehicle_id"], data[0]["auction_time"]);
                }
                else {
                    this.setState({...this.state, loadingCar: false, carFail: true, car: null});
                }
            }.bind(this));
    }

    // User has clicked "X" to delete
    deleteClicked() {
        this.setState({...this.state, deleteDialog: true});
    }

    // User has clicked "no" on "are you sure?"
    deleteCancelled() {
        this.setState({...this.state, deleteDialog: false});
    }

    deleteWatch() {
        const url = settings.api_server + "/watch/" + this.watch["vehicle_id"];
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function(response) {
                if (response.status >= 400) {
                    throw new Error("There was an error deleting this car (" +
                        response.status + " " + response.statusText + ")");
                }
            })
            .then(function() {
                this.props.forceUpdate();
            }.bind(this));
    }

    carLink(element) {
        return <Link target="_blank" className="nostyle" rel="noreferrer"
            to={"/auction_car/" + this.watch["vehicle_id"]}>{element}</Link>;
    }

    // Return an error message if car was not fetched, otherwise return the
    // first three columns
    getAuctionAndModelYear() {
        if (this.state.carFail) {
            // make error messages red
            return <td colSpan="3" className="auctionPassed">
                Failed to load car information.</td>;
        }
        else {
            return <>
                <td>{this.carLink(this.state.car["auction_house"])}</td>
                <td>{this.carLink(this.state.car["lot"])}</td>
                <td>{this.carLink(this.state.car["year"])}</td>
            </>;
        }
    }

    // Return the watch's cached car details if car was not fetched, otherwise
    // return the car name
    getCarName() {
        if (this.state.carFail) {
            return <td>{this.watch["car_details"]}</td>;
        }
        else {
            return <td>
                {this.carLink(this.state.car["make"] + " " + this.state.car["model"])}
            </td>;
        }
    }

    // Return one image of the car if available
    getImage() {
        if (this.state.carFail) {
            return <td></td>;
        }

        const images = this.state.car["images"].split("#");

        if (images.length <= 0) {
            return <td></td>;
        }
        else {
            // return the second image -- first is typically the auction sheet
            // if only one image, then use that
            const index = Math.min(1, images.length - 1);
            return <td>{this.carLink(
                <Image style={{height: "80px"}}
                    key={images[index]} src={images[index]}/>
            )}</td>;
        }
    }

    // Return the status of an image request
    getImageRequest() {
        if (this.imageReq == null) {
            return <td>-</td>;
        }
        else if (this.imageReq["completed"]) {
            return <td className="auctionOK">{this.carLink("Completed")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of a bid request
    getBidRequest() {
        if (this.bid === null) {
            return <td>-</td>;
        }
        else if (this.bid["confirmed"]) {
            return <td className="auctionOK">{this.carLink("Confirmed")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of a translation request
    getTranslationRequest() {
        if (this.translation === null) {
            return <td>-</td>;
        }
        else if (this.translation["translation_completed"]) {
            return <td className="auctionOK">{this.carLink("Done")}</td>;
        }
        else {
            return <td>{this.carLink("Pending")}</td>;
        }
    }

    // Return the status of the questions the user has asked
    getQuestions() {
        if (this.question === null) {
            return <td>-</td>;
        }
        else if (!this.question["replied"]) {
            return <td>{this.carLink("Pending")}</td>;
        }
        else if (this.question["read"]) {
            return <td>{this.carLink("View thread")}</td>;
        }
        else {
            return <td className="auctionOK">{this.carLink("New")}</td>;
        }
    }

    // Return whether a car was sold or unsold at auction, or if it was won by
    // the user (which should be highlighted green)
    getSoldUnsold() {
        // only fill this out if user bid on car and car auction is finished
        if (this.bid == null) {
            return <td>-</td>;
        }
        else if (this.bid["is_open"]) {
            return <td></td>;
        }
        else if (this.bid["won"] === true) {
            return <td className="auctionOK">{this.carLink("Won")}</td>;
        }
        else if (this.bid["sold"] === false) {
            return <td>{this.carLink("Unsold")}</td>;
        }
        else {
            return <td>{this.carLink("Sold")}</td>;
        }
    }

    // Return the highest bid for the car
    getResultPrice() {
        if (this.bid == null) {
            return <td>-</td>;
        }
        else {
            return <td>{this.carLink(formatMoney(this.bid["sold_for"]))}</td>;
        }
    }

    // Return absolute and relative time of the auction
    // Mostly taken from src/avto-components/result_views/result_row.js
    getAuctionTime() {
        // Auction time should be from car if possible so it's the most recent
        const auction_time = this.state.carFail ?
            this.watch["auction_time"] : this.state.car["auction_time"];
        
        if (auction_time == null) {
            return <td>-</td>;
        }

        const time_to_auction = moment.tz(auction_time, "Asia/Tokyo");

        // if time of day is midnight, assume only day is set
        if (time_to_auction.hour() === 0) {
            // if time of day not set and auction is today, mark it
            if (isToday(time_to_auction)) {
                return <td className="auctionTimeNotSet">{this.carLink(auction_time.substring(0, 10))}</td>;
            }
            // if it's not today, display time to auction as "in x days"
            else {
                const now = moment.tz("Asia/Tokyo");
                now.set({h: 0, m: 0});
                return <td>{time_to_auction.from(now)}<br/>{this.carLink(auction_time.substring(0, 10))}</td>;
            }
        }
        // if auction is temporarily marked as far in the future, display
        // "not set"
        if (time_to_auction.isAfter(moment().add(7, "days"))) {
            return <td>{this.carLink("Date not set")}</td>;
        }
        
        // if displaying full auction time and time_to, base color on proximity
        let color = "auctionOK";
        if (time_to_auction.isBefore()) {
            color = "auctionPassed";
        }
        else if (time_to_auction.isBefore(moment().add(1, "hour"))) {
            color = "auctionNear";
        }
        const content = <>{time_to_auction.fromNow()}<br/>{auction_time} JST</>;
        return <td className={color}>{this.carLink(content)}</td>;
    }

    getDeleteButton() {
        return <td style={{padding: "0px"}}><button onClick={this.deleteClicked}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={deleteBtn} style={{height: "2em"}}/>
        </button></td>;
    }

    getCancelButton() {
        return <button onClick={this.deleteCancelled}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={deleteBtn} style={{height: "2em"}}/>
        </button>;
    }

    getConfirmButton() {
        return <td style={{padding: "0px"}}><button onClick={this.deleteWatch}
            style={{margin: "0px", border: "0px", padding: "0px", backgroundColor: "rgba(0, 0, 0, 0)"}}>
            <Image src={checkBtn} style={{height: "2em"}}/>
        </button></td>;
    }

    render() {
        if (this.state.loadingCar) {
            return <tr id={this.watch["watch_id"]} style={{height: "85px"}}>
                <td colSpan="13">Loading... <Spinner variant="primary" animation="border" role="status"/></td>
            </tr>;
        }
        else if (this.state.deleteDialog) {
            return <tr id={this.watch["watch_id"]} style={{height: "85px"}}>
                {this.getAuctionAndModelYear()}
                {this.getCarName()}
                {this.getImage()}
                <td colSpan="8"><span style={{float: "right"}}>
                    Are you sure?&nbsp;{this.getCancelButton()}
                </span></td>
                {this.getConfirmButton()}
            </tr>;
        }

        return (
            <tr id={this.watch["watch_id"]} style={{cursor: "pointer", height: "85px"}}>
                {/* Auction */}
                {/* Auction Number */}
                {/* Year */}
                {this.getAuctionAndModelYear()}

                {/* Car */}
                {this.getCarName()}

                {/* Image */}
                {this.getImage()}

                {/* Image Request */}
                {this.getImageRequest()}
                
                {/* Translation Request */}
                {this.getTranslationRequest()}

                {/* Bid Request */}
                {this.getBidRequest()}

                {/* Questions */}
                {this.getQuestions()}

                {/* Bid Amount */}
                <td>{this.bid === null ? "-" : formatMoney(this.bid["amount"])}</td>

                {/* Result */}
                {this.getResultPrice()}

                {/* Sold / Unsold */}
                {this.getSoldUnsold()}

                {/* Auction Time */}
                {this.getAuctionTime()}

                {/* Delete */}
                {this.getDeleteButton()}
            </tr>
        );
    }
}

export default TaskRow;
