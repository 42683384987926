import React, { Component } from "react";
import "../App.css";

import { connect } from "react-redux";


import {
    ListGroup,
    Button,
} from "react-bootstrap";

import {
    showPasswordChange,
    toggleAddressChange
} from "../actions/profile_actions";



class ProfileShort extends Component {

    /*
   
    */
    render() {
        return (


            <ListGroup variant="flush">
                <ListGroup.Item><b>First name:</b> {this.props.firstname}</ListGroup.Item>
                <ListGroup.Item><b>Last name:</b> {this.props.lastname}</ListGroup.Item>
                <ListGroup.Item><b>Joined:</b> {this.props.profile.joined_on}</ListGroup.Item>
                <ListGroup.Item><b>Balance:</b> {this.props.balance}</ListGroup.Item>
                <ListGroup.Item><b>Translation requests:</b> {this.props.profile.translation_requests_available < 0 ? "Unlimited" : this.props.profile.translation_requests_available}</ListGroup.Item>
                <ListGroup.Item><b>Bids available:</b> {this.props.profile.bids_available < 0 ? "Unlimited" : this.props.profile.bids_available}</ListGroup.Item>
                <ListGroup.Item className="whitespace"><b>Address:</b>&nbsp;<Button variant="outline-secondary" size="sm" onClick={this.props.toggleAddressChange}>Update Address</Button><br /> {this.props.profile.address} </ListGroup.Item>
                <ListGroup.Item><b>Country:</b> {this.props.profile.ship_to_country}</ListGroup.Item>
                <ListGroup.Item><b>Phone Number:</b> {this.props.profile.phone_number}</ListGroup.Item>
            </ListGroup>

        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.profile.profileLoaded,
        username: state.profile.username,
        firstname: state.profile.firstname,
        lastname: state.profile.lastname,
        isAdmin: state.profile.is_admin,
        balance: state.profile.balance,
        profile: state.profile
    };
};

const mapDispatchToProps = dispatch => ({
    showPasswordChange: () => dispatch(showPasswordChange()),
    toggleAddressChange: () => dispatch(toggleAddressChange())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileShort);
