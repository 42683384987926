import React from "react";
import {Container} from "react-bootstrap";

import FounderOnly from "../dashboard-components/FounderOnly";
import PCANav from "../dashboard-components/navbar";
import StaffSales from "../admin_views/reports/sales/StaffSales";

/**
 * This page is only accessible by users with the FOUNDER role, and shows a
 * report of all staff sales
 */
export default function ReportsPage()
{
    return <div>
        <FounderOnly/>
        <PCANav/>
        <Container className="wideContainer">
            <Container
                className="whiteTitle"
                style={{maxWidth: "1400px", padding: "0px", marginBottom: "5px"}}
            >
                Reports
            </Container>
            <Container
                className="dropShadow cardSpace whiteBackground"
                style={{maxWidth: "1400px"}}
            >
                <StaffSales/>
            </Container>
        </Container>
    </div>;
}
