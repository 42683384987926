import React from "react";
import { settings } from "../../settings";
import { Button, Card, Table } from "react-bootstrap";
import FileDrop from "react-file-drop";
import Octicon, { Alert, Check } from "@githubprimer/octicons-react";
import "../../App.css";
import "./DocUploader.css";

import DocumentRow from "./DocumentRow";

/**
 * Displays the list of documents uploaded for the purchase. Allows uploading
 * new documents by dragging them onto the component, and modifying existing
 * documents (such as their description, flags, or deleting them).
 * @param {Object} props
 * @param {string} props.purchase_id
 */
export default function DocUploader({purchase_id}) {
    // true if we need to fetch the list of documents
    const [needsRefresh, setNeedsRefresh] = React.useState(true);
    const [documents, setDocuments] = React.useState(null);
    const [error, setError] = React.useState(null);
    // if we switch purchases, we need to refresh
    React.useEffect(() => {setNeedsRefresh(true);}, [purchase_id]);

    // fetch documents
    if (needsRefresh) {
        const url = `${settings.api_server}/documents/purchase/${purchase_id}`;
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                data => {
                    setNeedsRefresh(false);
                    setDocuments(data.documents);
                    setError(null);
                },
                reason => {
                    setNeedsRefresh(false);
                    setDocuments(null);
                    setError(reason);
                }
            );
    }

    /**
     * Return a promise which will be resolved when the given file has been
     * read and uploaded.
     * @param {File} file 
     */
    function uploadDoc(file) {
        return new Promise((uploadDocResolve, uploadDocReject) => {
            const reader = new FileReader();
            // when the file's done loading, convert to b64 and send to server
            reader.onload = () => {
                // default file description: strip file extension
                const desc = file.name.toLowerCase().endsWith(".pdf") ?
                    file.name.slice(0, -4) : file.name;
                // we'll read it as an ArrayBuffer, so to convert it to a binary
                // string we'll view it as a Uint8Array and covert bytes to
                // characters
                const reqBody = {
                    file_data: window.btoa(new Uint8Array(reader.result).reduce(
                        (prev, curr) => prev + String.fromCharCode(curr),
                        "" // empty string as initialValue param of reduce()
                    )),
                    file_name: file.name,
                    desc,
                    mimetype: "application/pdf",
                    is_vehicle_report: false
                };

                const url = `${settings.api_server}/documents/purchase/${purchase_id}`;
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(reqBody),
                    credentials: "include",
                    headers: {
                        "content-type": "application/json"
                    }
                })
                    .then(resp => resp.ok ?
                        resp.json()
                        :
                        Promise.reject(resp.status + " " + resp.statusText)
                    )
                    .then(data => data.success ?
                        Promise.resolve(data.url)
                        :
                        Promise.reject(data.message)
                    )
                    .then(uploadDocResolve, uploadDocReject);
            };
            reader.onerror = () => {
                uploadDocReject(reader.error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    /**
     * upload all documents dragged onto the FileDrop, update when done
     * @param {FileList} files 
     */
    function handleDrop(files) {
        const fileArray = [];
        for (const f of files) {
            fileArray.push(f);
        }
        // upload each of them, log any errors, then refresh the list
        Promise.allSettled(fileArray.map(uploadDoc))
            .then(
                results => {
                    for (const r of results) {
                        if (r.status === "rejected") {
                            console.log("Error uploading document: " + r.reason);
                        }
                    }
                },
                reason => {
                    console.log("Error handling file drop: " + reason);
                }
            )
            .finally(() => setNeedsRefresh(true));
    }

    let bodyContents;
    const reportFlags = []; // to trigger updates in ReportStatus
    if (error !== null) {
        bodyContents = <tr><td colSpan="4">
            Error fetching documents: {error}
        </td></tr>;
    }
    else if (documents === null) {
        bodyContents = <tr><td colSpan="4">
            Loading...
        </td></tr>;
    }
    else if (documents.length <= 0) {
        bodyContents = <tr><td colSpan="4">
            No documents have been uploaded
        </td></tr>;
    }
    else {
        bodyContents = documents.map(doc => <DocumentRow
            doc={doc}
            refreshCallback={setNeedsRefresh}
            key={doc.url}
        />);
        reportFlags.push(...documents.map(doc => doc.is_vehicle_report));
    }

    return <FileDrop className="docUploader" onDrop={handleDrop}>
        <Card>
            <Card.Header>
                <b>Documents</b> (drag and drop documents here to upload)
                <ReportStatus purchase_id={purchase_id}
                    reportFlags={reportFlags}
                />
            </Card.Header>
            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th className="timeCol">Uploaded</th>
                        <th className="flagsCol">Flags</th>
                        <th className="optionsCol">Options</th>
                    </tr>
                </thead>
                <tbody>
                    {bodyContents}
                </tbody>
            </Table>
        </Card>
    </FileDrop>;
}

/**
 * Display the vehicle report status for this purchase. If one has not been
 * requested, include an option to request one on behalf of the customer. If one
 * was requested but hasn't been completed, include a button to cancel it.
 * @param {any} props
 * @param {boolean[]} reportFlags used to trigger updates when new documents are
 * uploaded or a document's report flag is toggled
 */
function ReportStatus({purchase_id, reportFlags})
{
    const [wantsReport, setWantsReport] = React.useState(null);
    const [fulfilled, setFulfilled] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    // if true, show an "are you sure?" prompt for cancelling the report
    const [cancelling, setCancelling] = React.useState(false);

    const reportURL = settings.api_server
        + "/purchaseDetail/myCars/reportStatus/" + purchase_id;

    // Only call setLoading if we are fetching the status for a new purchase.
    // If only the flags changed, don't call setLoading because it's distracting
    // for the box to flicker when toggling the 'Vehicle Report' button.
    // (n.b. the loading variable only affects how this component is displayed)
    React.useEffect(() => setLoading(true), [purchase_id]);

    // Fetch the status for a new purchase, or re-fetch when the vehicle report
    // flags change.
    React.useEffect(() => {
        setCancelling(false);
        fetch(reportURL, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => {
                if (!data.success)
                    return Promise.reject(data.message);
                setWantsReport(data.wants_report);
                setFulfilled(data.report_uploaded);
                setError(null);
            })
            .catch(reason => {
                setWantsReport(null);
                setFulfilled(null);
                setError(reason);
            })
            .finally(() => setLoading(false));
    }, [purchase_id, reportFlags]);

    const toggleReport = (wantsReport) => {
        fetch(reportURL, {
            method: wantsReport ? "PUT" : "DELETE",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve(wantsReport)
                :
                Promise.reject(data.message)
            )
            .then(setWantsReport, setError);
    };

    let content = null;
    if (!loading)
    {
        // Display fetch error.
        if (error !== null)
        {
            content = <span style={{color: "red"}}>
                <Octicon icon={Alert}/>{" "}
                An error occurred while checking the status of this purchase's
                vehicle report: {error}
            </span>;
        }
        // Display "are you sure?" prompt for cancelling a vehicle report.
        else if (cancelling)
        {
            content = <span style={{color: "gray"}}>
                Are you sure?{" "}
                <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {toggleReport(false); setCancelling(false);}}
                >
                    Yes
                </Button>
                <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => setCancelling(false)}
                >
                    No
                </Button>
            </span>;
        }
        // Display the fulfillment status of the report. If unfulfilled, show a
        // button to cancel the report.
        else if (wantsReport)
        {
            if (fulfilled)
            {
                content = <span style={{color: "green"}}>
                    <Octicon icon={Check}/>{" "}
                    Vehicle report complete
                </span>;
            }
            else
            {
                content = <span style={{color: "#DB0"}}>
                    <Octicon icon={Alert}/>{" "}
                    Vehicle report requested{" "}
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => setCancelling(true)}
                    >
                        Cancel Report
                    </Button>
                </span>;
            }
        }
        // Show that no report was requested, show a button to request one.
        else
        {
            content = <span>
                No vehicle report has been requested{" "}
                <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => toggleReport(true)}
                >
                    Request Report
                </Button>
            </span>;
        }
    }

    return <div
        style={{
            width: "fit-content",
            marginTop: "5px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "5px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "5px"
        }}
    >
        {content}
    </div>;
}
