import React, { Component } from "react";
import "../App.css";

import {
    ListGroup,
    FormControl,
    Row,
    Col,
    FormGroup,
    Container
} from "react-bootstrap";
import { settings } from "../settings";

class LineItems extends Component {
    render () {
    // filter down on the query term
        const result = this.props.data.filter(invoice_data => invoice_data.invoice_id.startsWith(this.props.filter) || invoice_data.who.toLowerCase().includes(this.props.filter.toLowerCase()));
        const lines = result.slice(0, 5).map(
            x => <ListGroup.Item action href={"#/editPurchase/" + x.purchase_id} id={x.invoice_id} key={x.invoice_id}>
                <Row>
                    <Col md={6}>{x.who} </Col>
                    <Col md={6}>{x.invoice_id}</Col>
                </Row>
            </ListGroup.Item>
        );
        return lines;
    }
}

class InvoiceLookup extends Component {
    constructor () {
        super();
        this.state = {
            invoices: [],
            loaded: false,
            loading: false,
            invoice_id: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.getData = this.getData.bind(this);
    }

    getData () {
        const url = settings.api_server + "/invoice/admin/all";

        if (!this.state.loaded && !this.state.loading) {
            this.setState({ ...this.state, loading: true });
            fetch(url, {
                credentials: "include",
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        loaded: true,
                        invoices: data // returns an array of ids
                    });
                }.bind(this));
        }
    }

    handleChange (e) {
    // load data, if we don't have it
        this.getData();
        this.setState(
            {
                ...this.state,
                invoice_id: e.target.value
            }
        );
    }

    render () {
        return <div>
            <Container>
                <FormGroup controlId="formHorizontalButton">
                    <Row>
                        <Col md={4}>Search for invoice:</Col>
                        <Col md={8}>
                            <FormControl autoComplete="off" onChange={this.handleChange} value={this.state.invoice_id} />
                        </Col>
                    </Row>
                </FormGroup>
            </Container>
            <ListGroup>
                <LineItems data={this.state.invoices} filter={this.state.invoice_id} />
            </ListGroup>
        </div>;
    }
}

export default InvoiceLookup;
