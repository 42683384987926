import React, { Component } from "react";

import { settings } from "../settings";


import {
    Alert, Col,
    Container, Row, Form,
    Button
} from "react-bootstrap";
import * as Datetime from "react-datetime";

import AlertFilter from "./alertToolViews/AlertFilter";
import { Link } from "react-router-dom";

class AlertTool extends Component {
    constructor() {
        super();
        this.send = this.send.bind(this);
        this.state = {
            "message": "",
            "clear_at": null,
            "to_user": "",
            "pushing": false,
            "errorMessage": null,
            "successMessage": null,
            "filters": [],
            "filterSettings": []
        };
        this.changeFilter = this.changeFilter.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.stickyUntil = null; // refers to the Datetime used to set clear_at
    }
    
    addFilter(){
        this.setState(
            {
                ...this.state,
                "filters": [...this.state.filters, <AlertFilter key={"filter" + this.state.filters.length} which={this.state.filters.length} changeFilter={this.changeFilter} />],
                "filterSettings": [...this.state.filterSettings, ""] // add a string that the new filter can fill
            }
        );
    }
    changeFilter(whichID, value){
        let newSettings = [...this.state.filterSettings];
        newSettings[whichID] = value;
        this.setState({...this.state, filterSettings: newSettings} );
    }

    send() {
        // if the date is an empty string, replace it with null
        const clearAt = this.stickyUntil.state.inputValue ?
            this.stickyUntil.state.inputValue : null;

        this.setState({
            ...this.state,
            pushing: true,
            clear_at: clearAt,
            successMessage: null
        });

        // update the search parameters
        const url = settings.api_server + "/alerts/new";
        // be explicit about building the post
        const postData = {
            "message": this.state.message,
            "clear_at": clearAt,
            "filters": this.state.filterSettings
        };


        fetch(url, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json",
            },
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(
                function (data) {
                    if (data.success) {
                        // clear the form
                        this.setState({
                            ...this.state,
                            pushing: false,
                            message: "",
                            errorMessage: null,
                            successMessage: "Your alert has been posted",
                            clear_at: null,
                            to_user: "",
                            filters: [],
                            filterSettings: []
                        });
                    } else {
                        // show a message
                        this.setState({
                            ...this.state,
                            pushing: false,
                            errorMessage: data.errorMessage,
                        });
                    }
                }.bind(this)
            );
    }


    render() {
        let filterList = <Col>Sending to all users</Col>;

        if (this.state.filters.length > 0) {
            filterList = this.state.filters;
        }

        return (
            <Container className="whiteBackground dropShadow" fluid>
                <span className="greyText text-left italicTitle">Alert Tool <Link to={"/AllAlerts"}>See all</Link></span>
                <Row>
                    <Col md={6}>
                        <Form.Group >
                            <Form.Label>Sticky until {this.state.is_global ? <span>(Required)</span> : <span>(Leave blank for not sticky)</span>}</Form.Label>
                            <Datetime
                                closeOnSelect
                                value={this.state.clear_at}
                                dateFormat="YYYY-MM-DD"
                                disabled={this.state.pushing}
                                timeFormat={false}
                                ref={ref => this.stickyUntil = ref}/>

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={this.state.message}
                            onChange={(e) => this.setState({ ...this.state, message: e.target.value })}
                            placeholder="Message to the users"
                        />
                        <div className="float-right">
                            {this.state.successMessage} &nbsp; 
                            <Button variant="secondary" onClick={this.send} disabled={this.state.pushing}>Send</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {filterList}
                </Row>
                <Row>
                    <Col md={12}>
                        <Container>
                            Add a filter
                            <Button
                                onClick={this.addFilter}
                            >+</Button>
                        </Container>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.errorMessage !== null && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default AlertTool;
