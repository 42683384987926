import { settings } from "../settings";
import { encodeQueryString } from "../functions";

const fetchM3 = function () {
    const url = settings.api_server + "/m3/ports";

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            settings.store.dispatch(setM3Data(data));
        });
};

const fetchM3Calc = function (port, height, width, length) {
    const url = settings.api_server + "/m3/estimate";

    const data = { port, height, width, length };
    const queryString = encodeQueryString(data);
    fetch(url + queryString, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            if (data.success) { settings.store.dispatch(setChosenPort(data.m3, data.ocean_freight_usd, data.total, null)); } else { settings.store.dispatch(setChosenPort(null, null, null, data.message)); }
        });
};

const setM3Data = function (m3Data) {
    return {
        type: "SET_M3",
        m3Data
    };
};

const setChosenPort = function (m3_cost, ocean_freight_usd, total, message) {
    return {
        type: "CHOSEN_PORT",
        m3_cost,
        ocean_freight_usd,
        total,
        message
    };
};

const clearCurrentM3 = function () {
    return {
        type: "CLEAR_CHOSEN"
    };
};

const updatePortData = function (port_name, field, value) {
    return {
        type: "UPDATE_M3_PORT_DATA",
        port_name,
        field,
        value
    };
};

const removePort = function (port_name) {
    return {
        type: "REMOVE_PORT",
        port_name
    };
};

const updateShippingFee = function (fee_id, field, value) {
    return {
        type: "UPDATE_M3_SHIPPING_DATA",
        fee_id,
        field,
        value
    };
};

const deleteRow = function (fee_id) {
    return {
        type: "DELETE_M3_SHIPPING_DATA",
        fee_id
    };
};

const addRow = function (port_id) {
    return {
        type: "ADD_M3_SHIPPING_DATA",
        port_id
    };
};

export {
    setM3Data,
    fetchM3,
    setChosenPort,
    clearCurrentM3,
    fetchM3Calc,
    updatePortData,
    updateShippingFee,
    removePort,
    deleteRow,
    addRow
};
