import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import {
    Container, Row, Col,
    Card
} from "react-bootstrap";

import { settings } from "./settings";

import { setCarDetail } from "./actions/car_detail_actions";
import PCANav from "./dashboard-components/navbar";
import CarImages from "./dashboard-components/view_car_images";
import { ShowNotes, CarDetail, Invoices } from "./invoice_views/invoice_components";
import CarPendingQuizUser from "./quiz_views/CarPendingQuizUser";
import DocViewer from "./user_views/document_views/DocViewer";

class PurchaseDetail extends Component {
    constructor () {
        super();
    }

    componentDidMount () {
        const url = settings.api_server + "/purchaseDetail/" + this.props.match.params.purchase_id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setCarDetail(data);
            }.bind(this));
    }

    render () {
        return (
            <div>
                <PCANav />
                <Container>
                    <Row className="show-Container">
                        <Col md={7}>
                            <Card>
                                <Card.Header>Car Detail {this.props.isAdmin && <Link to={"/editPurchase/" + this.props.match.params.purchase_id}> Click here to edit the transaction</Link>}</Card.Header>
                                <Card.Body>
                                    {this.props.car_data.loading ? <p>Loading</p> : <CarDetail data={this.props.car_data.car_info} />}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5}>
                            <Card>
                                <Card.Header>Invoices</Card.Header>

                                <Invoices />

                            </Card>
                            <Card>
                                <Card.Header>Notes</Card.Header>
                                {this.props.car_data.loading ? <p>Loading</p> : <ShowNotes notes={this.props.car_data.car_info.notes} />}
                            </Card>
                            <Card>
                                <Card.Header>Quizzes</Card.Header>
                                <Card.Body>
                                    <CarPendingQuizUser
                                        purchase_id={this.props.match.params.purchase_id}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="show-Container">
                        <Col md={12}>
                            <DocViewer purchase_id={this.props.match.params.purchase_id}/>
                        </Col>
                    </Row>
                    <Row className="show-Container">
                        <Col md={12}>
                            <Card>
                                <Card.Header>Car Images</Card.Header>
                                <Card.Body>
                                    <CarImages purchase_id={this.props.match.params.purchase_id} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        car_data: state.car_detail,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setCarDetail: (car_data) => dispatch(setCarDetail(car_data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDetail);
