/**
 * This page is the UI for filling out a quiz after winning a car. Its URL
 * contains the ID of the purchase associated with the quiz, allowing us to
 * fetch the quiz template.
 */

import {settings} from "./settings";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Container, Row, Col, Card, Form, Button} from "react-bootstrap";

import {fetchCarData} from "./actions/car_detail_actions";

import PCANav from "./dashboard-components/navbar";
import LoadingSpinner from "./widgets/LoadingSpinner";
import LoadingError from "./widgets/LoadingError";
import QuizQuestion from "./quiz_views/QuizQuestion";

import "./quiz_views/QuizStyles.css";

/**
 * @typedef {import("./quiz_views/QuizQuestion.js").Question} Question
 */
/**
 * @typedef {import("./quiz_views/QuizAnswer.js").Answer} Answer
 */
/**
 * @typedef {Object} QuizTemplate
 * @property {number} quiz_id
 * @property {string} quiz_name
 * @property {Question[]} questions
 */

/**
 * Used to create a row in submitted_answer.
 * `question_text` and `text_input_label` are deduced from question_id.
 * Depending on `used_text_input`, `answer_text` is taken from `text_input` or
 * deduced from `answer_id`.
 * `answer_text`, `adds_line_item`, etc. are deduced from `answer_id`.
 * @typedef {Object} AnswerSubmission
 * @property {number} question_id
 * @property {boolean} used_text_input
 * @property {number | null} answer_id
 * @property {string | null} text_input
 */

/**
 * Contains the necessary info to create a row in submitted_quiz. The quiz name
 * can be deduced by providing the ID of the quiz_template.
 * @typedef {Object} QuizSubmission
 * @property {number} quiz_id
 * @property {number} purchase_id
 * @property {AnswerSubmission[]} answers
 */

class PurchaseQuiz extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** @type {QuizTemplate | null} */
            template: null,
            templateLoading: true,
            templateError: false,
            templateReason: null,

            autofill: null,
            autofillLoading: true,
            autofillError: false,
            autofillReason: null,

            // if true, highlight invalid answers in red (false initially so the
            // whole quiz isn't red right off the bat)
            showInvalid: false,

            // if we submit form and it's invalid, show error message
            submissionValid: true,
            submissionMessage: null,

            // if we submit the form successfully, this flag is set and we'll
            // render a dialog with a button to go back to the purchase
            submissionComplete: false
        };

        // We'll override the form submission behavior
        this.form = React.createRef();
        // So we can focus and activate the submit button when Enter is pressed
        this.submitButton = React.createRef();
        this.submitForm = this.submitForm.bind(this);
        this.onFormKeyDown = this.onFormKeyDown.bind(this);
    }

    componentDidMount() {
        // This will also fetch the autofill data afterwards, which needs to be
        // done after we know which quiz template we're doing
        this.fetchQuizTemplate();
        // We need this to display the year, make and model at the top
        fetchCarData(this.props.match.params.purchase_id);
    }

    fetchQuizTemplate() {
        const url = `${settings.api_server}/quiz/${this.props.match.params.purchase_id}`;
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(`${resp.status} ${resp.statusText}`)
            )
            // if we got a valid response but internally failed, reject
            .then(data => (data.success === false ?
                Promise.reject(data.message) : Promise.resolve(data.template))
            )
            // if there is no quiz associated with the purchase, reject
            .then(template => (template == null ?
                Promise.reject("No associated quiz found") : Promise.resolve(template))
            )
            .then(
                template => {
                    this.setState({...this.state,
                        template: template,
                        templateLoading: false,
                        templateError: false
                    });
                    // now that we know which quiz it is, we can try to get
                    // autofill data
                    this.fetchAutofill(this.state.template.quiz_id);
                },
                reason => {
                    console.log("Failed to GET quiz template. Reason: " + reason);
                    this.setState({...this.state,
                        template: null,
                        templateLoading: false,
                        templateError: true,
                        templateReason: reason
                    });
                }
            );
    }

    // Fetch the user's most recent submission for the given quiz, so we can use
    // it to auto-complete the quiz (if one exists)
    fetchAutofill(quiz_id) {
        const url = `${settings.api_server}/quiz/autofill/${quiz_id}`;
        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(`${resp.status} ${resp.statusText}`))
            .then(
                // if there isn't a previous submission, this should succeed and
                // set this.state.autofill to null
                autofill => {
                    this.setState({...this.state,
                        autofill: autofill,
                        autofillLoading: false,
                        autofillError: false
                    });
                },
                // we'll log the error, but won't prevent the user from
                // completing the quiz
                reason => {
                    console.log("Failed to GET quiz autofill. Reason: " + reason);
                    this.setState({...this.state,
                        autofill: null,
                        autofillLoading: false,
                        autofillError: true,
                        autofillReason: reason
                    });
                }
            );
    }

    onFormKeyDown(e) {
        const btn = this.submitButton.current;
        if (e.key == "Enter" && btn !== null) {
            // do this instead of calling submitForm so the page scrolls to the
            // submit button (and error if one occurs)
            btn.focus();
        }
    }

    submitForm() {
        const form = this.form.current;
        // Answers invalid? Highlight invalid controls, show error message.
        if (form.checkValidity() === false) {
            this.setState({...this.state,
                showInvalid: true,
                submissionValid: false,
                submissionMessage: "Some questions do not have valid answers. Please review your answers."
            });
            return;
        }

        // Note that from here on, we know all radio button groups have a
        // selected answer because checkValidity() passed.

        /** @type {QuizSubmission} */
        const submission = {
            quiz_id: this.state.template.quiz_id,
            purchase_id: this.props.match.params.purchase_id,
            answers: []
        };
        /** @type {FormData} */
        const formData = new FormData(form);
        for (const question of this.state.template.questions) {
            // if there are radio buttons, they'll set value "a_<answer_id>"
            // for the HTML name "q_<question_id>"
            const formVal = formData.get("q_" + question.question_id);
            // if there are radio buttons, and the selected one isn't the
            // text input choice, get the answer ID
            if (formVal !== null && formVal != "a_text") {
                submission.answers.push({
                    question_id: question.question_id,
                    used_text_input: false,
                    // chop off the "a_"
                    answer_id: parseInt(formVal.slice(2)),
                    text_input: null
                });
            }
            // otherwise, assume the text input was used, which will have
            // HTML name "input_<question_id>"
            else {
                submission.answers.push({
                    question_id: question.question_id,
                    used_text_input: true,
                    answer_id: null,
                    text_input: formData.get("input_" + question.question_id)
                });
            }
        }

        const url = `${settings.api_server}/quiz/${this.props.match.params.purchase_id}`;
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(submission),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(`${resp.status} ${resp.statusText}`)
            )
            .then(data => data.success === true ?
                Promise.resolve("Submission successful")
                :
                Promise.reject(`Operation unsuccessful. Message: ${data.message}`)
            )
            .then(
                successMsg => {
                    console.log(successMsg);
                    this.setState({...this.state,
                        submissionValid: true,
                        submissionMessage: null,
                        submissionComplete: true
                    });
                },
                rejectReason => {
                    const errMsg = `Error submitting quiz: ${rejectReason}`;
                    console.log(errMsg);
                    this.setState({...this.state,
                        submissionValid: false,
                        submissionMessage: errMsg,
                        submissionComplete: false
                    });
                }
            );
    }

    // If there was an error submitting the quiz, return a Card with the message
    renderError() {
        if (!this.state.submissionValid) {
            return <Card style={{color: "red", backgroundColor: "#FDD"}}>
                <Card.Body style={{padding: "10px"}}>
                    {this.state.submissionMessage}
                </Card.Body>
            </Card>;
        }
        else {
            return null;
        }
    }

    renderSuccessMessage() {
        const purchaseURL = `#/purchase_detail/${this.props.match.params.purchase_id}`;
        return <Card className="successCard">
            <Card.Title>
                Submission Successful!
            </Card.Title>
            <Card.Body>
                The questionaire was submitted successfully.
                <br/><br/>
                <Button variant="success" href={purchaseURL}>
                    Return to Purchase
                </Button>
            </Card.Body>
        </Card>;
    }

    // Search this.state.autofill for a question/answer matching the given one,
    // return null if nothing was found.
    findQuestionAutofill(question_id) {
        let result = null;
        if (this.state.autofill != null) {
            result = this.state.autofill.answers
                .find(ans => ans["q_template_id"] == question_id);
        }
        // find returns undefined if nothing is found, we want null
        return typeof(result) === "undefined" ? null : result;
    }

    renderQuiz() {
        // Display car name if loaded, but don't wait for car name before
        // rendering the rest of the quiz
        let carName;
        if (this.props.carLoading) {
            carName = "(loading...)";
        }
        else {
            const car = this.props.carInfo;
            carName = <span>{car.year} {car.make} {car.model}</span>;
        }

        let autofillMsg = null;
        if (this.state.autofillError) {
            autofillMsg = <span style={{color: "#FAA"}}>
                Error retrieving previous answers: {this.state.autofillReason}
            </span>;
        }
        else if (this.state.autofill != null) {
            autofillMsg = <span style={{color: "#AAA"}}>
                Answers restored from previous submission
            </span>;
        }


        const quiz = this.state.template;
        return <Card className="dropShadow quizCard">
            <Card.Title className="quizTitle">
                {quiz.quiz_name}
            </Card.Title>
            <Card.Body>
                <Card.Text style={{fontSize: "0.9em"}}>
                    {autofillMsg}
                </Card.Text>
                <Card.Text>
                    <b>Name: </b>{this.props.firstname} {this.props.lastname}
                </Card.Text>
                <Card.Text>
                    <b>Car: </b>{carName}
                </Card.Text>
                <Form ref={this.form} noValidate
                    validated={this.state.showInvalid}
                    onKeyDown={this.onFormKeyDown}
                >
                    {quiz.questions.map(q => (
                        <QuizQuestion question={q} key={q.question_id}
                            autofill={this.findQuestionAutofill(q.question_id)}
                        />
                    ))}
                    <Container>
                        <Row>
                            <Col md="auto" style={{paddingLeft: "0px"}}>
                                <Button variant="primary"
                                    onClick={this.submitForm}
                                    ref={this.submitButton}
                                >
                                    Submit
                                </Button>
                            </Col>
                            <Col style={{paddingRight: "0px"}}>
                                {this.renderError()}
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Card.Body>
        </Card>;
    }

    render() {
        let body;
        // don't prevent user from doing quiz if loading the autofill data fails
        if (this.state.templateError) {
            body = <LoadingError message={this.state.templateReason}/>;
        }
        // wait for quiz and autofill before rendering quiz
        else if (this.state.templateLoading || this.state.autofillLoading) {
            body = <LoadingSpinner message="Loading form..."/>;
        }
        else if (this.state.submissionComplete) {
            body = this.renderSuccessMessage();
        }
        else {
            body = this.renderQuiz();
        }

        return <Container className="wideContainer">
            <PCANav isAdmin={this.props.isAdmin}/>
            <Row>
                <Col className="whiteTitle">
                    You won a car!
                </Col>
            </Row>
            {body}
        </Container>;
    }
}

const mapStateToProps = state => ({
    isAdmin: state.profile.is_admin,
    firstname: state.profile.firstname,
    lastname: state.profile.lastname,
    carLoading: state.car_detail.loading,
    carInfo: state.car_detail.car_info
});

export default connect(mapStateToProps)(PurchaseQuiz);
