/**
 * This component returns a ListGroup with all users who are eligible to bid but
 * don't have account managers assigned to them.
 */

import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { settings } from "../settings";

export default class NoAcctManagerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: null,
            list_loading: true,
            list_error: null
        };
    }

    componentDidMount() {
        const url = settings.api_server + "/user/needAccountManagers";
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(
                (response) => {
                    if (response.ok)
                        return response.json();
                    else
                        return Promise.reject({
                            stage: "response",
                            reason: response.status + " " + response.statusText
                        });
                },
                (reject) => Promise.reject({stage: "fetch", reason: reject})
            )
            .then(
                (body) => {
                    if (body.success) {
                        this.setState({...this.state,
                            list_loading: false,
                            list_error: null,
                            list: body.users
                        });
                    }
                    else {
                        console.log("Failed to get users who need account managers, 'success' was false");
                        this.setState({...this.state,
                            list_loading: false,
                            list_error: "Internal error",
                            list: null
                        });
                    }
                },
                (reject) => {
                    console.log("Failed to get users who need account managers. Failed at stage: "
                        + reject.stage + ", reason: " + reject.reason);
                    this.setState({...this.state,
                        list_loading: false,
                        list_error: reject.reason,
                        list: null
                    });
                }
            );
    }

    render() {
        if (this.state.list_loading) {
            return "Loading...";
        }
        else if (this.state.list_error !== null) {
            return "Error: " + this.state.list_error;
        }

        let items;
        // green message when we're all caught up on assigning reps
        if (this.state.list.length <= 0) {
            items = <ListGroup.Item style={{backgroundColor: "#BFB"}}>
                All eligible users have account managers
            </ListGroup.Item>;
        }
        // otherwise, list of users to assign reps to
        else {
            items = this.state.list.map(user => {
                return <ListGroup.Item key={user.username}>
                    <a href={"#/user_detail/" + user.user_id}>
                        {user.firstname} {user.lastname} ({user.username})
                    </a>
                </ListGroup.Item>;
            });
        }
        
        return <ListGroup variant="flush">
            {items}
        </ListGroup>;
    }
}
