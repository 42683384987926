import React, { Component } from "react";
import { Accordion, Container, Card } from "react-bootstrap";

import { settings } from "../../settings";
import BidLevels from "./BidLevels";
import CustomAccordionToggle from "../user_admin_views/CustomAccordionToggle";

export default class UserBidLimits extends Component {
    render () {
        return (
            <Accordion defaultActiveKey="userBidLimits">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="userBidLimits">
                            Bid Limits
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="userBidLimits">
                        <Container style={{padding: "10px", paddingBottom: "10px"}}>
                            <p>If no data, user uses global bid limits.</p>
                            <BidLevels
                                fetchURL={settings.api_server + "/bids/limits/" + this.props.user_id}
                                updateURL={settings.api_server + "/bids/limits/update/" + this.props.user_id}
                            />
                        </Container>
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        );
    }
}
