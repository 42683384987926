import { settings } from "../settings";

// Helper for mapping image request info to full image URLs
function requestedImgLink(request)
{
    return settings.api_server + "/image_requests/images/" + request.link;
}

/**
 * Given a vehicle_id, this function returns a list of the URLs for all images
 * for that car (including regular images and ones from image requests).
 * The auction sheet is always at the front of the returned list, if one exists.
 * @param {string} vehicleID
 * @returns {Promise<string[]>}
 */
export default async function getSortedCarImages(vehicleID)
{
    const normalImgURL = settings.api_server + "/search/auction_car_images/" + vehicleID;
    const normalImagesPromise = fetch(normalImgURL, {credentials: "include"})
        .then(resp => resp.ok ?
            resp.json()
            :
            Promise.reject(resp.status + " " + resp.statusText)
        )
        .then(data => data["images"])
        .catch(reason => {
            console.log(`Unable to fetch images for ${vehicleID}: ${reason}`);
            return [];
        });

    const imgReqURL = settings.api_server + "/image_requests/vehicle/" + vehicleID;
    const imgReqsPromise = fetch(imgReqURL, {credentials: "include"})
        .then(resp => resp.ok ?
            resp.json()
            :
            Promise.reject(resp.status + " " + resp.statusText)
        )
        // 2D array: array of auction sheets, array of other images
        .then(data => [
            data.requestedImageList
                .filter(r => r.is_auction_sheet && r.link)
                .map(requestedImgLink),
            data.requestedImageList
                .filter(r => !r.is_auction_sheet && r.link)
                .map(requestedImgLink)
        ])
        .catch(reason => {
            console.log(`Unable to fetch requested images for ${vehicleID}: ${reason}`);
            return [[], []];
        });

    // await and concatenate the results
    const normalImages = await normalImagesPromise;
    const [reqAuctionSheets, reqImages] = await imgReqsPromise;
    // order to ensure auction sheets first: images marked as auction sheets,
    // normal images (for non-USS, auction sheet will be first in this list),
    // then images marked as not auction sheets
    return [...reqAuctionSheets, ...normalImages, ...reqImages];
}
