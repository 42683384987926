import React, { Component } from "react";
import PropTypes from "prop-types";

import { fetchShippingFee } from "../actions/shipping_fee_actions";

import {
    Col, Row,
    Modal,
    FormLabel, Form,
    Button,
} from "react-bootstrap";
import { settings } from "../settings";

export default class AddPort extends Component {
    constructor () {
        super();
        this.changePort = this.changePort.bind(this);
        this.createPort = this.createPort.bind(this);
        this.validatePortName = this.validatePortName.bind(this);

        this.toggle = this.toggle.bind(this);
        this.state = { show: false, updating: false, message: null, portName: "" };
    }

    toggle () {
        this.setState({ ...this.state, show: !this.state.show });
    }

    changePort (e) {
        this.setState({ ...this.state, portName: e.target.value });
    }

    createPort () {
        this.setState({ ...this.state, updating: true });

        const url = settings.api_server + "/m3/shipping_ports";
        const postData = { port_name: this.state.portName };
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // close the modal
                    this.props.toggle();
                    fetchShippingFee();
                    // update underlying data with redux
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    validatePortName () {
        if (this.state.portName === "") { return false; }
        // the port name must not already exist
        for (let i = 0; i < this.props.portList.length; i++) {
            if (this.props.portList[i].port === this.state.portName) { return false; }
        }
        return true;
    }

    render () {
        return (
            <Modal show={this.props.show} onHide={this.props.toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Port m<sup>3</sup></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.props.updatePort}>
                        <Row>
                            <Col componentclass={FormLabel} sm={3} md={3}>
                                <Form.Label>Port Name</Form.Label>
                            </Col>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.portName} onChange={this.changePort} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>{!this.validatePortName() ? <div className="redtext" style={{ textAlign: "center" }} >Port name must be non-blank, and unique!</div> : <div>&nbsp;</div>}  </Col>
                        </Row>

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.createPort}>Save changes</Button> <Button onClick={this.props.toggle}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
AddPort.propTypes = {
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    portList: PropTypes.array.isRequired
};
