import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup, Button, Accordion, Card, Container
} from "react-bootstrap";

import CustomAccordionToggle from "./user_admin_views/CustomAccordionToggle";

import { settings } from "../settings";
import { setViewProfile, update_view_profile } from "../actions/user_actions";
import RoleEditor from "./role_editor";
import SetFeeDiscount from "./user_admin_views/SetFeeDiscount";
import AcctManagerSelector from "./user_admin_views/AcctManagerSelector";

/*
view user's user id should be set in redux before
this loads
*/
class UserOverview extends Component {
    constructor () {
        super();
        this.deactivate = this.deactivate.bind(this);
        this.activate = this.activate.bind(this);
        this.fetch_profile = this.fetch_profile.bind(this);

        this.disable = this.disable.bind(this);
        this.setIsDealer = this.setIsDealer.bind(this);
    }

    componentDidMount () {
        this.fetch_profile();
    }

    deactivate () {
        const url = settings.api_server + "/user/admin/deactivate/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                // update to keep back and front end in sync
                this.fetch_profile();
            }.bind(this));
    }

    activate () {
        const url = settings.api_server + "/user/admin/activate/" + this.props.user_id;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                // update to keep back and front end in sync
                this.fetch_profile();
            }.bind(this));
    }

    disable (doDisable) {
    // disables or renables.
        const url = settings.api_server + "/user/admin/disable/" + this.props.user_id;
        const method = doDisable ? "PUT" : "DELETE";

        fetch(url, {
            credentials: "include",
            method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                // update to keep back and front end in sync
                this.fetch_profile();
            }.bind(this));
    }

    setIsDealer(newStatus) {
        const url = settings.api_server + "/user/admin/setdealer/" + this.props.user_id;
        const method = newStatus ? "PUT" : "DELETE";

        fetch(url, {
            credentials: "include",
            method: method,
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                } else { return response.json(); }
            })
            .then(function () {
                this.fetch_profile();
            }.bind(this));
    }

    fetch_profile () {
        update_view_profile(this.props.user_id);
    }

    render () {
        if (!this.props.profile_loaded) {
            return <Accordion defaultActiveKey="userOverview">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="userOverview">
                            Loading...
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="userOverview">
                        <Card.Body>
                            Loading...
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>;
        }

        const profile = this.props.user.profile;

        return <>
            <Accordion defaultActiveKey="userOverview">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="userOverview" editProfile>
                            Information
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="userOverview">
                        <Card.Body style={{padding: 0}}><ListGroup variant="flush">
                            <ListGroup.Item>
                                <b>Email/username:</b> {profile.username}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>First name:</b> {profile.firstname}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Last name:</b> {profile.lastname}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>User ID:</b> {this.props.user_id}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Joined on:</b> {profile.joined_on}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Subscribed to emails:</b> {profile.subscribed ? "Yes" : "No"}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Current balance:</b> {profile.balance} JPY
                            </ListGroup.Item>
                        </ListGroup></Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>

            <Accordion defaultActiveKey="manageUser">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="manageUser">
                            Account Properties
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="manageUser">
                        <Card.Body style={{padding: 0}}><ListGroup variant="flush">
                            <ListGroup.Item>
                                <b>Active:</b> {profile.active ? "Yes" : "No"}
                                &nbsp;
                                <Button size="sm" disabled={!profile.active} onClick={this.deactivate}>Deactivate</Button>
                                &nbsp;
                                <Button size="sm" disabled={profile.active} onClick={this.activate}>Activate</Button>
                                &nbsp;Only 'active' users can log in.
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Enabled:</b> {profile.disabled ? "No" : "Yes"}
                                &nbsp;
                                <Button size="sm" disabled={profile.disabled} onClick={() => this.disable(true)}>Disable</Button>
                                &nbsp;
                                <Button size="sm" disabled={!profile.disabled} onClick={() => this.disable(false)}>Enable</Button>
                                &nbsp;Only 'enabled' users will show up in speedy pay.
                            </ListGroup.Item>
                            
                            <ListGroup.Item><b>Roles</b>
                                {this.props.is_founder_admin
                                    ? <RoleEditor user_id={this.props.user.user_id} roles={profile.roles} refresh={this.fetch_profile} lastUpdate={this.props.lastUpdate} />
                                    : <ul>{profile.roles.map(r => <li key={r}>{r}</li>)}</ul>
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><b>Dealer:</b> {profile.is_dealer ? "Yes" : "No"}
                                &nbsp;
                                <Button size='sm' disabled={profile.is_dealer} onClick={() => this.setIsDealer(true)}>Mark as dealer</Button>
                                &nbsp;
                                <Button size='sm' disabled={!profile.is_dealer} onClick={() => this.setIsDealer(false)}>Mark as not a dealer</Button>
                                &nbsp;
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <SetFeeDiscount user_id={this.props.user_id} service_fee_discount={profile.service_fee_discount} />
                            </ListGroup.Item>
                        </ListGroup></Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>

            <AcctManagerSelector/>

            <Accordion defaultActiveKey="shippingInfo">
                <Container className="blueBorderContainer" fluid style={{padding: 0, marginBottom: "10px"}}>
                    <Card.Header>
                        <CustomAccordionToggle eventKey="shippingInfo" editProfile>
                            Default Shipping Information
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="shippingInfo">
                        <Card.Body style={{padding: 0}}><ListGroup variant="flush">
                            <ListGroup.Item>
                                <b>Account manager:</b> {profile.acct_manager_name}
                            </ListGroup.Item>
                            <ListGroup.Item className="whitespace">
                                <b>Address:</b><br />
                                {profile.address}<br />
                                {profile.city}<br />
                                {profile.country}<br />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Phone number:</b> {profile.phone_number}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Ship to country:</b> {profile.ship_to_country}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Ship to port:</b> {profile.ship_port}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Shipping notify parties:</b> {profile.ship_notify_name}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Shipping method:</b> {profile.ship_method}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <b>Shipping M3 cost:</b> {profile.ship_m3_cost}
                            </ListGroup.Item>
                        </ListGroup></Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        is_founder_admin: state.profile.is_founder_admin,
        user_id: state.user.user_id,
        profile_loaded: state.user.profile_loaded,
        user: state.user,
        lastUpdate: state.user.lastUpdate // to detect changes
    };
};

const mapDispatchToProps = dispatch => ({
    setViewProfile: (data) => dispatch(setViewProfile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
