import React from "react";
import "../../App.css";
import {useAccordionToggle} from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import Octicon, {TriangleDown, TriangleUp} from "@githubprimer/octicons-react";
import EditButton from "./EditButton";

/**
 * @callback toggleCallback
 * @param {string | number} eventKey
 */

/**
 * @param {Object} props
 * @param {boolean} [props.iconBefore=false] determines if the arrow icon is
 * placed after the children (default) or before
 * @param {any} props.children elemets to put in the toggle, such as a label
 * @param {string | number} props.eventKey eventKey for the associated Collapse
 * @param {boolean} props.editProfile only used on edit_user page
 * @param {toggleCallback} [props.callback] called when toggle is clicked
 * @returns
 */
export default function CustomAccordionToggle({
    iconBefore,
    children,
    eventKey,
    editProfile,
    callback
}) {
    if (typeof(iconBefore) === "undefined") {
        iconBefore = false;
    }

    const currentEventKey = React.useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div className="italicTitle border-0 w-100 text-left theHand"
            style={{backgroundColor: "rgba(0, 0, 0, 0)"}}
            onClick={decoratedOnClick}
        >
            {iconBefore && <>
                <Octicon icon={isCurrentEventKey ? TriangleUp : TriangleDown}/>
                &nbsp;&nbsp;
            </>}
            {children}
            {!iconBefore && <>
                &nbsp;&nbsp;
                <Octicon icon={isCurrentEventKey ? TriangleUp : TriangleDown}/>
            </>}
            <div style={{display: "inline", float: "right"}}>
                {editProfile ? <EditButton/> : null}
            </div>
        </div>
    );
}
