
const setNewsItems = function setNewsItems (NewsData) {
    return {
        type: "SET_NEWS",
        news_items: NewsData
    };
};

export {
    setNewsItems
};
