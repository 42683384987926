import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import {
    Card
} from "react-bootstrap";

import RequestTranslation from "./AdminActions/RequestTranslation";
import RequestBid from "./AdminActions/RequestBid";

class AdminActionCard extends Component {
    render () {
        if (!this.props.isAdmin) { return null; }

        return <Card>
            <Card.Header >Admin Actions</Card.Header>
            <Card.Body className="flush_card">
                <RequestTranslation />
                <br />
                <RequestBid />
            </Card.Body>
        </Card>;
    }
}

const stateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

export default connect(stateToProps)(AdminActionCard);
