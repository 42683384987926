
import { settings } from "../settings";

const invoice = (state = settings.invoice_defaultState, action) => {
    switch (action.type) {
    case "OPEN_CLOSE_CONFIRM":
        return {
            ...state,
            close_invoice_id: action.invoice_id,
            showCloseModal: true
        };
    case "CLOSE_CLOSE_CONFIRM":
        return {
            ...state,
            close_invoice_id: null,
            showCloseModal: false
        };
    case "OPEN_RELEASE_CONFIRM":
        return {
            ...state,
            release_invoice_id: action.invoice_id,
            showReleaseModal: true
        };
    case "CLOSE_RELEASE_CONFIRM":
        return {
            ...state,
            release_invoice_id: null,
            showReleaseModal: false
        };
    case "SET_PURCHASE_ID":
        return {
            ...state,
            purchase_id: action.purchase_id
        };
    case "RESET_INVOICE":
        return {
            ...settings.invoice_defaultState
        };
    case "ADD_INVOICE_DATA":
        var invoice_data = state.invoice_data;
        invoice_data[action.invoice_id] = action.line_items;
        return {
            ...state,
            invoice_data,
            last_invoice_update: (new Date()).getTime() // to trigger an update
        };

    case "SHOW_LINE_ITEM_MODAL":
        return {
            ...state,
            showAddLineItemModal: true,
            add_line_item_invoice_id: action.add_line_item_invoice_id
        };
    case "HIDE_LINE_ITEM_MODAL":
        return {
            ...state,
            showAddLineItemModal: false
        };

    default:
        return state;
    }
};

export default invoice;
