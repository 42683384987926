import { settings } from "../settings";

let profile_refresh = null;

function fetch_profile () {
    const url = settings.api_server + "/getProfilePageData";
    fetch(url, {
        cache: "no-cache",
        headers: {
            pragma: "no-cache",
            "cache-control": "no-store"
        },
        method: "GET",
        credentials: "include"
    }
    )
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            if (myJson.success) {
                // set cookie with session provided
                // redirect to site
                // pass to redux
                settings.store.dispatch(profileRefresh(myJson));
                // move to next
            } else {
                // failed auth
                settings.store.dispatch(failedLogin());
            }
        });
}

// do the refresh
const profileRefresh = function (data) {
    return {
        type: "SET_PROFILE",
        data
    };
};

/*
  make sure the user is logged in. Start the profile fetcher if it's not running
  */
const ensureLogin = function () {
    // start the timer
    // fetch every 2 minutes
    if (profile_refresh === null) {
    // fetch a profile
        fetch_profile();
        profile_refresh = setInterval(fetch_profile, 2 * 60 * 1000);
    }
};

// this is a login event
// have it set off
const createProfile = function (d) {
    ensureLogin();

    return {
        type: "SET_PROFILE",
        data: d
    };
};

const setBidList = function (bidList) {
    return {
        type: "SET_BID_LIST",
        bids: bidList
    };
};

const sortBidList = function (key) {
    return {
        type: "SORT_BID_COLUMN",
        key
    };
};

const setPurchaseList = function (purchaseList) {
    return {
        type: "SET_PURCHASE_LIST",
        purchaseList
    };
};

const setWatchedCarList = function (watchedCarList) {
    return {
        type: "SET_WATCHED_CAR_LIST",
        watchedCarList
    };
};

const logout = function () {
    return {
        type: "LOGOUT"
    };
};

const failedLogin = function () {
    return {
        type: "FAILED_LOGIN"
    };
};

const showPasswordChange = function () {
    return {
        type: "SHOW_PASSWORD_CHANGE"
    };
};
const hidePasswordChange = function () {
    return {
        type: "HIDE_PASSWORD_CHANGE"
    };
};

const toggleAddressChange = function () {
    return {
        type: "TOGGLE_ADDRESS_CHANGE"
    };
};

export {
    fetch_profile,
    createProfile,
    setBidList,
    setPurchaseList,
    setWatchedCarList,
    ensureLogin,
    logout,
    failedLogin,
    showPasswordChange,
    hidePasswordChange,
    sortBidList,
    toggleAddressChange
};
