import React, { Component } from "react";
import { connect } from "react-redux";

import { setStartYear, setEndYear } from "../actions/search_actions";

import {
    FormGroup,
    FormControl,
    FormLabel,
    Col, Row
} from "react-bootstrap";

class SearchYearStart extends Component {
    constructor () {
        super();
        this.handleYearStartChange = this.handleYearStartChange.bind(this);
        this.getYearValidationStateStart = this.getYearValidationStateStart.bind(this);
    }

    handleYearStartChange (e) {
        this.props.setStartYear(e.target.value);
    }

    getYearValidationStateStart () {
        if (this.props.start_year.length === 0) { return null; }
        const lengthStart = this.props.start_year.length;
        const isNumericStart = typeof this.props.start_year;
        if (lengthStart === 4 && isNumericStart) { return "success"; }
        return "error";
    }

    render () {
        return (
            <FormControl
                inline="true"
                type="text"
                value={this.props.start_year}
                onChange={this.handleYearStartChange}
                isValid={this.getYearValidationStateStart()}
            />);
    }
}
const mapStateToPropsStart = state => {
    return {
        start_year: state.search.start_year
    };
};
const mapDispatchToPropsStart = dispatch => ({
    setStartYear: (start) => dispatch(setStartYear(start))
});
const StartDate = connect(mapStateToPropsStart, mapDispatchToPropsStart)(SearchYearStart);

class SearchYearEnd extends Component {
    constructor () {
        super();
        this.handleYearEndChange = this.handleYearEndChange.bind(this);
        this.getYearValidationStateEnd = this.getYearValidationStateEnd.bind(this);
    }

    getYearValidationStateEnd () {
        if (this.props.end_year.length === 0) { return null; }
        const lengthEnd = this.props.end_year.length;
        const isNumericEnd = typeof this.props.end_year;
        if (lengthEnd === 4 && isNumericEnd) { return "success"; }
        return "error";
    }

    handleYearEndChange (e) {
        this.props.setEndYear(e.target.value);
    }

    render () {
        return (
            <FormControl
                inline="true"
                type="text"
                value={this.props.end_year}
                onChange={this.handleYearEndChange}
                isValid={this.getYearValidationStateEnd()}
            />
        );
    }
}
const mapStateToPropsEnd = state => {
    return {
        start_year: state.search.start_year,
        end_year: state.search.end_year
    };
};

const mapDispatchToPropsEnd = dispatch => ({
    setStartYear: (start) => dispatch(setStartYear(start)),
    setEndYear: (end) => dispatch(setEndYear(end))
});
const EndDate = connect(mapStateToPropsEnd, mapDispatchToPropsEnd)(SearchYearEnd);

class SearchYears extends Component {
    render () {
        return (
            <div>
                <FormGroup as={Row}>
                    <Col xs={3}>
                        <FormLabel>Model Years</FormLabel>
                    </Col>
                    <Col xs={3}>
                        <StartDate />
                    </Col>
                    <Col xs={1}>
                        -
                    </Col>

                    <Col xs={3}>
                        <EndDate />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}

export { StartDate, EndDate };
export default SearchYears;
