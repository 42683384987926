import { settings } from "../settings";

/**
 * Fetch the translation request queue and store it in redux.
 * @param {() => void} [callback] (optional) function to call when fetch is
 * complete
 */
const fetchTranslations = function fetchTranslations (callback) {
    const url = settings.api_server + "/translations/queue";

    fetch(url, {
        credentials: "include",
        headers: {
            "content-type": "application/json"
        }
    })
        .then(function (response) {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function (data) {
            // push to redux
            settings.store.dispatch(setTranslationList(data));
            if (typeof(callback) !== "undefined") {
                callback();
            }
        });
};

const resetTranslations = function () {
    return {
        type: "RESET_TRANSLATION_LIST"
    };
};

const setTranslationList = function (translist) {
    return {
        type: "SET_TRANSLATION_LIST",
        translationList: translist
    };
};

const showTranslation = function (id, transObj, vehicle_id) {
    return {
        type: "SHOW_TRANSLATION",
        show_translation_id: id,
        show_translation_data: transObj,
        vehicle_id
    };
};

const changeMain = function (text) {
    return {
        type: "CHANGE_TRANS_MAIN",
        text
    };
};

const changeMainAdd = function (key, val) {
    return {
        type: "CHANGE_TRANS_MAIN_ADD",
        key,
        val
    };
};

const changeMainDelete = function (key) {
    return {
        type: "CHANGE_TRANS_MAIN_DELETE",
        key
    };
};

const changeDescription = function (text) {
    return {
        type: "CHANGE_TRANS_DESCRIPTION",
        text
    };
};

const changeSalesPoints = function (text) {
    return {
        type: "CHANGE_TRANS_SALES",
        text
    };
};

const changeNotes = function (text) {
    return {
        type: "CHANGE_TRANS_NOTES",
        text
    };
};

const changeReport = function (text) {
    return {
        type: "CHANGE_TRANS_REPORT",
        text
    };
};

const changeDiagramNotes = function (text) {
    return {
        type: "CHANGE_TRANS_DIAGRAM_NOTES",
        text
    };
};

const changeExporterNotes = function (text) {
    return {
        type: "CHANGE_EXPORTER_NOTES",
        text
    };
};

const setAllTranslationData = function (main, desc, sales, notes, report, diagram, exporter, translation_completed) {
    return {
        type: "CHANGE_ALL_TRANS_DATA",
        main,
        desc,
        sales,
        notes,
        report,
        diagram,
        exporter_notes: exporter,
        translation_completed
    };
};

const unshowTranslation = function () {
    return {
        type: "UNSHOW_TRANSLATION"
    };
};

const toggleGiveBackRequest = function () {
    return {
        type: "TOGGLE_GIVE_BACK_TRANS_REQUEST"
    };
};

const setLockOwnership = function (lockOwner, who, when) {
    return {
        type: "LOCK_TRANSLATION",
        isLockOwner: lockOwner,
        who,
        when
    };
};

export {
    resetTranslations,
    setTranslationList,
    showTranslation,
    unshowTranslation,
    fetchTranslations,
    changeMain,
    changeMainAdd,
    changeMainDelete,
    changeDescription,
    changeSalesPoints,
    changeNotes,
    changeReport,
    changeDiagramNotes,
    changeExporterNotes,
    setAllTranslationData,
    toggleGiveBackRequest,
    setLockOwnership
};
