import React, { Component } from "react";
import { connect } from "react-redux";

import * as Datetime from "react-datetime";

import {
    Alert,
    Modal,
    Button,
    Form, FormGroup, FormLabel,
    FormControl,
    Row, Col,
    ListGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import { settings } from "../../settings";
import {
    setCarDetail,
    fetchCarData,
    closeEditPurchaseModal,
    clearCarDetail,
    setCarYear,
    setCarExchange,
    setCarMake,
    setCarModel,
    setCarChassisNum,
    setCarAmount,
    setCarAddress,
    setCarAddressCity,
    setCarAddressCountry,
    setCarPhoneNumber,
    setCarShipToCountry,
    setCarHeight,
    setCarWidth,
    setCarLength,
    setCarShipConsigneeName,
    setCarShipConsigneeAddress,
    setCarShipConsigneePhone,
    setCarShipConsigneeEmail,
    setCarShipMethod,
    setAcctManagerID,
    setAcctManagerName,
    setShipM3,
    setCarNotifyParty,
    setCarNotifyPhone,
    setCarNotifyAddress,
    setCarNotifyName,
    setCarNotifyNote,
    setCarNotifyPartySelection,
    setCarPort,
    setCarPortText,
    setShipM3Number,
    setOceanFreight,
    setCarPurchaseNote,
    setCarUserNote,
    setCarExportDate,
    setCarPaymentMethod,
    copyPurchaserToLogistics,
    copyPurchaserToConsignee,
    copyConsigneeToNotes,
    setExporterUse,
    copyPurchaserToExporterUse,
    setSingleField
} from "../../actions/car_detail_actions";
import { fetchM3, clearCurrentM3 } from "../../actions/m3_actions";
import AdminOnly from "../../dashboard-components/AdminOnly";
import NotifyDropdown from "../notify_party_dropdown";
import { fetchShippingFee } from "../../actions/shipping_fee_actions";
import { fetchAcctManagers } from "../../actions/acct_manager_actions";
import Octicon, {X} from "@githubprimer/octicons-react";
import "./edit_purchase.css";

class EditPurchaseModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            message: null,
            showPotentialPorts: false,
            showPotentialExportPorts: false,
            showPotentialAcctManagers: false,
            // Does the current acct_manager_name correspond to a valid account manager?
            managerNameValid: true
        };
        this.save = this.save.bind(this);
        this.restoreAndClose = this.restoreAndClose.bind(this);
        this.port_change = this.port_change.bind(this);
        this.export_port_change = this.export_port_change.bind(this);
        this.manager_change = this.manager_change.bind(this);
        this.choosePort = this.choosePort.bind(this);
        this.chooseExportPort = this.chooseExportPort.bind(this);
        this.chooseManager = this.chooseManager.bind(this);
        this.setM3 = this.setM3.bind(this);
    }

    componentDidMount () {
    // check if the m3 data is loaded
    // We need it to know known port names
        if (!this.props.m3Loaded) {
            fetchM3();
        }

        if (!this.props.shipping_fee_loaded){
            fetchShippingFee();
        }

        if (!this.props.acctManagersLoaded) {
            fetchAcctManagers();
        }
    }

    componentDidUpdate (prevProps) {
        // check to see if the notify party dropdown changed
        // if yes, update the fields with the new values
        if (prevProps.dropdownSelection !== this.props.dropdownSelection) {
            // find the party
            for (let i = 0; i < this.props.parties.length; i++) {
                if (this.props.parties[i].notify_id === parseInt(this.props.dropdownSelection, 10)) { this.props.setCarNotifyPartySelection(this.props.parties[i]); }
            }
        }
    }

    componentWillUnmount () {
        this.props.clearCurrentM3();
    }

    setM3 () {
        /* 
            set both the m3 cost and the bolster fee.
        */
        this.props.setShipM3Number(this.props.m3_cost);
        this.props.setOceanFreight(this.props.ocean_freight_usd);
    }

    port_change (e) {
        /* 
            update the state
            show possible ports
        */
        this.props.setCarPort(e);
        this.setState({ ...this.state, showPossiblePorts: true });
    }

    export_port_change (e) {
        /* 
            update the state
            show possible ports
        */
        this.props.setSingleField("exportPort", e.target.textContent);
        this.setState({ ...this.state, showPotentialExportPorts: true });
    }

    manager_change (e) {
        const newName = e.target.value;
        // if no name was entered (which is a valid input), clear the ID
        let newID = null;
        let isValid = true;

        // otherwise, if a name was entered, try to get the matching ID
        if (newName != null && newName.length > 0) {
            const match = this.props.acctManagersList.find(
                (manager) => manager.username == newName
            );
            // Invalid name -> invalid ID. If the user tries to submit the
            // invalid ID, it'll display an error for them.
            if (typeof(match) === "undefined") {
                newID = -1;
                isValid = false;
            }
            // Valid name -> valid ID
            else {
                newID = match.user_id;
            }
        }

        this.setState({...this.state,
            showPotentialAcctManagers: true,
            managerNameValid: isValid
        });
        // set ID even if it's invalid, we want an error so the user can't
        // submit the form
        this.props.setAcctManagerID(newID);
        this.props.setAcctManagerName(newName);
    }

    choosePort (e) {
        this.setState({ ...this.state, showPossiblePorts: false });
        this.props.setCarPortText(e.target.textContent);
    }

    chooseExportPort (e) {
        this.setState({ ...this.state, showPotentialExportPorts: false });
        this.props.setSingleField("exportPort", e.target.textContent);
    }

    // when the user clicks a account manager from the list of suggestions, we have a
    // valid username and user ID to set
    chooseManager (id, name) {
        this.setState({...this.state,
            showPotentialAcctManagers: false,
            managerNameValid: true
        });
        this.props.setAcctManagerID(id);
        this.props.setAcctManagerName(name);
    }

    save () {
        const url = settings.api_server + "/purchaseDetail/" + this.props.car_data.purchase_id;

        const postdata = {
            ...this.props.car_data
        };

        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                const newState = this.state;
                if (data.success) {
                    // push to redux
                    this.props.setCarDetail(data.data);
                    this.props.closeEditPurchaseModal();
                    // clear message, hide suggestion lists
                    this.setState({...newState,
                        message: null,
                        showPossiblePorts: false,
                        showPotentialExportPorts: false,
                        showPotentialAcctManagers: false
                    });
                } else {
                    // show the message
                    newState.message = data.message;
                    this.setState(newState);
                }
            }.bind(this));
    }

    // Re-fetches the purchase info and closes the modal. Changing fields in
    // this modal updates the data stored by Redux, so re-fecthing ensures that
    // the fields are in sync with what's stored in the backend.
    restoreAndClose() {
        this.props.fetchCarData(this.props.car_data.purchase_id);
        this.props.closeEditPurchaseModal();
    }

    // Get a button that clears the account manager name and ID
    getClearManagerButton() {
        return <OverlayTrigger overlay={
            <Tooltip>Remove account manager from purchase</Tooltip>
        }>
            <Button variant="outline-danger" size="sm" className="clearButton"
                onClick={() => this.chooseManager(null, "")}
            >
                <Octicon icon={X} verticalAlign="middle" className="xIcon"/>
            </Button>
        </OverlayTrigger>;
    }

    // Get the control for changing the account manager name. Only show the
    // button for clearing the field if there is text in the field.
    getAcctManagerControl() {
        const managername = this.props.car_data.acct_manager_name ?? "";
        const showClear = managername.length > 0;
        const textFieldSize = showClear ? 7 : 8;
        return <>
            <Col sm={textFieldSize} md={textFieldSize}>
                <FormControl autoComplete="off" placeholder="(No account manager)"
                    value={managername} onChange={this.manager_change}
                    isValid={this.state.managerNameValid}
                    isInvalid={!this.state.managerNameValid}
                />
            </Col>
            {showClear &&
                <Col sm={1} md={1} style={{paddingLeft: "0px"}}>
                    {this.getClearManagerButton()}
                </Col>
            }
        </>;
    }

    render () {
        let i = 0;

        const possible = [];
        if (this.state.showPossiblePorts && this.props.m3Loaded) {
            // make a list of possible ports from the list of ports known for the m3
            // add no more than 5
            for (i = 0; i < this.props.m3Data.length && possible.length < 5; i++) {
                if (this.props.m3Data[i].port.toLowerCase().indexOf(this.props.car_data.ship_port.toLowerCase()) >= 0) {
                    possible.push(<ListGroup.Item action key={this.props.m3Data[i].port} onClick={this.choosePort}>{this.props.m3Data[i].port}</ListGroup.Item>);
                }
            }
        }

        const possibleExport = [];
        if (this.state.showPotentialExportPorts && this.props.shipping_fee_loaded){
            for (i = 0; i < this.props.shipping_fee_data.length && possible.length < 5; i++) {
                if (this.props.shipping_fee_data[i].port.toLowerCase().indexOf(this.props.car_data.exportPort.toLowerCase()) >= 0) {
                    possibleExport.push(<ListGroup.Item action key={this.props.shipping_fee_data[i].port} onClick={this.chooseExportPort}>{this.props.shipping_fee_data[i].port}</ListGroup.Item>);
                }
            }
        }

        const possibleManagers = [];
        if (this.state.showPotentialAcctManagers && this.props.acctManagersLoaded) {
            // add at most 5 managers to the suggestions
            const howMany = Math.min(5, this.props.acctManagersList.length);
            for (i = 0; i < howMany; i++) {
                const curr_manager = this.props.acctManagersList[i].username;
                const curr_id = this.props.acctManagersList[i].user_id;
                const user_entry = this.props.car_data.acct_manager_name == null ?
                    "" : this.props.car_data.acct_manager_name.toLowerCase();
                if (curr_manager.toLowerCase().indexOf(user_entry) >= 0) {
                    possibleManagers.push(
                        <ListGroup.Item action key={curr_manager}
                            onClick={() => this.chooseManager(curr_id, curr_manager)}
                        >
                            {curr_manager}
                        </ListGroup.Item>
                    );
                }
            }
        }

        return (
            <Modal dialogClassName="modal-90w" onEnter={this.load}
                show={this.props.purchase_modal_is_open}
                onHide={this.restoreAndClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Edit Transaction Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup as={Row} controlId="exchange_rate">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Exchange rate</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Exchange Rate" value={this.props.car_data.exchange_rate ?? ""} onChange={this.props.setCarExchange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                &nbsp;
                            </Col>

                            <Col md={6}>
                                <h2>Car info</h2>
                                <FormGroup as={Row} controlId="Year">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Year</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Year" value={this.props.car_data.year ?? ""} onChange={this.props.setCarYear} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="make">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Make</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Make" value={this.props.car_data.make ?? ""} onChange={this.props.setCarMake} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="model">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Model</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="model" value={this.props.car_data.model ?? ""} onChange={this.props.setCarModel} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="chassis_num">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Chassis Number</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="chassis_num" value={this.props.car_data.chassis_num ?? ""} onChange={this.props.setCarChassisNum} />
                                    </Col>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <h2>Consignee, shipping notes</h2>
                                <Button onClick={this.props.copyPurchaserToConsignee}>Same as purchaser</Button>
                                <FormGroup as={Row} controlId="consignee_name">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Consignee Name</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Consignee name" value={this.props.car_data.consignee_name ?? ""} onChange={this.props.setCarShipConsigneeName} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="consignee_address">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Consignee Address</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl as="textarea" rows={3} autoComplete="off" placeholder="Consignee Address" value={this.props.car_data.consignee_address ?? ""} onChange={this.props.setCarShipConsigneeAddress} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="consignee_phone">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Consignee Phone</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Consignee Phone" value={this.props.car_data.consignee_phone ?? ""} onChange={this.props.setCarShipConsigneePhone} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="consignee_email">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Consignee Email</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Consignee Email" value={this.props.car_data.consignee_email ?? ""} onChange={this.props.setCarShipConsigneeEmail} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="purcahser_note">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Note</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Purchaser note" value={this.props.car_data.user_note ?? ""} onChange={this.props.setCarUserNote} />
                                    </Col>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <h2>Shipping info</h2>
                                <FormGroup as={Row} controlId="address">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Address</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" as="textarea" rows="3" placeholder="Address" value={this.props.car_data.address ?? ""} onChange={this.props.setCarAddress} />
                                    </Col>
                                    <Form.Label column sm={4} md={4}>
                                        <b>City</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="City" value={this.props.car_data.city ?? ""} onChange={this.props.setCarAddressCity} />
                                    </Col>
                                    <Form.Label column sm={4} md={4}>
                                        <b>Country</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Country" value={this.props.car_data.country ?? ""} onChange={this.props.setCarAddressCountry} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="phone_number">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Phone number</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Phone Number" value={this.props.car_data.phone_number ?? ""} onChange={this.props.setCarPhoneNumber} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="ship_to_country">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Ship to country</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Ship to Country" value={this.props.car_data.ship_to_country ?? ""} onChange={this.props.setCarShipToCountry} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="ship_port">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Ship to port</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Ship to port" onChange={this.port_change} value={this.props.car_data.ship_port ?? ""} />
                                    </Col>
                                </FormGroup>
                                {possible.length > 0 &&
                                    <ListGroup>
                                        {possible}
                                    </ListGroup>
                                }

                                <FormGroup as={Row} controlId="ship_method">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Shipping method</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Shipping method" value={this.props.car_data.ship_method ?? ""} onChange={this.props.setCarShipMethod} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="exportDate">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Export date</b> Choose date, or write ASAP
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <Datetime closeOnSelect value={this.props.car_data.export_date ?? ""} onChange={this.props.setCarExportDate} inputProps={{ placeholder: "Export date" }} dateFormat="YYYY-MM" timeFormat={false} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="exportPort">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Export port</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Export Port" value={this.props.car_data.exportPort ?? ""} onChange={ this.export_port_change } />
                                    </Col>
                                </FormGroup>
                                {possibleExport.length > 0 &&
                                    <ListGroup>
                                        {possibleExport}
                                    </ListGroup>
                                }
                            </Col>
                            <Col md={6}>

                                <h2>Logistics</h2>
                                <FormGroup as={Row} controlId="notifyParty">
                                    <Col componentclass={FormLabel} sm={4} md={4}>
                                        Existing notify party, or edit below
                                    </Col>
                                    <Col sm={8} md={8}>
                                        <NotifyDropdown />
                                    </Col>
                                </FormGroup>
                                <Row><Col md={4}>Or:</Col><Col md={8}>
                                    <Button onClick={this.props.copyPurchaserToLogistics}>Same as purchaser</Button></Col></Row>
                                <FormGroup as={Row} controlId="ship_name">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Logistics Name</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Shipping notify name" value={this.props.car_data.notify_name ?? ""} onChange={this.props.setCarNotifyName} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="ship_notify">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Shipping notify parties (comma separated)</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Shipping notify parties" value={this.props.car_data.ship_notify ?? ""} onChange={this.props.setCarNotifyParty} />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} controlId="ship_phone">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Logistics Phone</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Shipping notify phone" value={this.props.car_data.notify_phone ?? ""} onChange={this.props.setCarNotifyPhone} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="ship_Address">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Logistics Address</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl as="textarea" rows={3} autoComplete="off" placeholder="Shipping notify Address" value={this.props.car_data.notify_address ?? ""} onChange={this.props.setCarNotifyAddress} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="ship_note">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Logistics Note</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl autoComplete="off" placeholder="Shipping notify note" value={this.props.car_data.notify_note ?? ""} onChange={this.props.setCarNotifyNote} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <h2>Exporter Use only</h2>
                                <Button onClick={this.props.copyPurchaserToExporterUse}>Copy in purchaser info</Button>
                                <FormControl as="textarea" rows={5} autoComplete="off" placeholder="Exporter use only" value={this.props.car_data.exporter_use ?? ""} onChange={this.props.setExporterUse} />
                            </Col>
                            <Col md={6}>
                                <h2>The rest</h2>
                                <FormGroup as={Row} controlId="payment_method">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Payment Method</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl placeholder="Payment method" value={this.props.car_data.payment_method ?? ""} onChange={this.props.setCarPaymentMethod} />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row} controlId="manager_name">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Account manager</b>
                                    </Form.Label>
                                    {this.getAcctManagerControl()}
                                </FormGroup>
                                {possibleManagers.length > 0 &&
                                    <ListGroup>
                                        {possibleManagers}
                                    </ListGroup>
                                }

                                <FormGroup as={Row} controlId="purchase_note">
                                    <Form.Label column sm={4} md={4}>
                                        <b>Private notes about purchase</b>
                                    </Form.Label>
                                    <Col sm={8} md={8}>
                                        <FormControl as="textarea" rows={5} placeholder="Purchase note" value={this.props.car_data.purchase_note ?? ""} onChange={this.props.setCarPurchaseNote} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.save}>Save</Button> <Button onClick={this.restoreAndClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        car_data: state.car_detail.car_info,
        isAdmin: state.profile.is_admin,
        purchase_modal_is_open: state.car_detail.purchase_modal_is_open,
        m3Data: state.m3Data.m3Data,
        m3Loaded: state.m3Data.loaded,
        m3_cost: state.m3Data.m3_cost,
        ocean_freight_usd: state.m3Data.ocean_freight_usd,
        total: state.m3Data.total,
        m3_calc_message: state.m3Data.m3_calc_message,
        last_car_update: state.car_detail.last_car_update, // for triggering updates.

        shipping_fee_loaded: state.shipping_fees.loaded,
        shipping_fee_data: state.shipping_fees.export_ports,

        dropdownSelection: state.notify.dropdownSelection, // notify party
        parties: state.notify.parties,

        acctManagersLoaded: state.acct_managers.loaded,
        acctManagersList: state.acct_managers.acct_managers_list
    };
};

const mapDispatchToProps = dispatch => ({
    closeEditPurchaseModal: () => dispatch(closeEditPurchaseModal()),
    setCarDetail: (data) => dispatch(setCarDetail(data)),
    fetchCarData: (purchase_id) => fetchCarData(purchase_id),
    clearCarDetail: () => dispatch(clearCarDetail()),
    clearCurrentM3: () => clearCurrentM3(),

    setCarYear: (e) => dispatch(setCarYear(e)),
    setCarExchange: (e) => dispatch(setCarExchange(e)),
    setCarMake: (e) => dispatch(setCarMake(e)),
    setCarModel: (e) => dispatch(setCarModel(e)),
    setCarChassisNum: (e) => dispatch(setCarChassisNum(e)),
    setCarAmount: (e) => dispatch(setCarAmount(e)),
    setCarAddress: (e) => dispatch(setCarAddress(e)),
    setCarAddressCity: (e) => dispatch(setCarAddressCity(e)),
    setCarAddressCountry: (e) => dispatch(setCarAddressCountry(e)),
    setCarPhoneNumber: (e) => dispatch(setCarPhoneNumber(e)),
    setCarShipToCountry: (e) => dispatch(setCarShipToCountry(e)),
    setCarHeight: (e) => dispatch(setCarHeight(e)),
    setCarWidth: (e) => dispatch(setCarWidth(e)),
    setCarLength: (e) => dispatch(setCarLength(e)),

    setCarShipConsigneeName: (e) => dispatch(setCarShipConsigneeName(e)),
    setCarShipConsigneePhone: (e) => dispatch(setCarShipConsigneePhone(e)),
    setCarShipConsigneeAddress: (e) => dispatch(setCarShipConsigneeAddress(e)),
    setCarShipConsigneeEmail: (e) => dispatch(setCarShipConsigneeEmail(e)),

    setCarShipMethod: (e) => dispatch(setCarShipMethod(e)),
    setAcctManagerID: (id) => dispatch(setAcctManagerID(id)),
    setAcctManagerName: (name) => dispatch(setAcctManagerName(name)),
    setShipM3: (e) => dispatch(setShipM3(e)),
    setCarNotifyParty: (e) => dispatch(setCarNotifyParty(e)),
    setCarNotifyPhone: (e) => dispatch(setCarNotifyPhone(e)),
    setCarNotifyAddress: (e) => dispatch(setCarNotifyAddress(e)),
    setCarNotifyName: (e) => dispatch(setCarNotifyName(e)),
    setCarNotifyNote: (e) => dispatch(setCarNotifyNote(e)),
    setCarPortText: (t) => dispatch(setCarPortText(t)),
    setCarPort: (e) => dispatch(setCarPort(e)),
    setShipM3Number: (n) => dispatch(setShipM3Number(n)),
    setOceanFreight: (n) => dispatch(setOceanFreight(n)),
    setCarPurchaseNote: (n) => dispatch(setCarPurchaseNote(n)),
    setCarUserNote: (e) => dispatch(setCarUserNote(e)),
    setCarExportDate: (e) => dispatch(setCarExportDate(e)),
    setCarPaymentMethod: (e) => dispatch(setCarPaymentMethod(e)),
    copyConsigneeToNotes: () => dispatch(copyConsigneeToNotes()),
    setExporterUse: (e) => dispatch(setExporterUse(e)),
    copyPurchaserToExporterUse: () => dispatch(copyPurchaserToExporterUse()),

    setSingleField: (k, v) => dispatch(setSingleField(k, v)),

    setCarNotifyPartySelection: (p) => dispatch(setCarNotifyPartySelection(p)),
    copyPurchaserToConsignee: () => dispatch(copyPurchaserToConsignee()),
    copyPurchaserToLogistics: () => dispatch(copyPurchaserToLogistics())

});

export default connect(mapStateToProps, mapDispatchToProps)(EditPurchaseModal);
