import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Container,
    Row, Col,
    Button
} from "react-bootstrap";

import { settings } from "../../settings";
import { setBidList } from "../../actions/profile_actions";
import { openBidModal } from "../../actions/bid_actions";
import BidCloseModal from "../BidCloseModal";
import ConfirmButton from "./ConfirmButton";
import { LastSeen } from "../../functions";
import Octicon, {Sync} from "@githubprimer/octicons-react";

/**
 * The list of bids in the Request Monitoring page. It's able to auto-update but
 * right now it doesn't get passed an updateInterval prop and instead uses a
 * refresh button, to prevent users clicking "Confirm" on the wrong bid by
 * accident.
 */
class AdminBidQueue extends Component {
    // will be set to the timeout ID when we call setTimeout for auto-updates
    updateTimer = null;

    /**
     * @param {Object} props
     * @param {number} [props.updateInterval] The amount of time (ms) between
     * automatically re-fetching the data. If not provided, auto-updates won't
     * occur.
     * @param {number[]} [props.userWhitelist] If provided, only include bids
     * from users in this list. Otherwise, include all users.
     */
    constructor (props) {
        super(props);
        // if fetching, the refresh button is disabled
        this.state = { fetching: false };
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount () {
        this.updateData();
    }

    componentWillUnmount () {
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
    }

    // get bid requests, set/reset auto-update timer
    updateData () {
        // if already fetching, ignore
        if (this.state.fetching) {
            return;
        }
        // in case this was called for a reason besides a timeout, we'll cancel
        // any pending timeout before registering a new one
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }

        this.setState({...this.state, fetching: true});

        const url = settings.api_server + "/bids/open";
        // stop this click from clicking other things

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setBidList(data);
                if ("updateInterval" in this.props) {
                    this.updateTimer = setTimeout(this.updateData, this.props.updateInterval);
                }
                this.setState({...this.state, fetching: false});
            }.bind(this));

    }

    render () {
        let bids = [];
        let numBids = 0;
        if (!this.props.bidsLoaded) {
            bids = <Row><Col md="12">loading...</Col></Row>;
        }
        else if (this.props.bidList.length !== 0) {
            bids = this.props.bidList
                // only include if it's in the whitelist, or there's no whitelist
                .filter(x => this.props.userWhitelist?.includes(x.customer_id) ?? true)
                .map(x => <Row
                    className="theHand" // has a button in it, so can not be a button itself
                    as='div'
                    onClick={()=> this.props.openBidModal(x.bid_id)}
                    id={x.bid_id}
                    key={x.bid_id}
                >
                    <Col>{x.name}</Col>
                    <Col>{x.description}</Col>
                    <Col className="float-right">{x.can_confirm && <ConfirmButton bid_id={x.bid_id} confirmed={x.confirmed} refresh={this.updateData} />}</Col>
                    <Col>{x.bid_datetime !== null && LastSeen(x.bid_datetime)}</Col>
                </Row>);
            numBids = bids.length;
        }

        return (
            <Container className="whiteBackground dropShadow">
                <Row>
                    <Col>
                        <h2 className="greyTitle">Bids</h2>
                    </Col>
                    <Col md="2" className="text-end">
                        {this.props.bidsLoaded &&
                            <h2 className="greyTitle">
                                {numBids}
                            </h2>
                        }
                    </Col>
                    <Col md="1" className="text-end" style={{padding: "0px"}}>
                        {this.props.bidsLoaded &&
                            <Button size="sm"
                                onClick={this.updateData}
                                style={{
                                    marginRight: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <Octicon icon={Sync}/>
                            </Button>
                        }
                    </Col>
                </Row>
                {bids}
                <BidCloseModal updateCB={this.updateData} />
                <Row><Col md={12} className="text-end"><Button href="#/bid_list" variant="secondary">Full List</Button></Col></Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        bidList: state.profile.bidList,
        bidsLoaded: state.profile.bidsLoaded,
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list)),
    openBidModal: (bid_id) => dispatch(openBidModal(bid_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminBidQueue);
