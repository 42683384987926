/* This component contains a text-only summary of the user's stats, displayed on
 * the dashboard. It links to the seperate MyStats page, which has the charts.
*/

import React, { Component } from "react";

import {
    Row, ListGroup, Button
} from "react-bootstrap";

import * as Fetcher from "../stats_components/StatFetcher";

/**
 * @typedef {import("../stats_components/StatFetcher").successCallback} successCallback
 * @typedef {import("../stats_components/StatFetcher").statState} statState
 */

export default class MyStatsWidget extends Component {
    constructor(props) {
        super(props);

        this.fetchRejected = this.fetchRejected.bind(this);
        this.storeWinPrices = this.storeWinPrices.bind(this);
        this.storePurchaseDates = this.storePurchaseDates.bind(this);
        this.storeBidStrength = this.storeBidStrength.bind(this);
        this.storeBidWins = this.storeBidWins.bind(this);
        this.storeTranslations = this.storeTranslations.bind(this);
        this.renderAverageWinPrice = this.renderAverageWinPrice.bind(this);
        this.renderPurchaseCount = this.renderPurchaseCount.bind(this);
        this.renderBidStrength = this.renderBidStrength.bind(this);
        this.renderBidWins = this.renderBidWins.bind(this);
        this.renderTranslations = this.renderTranslations.bind(this);

        // Map stats to the callbacks for manipulating and storing them
        /** @type {[string, successCallback][]} */
        this.STATS_STORES = [
            [Fetcher.MyStats.winPrices, this.storeWinPrices],
            [Fetcher.MyStats.purchaseDates, this.storePurchaseDates],
            [Fetcher.MyStats.bidStrengths, this.storeBidStrength],
            [Fetcher.MyStats.bidWins, this.storeBidWins],
            [Fetcher.MyStats.translationsPerWin, this.storeTranslations]
        ];
        // Map stats to the functions for rendering them (also determines the
        // order the stats are displayed)
        /** @type {[string, (statName: string) => React.JSX.Element][]} */
        this.STATS_DISPLAYS = [
            [Fetcher.MyStats.purchaseDates, this.renderPurchaseCount],
            [Fetcher.MyStats.bidWins, this.renderBidWins],
            [Fetcher.MyStats.bidStrengths, this.renderBidStrength],
            [Fetcher.MyStats.winPrices, this.renderAverageWinPrice],
            [Fetcher.MyStats.translationsPerWin, this.renderTranslations]
        ];

        this.state = {...Fetcher.getInitialState(this.STATS_STORES.map(s => s[0]))};
    }

    componentDidMount() {
        Fetcher.fetchStats(this.STATS_STORES, this.fetchRejected);
    }

    /**
     * Callback for stats that couldn't be fetched
     * @param {string} statName
     * @param {statState} failState
     */
    fetchRejected(statName, failState) {
        this.setState({...this.state,
            [statName]: failState
        });
    }

    /**
     * Callback for when win prices have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeWinPrices(statName, data, successState) {
        // Compute the average in JPY, rounded to nearest integer
        const average = data.length == 0 ?
            "N/A"
            :
            "¥" + Math.round(data.reduce((sum, x) => sum + x.value, 0) / data.length);
        this.setState({...this.state,
            [statName]: {
                ...successState,
                average: average
            }
        });
    }

    /**
     * Callback for when purchase dates have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date_of_purchase: string}[]} data
     * @param {statState} successState
     */
    storePurchaseDates(statName, data, successState) {
        // we only want the total number of purchases
        this.setState({...this.state,
            [statName]: {
                ...successState,
                count: data.length
            }
        });
    }

    /**
     * Callback for when bid strengths have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeBidStrength(statName, data, successState) {
        // Compute average % with 4 significant digits
        const average = data.length == 0 ?
            "N/A"
            :
            (data.reduce((sum, x) => sum + x.value * 100, 0) / data.length)
                .toPrecision(4) + "%";
        this.setState({...this.state,
            [statName]: {
                ...successState,
                average: average
            }
        });
    }

    /**
     * Callback for when bid wins have been fetched successfully
     * @param {string} statName so we don't have to hard-code it
     * @param {{bids: number, won: number}} data
     * @param {statState} successState
     */
    storeBidWins(statName, data, successState) {
        this.setState({...this.state,
            [statName]: {
                ...successState,
                bids: data.bids,
                won: data.won
            }
        });
    }

    /**
     * Callback for translations
     * @param {string} statName
     * @param {{date: string, value: number}[]} data
     * @param {statState} successState
     */
    storeTranslations(statName, data, successState) {
        const average = data.length == 0 ?
            "N/A"
            :
            (data.reduce((sum, x) => sum + x.value, 0) / data.length)
                // only one digit after decimal point
                .toFixed(1);
        this.setState({...this.state,
            [statName]: {
                ...successState,
                average: average
            }
        });
    }

    getAllStats() {
        return this.STATS_DISPLAYS.map(([statName, renderFn]) => {
            if (Fetcher.isLoading(this.state[statName])) {
                return <div key={statName}>Loading...</div>;
            }
            else if (Fetcher.hasError(this.state[statName])) {
                return <div key={statName}>{Fetcher.getError(this.state[statName])}</div>;
            }
            else {
                return renderFn(statName);
            }
        });
    }

    renderAverageWinPrice(statName) {
        const average = this.state[statName].average;
        return this.renderLabelAndValue("Recent average auction win price", average);
    }

    renderPurchaseCount(statName) {
        const count = this.state[statName].count;
        return this.renderLabelAndValue("Total auctions won", count);
    }

    renderBidStrength(statName) {
        const average = this.state[statName].average;
        return this.renderLabelAndValue("Recent average bid strength", average);
    }

    renderBidWins(statName) {
        const stat = this.state[statName].won + "/" + this.state[statName].bids;
        return this.renderLabelAndValue("Recent auctions won", stat);
    }

    renderTranslations(statName) {
        const average = this.state[statName].average;
        return this.renderLabelAndValue("Recent average translations per win", average);
    }

    renderLabelAndValue(label, value) {
        return <Row>
            {/* Vertically center text by displaying it as a table cell
                https://stackoverflow.com/a/8865463 */}
            <div style={{display: "table"}}>
                <span style={{display: "table-cell", textAlign: "left", verticalAlign: "middle"}}>
                    <b>{label}: </b>
                </span>
                <span style={{display: "table-cell", textAlign: "right", verticalAlign: "middle"}}>
                    <code style={{color: "black"}}>{value}</code>
                </span>
            </div>
        </Row>;
    }

    render () {
        // remove bottom padding for list items because the plots have empty
        // space at the bottom
        return <>
            <ListGroup variant="flush">
                {this.getAllStats().map((elem, i) =>
                    <ListGroup.Item key={i} style={{paddingTop: "5px", paddingBottom: "5px"}}>
                        {elem}
                    </ListGroup.Item>
                )}
            </ListGroup>
            <div style={{width: "100%", position: "relative"}}>
                <Button href="#/MyStats" size="sm" style={{width: "100px", position: "absolute", right: "0px"}}>
                    More...
                </Button>
            </div>
        </>;
    }
}
