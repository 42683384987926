import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";
import { setOpenPurchases } from "../actions/purchase_data_actions";
import AdminOnly from "../dashboard-components/AdminOnly";

import commaNumber from "../widgets/commaNumbers";

class OpenPurchases extends Component {
    constructor () {
        super();
        this.state = { loaded: false, bids: [] };
    }

    componentDidUpdate (oldProps) {
        if (this.props.who !== oldProps.who) { this.updateData(); }
    }

    componentDidMount () {
        this.updateData();
    }

    updateData () {
        const url = settings.api_server + "/invoice/admin/";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a list of bids (even if it's empty)
                this.props.setOpenPurchases(data);
            }.bind(this));
    }

    render () {
    // needs to return data in a <ListGroup>
        if (!this.props.purchasesLoaded) {
            return (<ListGroup><ListGroup.Item>loading...</ListGroup.Item></ListGroup>);
        }

        const bids = this.props.purchaseList.map(x => <ListGroup.Item action href={"#/editPurchase/" + x.purchase_id} key={x.purchase_id}>{x.make} {x.model}, {x.username}, {x.invoice_count} open invoices, {commaNumber(x.owing)} JPY</ListGroup.Item>);
        return (
            <ListGroup>
                <AdminOnly />
                <ListGroup.Item action href={"#/balances"}>All balances</ListGroup.Item>
                {bids}
            </ListGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        purchaseList: state.purchases.purchaseList,
        purchasesLoaded: state.purchases.purchasesLoaded
    };
};

const mapDispatchToProps = dispatch => ({
    setOpenPurchases: (the_list) => dispatch(setOpenPurchases(the_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenPurchases);
