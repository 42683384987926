import React from "react";
import {Button, Card, Table} from "react-bootstrap";
import {settings} from "../../settings";
import "./DocViewer.css";

/**
 * Displays uploaded documents in a table. See the function below for the
 * component which displays a row in the table.
 * @param {Object} props
 * @param {string} props.purchase_id
 */
export default function DocViewer({purchase_id}) {
    // true if we need to fetch the list of documents
    const [needsRefresh, setNeedsRefresh] = React.useState(true);
    const [documents, setDocuments] = React.useState(null);
    const [error, setError] = React.useState(null);
    // if we switch purchases, we need to refresh
    React.useEffect(() => {setNeedsRefresh(true);}, [purchase_id]);

    // fetch documents
    if (needsRefresh) {
        const url = `${settings.api_server}/documents/purchase/${purchase_id}`;
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.json()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                data.documents
                :
                Promise.reject(data.message)
            )
            .then(
                docs => {
                    setNeedsRefresh(false);
                    setDocuments(docs);
                    setError(null);
                },
                reason => {
                    setNeedsRefresh(false);
                    setDocuments(null);
                    setError(reason);
                }
            );
    }

    let bodyContents;
    if (error !== null) {
        bodyContents = <tr><td colSpan="4">
            Error fetching documents: {error}
        </td></tr>;
    }
    else if (documents === null) {
        bodyContents = <tr><td colSpan="4">
            Loading...
        </td></tr>;
    }
    else if (documents.length <= 0) {
        bodyContents = <tr><td colSpan="4">
            No documents have been uploaded
        </td></tr>;
    }
    else {
        bodyContents = documents.map(doc => <DocRow
            doc={doc}
            key={doc.url}
        />);
    }

    return <Card>
        <Card.Header>
            Documents
        </Card.Header>
        <Table striped bordered size="sm">
            <thead>
                <tr>
                    <th>Description</th>
                    <th className="userTimeCol">Uploaded</th>
                    <th className="userInfoCol">Info</th>
                    <th className="userOptionsCol">Options</th>
                </tr>
            </thead>
            <tbody>
                {bodyContents}
            </tbody>
        </Table>
    </Card>;
}

/**
 * @typedef {Object} DocInfo
 * @prop {string} url
 * @prop {string} desc
 * @prop {string} added_time
 * @prop {boolean} is_vehicle_report
 */

/**
 * A row in the DocViewer table. Shows info about the document, and allows the
 * user to download it.
 * @param {Object} props
 * @param {DocInfo} props.doc
 */
function DocRow({doc}) {
    function downloadDoc() {
        fetch(settings.api_server + doc.url, {
            method: "GET",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.blob()
                :
                Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                blob => {
                    // To download the blob, create an object URL for it, then
                    // create and auto-click a download link to it.
                    // https://stackoverflow.com/questions/25547475/save-to-local-file-from-blob
                    const blobURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobURL;
                    link.download = doc.desc.endsWith(".pdf") ?
                        doc.desc : doc.desc + ".pdf";
                    link.target = "_blank";
                    link.rel = "noreferrer";
                    link.type = "application/pdf";
                    link.click();
                },
                reason => {
                    console.log("Error downloading PDF: " + reason);
                }
            );
    }

    const sep = doc.added_time.indexOf("T");
    const date = doc.added_time.slice(0, sep);
    const time = doc.added_time.slice(sep + 1, doc.added_time.indexOf("."));

    return <tr className="userDocumentRow">
        <td>{doc.desc}</td>
        <td>{date} {time}</td>
        <td>
            {doc.is_vehicle_report && "Vehicle Report"}
        </td>
        <td>
            <Button
                className="userDocRowButton"
                size="sm"
                variant="primary"
                onClick={downloadDoc}
            >
                Download
            </Button>
        </td>
    </tr>;
}
