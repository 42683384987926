import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import { fetch_fx } from "../actions/fx_actions";
import commaNumber from "./commaNumbers";
const fxType = "USD";

class FxView extends Component {
    componentDidMount () {
        if (!this.props.fxLoaded) { // fetch fx if it's not already loaded
            fetch_fx();
        }
    }

    render () {
        if (!this.props.fxLoaded) { return null; }
        if (typeof this.props.amount === "undefined") { return null; }
        // for now, just USD. Later, will be configurable
        // search for the FX desired
        let rate = -1;
        for (let i = 0; i < this.props.fx_rates.length; i++) {
            if (this.props.fx_rates[i].rate_name === fxType) { rate = this.props.fx_rates[i].rate; }
        }
        if (rate === -1) { return null; }
        if (isNaN(this.props.amount) || this.props.amount.length === 0) { return null; }
        // * 1000 because if values are in 1000s of JPYs
        let amt = parseInt(this.props.amount, 10) * (1 / rate);
        if (this.props.thousands) { amt = amt * 1000; }

        return <span>${commaNumber(Math.floor(amt))} USD</span>;
    }
}
// FxView.propTypes = {
//    amount: PropTypes.number.isRequired,
// }
FxView.defaultProps = { thousands: false };

const mapStateToProps = state => {
    return {
        fxLoaded: state.fx.loaded, // fx data
        fx_rates: state.fx.fx_rates
    };
};


export default connect(mapStateToProps)(FxView);
