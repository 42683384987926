
const bid_modal_defaultState = {
    id_id: -1,
    bid_modal_open: false,
    bid_data_loaded: false,
    bid_data: null,
    user_balance: 0,
    user_balance_loaded: false,
    private_note_modal_bid_id: null,
    give_bid_count_back: false,
    top_up: false,
    // If true, the quiz which gets auto-assigned to the purchase upon creation
    // will be auto-completed with the user's previous answers (if any).
    redo_quiz: false
};

const bid_modal = (state = bid_modal_defaultState, action) => {
    switch (action.type) {
    case "OPEN_BID_MODAL":
        return {
            ...state,
            bid_modal_open: true,
            bid_id: action.bid_id
        };
    case "LOAD_BID_DATA":
        return {
            ...state,
            bid_data_loaded: true,
            bid_data: action.bid_data
        };
    case "LOAD_USER_BALANCE":
        return {
            ...state,
            user_balance: action.user_balance,
            user_balance_loaded: true
        };
    case "CLEAR_BID_MODAL": // for clearing the view when reloading a bid
        return {
            ...state,
            bid_data_loaded: false,
            bid_data: null,
            user_balance: 0,
            user_balance_loaded: false
        };
    case "CLOSE_BID_MODAL":
        // reset to default, which is closed.
        return {
            ...bid_modal_defaultState
        };
    case "OPEN_BID_PRIVATE_NOTE_MODAL":
        return {
            ...state,
            private_note_modal_bid_id: action.bid_id
        };
    case "CLOSE_BID_PRIVATE_NOTE_MODAL":
        return {
            ...state,
            private_note_modal_bid_id: bid_modal_defaultState.private_note_modal_bid_id
        };
    case "GIVE_BID_COUNT_BACK":
        return {
            ...state,
            give_bid_count_back: !state.give_bid_count_back
        };
    case "TOGGLE_TOP_UP":
        return {
            ...state,
            top_up: !state.top_up
        };
    case "TOGGLE_REDO_QUIZ":
        return {
            ...state,
            redo_quiz: !state.redo_quiz
        };
    default:
        return state;
    }
};

export default bid_modal;
