/* for admin bid viewing */
const openBidModal = function openBidModal (bid_id) {
    return {
        type: "OPEN_BID_MODAL",
        bid_id
    };
};

const closeBidModal = function closeBidModal () {
    return {
        type: "CLOSE_BID_MODAL"
    };
};

const loadBidData = function loadBidData (bid_data) {
    return {
        type: "LOAD_BID_DATA",
        bid_data
    };
};

const loadUserBalance = function loadUserBalance (balance) {
    return {
        type: "LOAD_USER_BALANCE",
        user_balance: balance
    };
};

const clearBidData = function clearBidData () {
    return {
        type: "CLEAR_BID_MODAL"
    };
};

const openBidPrivateNoteModal = function (bid_id) {
    return {
        type: "OPEN_BID_PRIVATE_NOTE_MODAL",
        bid_id
    };
};

const closeBidPrivateNoteModal = function () {
    return {
        type: "CLOSE_BID_PRIVATE_NOTE_MODAL"
    };
};

const toggleGiveBidCountBack = function () {
    return {
        type: "GIVE_BID_COUNT_BACK"
    };
};

const toggleTopUp = function () {
    return {
        type: "TOGGLE_TOP_UP"
    };
};

const toggleRedoQuiz = function () {
    return {
        type: "TOGGLE_REDO_QUIZ"
    };
};

export {
    openBidModal,
    closeBidModal,
    loadBidData,
    loadUserBalance,
    clearBidData,
    openBidPrivateNoteModal,
    closeBidPrivateNoteModal,
    toggleGiveBidCountBack,
    toggleTopUp,
    toggleRedoQuiz
};
