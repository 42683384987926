import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { fetchImages, removeImage } from "../actions/image_request_list_actions";
import { settings } from "../settings";
// this component doesn't only deal with image requests, so we'll fetch the
// requested images, not it
import ImageList from "../dashboard-components/image_list_viewer";

class CarImages extends Component {
    constructor (props) {
        super(props);
        this.deleteImage = this.deleteImage.bind(this);
        this.toggleIsAuctionSheet = this.toggleIsAuctionSheet.bind(this);
    }

    componentDidMount () {
        fetchImages(this.props.vehicle_id);
    }

    deleteImage (uuid) {
        const url = settings.api_server + "/image_requests/images/" + uuid;

        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
            })
            .then(function () {
                this.props.removeImage(uuid);
            }.bind(this));
    }

    toggleIsAuctionSheet (uuid) {
        const url = settings.api_server + "/image_requests/images/" + uuid;

        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function () {
                // reload to update the toggle button (not very efficient)
                fetchImages(this.props.vehicle_id);
            }.bind(this));
    }

    render () {
        const links = this.props.current_image_list.map(x => ({ link: settings.api_server + "/image_requests/images/" + x.link, uuid: x.link, is_auction_sheet: x.is_auction_sheet }));

        // Container fluid so it shrinks down into the Card
        return (
            <ImageList image_links={links} deleteCB={this.deleteImage} toggleIsAuctionSheetCB={this.toggleIsAuctionSheet} />
        );
    }
}

CarImages.propTypes = {
    vehicle_id: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        current_image_list: state.image_request_list.current_image_list,
        num_images: state.image_request_list.num_images
    };
};

const mapDispatchToProps = dispatch => ({
    removeImage: (uuid) => dispatch(removeImage(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarImages);
