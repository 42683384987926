import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    ListGroup,
    Card,
    Button,
    Col, Row
} from "react-bootstrap";


import { toggleAskModal, fetch_questions, add_question } from "../actions/questions_actions";


class QuestionView extends Component {

    constructor() {
        super();
        this.state = { msg: "" };

        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.canAskQuestions = this.canAskQuestions.bind(this);
    }

    componentDidMount() {
        fetch_questions(this.props.auction_id);
    }

    handleChange(e){
        this.setState({ msg: e.target.value });
    }
    handleAdd() {
        if (this.state.msg !== "") {
            add_question(this.props.auction_id, this.state.msg);
            this.setState({ msg: "" });
        }
    }

    canAskQuestions() {
        return this.props.can_bid ||
            this.props.can_request_translation ||
            this.props.is_admin ||
            this.props.isvip;
    }

    render() {

        // don't show if there are not questions
        if (this.props.question_list === null || this.props.question_list.length === 0) {
            return null;
        }

        var questions = this.props.question_list.map((t) => <ListGroup.Item className="whitespace" key={t.question_id}><b>{t.author_name} says:</b> {t.text}</ListGroup.Item>);

        if (this.canAskQuestions()) {
            var reply = <Row>
                <Col sm={12} md={2}>Reply to this conversation</Col>
                <Col sm={12} md={8}>
                    <textarea rows={2} className="form-control" onChange={this.handleChange} value={this.state.msg} ></textarea>
                </Col>
                <Col sm={12} md={2}>
                    <Button onClick={this.handleAdd}>Reply</Button>
                </Col>
            </Row>;
        }
        return (
            <Card>
                <Card.Header>Questions</Card.Header>
                <Card.Body>
                    <ListGroup>
                        {questions}
                    </ListGroup>
                    <br />
                    {reply}
                </Card.Body>
            </Card >
        );
    }
}


const mapStateToProps = state => {
    return {
        question_list: state.questions.thread_data,
        can_bid: state.profile.can_bid,
        can_request_translation: state.profile.can_request_translation,
        isvip: state.profile.isvip,
        is_admin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    toggleAskModal: () => dispatch(toggleAskModal()), // for opening the modal

});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionView);