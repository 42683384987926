import React, { Component } from "react";
import { connect } from "react-redux";
import "../App.css";

import {
    Col, Row,
    Form,
    Button,
    ListGroup
} from "react-bootstrap";

import { settings } from "../settings";
import { updatePortData, removePort } from "../actions/m3_actions";
import EditCalculation from "./EditM3Calculation";

class M3Row extends Component {
    constructor () {
        super();
        this.populateForm = this.populateForm.bind(this);
        this.changeCalc = this.changeCalc.bind(this);
        this.changeBolster = this.changeBolster.bind(this);
        this.deletePort = this.deletePort.bind(this);

        this.state = { show: false, calculation: "", bolster: "", updating: false, message: null };
    }

    deletePort () {
        this.setState({ ...this.state, updating: true });

        const url = settings.api_server + "/m3/ports/" + this.props.rowdata.port;

        fetch(url, {
            method: "DELETE",
            credentials: "include",

            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data
                    this.props.removePort(this.props.rowdata.port);
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    changeCalc (e) {
        this.setState({ ...this.state, calculation: e.target.value });
    }

    changeBolster (e) {
        this.setState({ ...this.state, bolster: e.target.value });
    }

    populateForm () {
    /* dump in the passed props into the form as default values */
        const newState = this.state;
        newState.bolster = this.props.rowdata.bolster_fee;
        newState.calculation = this.props.rowdata.calculation;
        this.setState(newState);
    }

    numberValidate () {
    // if it's blank, or a number
    // pedantically, the blank string check is ok
        if (this.state.bolster === "" || !isNaN(this.state.bolster)) { return "success"; }
        return "warning";
    }

    /*
    baf_usd: 0
    bl_fee: 0
    costs: [{id: 1, maxHeight: null, ocean_freight_usd: 77.7}]
    0: {id: 1, maxHeight: null, ocean_freight_usd: 77.7}
    other_usd: 0
    port: "Vancouver, Canada"
    */
    render () {
        return <ListGroup.Item className="list-group-item-line" ><h3>Port: {this.props.rowdata.port}</h3>
            <EditCalculation id={this.props.rowdata.port} costs={this.props.rowdata.costs} /> <br />
            <b>Shipping Fees:</b> 
            <Form.Group as={Row}>
                <Form.Label column sm="2">BAF fee (USD):</Form.Label>
                <Col md="10">
                    <Form.Control value={this.props.rowdata.baf_usd} onChange={(e) => this.props.updatePortData(this.props.rowdata.port, "baf_usd", e.target.value)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm="2">BL fee (USD):</Form.Label>
                <Col md="10">
                    <Form.Control value={this.props.rowdata.bl_fee} onChange={(e) => this.props.updatePortData(this.props.rowdata.port, "bl_fee", e.target.value)} />
                </Col>
            </Form.Group>
            <div className="float-right"><Button onClick={this.deletePort} disabled={this.state.updating} variant='outline-danger'>Delete Port</Button></div>

        </ListGroup.Item>;
    }
}

const mapStateToProps = state => {
    state; // linter!
    return {
    };
};

const mapDispatchToProps = dispatch => ({
    updatePortData: (port, field, value) => dispatch(updatePortData(port, field, value)),
    removePort: (port) => dispatch(removePort(port))
});

export default connect(mapStateToProps, mapDispatchToProps)(M3Row);
