import React, { Component } from "react";
import { connect } from "react-redux";

import { setTrans } from "../actions/search_actions";

import {
    FormGroup,
    FormControl,
    FormLabel
} from "react-bootstrap";
import { settings } from "../settings";

export class TransmissionWithLabel extends Component {
    render () {
        return (
            <FormGroup controlId="formControlsSelect">
                <FormLabel>Transmission:</FormLabel>
                <AvtoTransComponent />
            </FormGroup>
        );
    }
}

class AvtoTrans extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            makes: []
        };
    }

    handleChange (e) {
        this.props.setTrans(e.target.value);
    }

    render () {
    // AVTO constance
    // numeric: 1=mt, 2=at
        return (

            <FormControl as="select" placeholder="select" onChange={this.handleChange} value={this.props.trans}>
                <option key="0" value="">{settings.trans_placeholder}</option>
                <option key="1" value="1">Manual</option>
                <option key="2" value="2">Auto</option>
            </FormControl>

        );
    }
}

const mapStateToProps = state => {
    return {
        makes: state.search.makes,
        trans: state.search.trans
    };
};

const mapDispatchToProps = dispatch => ({
    setTrans: (make) => dispatch(setTrans(make))
});
const AvtoTransComponent = connect(mapStateToProps, mapDispatchToProps)(AvtoTrans);
export default AvtoTransComponent;
