import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";

import {
    FormGroup,
    FormControl,
    FormLabel,
    Col, Row,
    ListGroup,
    Tooltip, OverlayTrigger
} from "react-bootstrap";

import Octicon, { Unverified } from "@githubprimer/octicons-react";

import {
    setViewProfile,
    changeFirstname,
    changeLastname,
    changeIsVip,
    changeAddress,
    changeAddressCountry,
    changeAddressCity,
    changePhoneNumber,
    changeCountry,
    changePort,
    changeNotify,
    changeMethod,
    changeEmail
} from "../actions/user_actions";
import { fetchM3 } from "../actions/m3_actions";

import { setNotifyDrowpdownFromId } from "../actions/notify_actions";

import NotifyDropdown from "./notify_party_dropdown";

class UserDataForm extends Component {
    constructor () {
        super();
        this.state = { message: null, showPossiblePorts: false };

        this.port_change = this.port_change.bind(this);
        this.set_port = this.set_port.bind(this);
        this.choosePort = this.choosePort.bind(this);
    }

    componentDidMount () {
    // check if the m3 data is loaded
    // We need it to know known port names
        if (!this.props.m3Loaded) { fetchM3(); }

        // set the notify data
        this.props.setNotifyDrowpdownFromId(this.props.user.profile.ship_notify);
    }

    set_port () {
    // we are controlling port - set it
        this.setState({ ...this.state });
    }

    port_change (e) {
    // update the state
    // show possible ports
        this.setState({ ...this.state, showPossiblePorts: true });
        // push to redux
        this.props.changePort(e.target.value);
    }

    choosePort (e) {
        this.setState({ ...this.state, showPossiblePorts: false });
        this.props.changePort(e.target.textContent);
    }

    render () {
        const possible = [];

        if (this.state.showPossiblePorts && this.props.m3Loaded) {
            // make a list of possible ports from the list of ports known for the m3
            // add no more than 5
            for (let i = 0; i < this.props.m3Data.length && possible.length < 5; i++) {
                if (this.props.m3Data[i].port.toLowerCase().indexOf(this.props.user.profile.ship_port.toLowerCase()) >= 0) {
                    possible.push(<ListGroup.Item action key={this.props.m3Data[i].port} onClick={this.choosePort}>{this.props.m3Data[i].port}</ListGroup.Item>);
                }
            }
        }

        return (
            <div>
                <FormGroup as={Row} controlId="formHorizontalEmail">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Username/email
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Email" value={this.props.user.profile.username} onChange={this.props.changeEmail} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="formHorizontalFirstname">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Firstname
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Firstname" value={this.props.user.profile.firstname} onChange={this.props.changeFirstname} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="formHorizontalLastName">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Lastname
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Lastname" value={this.props.user.profile.lastname} onChange={this.props.changeLastname} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="address">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Address
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl as="textarea" rows={3} placeholder="Address" value={this.props.user.profile.address} onChange={this.props.changeAddress} />
                    </Col>
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        City
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="City" value={this.props.user.profile.city} onChange={this.props.changeAddressCity} />
                    </Col>
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Country
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Country" value={this.props.user.profile.country} onChange={this.props.changeAddressCountry} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="phonenumber">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Phone number
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Phone number" value={this.props.user.profile.phone_number} onChange={this.props.changePhoneNumber} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="shippingCountry">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Ship to Country
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Ship to country" value={this.props.user.profile.ship_to_country} onChange={this.props.changeCountry} />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="shippingPort">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Shipping port <OverlayTrigger
                            placement="top"
                            key={"overlay"}
                            overlay={<Tooltip id="tooltip">You can create a new port, or choose from an existing port. M3 calculation will only work if an existing port is chosen.</Tooltip>}
                        ><span><Octicon icon={Unverified} /></span></OverlayTrigger>
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl autoComplete="off" placeholder="Ship port" onChange={this.port_change} value={this.props.user.profile.ship_port} />
                    </Col>
                </FormGroup>

                {possible.length > 0 &&
                    <ListGroup>
                        {possible}
                    </ListGroup>
                }

                <FormGroup as={Row} controlId="notifyParty">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Shipping notify party
                    </Col>
                    <Col sm={8} md={8}>
                        <NotifyDropdown />
                    </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="method">
                    <Col componentclass={FormLabel} sm={4} md={4}>
                        Shipping method
                    </Col>
                    <Col sm={8} md={8}>
                        <FormControl placeholder="Shipping method" value={this.props.user.profile.ship_method} onChange={this.props.changeMethod} />
                    </Col>
                </FormGroup>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.edit_user.open,
        user_id: state.user.user_id,
        isAdmin: state.profile.is_admin,
        user: state.user,
        m3Data: state.m3Data.m3Data,
        m3Loaded: state.m3Data.loaded
    };
};

const mapDispatchToProps = dispatch => ({
    setNotifyDrowpdownFromId: (id) => dispatch(setNotifyDrowpdownFromId(id)),
    setViewProfile: (data) => dispatch(setViewProfile(data)),
    changeFirstname: (e) => dispatch(changeFirstname(e)),
    changeLastname: (e) => dispatch(changeLastname(e)),
    changeIsVip: (e) => dispatch(changeIsVip(e)),
    changeAddress: (e) => dispatch(changeAddress(e)),
    changeAddressCountry: (e) => dispatch(changeAddressCountry(e)),
    changeAddressCity: (e) => dispatch(changeAddressCity(e)),

    changePhoneNumber: (e) => dispatch(changePhoneNumber(e)),
    changeCountry: (e) => dispatch(changeCountry(e)),
    changePort: (e) => dispatch(changePort(e)),
    changeNotify: (e) => dispatch(changeNotify(e)),
    changeMethod: (e) => dispatch(changeMethod(e)),
    changeEmail: (e) => dispatch(changeEmail(e))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDataForm);
