import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Table,
} from "react-bootstrap";

export default class ShowShippingFees extends Component {
    constructor () {
        super();
    }
    render(){
        /*
        Find this port in the list of ports
        */
        let found = false;
        let fees = null;
        for (let i = 0; i < this.props.ports.length; i++){
            if (this.props.ports[i].port === this.props.port){
                found = true;
                fees = this.props.ports[i].shipping_fees;
            }
        }
        
        if(!found){
            return <p>Port {this.props.port} not found, no fees loaded</p>;
        }

        return <Table striped>
            <thead>
                <tr>
                    <th>To m<sup>3</sup> size</th>
                    <th>Shipping fee (yen)</th>
                </tr>
            </thead>
            <tbody>
                {fees.map(row => <tr key={"to" + row.max_m3}><td>{row.max_m3}</td><td>{row.shipping_fee}</td></tr>)}
            </tbody>
        </Table>;

    }
}
ShowShippingFees.propTypes = {
    ports: PropTypes.array.isRequired,
    port: PropTypes.string.isRequired,
};
