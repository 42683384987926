/**
 * AcctManagerSelector
 * 
 * This component is an accordion dropdown for picking a user's account manager.
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Accordion, Container, Card, ListGroup,
    Button, OverlayTrigger, Tooltip
} from "react-bootstrap";

import CustomAccordionToggle from "./CustomAccordionToggle";
import { settings } from "../../settings";
import { update_view_profile } from "../../actions/user_actions";
import Octicon, {Alert} from "@githubprimer/octicons-react";

const ROLE_NAME = "ACCOUNT_MANAGER";

class AcctManagerSelector extends Component {
    constructor(props) {
        super(props);

        this.assignRep = this.assignRep.bind(this);
        this.unassignRep = this.unassignRep.bind(this);
        this.acctManagerRow = this.acctManagerRow.bind(this);

        this.state = {
            acct_managers_loading: true,
            acct_managers_error: false,
            acct_managers: null
        };
    }

    // fetch account managers
    componentDidMount() {
        const url = settings.api_server + "/user/usersWithRole/" + ROLE_NAME;
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            // reject if fetch was rejected, or response wasn't OK
            // if response was OK, return promise with the JSON body
            .then(
                (response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        return Promise.reject({
                            stage: "response",
                            reason: response.status + " " + response.statusText
                        });
                    }
                },
                (rejectedReason) => (
                    Promise.reject({stage: "fetch", reason: rejectedReason})
                )
            )
            // if fullfilled, we have the JSON data
            .then(
                (body) => {
                    if (body["success"]) {
                        this.setState({
                            ...this.state,
                            acct_managers_loading: false,
                            acct_managers_error: null,
                            acct_managers: body["users"]
                        });
                    }
                    else {
                        console.log("Failed to get account managers, success was false");
                        this.setState({
                            ...this.state,
                            acct_managers_loading: false,
                            acct_managers_error: "Internal error",
                            acct_managers: null
                        });
                    }
                },
                (rejection) => {
                    console.log("Failed to get account managers. Failed at stage: " +
                        rejection.stage + ", reason: " + rejection.reason);
                    this.setState({
                        ...this.state,
                        acct_managers_loading: false,
                        acct_managers_error: rejection.reason,
                        acct_managers: null
                    });
                }
            );
    }

    // make a request to assign a account managers to the current user
    assignRep(manager_id) {
        const user_id = this.props.user.user_id;
        const url = settings.api_server + "/user/account_manager/" + user_id +
            "/" + manager_id;

        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok)
                    return response.json();
                else
                    return Promise.reject("Bad response from server");
            })
            .then(
                (body) => {
                    if (!body.success) {
                        console.log("Server failed to set account managers.");
                    }
                },
                (reject) => {
                    console.log("Failed to set account managers. Reason: " + reject);
                }
            )
            // always update profile so state is correct
            .finally(function() {
                update_view_profile(this.props.user.user_id);
            }.bind(this));
    }

    // make a request to unassign the current user's account managers
    unassignRep() {
        const user_id = this.props.user.user_id;
        const url = settings.api_server + "/user/account_manager/" + user_id;

        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok)
                    return response.json();
                else
                    return Promise.reject("Bad response from server");
            })
            .then(
                (body) => {
                    if (!body.success) {
                        console.log("Server failed to clear account managers.");
                    }
                },
                (reject) => {
                    console.log("Failed to clear account managers. Reason: " + reject);
                }
            )
            // always update profile so state is correct
            .finally(function() {
                update_view_profile(this.props.user.user_id);
            }.bind(this));
    }

    // return a button to assign the given manager to the current user
    getAssignButton(manager_id) {
        return <Button size="sm" onClick={() => this.assignRep(manager_id)}>
            Assign
        </Button>;
    }

    // return a button to remove the given manager from the current user
    getUnassignButton() {
        return <Button size="sm" onClick={this.unassignRep}>
            Unassign
        </Button>;
    }

    getCurrentRep() {
        const profile = this.props.user.profile;
        // name and email of current account managers, if one is assigned
        let currRep = "none";
        // if there is a manager assigned but they aren't in the list of account managers,
        // we'll show a warning
        let invalidRep = false;

        if (profile.acct_manager !== null) {
            const manager = this.state.acct_managers.find(
                (currRep) => (currRep.user_id == profile.acct_manager)
            );

            if (typeof(manager) !== "undefined") {
                currRep = `${manager.firstname} ${manager.lastname} (${profile.acct_manager_name})`;
            }
            else {
                currRep = `${profile.acct_manager_name} (ID: ${profile.acct_manager})`;
                invalidRep = true;
            }
        }

        return <ListGroup.Item>
            <b>Current account managers:</b> {currRep} {invalidRep &&
                // Display alert icon if manager is invalid
                <OverlayTrigger overlay={
                    <Tooltip>This user does not have the {ROLE_NAME} role.</Tooltip>
                }>
                    <span><Octicon icon={Alert}/></span>
                </OverlayTrigger>
            }
        </ListGroup.Item>;
    }

    acctManagerRow(manager) {
        const manager_id = manager.user_id;
        const isAssigned = (manager_id === this.props.user.profile.acct_manager);

        return <ListGroup.Item key={"row_" + manager_id}>
            {isAssigned ? this.getUnassignButton() : this.getAssignButton(manager_id)}
            &nbsp; {manager.firstname} {manager.lastname}, {manager.username}
        </ListGroup.Item>;
    }

    render() {
        const key = "acctManagerSelect";
        // if not loaded yet, show a loading message
        if (!this.props.profile_loaded || this.state.acct_managers_loading) {
            return <Accordion defaultActiveKey={key}>
                <Container className="blueBorderContainer" fluid
                    style={{padding: 0, marginBottom: "10px"}}
                >
                    <Card.Header>
                        <CustomAccordionToggle eventKey={key}>
                            Account Manager
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={key}>
                        <Card.Body>
                            Loading...
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>;
        }
        // if we couldn't fetch, show the error
        else if (this.state.acct_managers_error !== null) {
            return <Accordion defaultActiveKey={key}>
                <Container className="blueBorderContainer" fluid
                    style={{padding: 0, marginBottom: "10px"}}
                >
                    <Card.Header>
                        <CustomAccordionToggle eventKey={key}>
                            Account Manager
                        </CustomAccordionToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={key}>
                        <Card.Body>
                            Error retrieving account managers: {this.state.acct_managers_error}
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
            </Accordion>;
        }

        const managerRows = this.state.acct_managers.length == 0 ?
            // If there are no account managers, show a message
            <ListGroup.Item>
                Cannot assign a account managers because no users
                have been given the {ROLE_NAME} role.
            </ListGroup.Item>
            :
            // Otherwise, show a list of account managers with assign/unassign buttons
            this.state.acct_managers.map(this.acctManagerRow);

        return <Accordion defaultActiveKey={key}>
            <Container className="blueBorderContainer" fluid
                style={{padding: 0, marginBottom: "10px"}}
            >
                <Card.Header>
                    <CustomAccordionToggle eventKey={key}>
                        Account Manager
                    </CustomAccordionToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={key}>
                    <Card.Body style={{padding: 0}}>
                        <ListGroup variant="flush">
                            {this.getCurrentRep()}
                            {managerRows}
                        </ListGroup>
                    </Card.Body>
                </Accordion.Collapse>
            </Container>
        </Accordion>;
    }
}

const mapStateToProps = state => {
    return {
        profile_loaded: state.user.profile_loaded,
        user: state.user
    };
};

export default connect(mapStateToProps)(AcctManagerSelector);
