import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";
import { settings } from "../settings";

import { connect } from "react-redux";

import {
    Accordion,
    Button,
    Card,
    Col, Row, Container,
    Collapse,
    FormControl,
    FormCheck,
} from "react-bootstrap";

import Models from "./avto_history_models";
import Makes from "./avto_history_makes";
import AvtoTransComponent from "./avto_trans";
import { StartDate, EndDate } from "./SearchYears";
import AuctionHouse from "./auctionHouse";
import VehicleTypes from "./vehicle_types";
import VehicleOptions from "./vehicle_options";

import {
    clearSelection,
    setMinGrade,
    setOdoMin,
    setOdoMax,
    setChassis,
    setEngineMin,
    setEngineMax,
    changeAuctionNumber,

    toggleGradeStar,
    toggleGrade2,
    toggleGradeThree,
    toggleGradeThreeFive,
    toggleGradeFour,
    toggleGradeFourFive,
    toggleGradeFive,
    toggleGradeR,
    setKeyword,
    setDrivetrain,

    setStartDate,
    setEndDate,
    setSoldPriceMin,
    setSoldPriceMax,
} from "../actions/search_actions";

import { OdoHelp } from "../widgets/mouseovers";
import DateRangePicker from "../widgets/DateRangePicker";

import "./Search.css";

class Advanced extends Component {
    constructor () {
        super();
        this.handleGradeChange = this.handleGradeChange.bind(this);
        this.changeMin = this.changeMin.bind(this);
        this.changeMax = this.changeMax.bind(this);
        this.changeChassis = this.changeChassis.bind(this);
        this.changeEngineMin = this.changeEngineMin.bind(this);
        this.changeEngineMax = this.changeEngineMax.bind(this);
        this.toggleExtra = this.toggleExtra.bind(this);

        this.state = {
            showExtraOptions: false,
        };
    }

    handleGradeChange (e) {
        this.props.setMinGrade(e.target.value);
    }

    changeMin (e) {
        this.props.setOdoMin(e.target.value);
    }

    changeMax (e) {
        this.props.setOdoMax(e.target.value);
    }

    changeChassis (e) {
        this.props.setChassis(e.target.value);
    }

    changeEngineMin (e) {
        this.props.setEngineMin(e.target.value);
    }

    changeEngineMax (e) {
        this.props.setEngineMax(e.target.value);
    }

    toggleExtra() {
        this.setState({
            ...this.state,
            showExtraOptions: !this.state.showExtraOptions,
        });
    }

    gradeToggle(id, flag, toggle) {
        return <Row key={"grade_toggle_" + id}>
            <Col>
                <FormCheck
                    type="switch"
                    id={id}
                    label={id}
                    checked={this.props[flag]}
                    onChange={this.props[toggle]}
                />
            </Col>
        </Row>;
    }

    render() {
        return <Container
            className="whiteBackground dropShadow wideContainer searchContainer"
        >
            <Accordion defaultActiveKey="0" className="greyText">
                <Container className="blueBorderContainer spaceBottom" fluid>
                    <Accordion.Toggle eventKey="0"
                        className="w-100 border-0 bg-white p-0 text-left italicTitle greyText"
                    >
                        History Search
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="searchFields">
                            {/* Make, Auction Number */}
                            <Row>
                                <Col md={1}>
                                    Make
                                </Col>
                                <Col md={5}>
                                    <Makes history/>
                                </Col>

                                <Col md={2}>
                                    Auction Number
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        type="text"
                                        placeholder="Auction Number"
                                        onChange={this.props.changeAuctionNumber}
                                        value={this.props.auctionNumber}
                                    />
                                </Col>
                            </Row>
                            {/* Model, Auction House */}
                            <Row>
                                <Col md={1}>
                                    Model
                                </Col>
                                <Col md={5}>
                                    {/* Not using isMultiple so it's compact */}
                                    <Models/>
                                </Col>

                                <Col md={2}>
                                    Auction House
                                </Col>
                                <Col md={4}>
                                    <AuctionHouse/>
                                </Col>
                            </Row>
                            {/* Year, Transmission */}
                            <Row>
                                <Col md={2}>
                                    Year
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <StartDate/>
                                        </Col>
                                        <Col md="auto" className="text-center">
                                            to
                                        </Col>
                                        <Col>
                                            <EndDate/>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={2}>
                                    Transmission
                                </Col>
                                <Col md={4}>
                                    <AvtoTransComponent/>
                                </Col>
                            </Row>
                            {/* Mileage, Drivetrain */}
                            <Row>
                                <Col md={2}>
                                    Mileage
                                    <OdoHelp/>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                placeholder="Minimum"
                                                onChange={this.changeMin}
                                                value={this.props.odo_min}
                                            />
                                        </Col>
                                        <Col md="auto">to</Col>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                placeholder="Maximum"
                                                onChange={this.changeMax}
                                                value={this.props.odo_max}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={2}>
                                    Drivetrain
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        as="select"
                                        onChange={this.props.setDrivetrain}
                                        value={this.props.drivetrain}
                                    >
                                        <option value={settings.drivetrain_placeholder}>
                                            {settings.drivetrain_placeholder}
                                        </option>
                                        <option value="2WD">2WD</option>
                                        <option value="4WD">4WD</option>
                                    </FormControl>
                                </Col>
                            </Row>
                            {/* Engine Size, Chassis Code */}
                            <Row>
                                <Col md={2}>
                                    Engine Size
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                placeholder="Minimum"
                                                onChange={this.changeEngineMin}
                                                value={this.props.engine_min}
                                            />
                                        </Col>
                                        <Col md="auto">to</Col>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                placeholder="Maximum"
                                                onChange={this.changeEngineMax}
                                                value={this.props.engine_max}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={2}>
                                    Chassis Code
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        type="text"
                                        placeholder="Chassis Code"
                                        onChange={this.changeChassis}
                                        value={this.props.chassis}
                                    />
                                </Col>
                            </Row>
                            {/* Auction Date, Keyword */}
                            <Row>
                                <Col md={6}>
                                    <DateRangePicker
                                        onStartChanged={this.props.setStartDate}
                                        onEndChanged={this.props.setEndDate}
                                        startValue={this.props.start_date}
                                        endValue={this.props.end_date}
                                        startLabel="Auction Date"
                                        endLabel="to"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        md={[4, undefined, "auto", undefined]}
                                    />
                                </Col>

                                <Col md={2}>
                                    Keyword
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        type="text"
                                        placeholder="Keyword"
                                        onChange={this.props.setKeyword}
                                        value={this.props.keyword}
                                    />
                                </Col>
                            </Row>
                            {/* Sale Price */}
                            <Row>
                                <Col md={2}>
                                    Sale Price (x1000&yen;)
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                onChange={e => this.props.setSoldPriceMin(e.target.value)}
                                                value={this.props.sold_price_min}
                                                placeholder="Minimum"
                                            />
                                        </Col>
                                        <Col md="auto">
                                            to
                                        </Col>
                                        <Col>
                                            <FormControl
                                                type="text"
                                                onChange={e => this.props.setSoldPriceMax(e.target.value)}
                                                value={this.props.sold_price_max}
                                                placeholder="Maximum"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Container>
                <Container className="blueBorderContainer spaceBottom" fluid>
                    <Row>
                        <Col className="italicTitle theHand"
                            onClick={this.toggleExtra}
                        >
                            Extra Options
                        </Col>
                    </Row>
                    <Collapse in={this.state.showExtraOptions}>
                        <Row>
                            <Col md={2} className="alignCentreCol rightAlignTextbox">
                                Auction Grade
                            </Col>
                            <Col md={2} className="alignCentreCol">
                                {[
                                    this.gradeToggle("***", "grade_star", "toggleGradeStar"),
                                    this.gradeToggle("2", "grade_two", "toggleGradeTwo"),
                                    this.gradeToggle("3", "grade_three", "toggleGradeThree"),
                                    this.gradeToggle("3.5", "grade_three_five", "toggleGradeThreeFive"),
                                    this.gradeToggle("4", "grade_four", "toggleGradeFour"),
                                    this.gradeToggle("4.5", "grade_four_five", "toggleGradeFourFive"),
                                    this.gradeToggle("5", "grade_five", "toggleGradeFive"),
                                    this.gradeToggle("R or RA", "grade_R", "toggleGradeR"),
                                ]}
                            </Col>
                            <Col md={2} className="alignCentreCol rightAlignTextbox">
                                Special Vehicles
                            </Col>
                            <Col md={2} className="alignCentreCol">
                                <VehicleTypes/>
                            </Col>
                            <Col md={2} className="alignCentreCol rightAlignTextbox">
                                Vehicle Options
                            </Col>
                            <Col md={2} className="alignCentreCol">
                                <VehicleOptions/>
                            </Col>
                        </Row>
                    </Collapse>
                </Container>
            </Accordion>

            <Row>
                <Col style={{textAlign: "right"}}>
                    <Button onClick={this.props.clearSelection}>
                        Clear Form
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={this.props.searchCallback}
                        disabled={this.props.fetching}
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Container>;
    }
}
Advanced.propTypes = {
    searchCallback: PropTypes.func.isRequired
};
const basicMapStateToProps = state => {
    return {
        fetching: state.search.fetching,
        min_grade: state.search.min_grade,
        odo_min: state.search.odo_min,
        odo_max: state.search.odo_max,
        chassis: state.search.chassis,
        engine_min: state.search.engine_min,
        engine_max: state.search.engine_max,
        auctionNumber: state.search.auctionNumber,
        drivetrain: state.search.drivetrain,
        keyword: state.search.keyword,

        grade_star: state.search.grade_star,
        grade_two: state.search.grade_two,
        grade_three: state.search.grade_three,
        grade_three_five: state.search.grade_three_five,
        grade_four: state.search.grade_four,
        grade_four_five: state.search.grade_four_five,
        grade_five: state.search.grade_five,
        grade_R: state.search.grade_R,

        start_date: state.search.start_date,
        end_date: state.search.end_date,
        sold_price_min: state.search.sold_price_min,
        sold_price_max: state.search.sold_price_max,
    };
};

const basicMapDispatchToProps = dispatch => ({
    clearSelection: () => dispatch(clearSelection()),
    setMinGrade: (grade) => dispatch(setMinGrade(grade)),
    setOdoMin: (s) => dispatch(setOdoMin(s)),
    setOdoMax: (s) => dispatch(setOdoMax(s)),
    setChassis: (s) => dispatch(setChassis(s)),
    setEngineMin: (s) => dispatch(setEngineMin(s)),
    setEngineMax: (s) => dispatch(setEngineMax(s)),
    changeAuctionNumber: (s) => dispatch(changeAuctionNumber(s.target.value)),
    setDrivetrain: (ev) => dispatch(setDrivetrain(ev.target.value)),
    setKeyword: (ev) => dispatch(setKeyword(ev.target.value)),

    toggleGradeStar: () => dispatch(toggleGradeStar()),
    toggleGradeTwo: () => dispatch(toggleGrade2()),
    toggleGradeThree: () => dispatch(toggleGradeThree()),
    toggleGradeThreeFive: () => dispatch(toggleGradeThreeFive()),
    toggleGradeFour: () => dispatch(toggleGradeFour()),
    toggleGradeFourFive: () => dispatch(toggleGradeFourFive()),
    toggleGradeFive: () => dispatch(toggleGradeFive()),
    toggleGradeR: () => dispatch(toggleGradeR()),

    setStartDate: (d) => dispatch(setStartDate(d)),
    setEndDate: (d) => dispatch(setEndDate(d)),
    setSoldPriceMin: (d) => dispatch(setSoldPriceMin(d)),
    setSoldPriceMax: (d) => dispatch(setSoldPriceMax(d)),
});

export default connect(basicMapStateToProps, basicMapDispatchToProps)(Advanced);
