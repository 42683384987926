import * as Plot from "@observablehq/plot";
// import * as d3 from "d3";
import * as React from "react";

// Create a bar graph from an array of values, counting their frequency.
// Groups data based on the given interval. It's passed directly to Plot so it
// can be a temporal string like "month" or "year".
// If the showText prop is true, displays the value of each bar above it.
export default function FrequencyPlot({
    plotData,
    width, height,
    label,
    showText,
    interval
}) {
    const divRef = React.useRef();

    React.useEffect(() => {
        const plot = Plot.plot({
            y: {
                label: label
            },
            width: width,
            height: height,
            marks: [
                Plot.rectY(plotData, Plot.binX(
                    {
                        y: "count"
                    },
                    {
                        x: {interval: interval}
                    }
                )),
                // show text above rectangles if the showText prop is given
                showText ?
                    Plot.text(plotData, Plot.binX(
                        {
                            y: "count",
                            text: "count"
                        },
                        {
                            x: {interval: interval},
                            dy: -6,
                            lineAnchor: "bottom"
                        }
                    ))
                    :
                    null
            ]
        });

        divRef.current.append(plot);
        return () => plot.remove();
    }, [plotData]);

    return <div ref={divRef}/>;
}
