
export function getNumber(_str) {
    /*
    Takes a string of a "number" (may have commas in it)
    returns the "pure" number
    Only works with integers
    */
    var arr = _str.split("");
    var out = new Array();
    for (var cnt = 0; cnt < arr.length; cnt++) {
        if (isNaN(arr[cnt]) == false) {
            out.push(arr[cnt]);
        }
    }
    return Number(out.join(""));
} 