import React, { Component } from "react";
import "../App.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    Alert,
    Modal,
    Button,
    FormGroup, FormLabel,
    FormControl,
    Col, Row
} from "react-bootstrap";

import AdminOnly from "../dashboard-components/AdminOnly";
import { settings } from "../settings";
import { closeBidPrivateNoteModal } from "../actions/bid_actions";

class PrivateNoteModal extends Component {
    constructor () {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.noteChange = this.noteChange.bind(this);
        this.fillForm = this.fillForm.bind(this);

        this.default_state = { note: null, message: null };
        this.state = { ...this.default_state };
    }

    handleClose () {
        this.props.close();
    }

    saveClick () {
    // click was saved
        this.setState({ ...this.state, working: true });
        const url = settings.api_server + "/bids/admin/" + this.props.bid_id + "/note";

        const postData = { note: this.state.note };

        fetch(url, {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, working: false });
                if (data.success) {
                    // update the underlying list
                    if (this.props.updateCB) { this.props.updateCB(); }

                    // clear the state
                    this.setState(this.default_state);
                    this.props.close();
                } else {
                    const setState = this.state;
                    setState.message = data.message;
                    this.setState(setState);
                }
            }.bind(this));
    }

    fillForm () {
    // find the note to fill the space with
        for (let i = 0; i < this.props.bidList.length; i++) {
            if (this.props.bidList[i].bid_id === this.props.bid_id) { this.setState({ ...this.state, note: this.props.bidList[i].private_note }); }
        }
    }

    noteChange (e) {
        this.setState({ ...this.state, note: e.target.value });
    }

    render () {
        let alert = "";
        if (this.state.message !== null) { alert = <Alert variant="warning"><strong>Error!</strong>  {this.state.message}</Alert>; }

        return (
            <Modal show={this.props.bid_id !== null} onHide={this.handleClose} size="lg" onEntered={this.fillForm}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        Update private note
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdminOnly />
                    {alert}
                    <FormGroup className="alignCentre" as={Row} controlId="comment">
                        <FormLabel column={true} sm={2} md={2} >Note:</FormLabel>
                        <Col sm={6} md={6}>
                            <FormControl placeholder="Private note" value={this.state.note} onChange={this.noteChange} />
                        </Col>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.saveClick}>Save</Button> <Button onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

PrivateNoteModal.propTypes = {
    updateCB: PropTypes.func
};

PrivateNoteModal.defaultProps = {
    updateCB: null
};

const mapStateToProps = state => {
    return {
        bid_id: state.bid_modal.private_note_modal_bid_id,
        bidList: state.profile.bidList
    };
};

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(closeBidPrivateNoteModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateNoteModal);
