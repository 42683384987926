import React, { Component } from "react";
import PropTypes from "prop-types";

import "./App.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import AdminOnly from "./dashboard-components/AdminOnly";

import PCANav from "./dashboard-components/navbar";

import { fetchItems } from "./actions/default_line_item_actions";
import { fetchM3 } from "./actions/m3_actions";

import {
    Container, Col, Row,
    Card, Modal,
    FormLabel, Form,
    Button,
    ListGroup
} from "react-bootstrap";
import { settings } from "./settings";

// Maps UI names for item scopes to internal IDs
const scopeNameToID = new Map([
    ["All purchases", "all"],
    ["Cars shipped to...", "import_port"]
]);
const scopeIDToName = new Map([
    ["all", "All purchases"],
    ["import_port", "Cars shipped to..."]
]);

const SCOPE_DD_ID = "scopeDropdown";
const IMPORT_DD_ID = "importPortDropdown";

class AddLineItem extends Component {
    constructor () {
        super();

        this.changeScope = this.changeScope.bind(this);
        this.changeLineItemType = this.changeLineItemType.bind(this);
        this.changeLineItemText = this.changeLineItemText.bind(this);
        this.changeLineItemAmount = this.changeLineItemAmount.bind(this);
        this.createLineItem = this.createLineItem.bind(this);
        this.changeImportPort = this.changeImportPort.bind(this);

        this.state = {
            show: false,
            // item_scope is which purchases the item applies to. "all" is all
            // purchases, such as the flat service fee charge. "import_port" is
            // all purchases where the import port is set to a specific port.
            item_scope: "",
            item_type: "",
            item_text: "",
            item_amount: "",
            // only applicable when item_scope == "import_port"
            item_port: "",
            updating: false,
            message: null
        };
    }

    // Fetch destination ports so we can create line items for them
    componentDidMount() {
        if (!this.props.m3Loaded) {
            fetchM3();
        }
    }

    changeScope(e) {
        // Use null for invalid scopes instead of undefined
        const newScope = scopeNameToID.has(e.target.value) ?
            scopeNameToID.get(e.target.value) : null;
        this.setState({ ...this.state, item_scope: newScope });
    }

    changeLineItemType (e) {
        this.setState({ ...this.state, item_type: e.target.value });
    }

    changeLineItemText (e) {
        this.setState({ ...this.state, item_text: e.target.value });
    }

    changeLineItemAmount (e) {
        this.setState({ ...this.state, item_amount: e.target.value });
    }

    changeImportPort(e) {
        // Ensure given value is a valid port
        if (this.props.m3Loaded && typeof(this.props.m3Data.find(
            port => port.port == e.target.value)) !== "undefined")
        {
            this.setState({ ...this.state, item_port: e.target.value });
        }
        else
        {
            this.setState({ ...this.state,
                item_port: null,
                message: "Port is not valid"
            });
        }
    }

    createLineItem () {
        const scopeDD = document.getElementById(SCOPE_DD_ID);
        const currScope = scopeNameToID.get(
            scopeDD.options[scopeDD.selectedIndex].text);
        if (typeof(currScope) === "undefined") {
            this.setState({...this.state,
                message: "Invalid setting for 'Applies to'"
            });
            return;
        }

        const url = settings.api_server + "/default_line_items/";
        const postData = {
            scope: currScope,
            item_type: this.state.item_type,
            detail: this.state.item_text,
            amount: this.state.item_amount
        };
        
        let importPort = "";
        if (currScope == "import_port") {
            const importDD = document.getElementById(IMPORT_DD_ID);
            importPort = importDD.options[importDD.selectedIndex].text;
            postData["import_port"] = importPort;
        }
        else {
            postData["import_port"] = null;
        }

        // Set updating flag and ensure state is in sync with dropdowns
        this.setState({ ...this.state,
            updating: true,
            item_scope: currScope,
            item_port: importPort
        });

        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                // instead of throwing an error, return a message to display
                if (response.status >= 400) {
                    return Promise.resolve({
                        success: false,
                        message: "Invalid value provided"
                    });
                }
                else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data with redux
                    fetchItems(false);
                    this.props.close();
                    this.setState({ ...this.state, message: null });
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    getScopeDropdown() {
        // Optional dropdown to follow the scope dropdown. E.g. for import_port,
        // the followup will contain a list of possible ports
        let followup = null;
        // If there is no followup, the scope dropdown will fill in the space
        let scopeColSpan = 2;

        if (this.state.item_scope == "import_port") {
            scopeColSpan = 1;
            let options = <option>Loading...</option>;
            if (this.props.m3Loaded) {
                options = this.props.m3Data.map(port => (
                    <option key={port.port} value={port.port}>
                        {port.port}
                    </option>
                ));
            }
            followup = <td>
                <Form.Control as="select" size="sm" id={IMPORT_DD_ID}
                    onChange={this.changeImportPort}
                >
                    {options}
                </Form.Control>
            </td>;
        }

        return <Col><table style={{width: "100%"}}><tbody><tr>
            <td style={{width: "5.5em"}}><b>Applies to:</b></td>
            <td colSpan={scopeColSpan}>
                <Form.Control as="select" size="sm" id={SCOPE_DD_ID}
                    onChange={this.changeScope}
                    defaultValue={scopeIDToName.get(this.state.item_scope)}
                >
                    <option key="All purchases" value="All purchases">
                        All purchases
                    </option>
                    <option key="Cars shipped to..." value="Cars shipped to...">
                        Cars shipped to...
                    </option>
                </Form.Control>
            </td>
            {followup}
        </tr></tbody></table></Col>;
    }

    render () {
        return (
            <Modal show={this.props.show} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Default Line Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            {this.getScopeDropdown()}
                        </Row>

                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item type/heading</Form.Label>
                            </Col>
                        </Row><Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_type} onChange={this.changeLineItemType} />
                            </Col>
                        </Row>

                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item text</Form.Label>
                            </Col>
                        </Row><Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_text} onChange={this.changeLineItemText} />
                            </Col>
                        </Row>

                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item amount (JPY)</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_amount} onChange={this.changeLineItemAmount} />
                            </Col>
                        </Row>

                    </Form>
                    {this.state.message !== null && <p>{this.state.message}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.createLineItem}>Save changes</Button> <Button onClick={this.props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
AddLineItem.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};
const mapAddItemStateToProps = state => {
    return {
        // for import_port items
        m3Loaded: state.m3Data.loaded,
        m3Data: state.m3Data.m3Data
    };
};
const AddLineItemModal = connect(mapAddItemStateToProps)(AddLineItem);


class EditLineItem extends Component {
    constructor () {
        super();

        this.changeLineItemType = this.changeLineItemType.bind(this);
        this.changeLineItemText = this.changeLineItemText.bind(this);
        this.changeLineItemAmount = this.changeLineItemAmount.bind(this);
        this.changeLineItemPort = this.changeLineItemPort.bind(this);

        this.updateLineItem = this.updateLineItem.bind(this);
        this.loadLineItem = this.loadLineItem.bind(this);
        this.deleteLineItem = this.deleteLineItem.bind(this);

        this.state = {
            show: false,
            item_type: "",
            item_text: "",
            item_amount: "",
            item_port: "",
            updating: false,
            message: null
        };
    }

    // Fetch destination ports so we can set default items for them
    componentDidMount() {
        if (!this.props.m3Loaded) {
            fetchM3();
        }
    }

    loadLineItem () {
    // find the LineItem id, put into the state
        for (let i = 0; i < this.props.line_items.length; i++) {
            if (this.props.line_items[i].default_line_item_id === this.props.show_id) {
                this.setState({ ...this.state,
                    item_type: this.props.line_items[i].item_type,
                    item_text: this.props.line_items[i].detail,
                    item_amount: this.props.line_items[i].amount,
                    item_port: this.props.line_items[i].import_port
                });
            }
        }
    }

    updateLineItem () {
        this.setState({ ...this.state, updating: true });

        const url = settings.api_server + "/default_line_items/" + this.props.show_id;
        const postData = {
            item_type: this.state.item_type,
            detail: this.state.item_text,
            amount: this.state.item_amount
        };

        if (this.state.item_port) {
            postData.import_port = this.state.item_port;
        }
        else {
            postData.import_port = null;
        }

        fetch(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(postData),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data with redux
                    fetchItems(false);

                    // close the modal
                    this.props.close();
                } else {
                    // show the alert
                    this.setState({ ...this.state, message: data.message });
                }
            }.bind(this));
    }

    deleteLineItem () {
        this.setState({ ...this.state, updating: true });

        const url = settings.api_server + "/default_line_items/" + this.props.show_id;
        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                this.setState({ ...this.state, updating: false });
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    // update underlying data with redux
                    fetchItems(false);

                    // close the modal
                    this.props.close();
                } else {
                    // show the alert
                    // this.setState( {...this.state, message:data.message});
                }
            }.bind(this));
    }

    changeLineItemType (e) {
        this.setState({ ...this.state, item_type: e.target.value });
    }

    changeLineItemText (e) {
        this.setState({ ...this.state, item_text: e.target.value });
    }

    changeLineItemAmount (e) {
        this.setState({ ...this.state, item_amount: e.target.value });
    }

    changeLineItemPort (e) {
        this.setState({ ...this.state, item_port: e.target.value });
    }

    getImportPortControl() {
        if (this.state.item_port) {
            const ports = this.props.m3Loaded ?
                this.props.m3Data.map(port => (
                    <option key={port.port} value={port.port}>
                        {port.port}
                    </option>
                ))
                :
                <option key="Loading..." value="Loading...">
                    Loading...
                </option>;
            return <>
                <Row>
                    <Col componentclass={FormLabel} sm={12} md={12}>
                        <Form.Label>For cars shipped to: </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm={9} md={9}>
                        <Form.Control as="select"
                            onChange={this.changeLineItemPort}
                            value={this.state.item_port}
                        >
                            {ports}
                        </Form.Control>
                    </Col>
                </Row>
            </>;
        }
        else {
            return null;
        }
    }

    render () {
        return (
            <Modal show={this.props.show} onHide={this.props.close} onEnter={this.loadLineItem}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Line Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item type/heading</Form.Label>
                            </Col>
                        </Row><Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_type} onChange={this.changeLineItemType} />
                            </Col>
                        </Row>

                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item text</Form.Label>
                            </Col>
                        </Row><Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_text} onChange={this.changeLineItemText} />
                            </Col>
                        </Row>

                        <Row>
                            <Col componentclass={FormLabel} sm={12} md={12}>
                                <Form.Label>Line Item amount (JPY)</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={9} md={9}>
                                <Form.Control type="text" value={this.state.item_amount} onChange={this.changeLineItemAmount} />
                            </Col>
                        </Row>

                        {/* If item has a port it applies to, display a control to change it */}
                        {this.getImportPortControl()}
                    </Form>
                    {this.state.message !== null && <p>{this.state.message}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.updateLineItem}>Save changes</Button> <Button variant="danger" onClick={this.deleteLineItem}>Delete Line Item</Button> <Button onClick={this.props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
EditLineItem.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    show_id: PropTypes.number.isRequired
};
const mapStateToPropsUpdate = state => {
    return {
        loaded: state.default_line_items.loaded,
        line_items: state.default_line_items.line_items,
        // for import_port items
        m3Loaded: state.m3Data.loaded,
        m3Data: state.m3Data.m3Data
    };
};



const EditLineItemModal = connect(mapStateToPropsUpdate)(EditLineItem);

class LineItems extends Component {
    constructor () {
        super();
        this.toggleAddLineItem = this.toggleAddLineItem.bind(this);
        this.chooseLineItem = this.chooseLineItem.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.closeAdd = this.closeAdd.bind(this);

        this.state = { showAddLineItem: false, showEditLineItem: false, chosenLineItem: -1 };
    }

    toggleAddLineItem () {
        this.setState({ ...this.state, showAddLineItem: !this.state.showAddLineItem });
    }

    componentDidMount () {
    // update the data, regardless of if there is something there
        fetchItems();
    }

    closeEdit () {
        this.setState({ ...this.state, showEditLineItem: !this.state.showEditLineItem });
    }

    closeAdd () {
        this.setState({ ...this.state, showAddLineItem: !this.state.showAddLineItem });
    }

    chooseLineItem (e) {
        this.setState({ ...this.state, showEditLineItem: true, chosenLineItem: parseInt(e.target.value, 10) });
    }

    render () {
    // Only show nav when loading (it loads profile)
        if (!this.props.loggedIn) { return <div><PCANav /> <p>Loading...</p> </div>; }
        // redirect if not an admin.
        if (!this.props.isAdmin) { return <Redirect to={"/"}></Redirect>; }

        // redundant call to admin-only, but /shrug
        return (
            <div>
                <AdminOnly />
                <PCANav />
                <div className="container">
                    <h2>Default Line Items <Button onClick={this.toggleAddLineItem}>Add a Line Item</Button></h2>

                    <AddLineItemModal show={this.state.showAddLineItem} close={this.toggleAddLineItem} />
                    <Container>
                        <Row>
                            <Col md={2}></Col>
                            <Col md={8}>
                                <Card>
                                    <Card.Header>List of All Line Items:</Card.Header>
                                    <Card.Body>
                                        <ListGroup variant="flush">
                                            {this.props.LineItems.map(d =>
                                                <ListGroup.Item action
                                                    onClick={this.chooseLineItem}
                                                    value={d.default_line_item_id}
                                                    key={d.default_line_item_id}
                                                >
                                                    {d.import_port !== null && d.import_port + ":"} {d.item_type} - {d.detail}, {d.amount}
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={2}></Col>
                        </Row>
                    </Container>
                    <EditLineItemModal show={this.state.showEditLineItem} close={this.closeEdit} show_id={this.state.chosenLineItem} />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loaded: state.default_line_items.loaded,
        LineItems: state.default_line_items.line_items,

        loggedIn: state.profile.profileLoaded,
        isAdmin: state.profile.is_admin
    };
};



export default connect(mapStateToProps)(LineItems);
