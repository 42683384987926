import { Redirect } from "react-router";
import React from "react";
import { connect } from "react-redux";

// If the user is not a FOUNDER, redirect them to the main page.
// Otherwise, do nothing and let the containing page render.
function FounderOnly(props)
{
    if (props.profileLoaded && !props.is_founder_admin)
    {
        return <Redirect to="/"/>;
    }
    // Return null if profile is not loaded. A re-render will be triggered when
    // it loads, and the redirection will occur if the user isn't a FOUNDER.
    else
    {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        profileLoaded: state.profile.profileLoaded,
        is_founder_admin: state.profile.is_founder_admin,
    };
};

export default connect(mapStateToProps)(FounderOnly);
