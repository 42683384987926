import * as React from "react";
import * as d3 from "d3";

/**
 * @typedef pieSlice Data for one slice of the pie chart
 * @property {string} name The name of the slice (used for the label)
 * @property {number} value The numeric value of the slice, determines size
 * @property {string} color The color of the slice (any CSS color specifier)
 */

/**
 * Creates a pie chart React component
 * @param {Object} props
 * @param {pieSlice[]} props.plotData
 * @param {number} props.width Width of the element (auto if undefined)
 * @param {number} props.height Height of the element (auto if undefined)
 * @param {number} props.minWidth
 * @param {number} props.minHeight
 * @param {number} props.labelDist How far each label is from the center.
 * `0.0` is the center of the chart, `1.0` is the edge.
 */
export default function PieChart({
    plotData,
    width, height,
    minWidth, minHeight,
    labelDist,
    strokeColor
}) {
    // viewbox size, doesn't affect HTML element size
    const VIEW_SZ = 100;
    // radius of pie chart within view box, allow some margin
    const RADIUS = VIEW_SZ / 2 - 5;
    // Create arc generators...
    // ...for slices
    const sliceArcGen = d3.arc()
        .innerRadius(0)
        .outerRadius(RADIUS);
    // ...for labels
    const labelMult = typeof(labelDist) === "number" ? labelDist : 0.75;
    const labelArcGen = d3.arc()
        .innerRadius(RADIUS * labelMult)
        .outerRadius(RADIUS * labelMult);

    if (typeof(strokeColor) === "undefined") {
        strokeColor = "#777";
    }

    // Create pie generator
    const pieGen = d3.pie()
        .value(d => d.value);
    // Each arc also contains a `data` field with the value from `plotData`
    const chartArcs = pieGen(plotData);
    // Set up SVG container
    const pieSVG = d3.create("svg")
        .attr("viewBox", [-VIEW_SZ/2, -VIEW_SZ/2, VIEW_SZ, VIEW_SZ]);
    // Add the pie chart slices
    pieSVG.append("g")
        .attr("stroke", strokeColor)
        .attr("stroke-width", "0.5px")
        .selectAll()
        .data(chartArcs)
        .join("path")
        .attr("fill", d => d3.color(d.data.color))
        .attr("d", sliceArcGen);
    // Add the slice labels
    pieSVG.append("g")
        .attr("text-anchor", "middle")
        .selectAll()
        .data(chartArcs)
        .join("text")
        .attr("transform", d => `translate(${labelArcGen.centroid(d)})`)
        .call(text => text.append("tspan")
            .text(d => `${d.data.name}: ${d.data.value}`)
            .attr("font-size", "6px"));

    if (typeof(width) === "undefined") {
        width = "100%";
    }
    if (typeof(height) === "undefined") {
        height = "100%";
    }
    return <svg
        style={{
            width, height,
            minWidth, minHeight,
        }}
        ref={ref => {
            if (ref) {
                // remove existing children so the new one doesn't get drawn on
                // top of/underneath the old one when rerendering occurs
                while (ref.firstChild) {
                    ref.removeChild(ref.firstChild);
                }
                ref.appendChild(pieSVG.node());
            }
        }}
    />;
}
