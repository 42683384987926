import { settings } from "../settings";

const notify = function (state = settings.notify_party_defaultState, action) {
    switch (action.type) {
    case "SET_NOTIFY":
        return {
            ...state,
            parties: action.parties,
            updateTime: new Date().getTime()
        };
    case "CHOSEN_NOTIFY":
        // find the id in the list
        var chosenOne = null;
        for (let i = 0; i < state.parties.length; i++) {
            if (state.parties[i].notify_id === parseInt(action.notify_id, 10)) { chosenOne = state.parties[i]; }
        }
        // shouldn't ever be null...
        return {
            ...state,
            chosen: action.notify_id,
            ...chosenOne // all of the chosen one fields
        };

        // for captive component
    case "NOTIFY_UPDATE_NAME":
        return {
            ...state,
            name: action.name
        };
    case "NOTIFY_UPDATE_ADDRESS":
        return {
            ...state,
            address: action.address
        };
    case "NOTIFY_UPDATE_NOTE":
        return {
            ...state,
            note: action.note
        };
    case "NOTIFY_UPDATE_EMAIL":
        return {
            ...state,
            email: action.email
        };
    case "NOTIFY_UPDATE_PHONE":
        return {
            ...state,
            phone: action.phone
        };

        // add modal (uses the same fields as above)
    case "NOTIFY_SHOW_ADD":
        return {
            ...state,
            showAdd: true,
            // also clear the state
            name: "",
            address: "",
            note: "",
            email: "",
            phone: ""
        };
    case "NOTIFY_HIDE_ADD":
        return {
            ...state,
            showAdd: false
        };
        // clear the modal
    case "CLEAR_NOTIFY_CHOSEN":
        return {
            ...state,
            chosen: settings.notify_party_defaultState.chosen
        };
    case "NOTIFY_DROPDOWN_SET":
        return {
            ...state,
            dropdownSelection: action.notify_id
        };
    case "NOTIFY_DROPDOWN_CLEAR":
        return {
            ...state,
            dropdownSelection: settings.notify_party_defaultState.dropdownSelection
        };
    default:
        return state;
    }
};

export default notify;
