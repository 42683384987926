import React, { Component } from "react";

import {
    ListGroup
} from "react-bootstrap";

import { settings } from "../../settings";
import OutstandingRow from "./OutstandingRow";

export default class OutstandingInvoices extends Component {
    constructor () {
        super();
        this.state = { invoices: [] };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        const url = settings.api_server + "/invoice/admin/overdue";

        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ invoices: data });
            }.bind(this));
    }

    render () {
        return <ListGroup>
            {this.state.invoices.map(x => <OutstandingRow data={x} key={x.invoice_id} refresh={this.loadData}/>)}
        </ListGroup>;
    }
}
