import React from "react";

import {
    Container, Row, Col,
    Table,
    OverlayTrigger, Tooltip,
    Button, ToggleButton, ToggleButtonGroup
} from "react-bootstrap";
import Octicon, {
    ArrowUp, ArrowDown,
    ChevronLeft, ChevronRight
} from "@githubprimer/octicons-react";

import commaNumbers from "../../../widgets/commaNumbers";

import "./StaffSales.css";

/** @typedef {import("./StaffSalesDisplay").UserInfo} UserInfo */

// Enumeration of column sort categories
const ColSorts = {
    DATE: 0,
    CAR: 1,
    STAFF: 2,
    PRICE: 3
};

/**
 * Display the given purchases in a table, which can be ordered by any column.
 * @param {Object} props
 * @param {Object[]} props.purchases
 * @param {UserInfo[]} props.staff
 */
export default function StaffSalesTable({purchases, staff})
{
    // Sort method: category and ascending/descending
    const [sort, setSort] = React.useState(ColSorts.DATE);
    const [desc, setDesc] = React.useState(false);

    // Which page of results we're displaying
    const [page, setPage] = React.useState(0);
    // Possible amounts of items per page
    const itemsPerPageOptions = [10, 20, 50];
    // Default to 20 items per page
    const [itemsPerPage, setItemsPerPage] = React.useState(itemsPerPageOptions[1]);
    const numPages = Math.floor((purchases.length - 1) / itemsPerPage) + 1;

    // Whenever the contents of the table change, go back to page 1
    React.useEffect(() => setPage(0), [purchases, sort, desc, itemsPerPage]);

    const sortClick = (category) => {
        if (category === sort)
        {
            setDesc(!desc);
        }
        else
        {
            setSort(category);
            setDesc(false);
        }
    };
    const sortIcon = (category) => {
        return category === sort ?
            <Octicon className="table-sort-icon" icon={desc ? ArrowDown : ArrowUp}/>
            :
            null;
    };

    const pageLeft = () => {
        setPage(Math.max(0, page - 1));
    };
    const pageRight = () => {
        setPage(Math.min(numPages - 1, page + 1));
    };

    // Pager to put at the top and bottom of the table along with a control for
    // setting the number of items per page. The 'top' flag is used to
    // differentiate the two groups of radio buttons so the correct one can be
    // selected at the top and bottom.
    const SalesTablePager = ({top}) => <Row xs={3}>
        {/* Empty column to center the pager */}
        <Col></Col>
        {/* The pager */}
        <Col>
            <Container>
                <Row className="sales-table-pager" xs={3}>
                    <Col md="auto">
                        <Button variant="primary" size="sm"
                            disabled={page === 0}
                            onClick={pageLeft}
                        >
                            <Octicon icon={ChevronLeft}/>
                        </Button>
                    </Col>
                    <Col md="auto">
                        Page<br/>{page + 1}/{numPages}
                    </Col>
                    <Col md="auto">
                        <Button variant="primary" size="sm"
                            disabled={page === numPages - 1}
                            onClick={pageRight}
                        >
                            <Octicon icon={ChevronRight}/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Col>
        {/* Radio buttons to select number of cars per page */}
        <Col>
            Results per page: <ToggleButtonGroup type="radio"
                name={"per-page-control-" + top ? "top" : "bottom"}
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(e)}
            >
                {itemsPerPageOptions.map(amt => <ToggleButton
                    size="sm"
                    variant="outline-primary"
                    value={amt}
                    key={amt}
                    className="per-page-button"
                >
                    {amt}
                </ToggleButton>)}
            </ToggleButtonGroup>
        </Col>
    </Row>;

    return <Container>
        <SalesTablePager top/>
        <Row>
            <Table striped bordered className="sales-table">
                <thead>
                    <tr>
                        <th onClick={() => sortClick(ColSorts.DATE)}>
                            Date&nbsp;{sortIcon(ColSorts.DATE)}
                        </th>
                        <th onClick={() => sortClick(ColSorts.CAR)}>
                            Vehicle&nbsp;{sortIcon(ColSorts.CAR)}
                        </th>
                        <th onClick={() => sortClick(ColSorts.STAFF)}>
                            Account&nbsp;manager&nbsp;{sortIcon(ColSorts.STAFF)}
                        </th>
                        <th onClick={() => sortClick(ColSorts.PRICE)}>
                            Sale&nbsp;price&nbsp;{sortIcon(ColSorts.PRICE)}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortPurchases(purchases, sort, desc)
                        .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
                        .map(p => <tr key={p.purchase_id}>
                            <td>{getPurchaseDate(p)}</td>
                            <td>{getCarInfo(p)}</td>
                            <td>{getAcctManagerName(p.acct_manager, staff)}</td>
                            <td>{carPriceInfo(p)}</td>
                        </tr>)
                    }
                </tbody>
            </Table>
        </Row>
        <SalesTablePager/>
    </Container>;
}

// ---------------
// --- Helpers ---
// ---------------

const DATE_TRUNC = "YYYY-MM-DD".length;
function getPurchaseDate(purchase)
{
    return purchase.date_of_purchase.slice(0, DATE_TRUNC);
}

function getCarInfo(purchase)
{
    const name = `${purchase.make} ${purchase.model}`.trim();
    if (purchase.year)
        return name + ` (${purchase.year})`;
    else
        return name;
}

function getAcctManagerName(userID, staff)
{
    if (staff.has(userID))
    {
        return `${staff.get(userID).firstname} ${staff.get(userID).lastname}`
            + ` (${staff.get(userID).username})`;
    }
    else
    {
        return "(None)";
    }
}

function carPriceInfo(purchase)
{
    if (purchase.bid)
    {
        return <span>&yen;{commaNumbers(
            purchase.bid.sold_for ?? purchase.bid.amount
        )}</span>;
    }
    else
    {
        return <span>
            (Unknown)
            <OverlayTrigger
                overlay={<Tooltip>No bid exists for this car, it may have been
                    sold outside of auction</Tooltip>}
            >
                <sup>[?]</sup>
            </OverlayTrigger>
        </span>;
    }
}

function sortPurchases(purchases, sort, desc)
{
    const dateSort = (p1, p2) => {
        return p1.date_of_purchase.localeCompare(p2.date_of_purchase);
    };

    const carSort = (p1, p2) => {
        const make1 = p1.make ?? "", make2 = p2.make ?? "";
        const model1 = p1.model ?? "", model2 = p2.model ?? "";
        const year1 = p1.year ?? "", year2 = p2.year ?? "";
        if (make1 === make2)
        {
            if (model1 === model2)
                return year1 < year2 ? -1 : +(year1 > year2);
            return model1.localeCompare(model2);
        }
        return make1.localeCompare(make2);
    };

    const staffSort = (p1, p2) => {
        if (p1.acct_manager === p2.acct_manager)
            return 0;
        if (p1.acct_manager === null)
            return -1;
        if (p2.acct_manager === null)
            return 1;
        return p1.acct_manager_name.localeCompare(p2.acct_manager_name);
    };

    const priceSort = (p1, p2) => {
        const price1 = p1.bid?.sold_for ?? p1.bid?.amount ?? -1;
        const price2 = p2.bid?.sold_for ?? p2.bid?.amount ?? -1;
        return price1 < price2 ? -1 : +(price1 > price2);
    };

    // Create a copy so we don't mutate the table's `purchases` prop being
    // passed to us. Then use sort/reverse instead of toSorted/toReversed so we
    // don't have to make two copies.
    const sorted = Array.from(purchases);
    switch (sort)
    {
    case ColSorts.DATE:
        sorted.sort(dateSort);
        break;
    case ColSorts.CAR:
        sorted.sort(carSort);
        break;
    case ColSorts.STAFF:
        sorted.sort(staffSort);
        break;
    case ColSorts.PRICE:
        sorted.sort(priceSort);
        break;
    }
    if (desc)
        sorted.reverse();
    return sorted;
}
