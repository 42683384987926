import React from "react";
import { Form } from "react-bootstrap";
import "./QuizStyles.css";

/**
 * @typedef {Object} Answer
 * @property {number} answer_id
 * @property {number} question_id
 * @property {string} answer_text
 * @property {boolean} adds_line_item
 * @property {string} line_item_type
 * @property {string} line_item_detail
 * @property {number} line_item_amount
 */

/**
 * @param {Object} props
 * @param {Answer} props.answer
 * @param {Object | null} props.autofill user's previous answer to this question
 */
export default function QuizAnswer({answer, autofill}) {
    // see if user previously selected this answer
    let checked = false;
    if (typeof(autofill) != "undefined" && autofill != null) {
        // user didn't choose text input, and chose this option
        checked = autofill.used_text_input === false &&
            autofill.a_template_id == answer.answer_id;
    }
    return <Form.Check className="quizRadio"
        id={"id_" + answer.answer_id}
        name={"q_" + answer.question_id}
        value={"a_" + answer.answer_id}
        type="radio"
        required
        label={answer.answer_text}
        defaultChecked={checked}
    />;
}
