/**
 * This component is for the "Status" column in a MyCarRow (my_car_row.js).
 * See `documentation/purchase_states.md` for all states that will be displayed
 * here.
 */

import React, {Component} from "react";
import {Link} from "react-router-dom";
import Octicon, {Alert} from "@githubprimer/octicons-react";

import * as Status from "./car_status";

/**
 * @typedef {Object} InvoiceObj this is incomplete, only has fields used here
 * @property {string | null} due_on_date
 * @property {number} amount unpaid amount
 * @property {string} invoice_id
 * @property {boolean} purchase_complete
 * @property {boolean} released_to_client
 */

/**
 * @typedef {Object} PurchaseObj this is incomplete, only has fields used here
 * @property {string} purchase_id
 * @property {string} vehicle_id
 * @property {InvoiceObj[]} invoices
 * @property {string | null} arrival_estimate
 * @property {string | null} date_of_purchase
 * @property {string | null} documents_sent
 * @property {string | null} export_date
 * @property {string | null} export_estimate
 * @property {string} make
 * @property {string} model
 * @property {string | null} ship_port
 * @property {number | null} quiz_required
 * @property {string | null} vessel_name
 * @property {number} year
 */

/**
 * @typedef {Object} StatusProps
 * @property {PurchaseObj} purchase
 */

export default class CarStatusDisplay extends Component {
    /**
     * @param {StatusProps} props
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null,
            awaitingReport: null
        };
    }

    componentDidMount() {
        Status.awaitingReportAsync(this.props.purchase.purchase_id)
            .then(
                awaiting => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: null,
                        awaitingReport: awaiting
                    });
                },
                reason => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: reason,
                        awaitingReport: null
                    });
                }
            );
    }

    /**
     * Return a Link component containing an Alert Octicon, linking the user to
     * a page where their action is required.
     * @param {string} page the page to link to. A leading and trailing '/' are
     * expected. Ex: /purchase_detail/
     * @param {string} message the message to put inside the link, beside the
     * Alert icon.
     */
    alertLink(page, message) {
        return <Link target="_blank" rel="noreferrer"
            to={page + this.props.purchase.purchase_id}
        >
            <Octicon icon={Alert}/> {message}
        </Link>;
    }

    /**
     * Return true if the status column should display something related to the
     * status of the vehicle report. That is, return true if we are still
     * loading, there was an error, or we finished loading and are awaiting a
     * report. This helper simplifies the else-if ladder in the render function.
     */
    showReportStatus() {
        return this.state.loading
            || this.state.error !== null
            || this.state.awaitingReport;
    }

    // Return the HTML to go inside the <td></td> tag for the Status column
    render() {
        /**
         * What to display (refer to documentation/purchase_states.md):
         * - if there's a quiz required, show that
         * - else if there are invoices requiring payment, show that
         * - else if there are unreleased invoices, show "Creating invoice"
         * - else if the customer requested a report and we are awaiting it,
         *   show "Awaiting report"
         * - at this point the car is ready to ship
         * - while the car is awaiting shipping, two events can happen in either
         *   order: the car being shipped, and the document package being sent
         * - there is currently no way to differentiate between a car being
         *   on the boat and the car having arrived
         */

        // Note about order: the flowchart puts 'creating invoice' before
        // 'payment required' but this code checks for them in the opposite
        // order so the status which requires user action is shown to the user
        // first (e.g. if one invoice is released and another isn't).

        /** @type {PurchaseObj} */
        const purchase = this.props.purchase;
        if (Status.quizRequired(purchase)) {
            return this.alertLink("/purchaseQuiz/", "Incomplete quiz");
        }
        else if (Status.paymentRequired(purchase)) {
            return this.alertLink("/purchase_detail/", "Payment required");
        }
        else if (Status.creatingInvoice(purchase)) {
            return "Creating invoice";
        }
        // Report display could be error/loading until it's fetched, or it could
        // be fetched and we need to display that we're awaiting a report
        else if (this.showReportStatus()) {
            if (this.state.loading) {
                return "Loading...";
            }
            else if (this.state.error !== null) {
                return "Error: " + this.state.error;
            }
            else {
                return "Awaiting report";
            }
        }
        else {
            const shippedStr = Status.awaitingShipping(purchase) ?
                "Awaiting shipping" : "Shipping";
            const docsStr = Status.documentsSent(purchase) ?
                "Documents sent" : "Documents not sent";
            return <span>{shippedStr}<br/>{docsStr}</span>;
        }
    }
}
