import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";

import {
    Container, Row, Col, Card, FormControl, FormGroup, FormLabel, FormText
} from "react-bootstrap";

import { setBidList } from "./actions/profile_actions";
import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";
import BidList from "./dashboard-components/CurrentBids";

class BidManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: ""
        };
    }

    filterChange(e) {
        console.log(e.target.value);
        this.setState({...this.state, filter: e.target.value ?? ""});
    }

    render() {
        return <Container className="wideContainer">
            <PCANav currentPage="#/dashboard" />
            <DashNav  which="#/BidManager"/>
            <Container>
                <div className="whiteTitle">
                    Bid Manager
                </div>
                <Container className="whiteBackground dropShadow wideContainer"
                    style={{"paddingTop": "10px", "paddingBottom": "10px"}}
                >
                    <Container className="blueBorderContainer" fluid
                        style={{padding: 0, marginBottom: "10px"}}
                    >
                        <Row><Col>
                            <Card.Header className="italicTitle">
                                My Bids
                            </Card.Header>
                        </Col></Row>
                        <FormGroup as={Row} controlId="bidFilter"
                            style={{margin: "0.5rem"}}
                        >
                            <Col componentclass={FormLabel} md="auto">
                                <strong>Filter</strong>
                            </Col>
                            <Col md="auto">
                                <FormControl size="sm"
                                    onChange={e => this.filterChange(e)}
                                    aria-describedby="filterHelpText"
                                />
                            </Col>
                            <Col componentclass={FormText} md="auto"
                                id="filterHelpText"
                                className="text-muted"
                            >
                                Search for car name or auction house
                            </Col>
                        </FormGroup>
                        <Row><Col>
                            <BidList who={-1} filter={this.state.filter}/>
                        </Col></Row>
                    </Container>
                </Container>
            </Container>
        </Container>;
    }
}

// These are here to shut up the linter.
const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(BidManager);
