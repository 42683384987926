import React from "react";
import {
    Row, Col,
    Card,
    ListGroup,
    Button,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {Link} from "react-router-dom";
import {settings} from "../settings";

import TranslationModal from "./translation_modal/translation_modal";
import TranslationLookupItem from "./translation_lookup/TranslationLookupItem";

export default function TranslationLookup() {
    const [results, setResults] = React.useState(null);
    const [error, setError] = React.useState(null);
    // the text that was searched for to get the currently-displayed results
    // (used by TranslationLookupItem)
    const [searchedText, setSearchedText] = React.useState("");

    function doSearch() {
        const searchText = document.querySelector("#trans_search_input").value;
        setSearchedText(searchText);
        const encoded = window.btoa(searchText);
        const url = `${settings.api_server}/translations/search?query=${encoded}`;
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve(data.results)
                : Promise.reject(data.message)
            )
            .then(
                data => {
                    setResults(data);
                    setError(null);
                },
                reason => {
                    setResults(null);
                    setError(reason);
                }
            );
    }

    // keydown handler for input group: when enter is pressed, do the search
    function keyDown(e) {
        if (e.key === "Enter")
            doSearch();
    }

    return <Card>
        <Card.Header>
            <Card.Title>
                Translation Lookup
                <Link to="/view_translations" style={{
                    display: "block",
                    float: "right"
                }}>
                    View all translations
                </Link>
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <Row>
                <Col md={4}>
                    Search for text:
                </Col>
                <Col md={8}>
                    <InputGroup onKeyDown={keyDown}>
                        <FormControl id="trans_search_input"/>
                        <InputGroup.Append>
                            <Button
                                size="sm"
                                variant="outline-success"
                                onClick={doSearch}
                            >
                                Search
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <LookupResults results={results} error={error} searchText={searchedText}/>
            </Row>
        </Card.Body>
        <TranslationModal/>
    </Card>;
}

/**
 * @typedef {Object} Translation
 * @prop {number} translation_id
 * @prop {string} vehicle_id
 * @prop {string} main stringified JSON with custom translation fields
 * @prop {string} description description of the car
 */

/**
 * @typedef {Object} TranslationResult
 * @prop {string[]} standard_fields
 * @prop {string[]} custom_fields
 * @prop {Translation} translation
 */

/**
 * @param {Object} props
 * @param {Object<number, TranslationResult> | null} props.results
 */
function LookupResults({results, error, searchText}) {
    if (results === null) {
        // if there's no error either, we display nothing, which works out
        return error;
    }
    else {
        let listContent = <ListGroup.Item>No results</ListGroup.Item>;
        const entries = Object.entries(results);
        if (entries.length > 0) {
            listContent = entries.map(([id, tr]) => <TranslationLookupItem
                key={id}
                searchText={searchText}
                translation={tr}
            />);
        }
        return <ListGroup
            variant="flush"
            style={{maxHeight: "500px", overflow: "auto", marginTop: "10px"}}
        >
            {listContent}
        </ListGroup>;
    }
}
