import React, { Component } from "react";

import { settings } from "../../settings";
import BidLevels from "./BidLevels";

export default class GlobalBidLimits extends Component {
    render () {
        return <BidLevels
            fetchURL={settings.api_server + "/bids/limits"}
            updateURL={settings.api_server + "/bids/limits/update"}
        />;
    }
}
