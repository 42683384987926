import React, { Component } from "react";
import { settings } from "../settings";

export default class InlandTransportInfo extends Component {
    constructor () {
        super();

        this.state = { message: null };
    }

    componentDidMount () {
    // load the data
        const url = settings.api_server + "/bids/inland_transport";

        // name change stuff is vestigial, or for future. Who knows.
        fetch(url, {
            credentials: "include",
            method: "GET"
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.text();
            })
            .then(function (data) {
                this.setState({ message: data });
            }.bind(this));
    }

    render () {
        return <p dangerouslySetInnerHTML={{ __html: this.state.message }} />;
    }
}
