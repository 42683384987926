import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

/*
 * prints how long it has been since the passed "epoch"
 * Refreshes every second
 */
class Since extends Component {
    constructor () {
        super();
        this.state = { message: "Never" };
        this.calcTime = this.calcTime.bind(this);
    }

    componentDidMount () {
    // start the timer
        this.refresh = setInterval(this.calcTime, 1000);
    }

    componentWillUnmount () {
    // stop the timer
        clearInterval(this.refresh);
    }

    calcTime () {
        const since = moment(this.props.epoch).fromNow();
        this.setState({ message: since });
    }

    render () {
        if (this.props.epoch === null) {
            return null;
        }
        return this.state.message;
    }
}
Since.propTypes = {
    epoch: PropTypes.object // a date type
};
export default Since;
