const setMessage = function (message) {
    return {
        type: "SET_MESSAGE",
        message
    };
};
const clearMessage = function () {
    return {
        type: "CLEAR_MESSAGE"
    };
};

const setPopup = function (message) {
    return {
        type: "SET_POPUP",
        message
    };
};
const clearPopup = function () {
    return {
        type: "CLEAR_POPUP"
    };
};

const setMaintenance = function (message) {
    return {
        type: "SET_MAINTENANCE",
        message
    };
};

export { setMessage, clearMessage, setPopup, clearPopup, setMaintenance };
