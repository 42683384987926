import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// there is no offline content, so...
// TODO remove this any time after Feb 20, 2021 - it should have cleaned up all the crap out there
// by then.
unregister();
// registerServiceWorker(); // see note in page
