import React from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Octicon, {
    TriangleUp,
    TriangleDown
} from "@githubprimer/octicons-react";

import { settings } from "../settings";

import PCANav from "../dashboard-components/navbar";
import AdminOnly from "../dashboard-components/AdminOnly";
import LoadingError from "../widgets/LoadingError";
import LoadingSpinner from "../widgets/LoadingSpinner";
import UserLookup from "../admin_views/UserLookupTool";
import DateRangePicker from "../widgets/DateRangePicker";
import { prettyDateTime } from "../functions";
import commaNumbers from "../widgets/commaNumbers";

/**
 * @typedef {Object} BidAdminDict The part of the of the BidInfo returned by the
 * backend which is from the bids table.
 * @prop {number} bid_id
 * @prop {number} customer_id
 * @prop {number} amount
 * @prop {string} vehicle_id
 * @prop {boolean} is_open
 * @prop {boolean} sold
 * @prop {number} sold_for
 * @prop {string} description
 * @prop {string} private_note
 * @prop {string} customer_note
 * @prop {boolean} confirmed
 * @prop {boolean} won
 * @prop {string} auction_at
 * @prop {string} bid_created
 */
/**
 * @typedef {Object} UserInfo Basic user info attached to each bid.
 * @prop {number} user_id
 * @prop {string} username
 * @prop {string} firstname
 * @prop {string} lastname
 */
/**
 * @typedef {Object} BidInfo Info for each bid returned by backend, including
 * info about the bid itself as well as info about the customer, and the ID of
 * the related purchase (if any).
 * @prop {BidAdminDict} bid
 * @prop {UserInfo} user
 * @prop {string | null} purchase_id
 * @prop {moment.Moment} auction_date_moment bid.auction_at parsed by Moment.
 * This property isn't returned by the backend, we add it when filtering the
 * results so we don't have to repeatedly parse the date string.
 */

// Enumeration of table columns we can sort by
const SortCol = Object.freeze({
    AUCTION_DATE: 0,
    VEHICLE: 1,
    CUSTOMER: 2,
    AMOUNT: 3,
});

// Functions used to sort table rows
const SortPredicates = Object.freeze({
    [SortCol.AUCTION_DATE]: (a, b) =>
        a.auction_date_moment - b.auction_date_moment,

    [SortCol.VEHICLE]: (a, b) =>
        (a.bid.description ?? "").localeCompare(b.bid.description),

    [SortCol.CUSTOMER]: (a, b) => {
        const nameA = a.user.firstname + " " + a.user.lastname;
        return nameA.localeCompare(b.user.firstname + " " + b.user.lastname);
    },

    [SortCol.AMOUNT]: (a, b) =>
        a.bid.amount - b.bid.amount,
});

/**
 * An admin-only page for viewing all bids, and filtering based on date, bidder
 * and the bid's notes.
 */
export default function ViewAllBids()
{
    // data from backend
    const [bidData, setBidData] = React.useState(null);
    const [error, setError] = React.useState(null);
    // date picker values
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    // user ID selected with UserLookupTool
    const [lookupUserID, setLookupUserID] = React.useState(null);

    // Fetch bids on mount
    React.useEffect(() => {
        const url = `${settings.api_server}/bids/admin/all`;
        fetch(url, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText))
            .then(data => data.success ?
                data.results
                : Promise.reject(data.message))
            .then(
                results => {
                    setBidData(results);
                    setError(null);
                },
                reason => {
                    setBidData(null);
                    setError(reason);
                }
            );
    }, []);

    // Callbacks for date range picker
    const onStartDate = React.useCallback((start) => {
        // don't accept strings, only valid Moments
        if (!start || typeof(start) === "string")
            setStartDate(null);
        else
            setStartDate(start.tz("Asia/Tokyo").startOf("day"));
    }, []);
    const onEndDate = React.useCallback((end) => {
        // don't accept strings, only valid Moments
        if (!end || typeof(end) === "string")
            setEndDate(null);
        else
            setEndDate(end.tz("Asia/Tokyo").endOf("day"));
    }, []);

    let content = null;
    if (bidData === null)
    {
        if (error)
        {
            content = <LoadingError
                message={"Failed to fetch bids: " + error}
            />;
        }
        else
        {
            content = <LoadingSpinner/>;
        }
    }
    else
    {
        const filteredResults = bidData
            // parse auction date for filtering and sorting
            .map(b => ({
                ...b,
                auction_date_moment: moment.tz(
                    b.bid.auction_at, "Asia/Tokyo"
                ),
            }))
            // filter to the selected user
            .filter(b => (!lookupUserID || b.bid.customer_id == lookupUserID))
            // filter to selected date range
            .filter(b => (!startDate || b.auction_date_moment >= startDate))
            .filter(b => (!endDate || b.auction_date_moment <= endDate));
        content = <BidsTable items={filteredResults}/>;
    }

    return <div>
        <AdminOnly/>
        <PCANav/>
        <Container className="wideContainer">
            <Container>
                <h2 className="whiteTitle">All Bids</h2>
            </Container>
            <Container className="whiteBackground dropShadow" style={{
                paddingTop: "15px"
            }}>
                <Row style={{marginBottom: "15px"}}>
                    <Col md={8}>
                        <DateRangePicker
                            onStartChanged={onStartDate}
                            onEndChanged={onEndDate}
                            startValue={startDate}
                            endValue={endDate}
                            startPlaceholder="Start auction date"
                            endPlaceholder="End auction date"
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            clearButtonVariant="outline-primary"
                        />
                    </Col>
                    <Col md={4}>
                        Requested by:
                        {/* Callback is supposed to take an ID and name but we
                            only need an ID. Include a clear button so we have
                            a way to undo filtering by user. */}
                        <UserLookup
                            chosenCallback={setLookupUserID}
                            clearButton
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {content}
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>;
}

/**
 * The table which displays all bids. The given bids are expected to already be
 * filtered, but this table will take care of sorting them.
 * @param {Object} props
 * @param {BidInfo[]} props.items
 */
function BidsTable({items})
{
    const [sortCol, setSortCol] = React.useState(SortCol.AUCTION_DATE);
    const [descending, setDescending] = React.useState(true);

    // Sort the table when a <th> is clicked
    const sortTable = React.useCallback((col) => {
        if (sortCol === col)
        {
            setDescending(!descending);
        }
        else
        {
            setDescending(true);
            setSortCol(col);
        }
    }, [sortCol, descending]);

    // return a <td> for the header, which sorts the table when clicked
    const tableCol = (name, col) => <td
        onClick={() => sortTable(col)}
        style={{cursor: "pointer"}}
    >
        {name}{" "}{col == sortCol &&
            <Octicon icon={descending ? TriangleDown : TriangleUp}/>
        }
    </td>;

    // use new array so we don't mutate our props
    const sortedItems = items.toSorted(SortPredicates[sortCol]);
    if (descending)
        sortedItems.reverse();

    return <Table striped bordered size="sm">
        <thead>
            <tr style={{fontWeight: "bold"}}>
                {tableCol("Auction Date", SortCol.AUCTION_DATE)}
                {tableCol("Vehicle", SortCol.VEHICLE)}
                {tableCol("Customer", SortCol.CUSTOMER)}
                {tableCol("Bid Amount", SortCol.AMOUNT)}
                <td>Purchase</td>
            </tr>
        </thead>
        <tbody>
            {sortedItems.map(b => <BidItem
                data={b}
                key={b.bid.bid_id}
            />)}
        </tbody>
    </Table>;
}

/**
 * A row in the BidsTable. When clicked, it links to the car's auction page
 * (except for the customer column, which links to the user's page).
 * @param {Object} props
 * @param {BidInfo} props.data
 */
function BidItem({data})
{
    return <tr>
        {tdCarLink(
            data.bid.vehicle_id,
            prettyDateTime(data.bid.auction_at, true, false)
        )}
        {tdCarLink(
            data.bid.vehicle_id,
            data.bid.description
        )}
        {tdUserLink(
            data.user.user_id,
            data.user.firstname + " " + data.user.lastname
        )}
        {tdCarLink(
            data.bid.vehicle_id,
            <>&yen;{commaNumbers(data.bid.amount)}</>
        )}
        <td>{data.purchase_id &&
            <Button href={`#/purchase_detail/${data.purchase_id}`} size="sm">
                View Purchase
            </Button>
        }</td>
    </tr>;
}

// helper for BidItem
function tdCarLink(vehicle_id, node)
{
    // to maximize clickable area, remove the padding on the <td>s and put it in
    // the <div>s instead
    return <td style={{padding: "0px"}}>
        <Link to={`auction_car/${vehicle_id}`} className="nostyle"
            target="_blank" rel="noreferrer"
        >
            <div style={{padding: "0.3rem"}}>
                {node}
            </div>
        </Link>
    </td>;
}

// helper for BidItem
function tdUserLink(user_id, node)
{
    // to maximize clickable area, remove the padding on the <td>s and put it in
    // the <div>s instead
    return <td style={{padding: "0px"}}>
        <Link to={`user_detail/${user_id}`} className="nostyle"
            target="_blank" rel="noreferrer"
        >
            <div style={{padding: "0.3rem"}}>
                {node}
            </div>
        </Link>
    </td>;
}
