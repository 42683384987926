import React from "react";
import { settings } from "../../settings";
import { Button, FormControl, InputGroup } from "react-bootstrap";

/**
 * @typedef {Object} DocInfo
 * @prop {string} url
 * @prop {string} desc
 * @prop {string} added_time
 * @prop {boolean} is_vehicle_report
 */

/**
 * This component is the <td> which displays the document's description and
 * allows it to be changed.
 * @param {Object} props
 * @param {DocInfo} props.doc
 */
export default function DocumentDesc({doc}) {
    // Are we editing the description? If true, render a text input and a Submit
    // button. If false, render the description and an Edit button.
    const [isEditing, setIsEditing] = React.useState(false);
    // Like with the Vehicle Report toggle in DocumentRow, we'll store the
    // description locally so we don't have to re-fetch after changing it.
    const [docDesc, setDocDesc] = React.useState(doc.desc);
    React.useEffect(() => {
        setIsEditing(false);
        setDocDesc(doc.desc);
    }, [doc]);

    const inputRef = React.useRef(null);

    function submitDesc() {
        const newVal = inputRef.current.value;
        fetch(settings.api_server + doc.url, {
            method: "PATCH",
            credentials: "include",
            body: JSON.stringify({desc: newVal}),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(body => body.success ?
                Promise.resolve()
                : Promise.reject(body.message)
            )
            .then(
                () => setDocDesc(newVal),
                error => console.log("Error setting description: " + error)
            )
            .finally(() => setIsEditing(false));
    }

    if (isEditing) {
        return <td>
            <InputGroup size="sm">
                <FormControl defaultValue={docDesc} ref={inputRef}/>
                <InputGroup.Append>
                    <Button variant="outline-danger"
                        onClick={() => setIsEditing(false)}
                    >
                        Cancel
                    </Button>
                    <Button variant="outline-success"
                        onClick={submitDesc}
                    >
                        Submit
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </td>;
    }
    else {
        return <td>
            {docDesc}
            <Button size="sm"
                onClick={() => setIsEditing(true)}
                style={{float: "right", marginRight: "2px"}}
            >
                Edit
            </Button>
        </td>;
    }
}
