/*
 * A place for settings to live
 */
import configData from "./config.json";

let data = null;
let store = null;

function set_store (theStore) {
    if (data !== null) { data.store = theStore; }
    store = theStore;
}

function fetch_or_create_settings () {
    if (!data) {
    // default settings
        data = {};
        data.api_server = ""; // API server should be the same system

        data.make_placeholder = "--All Makes--";
        data.model_placeholder = "--All Models--";
        data.trans_placeholder = "--All Transmissions--";
        data.gear_placeholder = "--Any number of Gears--";
        data.drivetrain_placeholder = "--Drivetrain--";

        // empty user profile
        data.new_profile = {
            username: "",
            firstname: "",
            lastname: "",
            isvip: false,
            acct_manager: null,
            acct_manager_name: "",
            address: "",
            phone_number: "",
            ship_port: "",
            ship_method: "",
            ship_to_country: "",
            ship_notify: -10
        };

        // default state
        // These are moving to their respective reducers
        data.purchasesDefaultState = { purchaseList: [], purchasesLoaded: false };
        data.viewUserDefaultState = { gl_loaded: false, user_id: -1, line_items: [], profile_loaded: false, profile: {}, lastUpdate: 0 };
        data.alert_defaultState = { message: null, popup: null, maintenance: null, maintenanceLoaded: false };
        data.searchHistory_defaultState = { total_auction: "loading", makes: [], make_model: [], make_selected: data.make_placeholder, models_selected: null, start_year: "", end_year: "", fetching: false, results: null, min_grade: "", odo_min: "", odo_max: "", chassis: "", engine_min: "", engine_max: "", trans: "", gears: null, auction: "", start_date: "", end_data: "", sortColumn: 3, page: 0, auctionHouseList: [], auctionHouseLoaded: false, auctionHouseLastUpdate: 0, showAuctionHouses: false, showSearch: true, baseinfoLastUpdate: 0 };
        data.auction_car_defaultState = { data: null, loading: true, translation_loaded: false, translation: null, bid_loaded: false, has_bid: false, bid_amount: false, is_cancelled: false, carIsBiddable: false, bidMessage: null, watch_loaded: false, id: null, failure: false, translation_completed: false, hasRequestedImages: false, requestedImagesLoaded: false, requestedImageList: [] };
        data.invoice_defaultState = { close_invoice_id: null, showCloseModal: false, purchase_id: null, invoice_data: {}, last_invoice_update: 0, showAddLineItemModal: false, add_line_item_invoice_id: -1, release_invoice_id: null, showReleaseModal: false };
        data.make_model_translation_defaultState = { make_translations: [], model_translations: [], loaded: false };
        data.notify_party_defaultState = { parties: [], chosen: 0, name: "", address: "", note: "", email: "", phone: "", showAdd: false, dropdownSelection: null };
        data.fx_defaultState = { fx_rates: [], loaded: false };
        data.tips_defaultState = { tips: [], loaded: false };
        data.default_line_items_defaultState = { line_items: [], loaded: false };
        data.config_items_defaultState = { config_items: [], loaded: false };
        data.user_bid_modal_defaultState = { show: false, message: null, showUpdate: false, fetching: false, amount: "", comment: "", showConfirmBid: false, showConfirmUpdate: false, showConfirmCancel: false };
        data.questions_defaultState = { question_list_loaded: false, question_list: null, count: "loading", show_thread_id: -1, fetching_thread: false, thread_loaded: false, thread_data: null, showAskModal: false };
        data.previous_auctions_defaultState = { loaded: false, message: null, auctions: null };
        data.all_purchases_defaultState = { loaded: false, purchases: [], lastUpdate: 0, showAddPayment: false, addPaymentPurchaseID: null, addPaymentInvoiceID: null };
        data.news_items = { news_items: [] };
        data.store = store;

        // for the search screen, and for lookups
        // Other is left off
        data.countries = ["Japanese", "German", "Italian", "British", "American", "Other Euro", "Other Asian"];
        data.others = "Others";
        // pretty stuff later.
        data.keys = data.countries;
        data.bins = {
            Japanese: ["MITSUBISHI", "DAIHATSU", "HONDA", "ISUZU", "TOYOTA", "SUBARU",
                "NISSAN", "LEXUS", "MAZDA", "SUZUKI", "HINO", "MITSUOKA", "INFINITI"],
            German: ["MINI", "AUDI", "BMW", "MERCEDES BENZ", "PORSCHE", "VOLKSWAGEN", "MAYBACH",
                "SMART", "BMW ALPINA", "OPEL"],
            Italian: ["ALFAROMEO", "FERRARI", "LAMBORGHINI", "LANCIA", "MASERATI", "FIAT"],
            British: ["ROLLS ROYCE", "AUSTIN", "LAND ROVER", "JAGUAR", "ASTON MARTIN", "BENTLEY", "ROVER"],
            American: ["BUICK", "CADILLAC", "CHEVROLET", "CHRYSLER",
                "DODGE", "FORD", "GM", "GMC",
                "HUMMER",
                "JEEP", "LINCOLN", "MERCURY", "TESLA"],
            "Other Euro": ["VOLVO", "SAAB", "RENAULT", "PEUGEOT", "CITROEN"],
            "Other Asian": ["HYUNDAI", "KIA"]
        };
        data.knownMakes = [];
        for (let i = 0; i < data.countries.length; i++) {
            data.knownMakes = data.knownMakes.concat(data.bins[data.countries[i]]);
        }

        data.api_server = configData.API_SERVER;
        data.recaptchaKey = configData.RECAPTCHA;
    }
    return data;
}

const settings = fetch_or_create_settings();

export { settings, set_store };
