import React, { Component } from "react";
import "./App.css";
import "./my_cars_views/MyCars.css";
import { connect } from "react-redux";

import {
    Container, Table, Spinner
} from "react-bootstrap";

import { settings } from "./settings";
import { setBidList } from "./actions/profile_actions";
import PCANav from "./dashboard-components/navbar";
import DashNav from "./dashboard-components/DashNav";
import MyCarRow from "./my_cars_views/my_car_row";
import MyCarCounter from "./my_cars_views/my_car_counter";
import MyCarPager, {itemsPerPage} from "./my_cars_views/my_car_pager";
import MyCarPanel from "./my_cars_views/my_car_panel";

import { awaitingShipping } from "./my_cars_views/car_status";

class MyCars extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            purchases: [],
            page: 0,
            // Last Month, This Month, Awaiting Shipping, or null
            filter: null,
            // Text in "From" field
            from: "",
            // Date object, parsed from above string
            fromDate: NaN,
            // Text in "To" field
            to: "",
            // Date object, parsed from above string
            toDate: NaN,
            // Text in "Search" field
            search: ""
        };

        // When a car is fetched, it gets sent here
        this.carCache = new Map();

        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.storeCar = this.storeCar.bind(this);
        this.radioFilter = this.radioFilter.bind(this);
        this.fromFilter = this.fromFilter.bind(this);
        this.toFilter = this.toFilter.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
    }

    componentDidMount() {
        const url = settings.api_server + "/purchaseDetail/myCars";
        fetch (url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({loading: false, purchases: data});
            }.bind(this));
    }

    prevPage() {
        const newPage = this.state.page - 1;
        this.setState({...this.state, page: newPage});
    }

    nextPage() {
        const newPage = this.state.page + 1;
        this.setState({...this.state, page: newPage});
    }

    // Update car filters, return to page 0
    updateFilters(fields) {
        this.setState({...this.state,
            page: 0,
            ...fields,
            fromDate: Date.parse(fields.from),
            toDate: Date.parse(fields.to)
        });
    }

    storeCar(carID, car) {
        this.carCache.set(carID, car);
    }

    hasCar(carID) {
        return this.carCache.has(carID) && this.carCache.get(carID) != null;
    }

    radioFilter(purchase) {
        const thisMonth = new Date().getMonth();
        const lastMonth = thisMonth == 1 ? 12 : thisMonth - 1;

        switch(this.state.filter) {
        case "Last Month":
            return new Date(purchase["date_of_purchase"]).getMonth() == lastMonth;
        case "This Month":
            return new Date(purchase["date_of_purchase"]).getMonth() == thisMonth;
        case "Awaiting Shipping":
            return awaitingShipping(purchase);
        default:
            return true;
        }
    }

    // purchase date is >= "From" filter, or filter not specified
    fromFilter(purchase) {
        return isNaN(this.state.fromDate) ||
            Date.parse(purchase.date_of_purchase) >= this.state.fromDate;
    }

    // purchase date is <= "To" filter, or filter not specified
    toFilter(purchase) {
        return isNaN(this.state.toDate) ||
            Date.parse(purchase.date_of_purchase) <= this.state.toDate;
    }

    // Return true if search field is in car's invoice
    invoiceFilter(purchase) {
        return purchase["invoices"].length > 0 &&
            purchase["invoices"][0]["invoice_id"].toLowerCase().includes(this.state.search);
    }

    // Return true if search field is in make, model, or make and model
    carNameFilter(purchase) {
        const id = purchase["vehicle_id"];
        const make = (this.hasCar(id) ?
            this.carCache.get(id)["make"] : purchase["make"]).toLowerCase();
        const model = (this.hasCar(id) ?
            this.carCache.get(id)["model"] : purchase["model"]).toLowerCase();
        
        return (make + " " + model).includes(this.state.search);
    }

    chassisFilter(purchase) {
        const id = purchase["vehicle_id"];
        const chassis = this.hasCar(id) ?
            this.carCache.get(id)["chassis_code"] : purchase["chassis_num"];
        return chassis != null && chassis.toLowerCase()
            .includes(this.state.search);
    }

    auctionFilter(purchase) {
        const id = purchase["vehicle_id"];
        return this.hasCar(id) && this.carCache.get(id)["auction_house"]
            .toLowerCase().includes(this.state.search);
    }

    portFilter(purchase) {
        return purchase["ship_port"] != null &&
            purchase["ship_port"].toLowerCase().includes(this.state.search);
    }

    vesselFilter(purchase) {
        return purchase["vessel_name"] != null &&
            purchase["vessel_name"].toLowerCase().includes(this.state.search);
    }

    // Return true if data in search field matches any of car's fields, or the
    // search field is empty
    searchFilter(purchase) {
        return this.state.search.length == 0 ||
            this.invoiceFilter(purchase) || this.carNameFilter(purchase) ||
            this.chassisFilter(purchase) || this.auctionFilter(purchase) ||
            this.portFilter(purchase) || this.vesselFilter(purchase);
    }

    render() {
        const start = this.state.page * itemsPerPage;

        // Keep filtered purchases so we can pass the list to the counter, and
        // pass length to pager (if still loading, this will be an empty array)
        const filtered = this.state.purchases
            .filter(this.radioFilter)
            .filter(this.fromFilter)
            .filter(this.toFilter)
            .filter(this.searchFilter);
        
        // If still loading or there are no results, use 1 row with message.
        // Otherwise use data
        let rows;
        if (this.state.loading) {
            rows = <tr><td colSpan="14">Loading... <Spinner variant="primary" animation="border" role="status"/></td></tr>;
        }
        else if (this.state.purchases.length <= 0) {
            rows = <tr><td colSpan="14">You have not purchased any cars</td></tr>;
        }
        else if (filtered.length <= 0) {
            rows = <tr><td colSpan="14">No cars match the current search</td></tr>;
        }
        else {
            rows = filtered.slice(start, start + itemsPerPage)
                .map(p => {
                    return <MyCarRow key={p.purchase_id} purchase={p}
                        car={this.carCache.get(p.vehicle_id)}
                        storeCar={this.storeCar} search={this.state.search}/>;
                });
        }

        return <div>
            <Container className="wideContainer">
                <PCANav currentPage="#/dashboard" />
                <DashNav which="#/MyCars" />
                <div id="myCarsRoot">
                    <div>
                        <div id="myCarsLeft">
                            <div className="whiteTitle" id="myCarsTitle">
                                My Cars
                            </div>
                            <div id="myCarsCounter">
                                <MyCarCounter purchases={filtered}/>
                            </div>
                        </div>
                    </div>
                    <div id="myCarsPager">
                        <MyCarPager count={filtered.length}
                            page={this.state.page} prevPage={this.prevPage}
                            nextPage={this.nextPage}/>
                    </div>
                    <div>
                        <MyCarPanel update={this.updateFilters}/>
                    </div>
                </div>
            </Container>
            <Container fluid className="whiteBackground dropShadow wideContainer">
                <Table striped bordered responsive size="sm" hover style={{marginBottom: 0}}>
                    <thead><tr>
                        <th>Invoice</th>
                        <th>Bought</th>
                        <th>Invoice Due</th>
                        <th>Car Name</th>
                        <th>Chassis Number</th>
                        <th>Image</th>
                        <th>Auction</th>
                        <th>Port</th>
                        <th>Export Estimate</th>
                        <th>Export Date</th>
                        <th>Arrival Estimate</th>
                        <th>Documents Sent</th>
                        <th>Vessel Name</th>
                        {/*
                        <th>Option</th> // not included for now
                        */}
                        <th>Status</th>
                    </tr></thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </Container>
        </div>;
    }
}

// These are here to shut up the linter.
const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

const mapDispatchToProps = dispatch => ({
    setBidList: (bid_list) => dispatch(setBidList(bid_list))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCars);
