
import React, { Component } from "react";
import "./App.css";

import PCANav from "./dashboard-components/navbar";

import { connect } from "react-redux";

import { settings } from "./settings";

import {
    ListGroup,
    Container,
    Button
} from "react-bootstrap";

import AddUser from "./admin_views/AddUser";

class AllUsers extends Component {
    constructor () {
        super();
        this.fetchUsers = this.fetchUsers.bind(this);
        this.handleNewUserClose = this.handleNewUserClose.bind(this);
        this.showAddUser = this.showAddUser.bind(this);

        this.state = { users: [], showNewUserModal: false };
    }

    componentDidMount () {
    // load all the question threads
        this.fetchUsers();
    }

    fetchUsers () {
        // get all users from backend
        const url = settings.api_server + "/user/getAllUsers";

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                this.setState({ ...this.state, users: data });
            }.bind(this));
    }

    handleNewUserClose () {
        this.setState({ ...this.state, showNewUserModal: false });
        // show the new user (maybe, just refresh)
        this.fetchUsers();
    }

    showAddUser () {
        this.setState({ ...this.state, showNewUserModal: true });
    }

    render () {
        // we have at least one user, so if there's none, it's loading
        if (this.state.users.length === 0) { return <p>Loading...</p>; }

        const userData = this.state.users.map(x => {
            return (
                <ListGroup.Item action href={"#/user_detail/" + x.user_id}
                    id={x.user_id} key={x.user_id}>
                    {x.firstname} {x.lastname} {x.username}
                </ListGroup.Item>
            );
        });

        return (
            <div>
                <PCANav isAdmin={this.props.isAdmin} />
                <Container>
                    <h1>All Users: <Button onClick={this.showAddUser}>Add a user</Button></h1>
                    <ListGroup>
                        {userData}
                    </ListGroup>
                    <AddUser show={this.state.showNewUserModal} closeCallback={this.handleNewUserClose} />
                </Container>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.profile.is_admin
    };
};

export default connect(mapStateToProps)(AllUsers);
